import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { MedicoService } from '../../shared/servicio/medico/medico.service';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import { AdminService } from '../services/admin/admin.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-comercial-activos',
  templateUrl: './comercial-activos.component.html',
  styleUrls: ['./comercial-activos.component.css'],
})
export class ComercialActivosComponent implements OnInit {
  /**
   * Nombre del comercial actual
   */
  commercialName;

  /**
   * Id del comercial actual
   */
  commercialID;

  /**
   * Id de la página actual
   */
  pageID;

  /**
   * Lista de médicos
   */
  surgeonList;

  /**
   * Total de páginas
   */
  totalpages;

  /**
   * Total de ítems por página
   */
  itemsSize;

  /**
   * Página actual
   */
  p;

  /**
   * Indica si se ha termiando de cargar la información
   */
  isDataLoaded;

  criterio;

  searchtext2;

  // orden ascendente y descendente de la lista
  order: number = 0;
  direction: number = 0;

  debug: boolean;

  searchTextFC: FormControl;
  criterioSelectorFC: FormControl;

  pastFilters;

  constructor(
    public _medic: MedicoService,
    public _comercial: ComercialService,
    public _ip: IpService,
    private _admin: AdminService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public _helper: HelpersService,
    private location: Location
  ) {}

  ngOnInit() {
    this.isDataLoaded = false;
    this.p = 1;
    this.commercialID = this.activatedRoute.snapshot.paramMap.get(
      'commercial_id'
    );
    this.pastFilters = history.state;
    this.getActiveSurgeonsList(this.commercialID, this.p);
  }

  getActiveSurgeonsList(commercialID, pageID) {
    this._admin.list_active_surgeons(commercialID, pageID).subscribe(
      (data) => {
        console.log('Datos de comercial');
        console.log(data);
        this.commercialName =
          data['comercial_first_name'] + ' ' + data['comercial_last_name'];
        this.surgeonList = data['activity']['info'];
        if (this.surgeonList.length > 0) {
          this.totalpages = data['activity']['page_info']['num_pages'];
          this.itemsSize = data['activity']['page_info']['total_items'];
          this.p = data['activity']['page_info']['current'];
        }
        this.isDataLoaded = true;
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  getPage(currentPage) {
    this.getActiveSurgeonsList(this.commercialID, currentPage);
  }

  ordenamiento(valor, orden) {
    this.order = valor;
    this.direction = orden;
    this.loadCommercialNextPage(this.p);
  }

  getActiveFiltersCount() {
    return Object.keys(this.pastFilters).length;
  }

  goBack() {
    if (this.getActiveFiltersCount() > 1) {
      this.router.navigate(['/admin/tracing/comerciales'], {
        queryParams: this.pastFilters,
      });
    } else {
      this.location.back();
    }
  }

  // los campos de busqueda a la api
  search_comercial(name) {
    if (name == undefined || name == '') {
      this.searchtext2 = 'none';
    }
    this.searchtext2 = name;
    this.loadCommercialNextPage(this.p);
  }

  loadCommercialNextPage(page) {
    this.isDataLoaded = false;
    if (page === undefined) {
      page = 1;
    }
    this.getActiveSurgeonsList(this.commercialID, page);
  }

  // Metodo para  controlar las exepciones cuando falla las conexiones con el
  // servidor
  manejador_errores(error) {
    if (this.debug === true) {
      console.log(error);
    } else {
      console.log('Debug no habilitado');
    }
    if (error.status === 400) {
      this._helper.modalError('¡' + error.error + '!');
    } else if (error.statusText === 'Unknown Error') {
      this._helper.modalError('¡Error de conexión con el servidor!');
    } else if (error.status === 500) {
      this._helper.modalError('¡Error del servidor!');
    } else if (error.status === 403) {
      this._helper.modalError('¡Página no encontrada!');
      this.router.navigate(['/login']);
    }
  }
}
