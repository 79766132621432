import { Component, OnInit, TemplateRef } from '@angular/core';
import { AdminService } from './../../services/admin/admin.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-registrometas',
  templateUrl: './registrometas.component.html',
  styleUrls: ['./registrometas.component.css'],
})
export class RegistrometasComponent implements OnInit {
  constructor(
    private _admin: AdminService,
    private modalService: BsModalService,
    private formbuilder: FormBuilder
  ) {}

  registrometas: any = [];
  form;
  modalRef: BsModalRef;
  search;
  ArayCamp: any = [];
  campaingName: any;
  campaingId;

  // formulaTabla: any = [];

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;
  config = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  ngOnInit() {
    this.showLoading = true;
    this.getMetaRegister();
  }

  getMetaRegister() {
    this._admin
      .registroMetas(this.page, this.search, this.campaingId)
      .toPromise()
      .then((data) => {
        console.log(data);

        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.registrometas = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  async getCampaingLogro(searchTerm: string = '') {
    const params = {
      paginate: true,
      goals: searchTerm,
    };

    try {
      // Solo realiza la solicitud si searchTerm no está vacío
      const data =
        searchTerm !== ''
          ? await this._admin.getCampaing(params).toPromise()
          : { results: [] };

      this.ArayCamp = data['results'];
    } catch (error) {
      console.log(error);
    }
  }

  onSelect2(data: Object) {
    if (data['item']['id'] === '') {
      // Se seleccionó la opción "Todas las Campañas"
      // Realizar acciones específicas o simplemente limpiar el término de búsqueda
      this.campaingName = '';
      this.campaingId = '';
      // Otras acciones si es necesario
    } else {
      // Se seleccionó una campaña específica
      this.campaingName = data['item']['name'];
      this.campaingId = data['item']['id'];
      // Otras acciones si es necesario
    }

    console.log(data);
    console.log(this.campaingName);
    // this.medicosType = '';
  }

  pageChanged(p) {
    this.page = p.page;
    this.getMetaRegister();
  }
}
