import { Component, OnInit,TemplateRef,Input,Output,EventEmitter } from '@angular/core';
import { AdminService } from '../../services/admin/admin.service';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {

  @Output() lista = new EventEmitter;
  @Input() id_banner: any;


  constructor(public admin:AdminService, private _helper: HelpersService) { }

  ngOnInit() {
  }


  delete()
  {
    this._helper.modalConfirm("¿Deseas eliminar este banner?")
    .then((willDelete) => {

        if(willDelete.value){
            this._helper.modalSuccess("Eliminado");
             this.admin.delete_banner(this.id_banner).subscribe(
              data=>{
                
                console.log(data);
                this.lista.emit(true);
              
              },
                error=>{console.log(error)
              }
            )

        }
    });
   
  }
}
