import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../shared/servicio/login/login.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token: string;

  constructor(private _login: LoginService) {}

  getToken() {
    return this._login.getToken();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Verificar si la solicitud ya tiene el encabezado `Token`
    if (!request.headers.has('Token')) {
      // Agregar el token de sesión solo si no existe un token personalizado
      this.token = this.getToken();
      if (this.token != null) {
        request = request.clone({
          setHeaders: {
            Token: 'Bearer ' + this.token
          }
        });
      }
    }

    return next.handle(request);
  }
}
