import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from './../ip/ip.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router
  ) {}
  // servicio de comunicacion con la api para loguin de la pagina
  login(username: string, password: string) {
    return this._http.post(environment.Api_login, { username, password });
  }

  changed_password(password1: string, password2: string, token: string) {
    token = localStorage.getItem('token');
    return this._http.post(environment.Api_update_medic, {
      password1,
      password2,
      token,
    });
  }

  forgot_password(email: string) {
    return this._http.post(environment.Api_forgot_password, { email });
  }

  verify_code(code: number) {
    return this._http.post(environment.Api_verify_code, { code });
  }

  forgot_change_password(code, password1, password2) {
    return this._http.post(environment.Api_change_password, {
      code,
      password1,
      password2,
    });
  }

  getToken() {
    return localStorage.getItem('token');
  }
}
