import { Component, OnInit, Input } from '@angular/core';
import { NgForm, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-update-password-personal',
  templateUrl: './update-password-personal.component.html',
  styleUrls: ['./update-password-personal.component.css'],
})
export class UpdatePasswordPersonalComponent implements OnInit {
  @Input() modalRef: BsModalRef;

  updatePass: FormGroup;
  submitted: boolean;
  preloader: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _http: HttpClient,
    public helper: HelpersService
  ) {}

  ngOnInit() {
    this.updateForm();
  }

  updateForm() {
    this.updatePass = this.formBuilder.group({
      password_old: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ0123456789 ]*'),
        ],
      ],

      password1: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ0123456789 ]*'),
        ],
      ],

      password2: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ0123456789 ]*'),
        ],
      ],
    });
  }

  get shortPass() {
    return this.updatePass.controls;
  }

  updatePassword() {
    this.preloader = true;
    if (this.updatePass.valid) {
      if (this.shortPass.password1.value === this.shortPass.password2.value) {
        let password = this.shortPass.password_old.value;
        let password1 = this.shortPass.password1.value;
        let password2 = this.shortPass.password1.value;
        this._http
          .post(environment.Api_update_password, {
            password,
            password1,
            password2,
          })
          .subscribe(
            (data) => {
              this.preloader = false;
              this.submitted = false;
              this.helper.modalSuccess('¡Contraseña actualizada!');
              this.modalRef.hide();
            },
            (error) => {
              this.preloader = false;
              this.submitted = false;
              this.helper.modalError(
                this.helper.splitErrorMessages(error.error, '<br>')
              );
            }
          );
      } else {
        this.preloader = false;
        this.helper.modalError('¡Las contraseñas no coinciden!');
      }
    } else {
      this.submitted = true;
      this.preloader = false;
    }
  }
}
