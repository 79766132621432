import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin/admin.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ver-encuestas',
  templateUrl: './ver-encuestas.component.html',
  styleUrls: ['./ver-encuestas.component.css']
})
export class VerEncuestasComponent implements OnInit {
  encuestaInfo;
  idEncuesta;
  /**
   * Arreglo que contiene un ARREGLO de puntos por cada opción de respuesta de cada pregunta
   */
  answerCountPerOption;
  /**
   * Arreglo que contiene los titulos de las preguntas
   */
  arrQuestions;
  answerSelectionList;
  /**
   * Variable booleana que indica si se muestra el loading o no
   */
  showLoading: boolean;

  constructor(
    private admin: AdminService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.showLoading = true;

    this.answerCountPerOption = [];
    this.arrQuestions = [];
    this.answerSelectionList = [];

    this.activeRoute.params.subscribe(data => {
      this.idEncuesta = data.id;
      this.loadEncuesta();
    });
  }

  /**
   * Se trae del backend las preguntas y respuestas
   */
  loadEncuesta() {
    this.admin.list_idSurvey(this.idEncuesta).subscribe((data: any) => {
      this.encuestaInfo = data;
      this.loadQuestionsInfo(data['body']);
      this.getQuestions(data['body']);
      this.getOptionsOfQuestion(data['body']);
      this.showLoading = false;
    });
  }
  /**
   * Función para obtener las opciones de cada pregunta en un array
   */
  getOptionsOfQuestion(body: any) {
    let optionsList = [];
    console.log(body);
    body.forEach(question => {
      optionsList = this.getOptionsCount(question.answers);
      this.answerSelectionList.push(optionsList);
    });
  }

  /**
   * Función que toma una lista de opciones de respuesta de una pregunta y la mete en un arreglo
   * @param options Lista de respuestas
   * @returns Un arreglo con las respuestas por pregunta
   */
  getOptionsCount(options) {
    let answerSelectedCount = [];
    options.forEach(option => {
      answerSelectedCount.push(option.answer);
    });
    return answerSelectedCount;
  }

  /**
   * Función que toma la lista de preguntas de una encuesta para calcular la
   * cantidad de votos que obtuvo en cada opción de respuesta
   * @param questions Lista de preguntas
   */
  loadQuestionsInfo(questions) {
    let answerList = [];
    questions.forEach(question => {
      answerList = this.getAnswersCount(question.answers);
      this.answerCountPerOption.push(answerList);
    });
  }

  /**
   * Función que toma una lista de opciones de respuesta y cuenta sus votos
   * @param options Lista de respuestas
   * @returns Un arreglo con la cantidad de votos por respuesta
   */
  getAnswersCount(options) {
    let answerSelectedCount = [];
    options.forEach(option => {
      answerSelectedCount.push(option.votes);
    });
    return answerSelectedCount;
  }

  /**
   * Función para obtener los nombres de las preguntas en un array
   * @param questions Array que viene del backend con las preguntas, votos, etc.
   */
  getQuestions(questions) {
    questions.forEach(question => {
      const questionTitle = question.question;
      this.arrQuestions.push(questionTitle);
    });
  }
}
