import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { HelpersService } from './../../../shared/servicio/helpers/helpers.service';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-create-associate',
  templateUrl: './create-associate.component.html',
  styleUrls: ['./create-associate.component.css'],
})
export class CreateAssociateComponent implements OnInit {
  /**
   * Contiene la información del administrador seleccionado a modificar
   */
  @Input() modalRef: BsModalRef;

  submitted = false;
  preloader = false;
  load = true;

  cityList: any = [];
  selectedCity: any;

  create: FormGroup;
  name: FormControl;
  name2: FormControl;
  email: FormControl;
  cell: FormControl;
  dni: FormControl;
  lastname: FormControl;
  lastname_2: FormControl;
  cityForm: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    private _comercial: ComercialService,
    public _helper: HelpersService
  ) {}

  ngOnInit() {
    this.listCiudades();
    this.createComercial();
  }

  listCiudades() {
    this._comercial.list_ciudades().subscribe(
      (data) => {
        console.log(data);
        this.cityList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  createComercial() {
    console.log('me ejecuto primero');
    this.create = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],
      name2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],
      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],
      lastname_2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],
      cityForm: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],
      cell: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],
      dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],
      email: [null, [Validators.required, Validators.email]],
    });
  }

  get shortCreate() {
    return this.create.controls;
  }

  clear(id) {
    if (id === 1) {
      if (this.shortCreate.cityForm.value != '') {
        this.shortCreate.cityForm.setValue(null);
      }
    }
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedCity = event.item;
  }

  createAssociate() {
    console.log(this.create);
    this.preloader = true;
    if (this.create.valid) {
      if (this.selectedCity === undefined) {
        this.preloader = false;
        this._helper.modalError('¡Debe seleccionar una ciudad!');
      } else {
        this._comercial
          .create_comercial(
            'associate',
            this.shortCreate.name.value,
            this.shortCreate.name2.value,
            this.shortCreate.lastname.value,
            this.shortCreate.lastname_2.value,
            this.shortCreate.email.value,
            this.shortCreate.cell.value,
            this.shortCreate.dni.value,
            this.selectedCity['id']
          )
          .subscribe(
            (data) => {
              console.log(data);
              this.preloader = false;
              this.submitted = false;
              this._helper.modalSuccess('¡Nuevo comercial agregado!');

              this.modalRef.hide();
            },
            (error) => {
              this.preloader = false;
              this.submitted = false;
              this._helper.modalError(
                this._helper.splitErrorMessages(error.error, '<br>')
              );
            }
          );
      }
    } else {
      this.submitted = true;
      this.preloader = false;
    }
  }
}
