import { ComercialService } from './../../shared/servicio/comercial/comercial.service';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { FormControl } from '@angular/forms';

/**
 * Chart.js
 * Gráficas
 */
import { Chart } from 'chart.js';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
  /**
   * Gráfica creada
   */
  public chart: Chart;

  showLoading;
  dataLista;
  ciudadFilter: FormControl;
  cityList;

  totalPoints;
  totalPointsArray;

  meses = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC'
  ];

  mesesNumber = {
    january: 0,
    february: 1,
    march: 2,
    april: 3,
    may: 4,
    june: 5,
    july: 6,
    august: 7,
    september: 8,
    october: 9,
    november: 10,
    december: 11
  };

  modelDate: FormControl;
  graphicsConfig;

  yearActual;

  cantidadPacientes;
  cantidadMedicos;
  puntosTotales;

  medicosRegistradosMes;
  puntosPorMes;

  dataMedicosRegistradosMes;
  dataPuntosPorMes;
  layoutPuntosPorMes;
  layoutMedicosRegistradosMes;

  selection: number;

  constructor(
    private admin: AdminService,
    private _comercial: ComercialService
  ) {}

  ngOnInit() {
    this.selection = 1;
    this.showLoading = true;
    this.totalPointsArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    this.cityList = [];

    this.modelDate = new FormControl(new Date().getFullYear());
    this.ciudadFilter = new FormControl(0);

    this.modelDate.valueChanges.subscribe(event => {
      this.clickcalendar();
    });

    this.ciudadFilter.valueChanges.subscribe(event => {
      this.clickcalendar();
    });

    this.listCiudades();
    this.clickcalendar();
  }

  onOpenCalendar(container) {
    container.yearSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('year');
  }

  /**
   * Al cambiar el chart
   */
  changeChart(number) {
    this.selection = number;
    this.loadStats();
  }
  /**
   * Año seleccionado por el usuario en el datepicker
   */
  clickcalendar() {
    this.showLoading = true;

    this.loadStats();
  }

  /**
   * Lista de ciudades en el select
   */
  listCiudades() {
    this._comercial.list_ciudades().subscribe(
      data => {
        this.cityList = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadStats() {
    var year = this.modelDate.value.toString();
    var city = this.ciudadFilter.value;

    if (year.length > 4) {
      year = year.split(' ')[3];
    }
    this.yearActual = year;

    this.admin.load_stats(year, city).subscribe(
      data => {
        console.log(data);
        // Si ya existía un chart, se destruye para crear el nuevo
        if (this.chart && this.chart !== null) {
          this.chart.destroy();
        }
        this.generateGraphics(data['medics_month'], data['points_month']);
        this.cantidadMedicos = data['medics_quantity'];
        this.cantidadPacientes = data['patients_quantity'];
        this.puntosTotales = data['total_points'];
        this.medicosRegistradosMes = data['medics_month'];
        this.puntosPorMes = data['points_month'];
        this.showLoading = false;
      },
      error => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }

  /**
   * Función que toma una lista de puntajes por mes (en desorden) y su respectivo año y
   * devuelve un arreglo ORDENADO con los puntajes obtenidos que ha sido referido por mes
   * @param scoreList Lista de puntajes desordenada
   */
  getScoresGraphicPerMonth(scoreList) {
    var pointsPerMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    Object.keys(scoreList).forEach(key => {
      if (key != 'year') {
        pointsPerMonth[this.mesesNumber[key]] = scoreList[key];
        this.totalPointsArray[this.mesesNumber[key]] += scoreList[key];
      }
    });
    return pointsPerMonth;
  }

  /**
   * Función que toma una lista de contadores de referidos por mes (en desorden) y su respectivo año.
   * @param referedPeopleCounterList Lista que contiene la cantidad de referidos por mes
   * @returns Un arreglo con la cantidad de referidos ORDENADOS por mes
   */
  getReferedCounterGraphicPerMonth(referedPeopleCounterList) {
    var referedCounter = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    Object.keys(referedPeopleCounterList).forEach(key => {
      if (key != 'year') {
        referedCounter[this.mesesNumber[key]] = referedPeopleCounterList[key];
      }
    });
    return referedCounter;
  }

  /**
   * Genera las gráficas de puntos acumulados y de personal referido
   */
  generateGraphics(montlyMedics, montlyPoints) {
    // Puntos
    const montlyMedicschart = this.getScoresGraphicPerMonth(montlyMedics);
    const montlyPointsChart = this.getScoresGraphicPerMonth(montlyPoints);

    // Se inicializa el gráfico pasandole los valores y nombres
    this.initCharts(montlyMedicschart, montlyPointsChart, this.meses);
  }

  /**
   * Inicialización del gráfico que se mostrará para mostrar los puntos ganados en el mes
   */
  initCharts(monthlyMedicschart, monthlyPointsChart, arrNombresMeses) {
    Chart.defaults.global.defaultFontColor = '#452c80';
    Chart.defaults.global.defaultFontFamily = 'TTCommonsBold';

    if (this.selection == 1) {
      // Se crea una nueva instancia de Chart con el ID
      // del canvas en el HTML
      this.chart = new Chart('chartPuntosGanadosAnual', {
        type: 'bar',
        parentInstance: this,
        data: {
          // labels: weatherDays,
          labels: arrNombresMeses,
          // Los últimos 3 meses
          datasets: [
            {
              data: monthlyPointsChart,
              label: 'Cantidad de Puntos',
              backgroundColor: '#89d3ff',
              borderColor: 'white',
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // Para no mostrar la leyenda arriba, el cual oculta las barras
            display: false
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  color: 'rgb(208, 202, 223)'
                },
                ticks: {
                  // suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                  // OR //
                  beginAtZero: true // minimum value will be 0.
                }
              }
            ]
          }
        }
      });
    } else if (this.selection == 2) {
      this.chart = new Chart('chartMedicosReferidosAnual', {
        type: 'bar',
        data: {
          // labels: weatherDays,
          labels: arrNombresMeses,
          // Los últimos 3 meses
          datasets: [
            {
              data: monthlyMedicschart,
              label: 'Cantidad de Médicos',
              backgroundColor: '#61c4ff',
              borderColor: 'white',
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // Para no mostrar la leyenda arriba, el cual oculta las barras
            display: false
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgb(208, 202, 223)'
                },
                ticks: {
                  // suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                  // OR //
                  beginAtZero: true // minimum value will be 0.
                }
              }
            ]
          }
        }
      });
    }
  }
}
