import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-menu-horizontal',
  templateUrl: './menu-horizontal.component.html',
  styleUrls: ['./menu-horizontal.component.css'],
})
export class MenuHorizontalComponent implements OnInit {
  /**
   * Controla si el menú izquierdo está activado o no
   */

  constructor(
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router
  ) {}

  ngOnInit() {
    $(document).ready(() => {
      $('#sidebarCollapse').on('click', () => {
        $('#sidebar').toggleClass('active');
      });
    });
  }

  logout() {
    this._http.get(environment.Api_logout).subscribe(
      (data) => {
        console.log('logout');
        localStorage.removeItem('token');
        localStorage.removeItem('type');
        this.router.navigate(['/']);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
