import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AsistentesRoutingModule } from './asistentes-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// NGX-BOOTSTRAP
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// NGX Bootstrap DatePicker, config y lenguaje
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
defineLocale('es', esLocale);

import { TokenInterceptor } from '../interceptors/token.interceptor';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { SharedModule } from '../shared/shared.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AsistenteListaComponent } from './components/asistente-lista/asistente-lista.component';
import { ReferraListComponent } from './components/referra-list/referra-list.component';
import { FormulacionComponent } from './components/formulacion/formulacion.component';
import { AddFormulacionComponent } from './components/formulacion/add-formulacion/add-formulacion.component';
import { PremiosComponent } from './components/premios/premios.component';
import { QrCodeModule } from 'ng-qrcode';

@NgModule({
  declarations: [DashboardComponent, AsistenteListaComponent, ReferraListComponent, FormulacionComponent, AddFormulacionComponent, PremiosComponent],
  imports: [
    CommonModule,
    AsistentesRoutingModule,
    NgxPaginationModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
    QrCodeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AsistentesModule {}
