import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private _http : HttpClient) { }

  //Datos de user
nombre:string;
rol:any;

  ngOnInit() {
    this.user();
  }

  user() {
   this._http.get(environment.Api_get_user)
        .subscribe(data => {
          console.log("datos del dashboard")
            console.log(data);
            this.nombre = data['user_name'] + ' ' + data['last_name'],
            this.rol = data['role']
           
        }, error => {
            console.log(error);
            
        });

}

}
