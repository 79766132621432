import { Component, OnInit, TemplateRef } from '@angular/core';
import { MedicoService } from './../../shared/servicio/medico/medico.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';

import swal from 'sweetalert2';

@Component({
  selector: 'app-asistentes',
  templateUrl: './asistentes.component.html',
  styleUrls: ['./asistentes.component.css'],
})
export class AsistentesComponent implements OnInit {
  modalRef: BsModalRef;
  idmedic;
  formulaTabla: any = [];
  formulaTablaDiagnostico: any = [];

  dni;
  email;
  first_name;
  last_name;
  isChecked = true; // Cambiar a `false` si el checkbox está desmarcado

  cell;

  form;

  config = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  configModalDetalle = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  configMetas = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    private medic: MedicoService,
    private modalService: BsModalService,
    private _http: HttpClient,
    public _helper: HelpersService
  ) {}

  asistentes: any = [];

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;
  product__name: string;
  product__ean_code: string;
  product__maker: string;
  provider: string;

  medicoModal;

  ngOnInit() {
    this.showLoading = true;
    this.getasistant();
  }
  getasistant() {
    this.medic
      .asistenteMedico(this.page)
      .toPromise()
      .then((data) => {
        console.log(data);
        this.limit = Math.ceil(data['count'] / 10);
        this.count = data['count'];
        this.asistentes = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.getasistant();
  }

  async AbrirModalCrearAsistente(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.configMetas);
  }

  crearAsist() {
    this.medic
      .crearAsistente(
        this.dni,
        this.email,
        this.first_name,
        this.last_name,
        this.cell
      )
      .toPromise()
      .then((data) => {
        swal({
          title:
            '<img src="assets/icons/good.png" width="80px" style="margin-top:0px;">',
          html: 'Asistente creado',
          confirmButtonText:
            '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
            'to</p>',
          confirmButtonColor: '#6eceee',
        });
        this.getasistant();
        this.modalRef.hide();
      })

      .catch((error) => {
        this._helper.modalError('Error al crear asistente');
      });
  }
  UpdateAssistant(item, newState, isChecked) {
    this.medic.changeStatusAssistant(item, newState).subscribe(
      (data) => {
        swal({
          title:
            '<img src="assets/icons/good.png" width="80px" style="margin-top:0px;">',
          html: 'Estado de asistente actualizado',
          confirmButtonText:
            '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
            'to</p>',
          confirmButtonColor: '#6eceee',
        }).then((result) => {
          // Actualizar el estado local si el checkbox está marcado
          if (isChecked) {
            item.state.id = newState;
          }

          location.reload(); // Recargar siempre después de confirmar el cambio
        });
      },
      (error) => {
        this._helper.modalError(error.error.detail);
        setTimeout(() => {
          location.reload();
        }, 3500);
      }
    );
  }

  onChange(e, item) {
    // Cambiar el estado del asistente
    item.state.id = item.state.id === 5 ? 6 : 5;

    // Llamar a UpdateAssistant con el estado actualizado
    this.UpdateAssistant(item, item.state.id, e.target.checked);
  }
}
