import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Component, OnInit, TemplateRef } from '@angular/core';
import { MedicoService } from '../../../shared/servicio/medico/medico.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
declare var $: any;
@Component({
  selector: 'app-prize',
  templateUrl: './prize.component.html',
  styleUrls: ['./prize.component.css'],
})
export class PrizeComponent implements OnInit {
  /**
   * Modals
   */
  modalRedencion: BsModalRef;
  modalCine: BsModalRef;

  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  prizes: any;
  points: any;
  tc = '';
  id = 0;
  learning = false;
  condition = false;
  preloader = false;

  /**
   * Variables para guardar los campos de los filtros
   */
  brandList;
  zoneList;
  cityList;
  sitieList;

  /**
   * Varables para guardar las id
   */
  rewardId: any;
  brandId: any;
  ZoneId: any;
  cityId: any;
  sitieId: any;

  showLoading: boolean;
  constructor(
    public _medic: MedicoService,
    private _http: HttpClient,
    public router: Router,
    public dash: DashboardComponent,
    public helper: HelpersService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.showLoading = true;
    this.prize();
    this.user();
    this.brandList = [];
    this.rewardId = '';
    this.brandId = '';
    this.ZoneId = '';
    this.cityId = '';
    this.sitieId = '';
    // this.brandFilter = new FormControl('');
  }

  openModal(term, id, template: TemplateRef<any>) {
    this.brandId = undefined;
    this.ZoneId = undefined;
    this.cityId = undefined;
    this.sitieId = undefined;
    this.brandList = [];
    this.zoneList = [];
    this.cityList = [];
    this.sitieList = [];
    this.preloader = false;
    this.tc = term;
    this.id = id;
    console.log(template);

    this.modalRedencion = this.modalService.show(template, this.config);
  }

  user() {
    this._http.get(environment.Api_get_user).subscribe(
      (data) => {
        this.points = data['points'];
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  prize() {
    this._medic.prize().subscribe(
      (data) => {
        console.log(data);
        this.prizes = data;

        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  learn(accept) {
    this.learning = accept;
  }
  conditional(accept) {
    this.condition = accept;
  }

  redimir(id, cine) {
    this.preloader = true;

    // this.sitieId = undefined;
    this._medic.getBrand(id).subscribe(
      (data) => {
        console.log(data);

        this.rewardId = id;

        this.preloader = false;
        this.learning = false;
        this.condition = false;
        this.brandList = data;

        if (data[1]) {
          this.modalCine = this.modalService.show(cine, this.config);
          this.modalRedencion.hide();

          //Llamados de los filtros
        } else {
          this.brandId = data[0];
          this.modalCine = this.modalService.show(cine, this.config);
          this.modalRedencion.hide();
          this.onSelectBrand(this.brandId);

          // this._medic.redeem(id).subscribe(
          //   res => {
          //     this.helper.modalSuccess(
          //       '¡Se ha enviado un cupón a tu correo electrónico!'
          //     );
          //     $('#term').modal('hide');
          //     this.dash.user();
          //   },
          //   error => {
          //     this.helper.modalError(
          //       this.helper.splitErrorMessages(error.error, '<br>')
          //     );
          //     console.log(error);
          //   }
          // );
        }
      },
      (error) => {
        this.preloader = false;
        this.helper.modalError(
          this.helper.splitErrorMessages(error.error, '<br>')
        );
        console.log(error);
      }
    );
    // this.preloader = false;
    // this.learning = false;
    // this.condition = false;
  }

  term(term, id) {
    this.tc = term;
    this.id = id;
    $('#term').modal('show');
  }

  async onSelectBrand(one) {
    this.preloader = true;
    console.log(one);

    this.brandId = one;

    this._medic.getZones(this.rewardId, this.brandId).subscribe((data) => {
      console.log(data);
      this.zoneList = data;
      this.preloader = false;
    });
  }

  onSelectZone(one) {
    this.preloader = true;
    this.ZoneId = one;

    this._medic
      .getCitys(this.rewardId, this.brandId, this.ZoneId)
      .subscribe((data) => {
        console.log(data);
        this.cityList = data;
        this.preloader = false;
      });
  }

  onSelecCity(one) {
    this.preloader = true;
    this.cityId = one;
    this._medic
      .getSities(this.rewardId, this.brandId, this.ZoneId, this.cityId)
      .subscribe((data) => {
        console.log(data);
        this.sitieList = data;
        this.preloader = false;
      });
  }

  onSelectSitie(one) {
    this.preloader = true;
    this.sitieId = one;
    this._medic.redeem(this.rewardId, this.brandId, this.sitieId).subscribe(
      (data) => {
        console.log(data);
        this.modalCine.hide();
        this.preloader = false;
      },
      (error) => {
        console.log(error);

        this.modalCine.hide();
      }
    );
  }
}
