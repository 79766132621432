import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {IpService} from './../ip/ip.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PacientesService {

    constructor(
        public _ip : IpService,
        private _http : HttpClient,
        public router : Router
    ) {}
    especialidad_medico() {
        const httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
        return this
            ._http
            .get(
                 environment.Api_get_medic_specialties,
                {headers: httpHeaders}
            );

    }

    create_paciente(first_name:string,last_name:string,cc_type:number,cc:number, cell : number, specialty_id : number, country_code:number=57) {


        return this._http.post(environment.Api_create_patient, {first_name,last_name,cc_type,cc,cell,specialty_id,country_code});

    }
    create_pacienteWithoutSession(
      first_name: string,
      last_name: string,
      cc_type: number,
      cc: number,
      cell: number,
      genero: string,
      city_id: string,
      email: string,
      fecha: string,
      specialty_id: number,
      country_code: number = 57,
      token: string
    ): Observable<any> {

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Token': `Bearer ${token}`
      });

      // Asegurándonos de que el payload esté correctamente formateado y ordenado
      const body = {
        cc,
        cc_type,
        cell,
        city_id,
        country_code,
        email,
        fecha,
        first_name,
        genero,
        last_name,
        specialty_id
      };

      return this._http.post(environment.api_formulario, body, { headers });
    }
  }



