import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AsistenteServicesService {
  constructor(
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router
  ) {}

  AssistanRegList(page) {
    let params = '?page=' + page;
    return this._http.get(environment.rolListaAsistente + params);
  }

  crearAsistente(
    cc_type,
    first_name,
    last_name,
    cc,
    country_code,
    cell,
    specialty_id
  ) {
    const body = {
      cc_type: cc_type,
      first_name: first_name,
      last_name: last_name,
      cc: cc,
      country_code: 57,
      cell: cell,
      specialty_id: specialty_id,
    };

    return this._http.post(environment.addReferral, body);
  }

  referralList(page) {
    let params = '?page=' + page;
    return this._http.get(environment.ReferralList + params);
  }

  especialidad_medico() {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(environment.getMedicEspecialitiesAssistant, {
      headers: httpHeaders,
    });
  }

  formulaMedic(idmedic, page) {
    let params = idmedic + '?page=' + page;
    return this._http.get(environment.formulaAssistant + params);
  }

  detalleFormulaAsistente(id) {
    let params = id;
    return this._http.get(environment.detalleFormulaAsistente + params);
  }

  products(name) {
    let params = '?name=' + name || '';
    return this._http.get(environment.ProductAssistant + params);
  }

  diagnosticos(name) {
    let params = '?name=' + name || '';
    return this._http.get(environment.diagnosticos + params);
  }

  buscarPaciente(cc) {
    let params = '?cc=' + cc || '';

    return this._http.get(environment.PatientListAssistant + params);
  }

  crearFormula(
    medic_id,
    patient_id,
    email_patient,
    description,
    diagnostic,
    formuladetail_set
  ) {
    const body = {
      medic_id: medic_id,
      patient_id: patient_id,
      email_patient: email_patient,
      description: description,
      diagnostic: diagnostic,
      formuladetail_set: formuladetail_set,
    };

    return this._http.post(environment.CreateFormulaAsistente, body);
  }

  prize() {
    return this._http.get(environment.getPrizeAssistant);
  }

  redeem(reward_id, brand_id, site_id) {
    let body = {
      reward_id: reward_id,
      brand_id: brand_id,
      site_id: site_id,
    };
    console.log(body);

    return this._http.post(environment.redemAssistant, body);
  }

  getBrand(reward_id) {
    const params = reward_id;
    return this._http.get(environment.getBrandAssistant + params);
  }

  getZones(reward_id, brand_id) {
    const params = reward_id + '/' + brand_id;
    return this._http.get(environment.getZoneAssistant + params);
  }

  getCitys(reward_id, brand_id, zone_id) {
    const params = reward_id + '/' + brand_id + '/' + zone_id;
    return this._http.get(environment.getCityAssistant + params);
  }

  getSities(reward_id, brand_id, zone_id, city_id) {
    const params = reward_id + '/' + brand_id + '/' + zone_id + '/' + city_id;
    return this._http.get(environment.getSiteAssistant + params);
  }
}
