import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css'],
})
export class LogsComponent implements OnInit {
  medic_id;

  // dato para paginacion de resultados
  page: number;
  p: number;
  totalpages: number;
  itemsPagina: any;

  log_array;

  nombreMedico;
  nombre2Medico;
  apellidoMedico;
  apellido2Medico;

  logDetails;

  order = 0;
  direction = 0;
  user_id = 0;
  action_id = 0;

  modalRef: BsModalRef;
  config = {
    class: 'modal-xl',
  };

  constructor(
    private admin: AdminService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.p = 1;
    this.log_array = [];
    this.getPage(this.p);
    this.logDetails = '';
  }

  seeDetails(log, modal) {
    log = log == '' ? '{}' : log;
    var logJSON = JSON.stringify(JSON.parse(log), undefined, 4);
    console.log(logJSON);

    this.logDetails = logJSON;

    this.modalRef = this.modalService.show(modal, this.config);
  }

  syntaxHighlight(json) {
    if (typeof json != 'string') {
      json = JSON.stringify(json, undefined, 2);
    }
    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])"(\s:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      }
    );
  }

  getPage(page) {
    this.admin
      .get_logs(this.order, this.direction, this.user_id, this.action_id, page)
      .subscribe((data) => {
        console.log(data);
        this.log_array = data['info'];
        this.totalpages = data['page_info']['count'];
        this.itemsPagina = data['page_info']['total_items'];
        this.p = data['page_info']['current'];
      });
  }
}
