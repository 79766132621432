import {
  Component,
  OnInit,
  Output,
  TemplateRef,
  EventEmitter,
} from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { MedicoService } from 'src/app/shared/servicio/medico/medico.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';

@Component({
  selector: 'app-create-medic-comercial',
  templateUrl: './create-medic-comercial.component.html',
  styleUrls: ['./create-medic-comercial.component.css'],
})
export class CreateMedicComercialComponent implements OnInit {
  @Output() createMedicE = new EventEmitter();
  state: any[] = [
    // { value: 0, name: 'Seleccione' },
    { value: 1, name: 'Registrado' },
    { value: 2, name: 'Incomunicado' },
    { value: 3, name: 'Pendiente' },
    { value: 4, name: 'No interesado' },
    { value: 5, name: 'Inactivo' },
    { value: 6, name: 'Activo' },
  ];
  selectedCity: any;

  create: FormGroup;
  name: FormControl;
  name2: FormControl;
  address: FormControl;
  email: FormControl;
  cell: FormControl;
  dni: FormControl;
  selectID: any;
  lastname: FormControl;
  lastname_2: FormControl;
  license: FormControl;
  stateForm: FormControl;
  especial: FormControl;
  direccionCompleta = '';  // Variable para almacenar la dirección completa

  selectedState: any; //Seleccion del estado
  special_list: any = []; // lista de especialidades
  arrEspecialidad: Array<any> = []; //especialidades para agregar medico
  selectedSpecial: any; //seleccion de especialidad

  modalRef: BsModalRef;
  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  cityForm: FormControl;
  cityList: any = [];

  submitted = false;
  preloader: boolean = false;
  load: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    public _medic: MedicoService,
    private modalService: BsModalService,
    private _helper: HelpersService,
    private _comercial: ComercialService,

  ) { }

  ngOnInit() {
    this.listCiudades();
    this.specialist_list();
    this.createMedic();
  }

  createMedic() {
    this.create = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      name2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      address: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastname_2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      stateForm: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ_ ]*'),
        ],
      ],

      cityForm: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],

      cell: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      email: [null, [Validators.required, Validators.email]],

      license: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(20),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ1234567890 ]*'),
        ],
      ],

      especial: [null],
    });
  }
  get shortCreate() {
    return this.create.controls;
  }

  onSelect3(event: TypeaheadMatch): void {
    this.selectedCity = event.item;
    this.selectID = event.item.id;

  }

  listCiudades() {
    this._comercial.list_ciudades().subscribe(
      (data) => {
        console.log(data);
        this.cityList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  clear(id) {
    if (id === 1) {
      if (this.shortCreate.stateForm.value != '') {
        this.shortCreate.stateForm.setValue(null);
      }
    }
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedState = event.item;
  }

  onSelect2(event: TypeaheadMatch, template: TemplateRef<any>): void {
    this.selectedSpecial = event.item;
    this.modalRef = this.modalService.show(template, this.config);
    this.shortCreate.especial.setValue(null);
  }

  specialist_list() {
    this._medic.getAllSpecialties().subscribe(
      (data) => {
        console.log(data);
        this.special_list = data;
      },
      (error) => {
        this._helper.modalError(
          '¡Ocurrió un error intentando obtener la lista de especialidades!'
        );
      }
    );
  }

  eliminarPos(nombre, idEspecial, arr, id, tipe) {
    if (tipe === 1) {
      this.arrEspecialidad.push({
        id: idEspecial,
        nombre: nombre,
      });
    }
    arr.splice(id, 1);
  }

  addSpecialties() {
    let data: boolean;
    data = this.validArrRepeat(this.selectedSpecial['id']);
    if (data === false) {
      this.arrEspecialidad.push({
        id: this.selectedSpecial['id'],
        nombre: this.selectedSpecial['name'],
      });
    } else {
      this._helper.modalAlert(
        '¡Esta especialidad ya se encuentra en la lista!'
      );
    }
    this.modalRef.hide();

    console.log(this.selectedSpecial);
    console.log(this.arrEspecialidad);
  }

  validArrRepeat(id) {
    var sw = false;
    for (var _i = 0; _i < this.arrEspecialidad.length; _i++) {
      if (this.arrEspecialidad[_i]['id'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  createMedicP() {
    this.preloader = true;

    console.log(this.create.value);

    console.log(this.arrEspecialidad);

    const direccion = this.direccionCompleta

    if (this.create.valid) {
      if (
        this.selectedState === undefined ||
        this.selectedState['value'] === 0
      ) {
        this._helper.modalError('¡Debes seleccionar un estado!');
        this.preloader = false;
      } else {
        this._medic
          .create_medic_adress(
            this.shortCreate.name.value,
            this.shortCreate.name2.value,
            direccion,
            this.selectID,
            this.shortCreate.lastname.value,
            this.shortCreate.lastname_2.value,
            this.shortCreate.email.value,
            this.shortCreate.license.value,
            this.shortCreate.cell.value,
            this.shortCreate.dni.value,
            this.selectedState['value'],
            this.arrEspecialidad
          )
          .subscribe(
            (data) => {
              console.log(data);
              this.preloader = false;
              this.submitted = false;
              this.createMedicE.emit(true);
              this._helper.modalSuccess('¡Médico agregado!');
            },
            (error) => {
              this.preloader = false;
              this.submitted = false;
              this._helper.modalError(
                this._helper.splitErrorMessages(error.error, '<br>')
              );
            }
          );
      }
    } else {
      this.submitted = true;
      this.preloader = false;
    }
  }

  updateAddress() {
    const tipoVia = (document.getElementById('tipoVia') as HTMLSelectElement).value || '';
    const numero1 = (document.getElementById('numero1') as HTMLInputElement).value || '';
    const numero2 = (document.getElementById('numero2') as HTMLInputElement).value || '';
    const numero3 = (document.getElementById('numero3') as HTMLInputElement).value || '';

    // Validar que todos los campos estén completos
    if (tipoVia && numero1 && numero2 && numero3) {
      this.direccionCompleta = `${tipoVia} ${numero1}#  ${numero2}-${numero3}`;
    } else {
      this.direccionCompleta = '';  // Limpiar si falta algún campo
    }

    // Asignar la dirección construida al campo oculto "address"
    (document.getElementById('address') as HTMLInputElement).value = this.direccionCompleta.trim();
  }
}
