import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringToDatePipe } from './pipes/string-to-date.pipe';
import { ShowPasswordDirective } from './directives/show-password.directive';

@NgModule({
  declarations: [StringToDatePipe, ShowPasswordDirective],
  imports: [CommonModule],
  exports: [StringToDatePipe, ShowPasswordDirective],
})
export class SharedModule {}
