import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AsistenteServicesService } from '../../../services/asistente-services.service';
import swal from 'sweetalert2';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-formulacion',
  templateUrl: './add-formulacion.component.html',
  styleUrls: ['./add-formulacion.component.css'],
})
export class AddFormulacionComponent implements OnInit {
  constructor(
    private AsistenteService: AsistenteServicesService,
    public _helper: HelpersService
  ) {}

  productsModal: any;

  diagnosticosModal: any;

  nombreCompleto: string;

  entidad = '';

  product_id: number;

  productosmedicos: Array<Object>;

  diagnosticosmedicos: any;

  productosMedicos: any = [];

  pacienteConsultado: any = [];

  prod = 'bolsa';

  arrayBlue = [];

  prod2 = 'dolor';

  arrayBlue2 = [];

  @Input() medicM: any;

  email: string = 'mefidemail@binar10.co';

  description: string;

  paciente: string;

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  modalRef: BsModalRef;

  @Output() reloadFormula = new EventEmitter();

  async ngOnInit() {
    this.prod = 'bolsa';
    this.prod2 = 'dolor';

    this.productsMedics();
    this.diagnosticos();
    console.log(this.medicM);
  }

  guardarFormula() {}

  changeTypeaheadLoading() {
    this.product_id = null;
  }

  async productsMedics() {
    if (this.prod == '' || this.prod == null) {
      this.productosmedicos = [];
    }
    await this.AsistenteService.products(this.productsModal)
      .toPromise()
      .then((data: Object) => {
        this.productosmedicos = data['response']['data'];
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async diagnosticos() {
    if (this.prod2 == '' || this.prod2 == null) {
      this.diagnosticosmedicos = [];
    }
    await this.AsistenteService.diagnosticos(this.diagnosticosModal)
      .toPromise()
      .then((data) => {
        console.log(data);
        this.diagnosticosmedicos = data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onSelect(data: Object) {
    this.arrayBlue.push({
      ...data['item'],
      quantity: 1,
      dose_quantity: 1,
      days: 1,
      interval: 1,
      via: 1,
      fraction: 1,
      presentation: 1,
      description: '',
    });
    this.productsModal = '';
  }
  onSelect2(data: Object) {
    this.arrayBlue2.push(data['item']);
    this.diagnosticosModal = '';
  }

  crear() {
      // Verificar que haya al menos un paciente consultado
      const paciente = this.pacienteConsultado[0]; // Tomar el primer paciente
      const pacienteId = paciente.id; // Obtener el ID del paciente
      this.AsistenteService.crearFormula(
        this.medicM.idmedic,
        pacienteId, // Usar el ID del paciente
        this.email,
        this.description,
        this.arrayBlue2,
        this.arrayBlue
      )
        .toPromise()
        .then((data: any) => {
          console.log(data);

          swal({
            title:
              '<img src="assets/icons/good.png" width="80px" style="margin-top:0px;">',
            html: 'Formula creada',
            confirmButtonText:
              '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Aceptar</p>',
            confirmButtonColor: '#6eceee',
          });
          this.reloadFormula.emit('r');
        })

        .catch((error) => {
          this._helper.modalError(error.error.error);
        });
  }
  buscarpaciente() {
    this.AsistenteService.buscarPaciente(this.paciente)
      .toPromise()
      .then((data: any) => {
        console.log(data);
        this.pacienteConsultado = data['results'];
        if (this.pacienteConsultado.length > 0) {
          const primerResultado = this.pacienteConsultado[0];
          this.nombreCompleto = `${primerResultado.first_name} ${primerResultado.last_name}`;
        } else {
          this._helper.modalError('Paciente no encontrado');
        }
      })
      .catch((error) => {
        this._helper.modalError('Paciente no encontrado');
      });
  }
}
