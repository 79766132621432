import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MedicoService } from './../../shared/servicio/medico/medico.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-logrosucces',
  templateUrl: './logrosucces.component.html',
  styleUrls: ['./logrosucces.component.css'],
})
export class LogrosuccesComponent implements OnInit {
  constructor(
    private _medico: MedicoService,
    private modalService: BsModalService,
    private formbuilder: FormBuilder
  ) {}

  logrosmediCompletado: any = [];
  form;
  modalRef: BsModalRef;
  // formulaTabla: any = [];

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;
  config = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  ngOnInit() {
    this.showLoading = true;
    this.getLogroMedicoCompletado();
  }

  getLogroMedicoCompletado() {
    this._medico
      .getLogrosMedicoCompletado(this.page)
      .toPromise()
      .then((data) => {
        console.log(data);

        this.limit = Math.ceil(data['count']);
        this.count = data['count'];

        if (Array.isArray(data)) {
          const sortedLogrosMedic = data.sort((a, b) => {
            if (a.is_achieved && !b.is_achieved) {
              return -1;
            } else if (!a.is_achieved && b.is_achieved) {
              return 1;
            } else {
              return 0;
            }
          });

          this.logrosmediCompletado = sortedLogrosMedic;
        }

        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.getLogroMedicoCompletado();
  }
}
