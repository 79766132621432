import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { MenuComponent } from './menu/menu.component';
import { MenuHorizontalComponent } from './menu-horizontal/menu-horizontal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// NGX-BOOTSTRAP
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// interceptors
import { TokenInterceptor } from '../interceptors/token.interceptor';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
// shared
import { SharedModule } from '../shared/shared.module';
// Components
import { HomeAdminComponent } from './admin-medicos/home-admin.component';
import { UpdateMedicComponent } from './util/update-medic/update-medic.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminComercialesComponent } from './admin-comerciales/admin-comerciales.component';
import { AdminPacientesComponent } from './admin-pacientes/admin-pacientes.component';
import { UpdateComercialComponent } from './util/update-comercial/update-comercial.component';
import { GraficasComercialComponent } from './graficas/graficas-comercial/graficas-comercial.component';
import { MensajeriaComponent } from './mensajeria/mensajeria.component';
import { GraficasMedicosComponent } from './graficas/graficas-medicos/graficas-medicos.component';
import { BannersComponent } from './banners/banners.component';
import { CreateComponent } from './banners/create/create.component';
import { StateComponent } from './banners/state/state.component';
import { EditComponent } from './banners/edit/edit.component';
import { DeleteComponent } from './banners/delete/delete.component';
import { EncueestasComponent } from './encueestas/encueestas.component';
import { CreateEncuestaComponent } from './encueestas/create-encuesta/create-encuesta.component';
import { CreateQuestionsComponent } from './encueestas/create-questions/create-questions.component';
import { VerEncuestasComponent } from './encueestas/ver-encuestas/ver-encuestas.component';
import { AdminAdminsComponent } from './admin-admins/admin-admins.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { CiudadesComponent } from './ciudades/ciudades.component';
import { UpdateAdminComponent } from './util/update-admin/update-admin.component';
import { RedencionesComponent } from './redenciones/redenciones.component';
import { LogsComponent } from './logs/logs.component';
import { StatsComponent } from './stats/stats.component';
import { LogrosComponent } from './logros/logros.component';
import { ComercialActivosComponent } from './comercial-activos/comercial-activos.component';
import { UpdateAdminPasswordComponent } from './menu/update-admin-password/update-admin-password.component';
import { UpdateAdminProfileComponent } from './menu/update-admin-profile/update-admin-profile.component';
import { AddNewAdminComponent } from './admin-admins/add-new-admin/add-new-admin.component';
import { UpdateComercialAdminComponent } from './admin-comerciales/update-comercial-admin/update-comercial-admin.component';
import { CreateComercialAdminComponent } from './admin-comerciales/create-comercial-admin/create-comercial-admin.component';
import { EncuestaChartComponent } from './encueestas/ver-encuestas/encuesta-chart/encuesta-chart.component';
import { AdminAsociadosComponent } from './admin-asociados/admin-asociados.component';
import { CreateAssociateComponent } from './admin-asociados/create-associate/create-associate.component';
import { ReporteMedicoPacientesComponent } from './reportes/reporte-medico-pacientes/reporte-medico-pacientes.component';
import { ReportePuntosComponent } from './reportes/reporte-puntos/reporte-puntos.component';
import { ComercioRetailComponent } from './reportes/comercio-retail/comercio-retail.component';
import { ComercioNotUsedComponent } from './reportes/comercio-not-used/comercio-not-used.component';
import { ProductListComponent } from './reportes/product-list/product-list.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormulacionComponent } from './reportes/formulacion/formulacion.component';
import { FormulallComponent } from './reportes/formulall/formulall.component';
import { InfologrosComponent } from './logros/infologros/infologros.component';
import { RegistrometasComponent } from './logros/registrometas/registrometas.component';
import { RegistroAsistenteComponent } from './registro-asistente/registro-asistente.component';
import { AsistentesComponent } from './asistentes/asistentes.component';
import { ReferirAsistenteComponent } from './referir-asistente/referir-asistente.component';
import { MedicRedemptionComponent } from './reportes/medic-redemption/medic-redemption.component';
import { FormulaMedicComponent } from './reportes/formula-medic/formula-medic.component';
import { GoalStadisticsComponent } from './reportes/goal-stadistics/goal-stadistics.component';

@NgModule({
  declarations: [
    DashboardComponent,
    MenuComponent,
    MenuHorizontalComponent,
    HomeAdminComponent,
    UpdateMedicComponent,
    AdminComercialesComponent,
    AdminPacientesComponent,
    UpdateComercialComponent,
    GraficasComercialComponent,
    MensajeriaComponent,
    GraficasMedicosComponent,
    BannersComponent,
    CreateComponent,
    StateComponent,
    EditComponent,
    DeleteComponent,
    EncueestasComponent,
    CreateEncuestaComponent,
    CreateQuestionsComponent,
    VerEncuestasComponent,
    AdminAdminsComponent,
    NotificacionesComponent,
    CiudadesComponent,
    UpdateAdminComponent,
    RedencionesComponent,
    LogsComponent,
    StatsComponent,
    LogrosComponent,
    ComercialActivosComponent,
    UpdateAdminPasswordComponent,
    UpdateAdminProfileComponent,
    AddNewAdminComponent,
    UpdateComercialAdminComponent,
    CreateComercialAdminComponent,
    EncuestaChartComponent,
    AdminAsociadosComponent,
    CreateAssociateComponent,
    ReporteMedicoPacientesComponent,
    ReportePuntosComponent,
    ComercioRetailComponent,
    ComercioNotUsedComponent,
    ProductListComponent,
    FormulacionComponent,
    FormulallComponent,
    InfologrosComponent,
    RegistrometasComponent,
    RegistroAsistenteComponent,
    AsistentesComponent,
    ReferirAsistenteComponent,
    MedicRedemptionComponent,
    FormulaMedicComponent,
    GoalStadisticsComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    NgxPaginationModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-Co' },
  ],
  entryComponents: [CreateQuestionsComponent],
})
export class AdminModule {}
