import { PremiosComponent } from './components/premios/premios.component';
import { ReferraListComponent } from './components/referra-list/referra-list.component';
import { AsistenteListaComponent } from './components/asistente-lista/asistente-lista.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FormulacionComponent } from './components/formulacion/formulacion.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: 'asistente', component: AsistenteListaComponent },
      { path: 'referal', component: ReferraListComponent },
      { path: 'formula', component: FormulacionComponent },
      { path: 'premio', component: PremiosComponent },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AsistentesRoutingModule {}
