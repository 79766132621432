import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from './../ip/ip.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeguimientoService {

  constructor(public _ip : IpService, private _http : HttpClient, public router : Router) { }


referral_medic(id:any)
{
  return this._http.get(environment.Api_tracing_medic_id+id+'/');
}

medicos_referidos_mes(year:any)
{
  return this._http.get(environment.Api_monitoring_graphics+year+'/');
}


}
