import { ComercialFormulaReportComponent } from './reportes/comercial-formula-report/comercial-formula-report.component';
import { ProductComercialComponent } from './reportes/product-comercial/product-comercial.component';
import { BonoNoUtilizadoComponent } from './reportes/bono-no-utilizado/bono-no-utilizado.component';
import { PuntosVencerComponent } from './reportes/puntos-vencer/puntos-vencer.component';
import { BonosClientesComercialComponent } from './reportes/bonos-clientes-comercial/bonos-clientes-comercial.component';
import { MedicRedClientComponent } from './reportes/medic-red-client/medic-red-client.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComercialComponent } from './comercial/home-comercial/home-comercial.component';
import { SeguimientoComponent } from './comercial/seguimiento/seguimiento.component';
import { GraphicsComponent } from './comercial/graphics/graphics.component';
import { MedicComponent } from './comercial/seguimiento/medic/medic.component';
import { ReferralsComponent } from './comercial/seguimiento/referrals/referrals.component';
import { DashboardComponent } from '../comercial/dashboard/dashboard.component';
import { LogrosComponent } from './logros/logros.component';
// import { ReporteMedicoPacientesComponent } from '../admin/reportes/reporte-medico-pacientes/reporte-medico-pacientes.component';
// import { ReportePuntosComponent } from '../admin/reportes/reporte-puntos/reporte-puntos.component';
// import { ComercioRetailComponent } from '../admin/reportes/comercio-retail/comercio-retail.component';
// import { ComercioNotUsedComponent } from '../admin/reportes/comercio-not-used/comercio-not-used.component';
// import { ProductListComponent } from '../admin/reportes/product-list/product-list.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: '', component: HomeComercialComponent },
      { path: 'tracing', component: SeguimientoComponent },
      { path: 'graphics', component: GraphicsComponent },

      /*PARAMETROS QUE RECIBEN ID*/
      { path: 'tracing/medic/:id', component: MedicComponent },
      { path: 'tracing/referral_medic/:id', component: ReferralsComponent },

      { path: 'tracing/medic', component: MedicComponent },
      { path: 'tracing/referral_medic', component: ReferralsComponent },
      { path: 'logrosComercial', component: LogrosComponent },
      { path: 'comercialRedClient', component: MedicRedClientComponent },
      {
        path: 'bonosClientesComercial',
        component: BonosClientesComercialComponent,
      },
      {
        path: 'puntosVencer',
        component: PuntosVencerComponent,
      },
      {
        path: 'bonosNoUtilizados',
        component: BonoNoUtilizadoComponent,
      },
      {
        path: 'productosComercial',
        component: ProductComercialComponent,
      },
      {
        path: 'efectividadComercial',
        component: ComercialFormulaReportComponent,
      },
      /** Gráficas de médicos*/

      //REPORTES MEFID
      // { path: 'reporteMed', component: ReporteMedicoPacientesComponent },
      // { path: 'reportePun', component: ReportePuntosComponent },
      // { path: 'comretail', component: ComercioRetailComponent },
      // { path: 'comnotused', component: ComercioNotUsedComponent },
      // { path: 'prolist', component: ProductListComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComercialRoutingModule {}
