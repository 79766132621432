import { Component, OnInit } from '@angular/core';
import { MedicoService } from '../../../shared/servicio/medico/medico.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  images: any;
  imageRoute;

  constructor(public medic: MedicoService) {}

  ngOnInit() {
    this.imageRoute = environment.baseImageUrl;
    this.list_banner();
  }
  list_banner() {
    this.medic.list_banner().subscribe(
      data => {
        console.log(data);

        this.images = data['images'];
      },
      error => {
        console.log(error);
      }
    );
  }
}
