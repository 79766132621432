import {
  Component,
  OnInit,
  TemplateRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { AdminService } from '../../services/admin/admin.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
declare var require: any;
const fileUpload = require('fuctbase64');
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  @Output() lista = new EventEmitter();
  @Input() id_banner: any;

  // parametros del modal de ngx-bootstrap
  modalRef: BsModalRef;
  config = {
    class: 'modal-dialog-centered modal-app modal-app-lg',
    backdrop: true,
    ignoreBackdropClick: true
  };

  // formulario para agregar
  selectedFile: File = null;
  bannerUpdate: FormGroup;
  name: FormControl;
  description: FormControl;
  file: FormControl;
  file2: FormControl;
  typeBanner: FormControl;
  image_mobile: any;
  image_desktop: any;

  // mostrar errores de validación
  submitted: boolean;
  format_file: boolean;
  format_file2: boolean;
  fileResult: any;
  fileResult2: any;

  // preloaders de carga
  preloader = false;
  div_error = 0;
  div_text_error = '';

  fileProps: any;
  file2Props: any;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public helper: HelpersService,
    public admin: AdminService
  ) {}

  ngOnInit() {
    this.fileProps = null;
    this.file2Props = null;
    this.submitted = false;
    this.format_file = true;
    this.format_file2 = true;
    this.formBannerUpdate();
  }

  banner_id(template: TemplateRef<any>) {
    this.admin.banner_id(this.id_banner).subscribe(
      data => {
        console.log(data);
        this.div_error = 0;
        this.div_text_error = '';
        this.name = data['name'];
        this.description = data['description'];
        this.typeBanner = data['type'];
        this.image_mobile = data['image_mobile'];
        this.image_desktop = data['image_web'];
        this.formBannerUpdate();
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => {
        console.log(error);
      }
    );
  }

  formBannerUpdate() {
    this.bannerUpdate = this.formBuilder.group({
      description: [
        this.description,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(150),
          Validators.pattern(
            '[a-zA-Z_0-9 ?,¿¡!.´¨\n ñáéíóú ÑÁÉÍÓÚ ÄËÏÖÜ äëïöü]*'
          )
        ]
      ],

      file: [null, []],

      file2: [null, []],

      name: [
        this.name,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(150),
          Validators.pattern(
            '[a-zA-Z_0-9 ?,¿¡!;:.´¨\n ñáéíóú ÑÁÉÍÓÚ ÄËÏÖÜ äëïöü]*'
          )
        ]
      ]
    });
  }

  get shortBannerUpdateForm() {
    return this.bannerUpdate.controls;
  }

  update_image() {
    // console.log(this.format_file);
    // console.log(this.bannerAdd.value.name);
    this.preloader = true;

    if (
      this.bannerUpdate.valid &&
      this.fileResult !== undefined &&
      this.fileResult2 !== undefined
    ) {
      console.log('Válido');
      const image = this.fileResult['__zone_symbol__value']['base64'];
      const image2 = this.fileResult2['__zone_symbol__value']['base64'];

      this.admin
        .banner_update(
          this.id_banner,
          this.bannerUpdate.value.name,
          this.bannerUpdate.value.description,
          image,
          image2
        )
        .subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              console.log(
                'Upload progress: ' +
                  Math.round(event.loaded / event.total) * 100 +
                  ' %'
              );
            } else if (event.type === HttpEventType.Response) {
              this.admin.banner_id(this.id_banner).subscribe(
                data => {
                  console.log(data);
                  this.div_error = 0;
                  this.div_text_error = '';
                  this.name = data['name'];
                  this.description = data['description'];
                  this.typeBanner = data['type'];
                  this.image_mobile = data['image_mobile'];
                  this.image_desktop = data['image_web'];
                  this.div_error = 2;
                  // this.div_text_error = 'Banner ha sido editado correctamente';
                  this.helper.modalSuccess(
                    'Se ha actualizado el Banner correctamente'
                  );
                  this.preloader = false;
                  this.lista.emit(true);
                },
                error => {
                  console.log(error);
                }
              );
            }
          },
          error => {
            this.preloader = false;
            this.div_error = 1;
            this.div_text_error = this.helper.error('', error);
          }
        );
    } else if (this.bannerUpdate.invalid) {
      console.log('lala');

      this.preloader = false;
      this.submitted = true;
    } else {
      console.log('Error');
      this.helper.modalError('Por favor, selecciona las 2 imágenes');
      this.preloader = false;
    }
  }

  onFileSelected(event) {
    const file: File = event.target.files.item(0);

    if (file !== undefined) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/PNG' ||
        file.type === 'image/jpg' ||
        file.type === 'image/JPG' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/JPEG'
      ) {
        this.fileProps = file.name;
        console.log('formato admitido');
        this.format_file = true;
        const result = fileUpload(event);
        this.fileResult = result;
      } else {
        this.format_file = false;
      }
    } else {
      this.format_file = true;
    }
  }

  onFileSelected2(event) {
    const file: File = event.target.files.item(0);

    if (file !== undefined) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/PNG' ||
        file.type === 'image/jpg' ||
        file.type === 'image/JPG' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/JPEG'
      ) {
        this.file2Props = file.name;
        console.log('formato admitido');
        this.format_file2 = true;
        const result = fileUpload(event);
        this.fileResult2 = result;
      } else {
        this.format_file2 = false;
      }
    } else {
      this.format_file2 = true;
    }
  }
}
