import { environment } from './../../../environments/environment';
import {
  Component,
  OnInit,
  TemplateRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { MedicoService } from '../../shared/servicio/medico/medico.service';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import { AdminService } from '../services/admin/admin.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { formatDate, TypeaheadMatch } from 'ngx-bootstrap';

@Component({
  selector: 'app-admin-comerciales',
  templateUrl: './admin-comerciales.component.html',
  styleUrls: ['./admin-comerciales.component.css'],
})
export class AdminComercialesComponent implements OnInit {
  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  //contenedor del componente HTML
  showLoading: boolean;

  // array de los datos de backend muestra informacion sobre comerciales
  comerciales_array: any;

  searchTextFC: FormControl;
  ciudadSelectorFC: FormControl;
  criterioSelectorFC: FormControl;

  // dato para paginacion de resultados
  page: number;
  p: number;
  totalpages: number;
  itemsPagina: any;

  // variable para las busquedas del formulario principal
  criterio: string = 'none';
  searchtext: string = '';
  searchtext2: string = 'none';
  orden: string = 'none';
  selectradio: any = 0;

  // inputs para crear y actualizar comerciales
  modal_ver_nombre: string = '';
  modal_ver_nombre2: string = '';
  modal_ver_apellido: string = '';
  modal_ver_apellido2: string = '';
  modal_ver_telefono: string = '';
  modal_ver_email: string = '';
  modal_ver_user: string = '';
  modal_ver_city: string = 'none';

  preloader: boolean = false;

  //Lista de ciudades
  cityList: any;

  //orden ascendente y descendente de la lista
  order: number = 0;
  direction: number = 0;

  //estado de debugs
  debug: boolean;

  //estado de medico
  estado: boolean;

  //select estados
  estado2: string = 'none';
  text_estado: number;

  // Identificador del medico
  id: number;

  selectedCity;

  /**
   * Input de fecha inicial (fecha de activación de cuenta)
   */
  startDateFC: FormControl;

  /**
   * Input de fecha de finalización (fecha de activación de cuenta)
   */
  endDateFC: FormControl;

  @ViewChild('citySelect') citySelectorNE;

  constructor(
    public _medic: MedicoService,
    public _comercial: ComercialService,
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router,
    private activeRoute: ActivatedRoute,
    public _helper: HelpersService,
    private admin: AdminService,
    private modalService: BsModalService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.showLoading = true;
    this.cityList = [];
    this.searchTextFC = new FormControl('');
    this.ciudadSelectorFC = new FormControl('');
    this.criterioSelectorFC = new FormControl('none');
    this.startDateFC = new FormControl('');
    this.endDateFC = new FormControl('');
    this.listCiudadesAndInitComponents();
  }

  /**
   * Establece los valores iniciales de los filtros, teniendo en cuenta si viene información
   * de otra vista
   */
  loadLastUsedFilters() {
    this.activeRoute.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 1) {
        this.selectedCity = this.getCityByName(params['ciudad']);
        this.ciudadSelectorFC.setValue(this.selectedCity['dane_id__name']);
        this.criterioSelectorFC.setValue(params['filtro']);
        if (params['startDate'] === '') {
          this.startDateFC.setValue('');
        } else {
          this.startDateFC.setValue(new Date(params['startDate']));
        }
        if (params['endDate'] === '') {
          this.endDateFC.setValue('');
        } else {
          this.endDateFC.setValue(new Date(params['endDate']));
        }
        this.searchTextFC.setValue(params['searchText']);
        this.p = params['page'];
        console.log(params);
      } else {
        this.p = 1;
        this.selectedCity = this.getCityById(0);
        this.ciudadSelectorFC.setValue(this.selectedCity['dane_id__name']);
      }
      this.isSearchFieldValid(this.searchTextFC.value);
      this.iniOnValueChangeListeners();
    });
  }

  /**
   * Inicializa los eventos valueChange de cada componente de form control
   */
  iniOnValueChangeListeners() {
    this.searchTextFC.valueChanges.subscribe((value) => {
      this.isSearchFieldValid(value);
    });
    this.criterioSelectorFC.valueChanges.subscribe((value) => {
      this.p = 1;
      this.isSearchFieldValid(this.searchTextFC.value);
    });
    this.startDateFC.valueChanges.subscribe((onDateChange) => {
      this.p = 1;
      this.isSearchFieldValid(this.searchTextFC.value);
    });
    this.endDateFC.valueChanges.subscribe((onDateChange) => {
      this.p = 1;
      this.isSearchFieldValid(this.searchTextFC.value);
    });
    this.debug_db();
  }

  /**
   * Obtiene la lista de todas las ciudades e inicializa los parámetros de busqueda si hay alguno.
   */
  listCiudadesAndInitComponents() {
    this._comercial.list_ciudades().subscribe(
      (data) => {
        this.cityList = data;
        this.cityList.push({
          dane_id__dane_code: 10,
          dane_id__name: 'Todas las ciudades',
          id: 0,
        });
        this.loadLastUsedFilters();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * Obtiene la lista de comerciales (paginado) del server y los muestra.
   * @param p2 Página actual
   */
  list_comercial(p2) {
    let ciudadValue = 0;
    var [startDate, endDate] = this.validateDate();
    if (this.selectedCity) {
      ciudadValue = this.selectedCity['id'];
    }
    if (p2 === undefined) {
      p2 = 1;
    } else {
      p2 - 1;
    }
    const requestLink =
      environment.Api_get_all_commercial_list +
      ciudadValue +
      '/' +
      0 +
      '/' +
      this.searchtext2 +
      '/' +
      this.order +
      '/' +
      this.direction +
      '/' +
      startDate +
      '/' +
      endDate +
      '/' +
      p2;
    console.log(requestLink);
    this._http.get(requestLink).subscribe(
      (data) => {
        this.vista_console(data, 'Lista de comerciales');

        this.comerciales_array = data['info'];
        this.totalpages = data['page_info']['count'];
        this.itemsPagina = data['page_info']['total_items'];
        this.p = data['page_info']['current'];
        this.showLoading = false;
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  /**
   * Método para que cuando se ejecute un cambio de página, se cargue nuevamente la información.
   * @param p Siguiente página
   */
  getPage(p) {
    let ciudadValue = 0;
    var [startDate, endDate] = this.validateDate();
    if (this.selectedCity) {
      ciudadValue = this.selectedCity['id'];
    }
    const requestLink =
      environment.Api_get_all_commercial_list +
      ciudadValue +
      '/' +
      this.criterioSelectorFC.value +
      '/' +
      this.searchtext2 +
      '/' +
      this.order +
      '/' +
      this.direction +
      '/' +
      startDate +
      '/' +
      endDate +
      '/' +
      p;
    console.log(requestLink);
    this._http.get(requestLink).subscribe(
      (data) => {
        this.showLoading = false;
        this.list_comercial(p);
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  /**
   * Valida los valores de los input de fecha de inicio y fin para el filtro de lista
   */
  validateDate() {
    var startDate, endDate;
    if (this.startDateFC.value == undefined || this.startDateFC.value == '') {
      startDate = 'none';
    } else {
      startDate = this.getFormatedDate(this.startDateFC.value);
    }

    if (this.endDateFC.value == undefined || this.endDateFC.value == '') {
      endDate = 'none';
    } else {
      endDate = this.getFormatedDate(this.endDateFC.value);
    }
    return [startDate, endDate];
  }

  /**
   * Verifica que el campo de búsqueda tenga valores válidos para filtrar
   * @param text Información a buscar
   */
  isSearchFieldValid(text) {
    if (text == undefined || text == '') {
      this.searchtext2 = 'none';
    } else {
      this.searchtext2 = text;
    }
    this.list_comercial(this.p);
  }

  /**
   * Activa la cuenta de un comercial
   * @param comercialID Id del comercial
   */
  activar(comercialID) {
    this._helper
      .modalConfirm('¿Desea activar este comercial?')
      .then((willActivate) => {
        if (willActivate.value) {
          this.admin.activate_user(comercialID, true).subscribe(
            (data) => {
              this.emitReload();
            },
            (error) => {
              console.log('there was an error');
            }
          );
        }
      });
  }

  /**
   * Desactiva la cuenta de un comercial
   * @param comercialID ID del comercial
   */
  desactivar(comercialID) {
    this._helper
      .modalConfirm('¿Desea bloquear este comercial?')
      .then((willDelete) => {
        if (willDelete.value) {
          this.admin.activate_user(comercialID, false).subscribe(
            (data) => {
              this.emitReload();
            },
            (error) => {
              console.log('there was an error');
            }
          );
        }
      });
  }

  /**
   * Función que intercepta el evento de elección de fecha del NGX DatePicker
   * @param calendarView Instancia de calendario que disparó el evento
   * @param isStart Booleano que indica si al instancia originaria fue la fecha de inicio o la de fin
   */
  opencalendar(calendarView, isStart) {
    if (isStart) {
      this.startDateFC.setValue('');
    } else {
      this.endDateFC.setValue('');
    }
    calendarView.yearSelectHandler = (event: any): void => {
      calendarView._store.dispatch(calendarView._actions.select(event.date));
    };
    calendarView.setViewMode('day');
  }

  /**
   * Obtiene la información de la ciudad seleccionada
   * @param event Evento de opción seleccionada
   */
  onCitySelected(event: TypeaheadMatch): void {
    console.log(event.item);
    this.selectedCity = event.item;

    this.isSearchFieldValid(this.searchTextFC.value);
  }

  receiveReloadSignal = function (event) {
    this.getPage(this.p);
  };

  /**
   * Función que toma una fecha completa y en Y-M-D:H-M-S y la transforma a Y-M-D
   * @param joinDate Fecha
   */
  getFormatedDate(joinDate) {
    return formatDate(joinDate, 'YYYY-MM-DD', 'es');
  }

  emitReload() {
    this.showLoading = true;
    this.getPage(this.p);
  }

  /**
   * Obtiene el objeto de ciudad con base en el ID de la ciudad seleccionada
   * @param cityID Id de la ciudad seleccionada
   */
  getCityById(cityID) {
    return this.cityList.find((city) => {
      return city['id'] == cityID;
    });
  }

  /**
   * Obtiene el objeto de ciudad con base en el nombre
   * @param cityName
   */
  getCityByName(cityName) {
    return this.cityList.find((city) => {
      return city['dane_id__name'] == cityName;
    });
  }

  clear(id) {
    if (id === 1) {
      if (this.ciudadSelectorFC.value != '') {
        this.ciudadSelectorFC.setValue(null);
      }
    }
  }

  /**
   * Función para manejar los errores del servidor y mostrarlos
   * @param error Error HTTP proveniente del servidor
   */
  manejador_errores(error) {
    if (this.debug === true) {
      console.log(error);
    } else {
      console.log('Debug no habilitado');
    }
    if (error.status === 400) {
      this._helper.modalError(error['error']);
    } else if (error.statusText === 'Unknown Error') {
      this._helper.modalError('¡Error de conexión con el servidor!');
    } else if (error.status === 500) {
      this._helper.modalError('¡Error de servidor!');
    } else if (error.status === 403) {
      this._helper.modalError('¡Página no encontrada!');
      this.router.navigate(['/login']);
    }
  }

  /**
   * Muestra los console.log en consola de otra forma.
   * @param data Dato a mostrar
   * @param string Nombre con el que aparecerá el debug
   */
  vista_console(data, string: string) {
    if (this.debug === true) {
      console.log(string, data);
    } else {
      console.log('Debug no habilitado');
    }
  }

  /**
   * Conexión para el debug
   */
  debug_db() {
    this._ip.debug().subscribe(
      (data) => {
        this.debug = data['state'];
        this.vista_console(data, 'valor del debug');
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  ordenamiento(valor: number, orden: number) {
    this.order = valor;
    this.direction = orden;
    this.list_comercial(this.p);
  }

  //Actualizar lista
  actualizar_lista(event) {
    console.log(event);
    if (event === '1') {
      this.list_comercial(this.p);
    }
  }

  actualizar_lista2(event) {
    console.log(event);
    if (event === true) {
      this.list_comercial(this.p);
      this.modalRef.hide();
    }
  }

  actualizar_lista3(event) {
    console.log(event);
    if (event === true) {
      this.list_comercial(this.p);
      this.modalRef2.hide();
    }
  }

  /**
   * Función para restablecer las credenciales de un comercial
   * @param comercialID Id del comercial a reestablecer las credenciales
   */
  resendComercialPassword(comercialID) {
    this.admin.resend_credentials(comercialID).subscribe(
      (data) => {
        this._helper.modalSuccess('¡Contraseña enviada al correo del usuario!');
      },
      (error) => {
        this._helper.modalError('¡Error al restablecer las credenciales!');
      }
    );
  }

  //faby update comercial
  modalUpdate(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  modalCreateComercial(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, this.config);
  }
}
