import { Component, OnInit, TemplateRef } from '@angular/core';
import { HelpersService } from '../../../../shared/servicio/helpers/helpers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IpService } from '../../../../shared/servicio/ip/ip.service';
import { HttpClient } from '@angular/common/http';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { formatDate } from 'ngx-bootstrap';
import { Location } from '@angular/common';

@Component({
  selector: 'app-medic',
  templateUrl: './medic.component.html',
  styleUrls: ['./medic.component.css'],
})
export class MedicComponent implements OnInit {
  updateMedicModalRef: BsModalRef;

  updateMedicModalConfig = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  //datos de la paginación
  paginate_change: number;
  totalpages: number;
  itemsPagina: any;

  //Id del medico
  id: any;

  //Obtiene la lista de medicos de la api
  medic: any;

  //Filtrar por los datos de los medicos como nombre y apellidos
  filter: string = 'none';
  search: string = 'none';
  search_text: string = '';

  //ordenar por atributo en ascendente o descendente
  order: number = 0;
  direction: number = 0;

  //Filtra por los estados de los medicos
  state: number = 2;

  //Lista de datos
  dataLista;

  /**
   *
   */
  criterioSelectorFC: FormControl;

  /**
   *
   */
  searchTextFC: FormControl;

  /**
   * Input de fecha inicial (fecha de activación de cuenta)
   */
  startDateFC: FormControl;

  /**
   * Input de fecha de finalización (fecha de activación de cuenta)
   */
  endDateFC: FormControl;

  isActiveFilterON;

  isInactiveFilterON;

  /**
   * Filtros activos de la ruta anterior
   */
  pastFilters;

  constructor(
    private modalService: BsModalService,
    private _http: HttpClient,
    public _ip: IpService,
    public _helper: HelpersService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private location: Location
  ) {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.medic = [];
    this.dataLista = false;
    this.isActiveFilterON = false;
    this.isInactiveFilterON = false;

    this.searchTextFC = new FormControl('');
    this.criterioSelectorFC = new FormControl('none');
    this.startDateFC = new FormControl('');
    this.endDateFC = new FormControl('');

    this.criterioSelectorFC.valueChanges.subscribe((value) => {
      this.list_medic(this.paginate_change);
    });
    this.searchTextFC.valueChanges.subscribe((value) => {
      this.search_filter(value);
    });
    this.startDateFC.valueChanges.subscribe((onDateChange) => {
      this.list_medic(this.paginate_change);
    });
    this.endDateFC.valueChanges.subscribe((onDateChange) => {
      this.list_medic(this.paginate_change);
    });
    this.list_medic(1);
    this.pastFilters = history.state;
  }

  getActiveFiltersCount() {
    return Object.keys(this.pastFilters).length;
  }

  goBack() {
    if (this.getActiveFiltersCount() > 1) {
      this.router.navigate(['/home/comercial/tracing'], {
        queryParams: this.pastFilters,
      });
    } else {
      this.router.navigate(['/home/comercial/tracing']);
    }
  }

  /**
   * Valida los valores de los input de fecha de inicio y fin para el filtro de lista
   */
  validateDate() {
    var startDate, endDate;
    if (this.startDateFC.value == undefined || this.startDateFC.value == '') {
      startDate = 'none';
    } else {
      startDate = this.getFormatedDate(this.startDateFC.value);
    }

    if (this.endDateFC.value == undefined || this.endDateFC.value == '') {
      endDate = 'none';
    } else {
      endDate = this.getFormatedDate(this.endDateFC.value);
    }
    return [startDate, endDate];
  }

  /**
   * Función que intercepta el evento de elección de fecha del NGX DatePicker
   * @param calendarView Instancia de calendario que disparó el evento
   * @param isStart Booleano que indica si al instancia originaria fue la fecha de inicio o la de fin
   */
  opencalendar(calendarView, isStart) {
    if (isStart) {
      this.startDateFC.setValue('');
    } else {
      this.endDateFC.setValue('');
    }
    calendarView.yearSelectHandler = (event: any): void => {
      calendarView._store.dispatch(calendarView._actions.select(event.date));
    };
    calendarView.setViewMode('day');
  }

  /**
   * Función que toma una fecha completa y en Y-M-D:H-M-S y la transforma a Y-M-D
   * @param joinDate Fecha
   */
  getFormatedDate(joinDate) {
    return formatDate(joinDate, 'YYYY-MM-DD', 'es');
  }

  //Lista de los medicos referidos
  list_medic(page) {
    var [startDate, endDate] = this.validateDate();
    this._http
      .get(
        environment.Api_get_medic_referral +
          this.id +
          '/' +
          this.criterioSelectorFC.value +
          '/' +
          this.search +
          '/' +
          this.state +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          startDate +
          '/' +
          endDate +
          '/' +
          page
      )
      .subscribe(
        (data) => {
          console.log(data);

          this.medic = data['info'];
          this.totalpages = data['page_info']['count'];
          this.itemsPagina = data['page_info']['total_items'];
          this.paginate_change = data['page_info']['current'];
          this.dataLista = true;
        },
        (error) => {
          this.dataLista = true;

          this._helper.modalError('¡' + error.error + '!');
        }
      );
  }

  //Paginacion de los datos obtenidos por la api.
  pagination(page2) {
    var [startDate, endDate] = this.validateDate();
    this._http
      .get(
        environment.Api_get_medic_referral +
          this.id +
          '/' +
          this.criterioSelectorFC.value +
          '/' +
          this.search +
          '/' +
          this.state +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          startDate +
          '/' +
          endDate +
          '/' +
          page2
      )
      .subscribe(
        (data) => {
          this.list_medic(page2);
          console.log(data);
        },
        (error) => {
          this.dataLista = true;

          this._helper.modalError('¡' + error.error + '!');
        }
      );
  }

  //Actualizar la lista de medicos para indicar parametros de busqueda
  //filtrar, buscar por estados y ordenamiento ascendente y descendente
  search_filter(name) {
    if (name != '') {
      this.search = name;
    } else {
      this.search = 'none';
    }
    this.list_medic(this.paginate_change);
  }

  //ordenar la lista acepta dos parametros su orden y parametro a ordenar.
  ordenamiento(valor: number, orden: number) {
    this.order = valor;
    this.direction = orden;
    this.list_medic(this.paginate_change);
  }

  actualizar_lista(event) {
    if (event === '1') {
      this.list_medic(this.paginate_change);
    }
  }

  onActiveToggle() {
    this.isActiveFilterON = !this.isActiveFilterON;
    this.updateActiveSearchValue(
      this.isActiveFilterON,
      this.isInactiveFilterON
    );
  }

  onInactiveToggle() {
    this.isInactiveFilterON = !this.isInactiveFilterON;
    this.updateActiveSearchValue(
      this.isActiveFilterON,
      this.isInactiveFilterON
    );
  }

  updateActiveSearchValue(isActive, isInactive) {
    if ((isActive && isInactive) || (!isActive && !isInactive)) {
      this.state = 2;
    } else if (isActive) {
      this.state = 1;
    } else if (isInactive) {
      this.state = 0;
    }
    this.list_medic(this.paginate_change);
  }

  /**
   * Función que abre el modal para actualizar los datos del médico
   * @param template template de actualizar médico
   */
  updateMedic(template: TemplateRef<any>) {
    this.updateMedicModalRef = this.modalService.show(
      template,
      this.updateMedicModalConfig
    );
  }
}
