import { Component, OnInit, TemplateRef } from '@angular/core';
import { ReportService } from './../../../admin/services/report.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { MedicoService } from './../../../shared/servicio/medico/medico.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-comercial-formula-report',
  templateUrl: './comercial-formula-report.component.html',
  styleUrls: ['./comercial-formula-report.component.css'],
})
export class ComercialFormulaReportComponent implements OnInit {
  constructor(
    private formula: ReportService,
    private modalService: BsModalService,
    private formbuilder: FormBuilder,
    private medic: MedicoService
  ) {}

  formulaArray: any = [];
  form;

  modalRef: BsModalRef;
  formulaTabla: any = [];
  formulaTablaDiagnostico: any = [];

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;
  date_created_after;
  date_created_before;
  token = window.localStorage.getItem('token');
  configModalDetalle = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  ngOnInit() {
    this.showLoading = true;

    this.getFormulaMedic();
  }

  getFormulaMedic() {
    this.formula
      .getComercialFormulaMedic(this.page)
      .toPromise()
      .then((data) => {
        console.log(data);
        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.formulaArray = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.getFormulaMedic();
  }

  async AbrirModal(template: TemplateRef<any>, item) {
    this.showLoading = true;

    this.medic
      .formulaDetail(item.id)
      .toPromise()
      .then((data: any) => {
        this.formulaTabla = data['formuladetail_set'];
        this.formulaTablaDiagnostico = data['diagnostic'];

        this.form = this.formbuilder.group({
          cc_doc: [data.medic_idoc],
          cc_pac: [data.patient_idoc],
          medic_name: [data.medic_first_name.concat(' ', data.medic_last_name)],
          patient_name: [
            data.patient_first_name.concat(' ', data.patient_last_name),
          ],

          profesional_license: [data.profesional_license],
          date_created: [data.date_created],
        });

        this.modalRef = this.modalService.show(
          template,
          this.configModalDetalle
        );
        this.showLoading = false;

        //Swal.close();
      })

      .catch((error) => console.log(error));
  }

  xslEfectyComercial() {
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getDate() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    window.open(
      environment.comercialMedicReport +
        '?xls' +
        '&' +
        'token=' +
        this.token +
        '&start=' +
        init +
        '&end=' +
        end
    );
  }
}
