import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { NgForm, FormControl } from '@angular/forms';
import swal from 'sweetalert2';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { ClipboardService } from 'ngx-clipboard';

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  constructor(
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router,
    public _helper: HelpersService,
    private _clipboardService: ClipboardService
  ) {}
  cont: number = 0;
  nombre: string = '';
  rol: string = '';
  referral_code: string = '';
  points: string = '';

  //actualizar registros personales
  nombre_modal: string;
  nombre_modal2: string;
  apellido1_modal: string;
  apellido2_modal: string;
  celular_modal: string;
  email_modal: string;
  dni_modal: string;
  profesional_lisense: string;
  debug: boolean;

  //formulario actualizar contraseñas
  pass_old: string = '';
  pass1: string = '';
  pass2: string = '';

  preloader: boolean = false;
  div_error: number = 0;
  text_error: string = '';

  ngOnInit() {
    this.user();
  }

  user() {
    this._http.get(environment.Api_get_user).subscribe(
      (data) => {
        console.log(data);
        (this.nombre = data['user_name'] + ' ' + data['last_name']),
          (this.rol = data['role']);
        this.referral_code = data['referral_code'];
        this.points = data['points'];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  logout() {
    this._http.get(environment.Api_logout).subscribe(
      (data) => {
        console.log(data);
        localStorage.removeItem('token');
        localStorage.removeItem('type');

        this.router.navigate(['/login']);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  perfil() {
    this._http.get(environment.Api_info_user).subscribe(
      (data) => {
        this.nombre_modal = data['user_info']['first_name'];
        this.nombre_modal2 = data['user_info']['first_name2'];
        this.apellido1_modal = data['user_info']['last_name'];
        this.apellido2_modal = data['user_info']['last_name2'];
        this.celular_modal = data['user_info']['cell'];
        this.email_modal = data['user_info']['email'];
        this.dni_modal = data['user_info']['dni'];
        this.profesional_lisense = data['medic_info']['profesional_license'];
        $('#info_user').modal('show');
        console.log(data);
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  actualizar_user(update: NgForm) {
    this.preloader = true;
    console.log(update.value);
    let first_name1 = update.value['nombre_modal'];
    let first_name2 = update.value['nombre_modal2'];
    let last_name1 = update.value['apellido1_modal'];
    let last_name2 = update.value['apellido2_modal'];
    let cell = update.value['celular_modal'];
    let email = update.value['email_modal'];
    let dni = update.value['dni_modal'];
    let profesional_license = this.profesional_lisense;

    this._http
      .post(environment.Api_info_user, {
        first_name1,
        first_name2,
        last_name1,
        last_name2,
        cell,
        email,
        dni,
        profesional_license,
      })
      .subscribe(
        (data) => {
          this.preloader = false;
          this.div_error = 0;
          this.text_error = '';
          $('#info_user').modal('hide');
          console.log(data);
        },
        (error) => {
          this.preloader = false;
          this.div_error = 1;
          this.text_error = this._helper.error('', error);
        }
      );
  }

  update_password(value, up: NgForm = null) {
    switch (value) {
      case 1:
        $('#pass_change').modal('show');
        break;

      case 2:
        this.preloader = true;
        console.log(up.value);
        let password = up.value['pass_old'];
        let password1 = up.value['pass1'];
        let password2 = up.value['pass2'];

        if (password1 === password2) {
          this._http
            .post(environment.Api_update_password, {
              password,
              password1,
              password2,
            })
            .subscribe(
              (data) => {
                this.preloader = false;
                this.div_error = 0;
                this.text_error = '';
                this.pass_old = '';
                this.pass1 = '';
                this.pass2 = '';

                console.log(data);
                swal({
                  title:
                    '<img src="assets/icons/good.png" width="80px" style="margin-top:0px;">',
                  html: 'Tu contraseña se ha actualizado correctamente',
                  confirmButtonText:
                    '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
                    'to</p>',
                  confirmButtonColor: '#6eceee',
                });
                $('#pass_change').modal('hide');
              },
              (error) => {
                this.preloader = false;
                this.div_error = 1;
                this.text_error = this._helper.error('', error);

                console.log(error);
              }
            );
        } else {
          this.preloader = false;
          this.div_error = 1;
          this.text_error = 'Las contraseñas no coinciden';
        }

        break;
    }
  }

  manejador_errores(error) {
    if (this.debug === true) {
      console.log(error);
    } else {
      console.log('Debug no habilitado');
    }

    if (error.status === 400) {
      swal({
        title:
          '<img src="assets/icons/error_modal.png" width="80px" style="margin-top:0px;"><' +
          'br>ERROR!',
        html: error['error'],
        confirmButtonText:
          '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
          'to</p>',
        confirmButtonColor: '#6eceee',
      });
    } else if (error.statusText === 'Unknown Error') {
      swal({
        title:
          '<img src="assets/icons/error_modal.png" width="80px" style="margin-top:0px;"><' +
          'br>ERROR!',
        html: 'ERROR DE CONEXION CON EL SERVIDOR',
        confirmButtonText:
          '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
          'to</p>',
        confirmButtonColor: '#6eceee',
      });
    } else if (error.status === 500) {
      swal({
        title:
          '<img src="assets/icons/error_modal.png" width="80px" style="margin-top:0px;"><' +
          'br>ERROR!',
        html: 'ERROR DE SERVIDOR',
        confirmButtonText:
          '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
          'to</p>',
        confirmButtonColor: '#6eceee',
      });
    } else if (error.status === 403) {
      swal({
        title:
          '<img src="assets/icons/error_modal.png" width="80px" style="margin-top:0px;"><' +
          'br>ERROR!',
        html: 'PAGINA NO ENCONTRADA',
        confirmButtonText:
          '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
          'to</p>',
        confirmButtonColor: '#6eceee',
      });

      this.router.navigate(['/login']);
    }
  }

  debug_db() {
    this._ip.debug().subscribe(
      (data) => {
        this.debug = data['state'];
        this.vista_console(data, 'valor del debug');
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  vista_console(data, string: string) {
    if (this.debug === true) {
      console.log(string, data);
    } else {
      console.log('Debug no habilitado');
    }
  }

  //funcion para obtener enlace de referido
  getLink() {
    this._clipboardService.copyFromContent(
      'https://mefid.co/mefid_register.html?code=' + this.referral_code
    );
  }
}
