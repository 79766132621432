import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../services/admin/admin.service';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-redenciones',
  templateUrl: './redenciones.component.html',
  styleUrls: ['./redenciones.component.css'],
})
export class RedencionesComponent implements OnInit {
  medic_id;

  // dato para paginacion de resultados
  page: number;
  p: number;
  totalpages: number;
  itemsPagina: any;

  redenciones_array;

  nombreMedico;
  nombre2Medico;
  apellidoMedico;
  apellido2Medico;

  pastFilters;

  showLoading: boolean;

  /**
   * Input de fecha inicial (fecha de activación de cuenta)
   */
  startDateFC: FormControl;

  /**
   * Input de fecha de finalización (fecha de activación de cuenta)
   */
  endDateFC: FormControl;

  constructor(
    private route: ActivatedRoute,
    private admin: AdminService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.showLoading = true;

    this.p = 1;
    this.redenciones_array = [];

    this.startDateFC = new FormControl('');
    this.endDateFC = new FormControl('');

    this.route.params.subscribe((data) => {
      this.medic_id = data.id;
      this.getMedic();
      this.getPage(this.p);
    });

    this.pastFilters = history.state;
  }

  getActiveFiltersCount() {
    return Object.keys(this.pastFilters).length;
  }

  goBack() {
    if (this.getActiveFiltersCount() > 1) {
      this.router.navigate(['/admin/tracing/medicos'], {
        queryParams: this.pastFilters,
      });
    } else {
      this.location.back();
    }
  }

  getMedic() {
    this.admin.medic_details(this.medic_id).subscribe((data) => {
      this.nombreMedico = data['user_info']['first_name'];
      this.nombre2Medico = data['user_info']['first_name2'];
      this.apellidoMedico = data['user_info']['last_name'];
      this.apellido2Medico = data['user_info']['last_name2'];
    });
  }

  getPage(page) {
    this.admin.view_medic_redemptions(this.medic_id, page).subscribe((data) => {
      this.p = page;
      this.redenciones_array = data['info'];
      this.totalpages = data['page_info']['count'];
      this.itemsPagina = data['page_info']['total_items'];
      this.p = data['page_info']['current'];

      this.showLoading = false;
    });
  }
}
