import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-endpage',
  templateUrl: './endpage.component.html',
  styleUrls: ['./endpage.component.css']
})
export class EndpageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
