import { Component, OnInit } from '@angular/core';
import { ReportService } from './../../services/report.service';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-goal-stadistics',
  templateUrl: './goal-stadistics.component.html',
  styleUrls: ['./goal-stadistics.component.css'],
})
export class GoalStadisticsComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;
  showLoading: boolean = false;

  statsArray: any = [];

  fechaArray: any[] = [];

  public chart: Chart;

  start;
  end;

  constructor(private _admin: ReportService, public _helper: HelpersService) {
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-blue', dateInputFormat: 'YYYY-MM-DD' }
    );
  }

  ngOnInit(): void {}

  eficiency() {
    this.showLoading = true;

    let initYear = (this.start && this.start.getFullYear()) || '';

    let initMonth = (this.start && this.start.getMonth() + 1) || '';

    let initDay = (this.start && this.start.getDate()) || '';

    let endYear = (this.end && this.end.getFullYear()) || '';

    let endMonth = (this.end && this.end.getMonth() + 1) || '';

    let endDay = (this.end && this.end.getDate()) || '';

    let init = (this.start && initYear + '-' + initMonth + '-' + initDay) || '';

    let end = (this.end && endYear + '-' + endMonth + '-' + endDay) || '';

    if (this.start.getTime() > this.end.getTime()) {
      this._helper.modalAlert('Las fechas no coinciden');
    } else {
      this._admin
        .eficiencyStat(init, end)
        .toPromise()
        .then((data) => {
          this.statsArray = data;
          if (this.statsArray != null) {
            this.initCharts(this.statsArray['medicos_registrados_stat']);
            this.initCharts2(this.statsArray['medicos_activos_stat']);
            this.initCharts3(this.statsArray['pacientes_referidos_stat']);
          }
          this.showLoading = false;
        })

        .catch((error) => {
          this._helper.modalAlert('Error al buscar');
        });
    }
  }

  initCharts(fechaArray: any) {
    Chart.defaults.global.defaultFontColor = '#452c80';
    Chart.defaults.global.defaultFontFamily = 'TTCommonsBold';

    // console.log(Object.values(fechaArray));

    this.chart = new Chart('chartEfecty', {
      type: 'bar',
      parentInstance: this,
      data: {
        labels: Object.keys(fechaArray),
        datasets: [
          {
            data: Object.values(fechaArray),
            label: 'Cantidad de Puntos',
            backgroundColor: '#89d3ff',
            borderColor: 'white',
            borderWidth: 1,
            showLine: true,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: 'rgb(208, 202, 223)',
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  initCharts2(fechaArray: any) {
    Chart.defaults.global.defaultFontColor = '#452c80';
    Chart.defaults.global.defaultFontFamily = 'TTCommonsBold';

    // console.log(Object.values(fechaArray));

    this.chart = new Chart('chartEfecty2', {
      type: 'bar',
      parentInstance: this,
      data: {
        labels: Object.keys(fechaArray),
        datasets: [
          {
            data: Object.values(fechaArray),
            label: 'Cantidad de Puntos',
            backgroundColor: '#89d3ff',
            borderColor: 'white',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: 'rgb(208, 202, 223)',
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  initCharts3(fechaArray: any) {
    Chart.defaults.global.defaultFontColor = '#452c80';
    Chart.defaults.global.defaultFontFamily = 'TTCommonsBold';

    // console.log(Object.values(fechaArray));

    this.chart = new Chart('chartEfecty3', {
      type: 'bar',
      parentInstance: this,
      data: {
        labels: Object.keys(fechaArray),
        datasets: [
          {
            data: Object.values(fechaArray),
            label: 'Cantidad de Puntos',
            backgroundColor: '#89d3ff',
            borderColor: 'white',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: 'rgb(208, 202, 223)',
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }
}
