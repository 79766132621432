import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-comprobacion-cuenta',
  templateUrl: './comprobacion-cuenta.component.html',
  styleUrls: ['./comprobacion-cuenta.component.css']
})
export class ComprobacionCuentaComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
  ) {}

  code: string;

  mensaje: string;
  mensaje2: string;
  comprobado: boolean;

  ngOnInit() {
    this.mensaje = '';
    this.activatedRoute.params.subscribe(data => {
      this.code = data['code'];
      this.sendComprobacion();
    });
  }

  sendComprobacion() {
    this.http
      .post(environment.Api_check_account, { code: this.code })
      .subscribe(
        data => {
          console.log(data);
          this.mensaje = '¡Te damos la bienvenida a MEFID!';
          this.mensaje2 =
            'Pronto serás contactado por uno de nuestros asesores comerciales';
          this.comprobado = true;
        },
        error => {
          this.mensaje = '¡Error al comprobar tu cuenta!';
          this.mensaje2 = error.error.error;
          this.comprobado = false;
        }
      );
  }
}
