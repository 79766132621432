import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MedicoService } from '../../../shared/servicio/medico/medico.service';
import { FormControl, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { AdminService } from '../../services/admin/admin.service';

declare var $: any;

@Component({
  selector: 'app-update-medic',
  templateUrl: './update-medic.component.html',
  styleUrls: ['./update-medic.component.css'],
})
export class UpdateMedicComponent implements OnInit {
  // id del medico
  @Input() id_medic: number;

  @Output() reloadEmitter = new EventEmitter();

  // parametros del modal de ngx-bootstrap
  modalRef: BsModalRef;
  config = {
    class: 'modal-md',
  };

  load: boolean = true;
  //inputs del modal
  nombre: string;
  nombre2: string;
  apellido: string;
  apellido2: string;
  telefono: any;
  email: string;
  user: string;
  puntos: number;
  matricula: string;
  isActive: boolean;
  codigo: string;
  referido: string;
  estado: any;
  estado2: any;
  user_id: number;

  // array de datos de especialidades de los medicos para actualizacion
  especialidades_array: Array<any> = [];

  // array de datos de especialidades de los medicos para creacion
  especialidades_array_create: Array<any> = [];

  // array de datos de especialidades de los medicos para eliminacion
  especialidades_array_delete: Array<any> = [];

  // array de los datos de backend muestra informacion sobre las especialidades de  un medico en particular
  especialidades: any;

  //valor string del estado
  text_estado: number;

  // array de los datos de la busqueda en los select de busqueda de formulario
  especialidades_search: any;

  // campo de formulario para mostrar lista de medicos en las actualizacion de
  searchLab: FormControl;

  //SALIDA DE DATOS VALOR BOOLEANO
  @Output() lista = new EventEmitter();

  constructor(
    private modalService: BsModalService,
    public _medic: MedicoService,
    public _helper: HelpersService,
    private admin: AdminService
  ) {
    this.searchLab = new FormControl('', Validators.required);
  }

  ngOnInit() {}

  emitReload() {
    this.reloadEmitter.emit({});
  }

  desactivar() {
    swal({
      title: '¿Deseas bloquear este comercial?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        this.admin.activate_user(this.user_id, false).subscribe(
          (data) => {
            this.modalRef.hide();
            this.emitReload();
          },
          (error) => {}
        );
      }
    });
  }

  activar() {
    this.admin.activate_user(this.user_id, true).subscribe(
      (data) => {
        this.modalRef.hide();
        this.emitReload();
      },
      (error) => {}
    );
  }

  medico_id(medic, template: TemplateRef<any>) {
    this.admin.medic_details(medic).subscribe(
      (data) => {
        console.log(data);
        this.load = false;
        this.nombre = data['user_info']['first_name'];
        this.nombre2 = data['user_info']['first_name2'];
        this.apellido = data['user_info']['last_name'];
        this.apellido2 = data['user_info']['last_name2'];
        this.telefono = data['user_info']['cell'];
        this.isActive = data['user_info']['is_active'];
        this.user_id = data['user_info']['id'];
        this.email = data['user_info']['email'];
        this.user = data['user_info']['dni'];
        this.puntos = data['medic_info']['points'];
        this.matricula = data['medic_info']['profesional_license'];
        this.codigo = data['medic_info']['referral_code'];
        this.estado = data['medic_info']['validated'];
        this.especialidades_array = [];
        this.especialidades_array_delete = [];
        this.especialidades = data['specialties'];
        this.estado2 = data['medic_info']['state'];

        if (
          data['medic_info']['state'] === 5 ||
          data['medic_info']['state'] === 6
        ) {
          this.text_estado = 1;
        } else {
          this.text_estado = 0;
        }

        if (data['referral_info'].length === 0) {
          this.referido = 'No aplica';
        } else {
          this.referido =
            data['referral_info'][0]['medic_patreon__user_id__first_name'] +
            ' ' +
            data['referral_info'][0]['medic_patreon__user_id__last_name'];
        }

        this.modalRef = this.modalService.show(template, this.config);
      },
      (error) => {
        //this.manejador_errores(error);
        this._helper.error('', error);
      }
    );
  }

  // Validacion de datos para las actualizaciones y creaciones de medicos
  validators() {
    var create_medic = [];
    create_medic.push(
      {
        tipo: 'text',
        nombre: 'NOMBRE',
        valor: this.nombre,
        requerido: 'si',
        min_length: 2,
      },
      {
        tipo: 'text',
        nombre: 'NOMBRE2',
        valor: this.nombre2,
        requerido: 'no',
        min_length: 2,
      },
      {
        tipo: 'text',
        nombre: 'APELLIDO',
        valor: this.apellido,
        requerido: 'si',
        min_length: 2,
      },
      {
        tipo: 'text',
        nombre: 'APELLIDO 2',
        valor: this.apellido2,
        requerido: 'no',
        min_length: 2,
      },
      {
        tipo: 'phone',
        nombre: 'CELULAR',
        valor: this.telefono,
        requerido: 'si',
        min_length: 10,
      },
      {
        tipo: 'email',
        nombre: 'CORREO',
        valor: this.email,
        requerido: 'si',
        min_length: 6,
      },
      {
        tipo: 'number',
        nombre: 'DNI',
        valor: this.user,
        requerido: 'si',
        min_length: 5,
      },
      {
        tipo: 'alpha',
        nombre: 'LICENCIA',
        valor: this.matricula,
        requerido: 'si',
        min_length: 5,
      },
      {
        tipo: 'select',
        nombre: 'ESTADO',
        valor: this.estado2,
        requerido: 'si',
        min_length: 1,
      }
    );
    return this._helper.validators(create_medic);
  }
}
