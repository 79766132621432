import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicoService } from 'src/app/shared/servicio/medico/medico.service';
import { FormControl, Validators } from '@angular/forms';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';

@Component({
  selector: 'app-realizar-encuesta',
  templateUrl: './realizar-encuesta.component.html',
  styleUrls: ['./realizar-encuesta.component.css'],
})
export class RealizarEncuestaComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private _medic: MedicoService,
    public dash: DashboardComponent,
    private helper: HelpersService
  ) {}
  surveyId;
  survey_data;

  questionIds;
  questions;
  answers;
  questionFormControls;
  sentMessage;

  loading: boolean;
  sent: boolean;
  dataLista = false;

  preloader: boolean = false;

  radio = [];

  ngOnInit() {
    this.loading = true;
    this.sent = false;
    this.route.params.subscribe((data) => {
      this.surveyId = data.id;
      this._medic.verEncuesta(this.surveyId).subscribe((data) => {
        this.survey_data = data;
        console.log(data);
        this.questionIds = [];
        this.questions = {};

        this.questionFormControls = {};
        this.answers = {};
        this.survey_data.body.forEach((element) => {
          this.radio.push({ questionID: element.question.id, answer: -1 });
          this.dataLista = true;
          this.questionIds.push(element.question.id);
          this.questions[element.question.id] = element.question.question;
          this.answers[element.question.id] = [];
          this.questionFormControls[element.question.id] = new FormControl(
            '',
            Validators.required
          );
          console.log(this.radio);
          element.answers.forEach((ans) => {
            this.answers[element.question.id].push({
              id: ans.id,
              answer: ans.answer,
            });
          });
        });
        this.loading = false;
      });
    });
  }

  isValid(arr) {
    var i;
    var cont = 0;
    for (i = 0; i < arr.length; i++) {
      if (arr[i]['answer'] < 0) {
        cont = cont + 1;
      }
    }
    if (cont > 0) {
      return false;
    } else {
      return true;
    }
  }

  enviar() {
    var data = {
      survey_id: this.surveyId,
      answers: [],
    };

    this.radio.forEach((element) => {
      data.answers.push({
        answer_id: element.answer,
        question_id: element.questionID,
      });
    });

    if (this.isValid(this.radio)) {
      this._medic.enviarEncuesta(data).subscribe(
        (resp) => {
          console.log(resp);
          this.helper.modalSuccess('¡Encuesta finalizada!');
          this.dash.user();
          this.dash.notification();
          this.sent = true;
        },
        (error) => {
          this.preloader = false;
          console.log(error);
          this.helper.modalError('¡Ya habías respondido esta encuesta!');
          this.sent = true;
        }
      );
    } else {
      this.helper.modalError(
        '¡Debes responder todas las preguntas del cuestionario!'
      );
    }
  }

  test(rest, pregunt) {
    this.radio.forEach((element) => {
      if (element.questionID == pregunt) {
        element.answer = rest;
      }
    });
    console.log(this.radio);
  }
}
