import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-admin-asociados',
  templateUrl: './admin-asociados.component.html',
  styleUrls: ['./admin-asociados.component.css'],
})
export class AdminAsociadosComponent implements OnInit {
  modalRef: BsModalRef;

  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(private modalService: BsModalService) {}

  ngOnInit() {}

  openModalCreateAssociate(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
}
