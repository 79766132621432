import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { environment } from 'src/environments/environment';
import { AsistenteServicesService } from './../../services/asistente-services.service';

@Component({
  selector: 'app-formulacion',
  templateUrl: './formulacion.component.html',
  styleUrls: ['./formulacion.component.css'],
})
export class FormulacionComponent implements OnInit {
  medicoModal;
  modalRef: BsModalRef;
  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;
  form;
  formulamedicos: any = [];
  formulaTabla: any = [];
  formulaTablaDiagnostico: any = [];

  constructor(
    private _http: HttpClient,
    private modalService: BsModalService,
    private AsistenteServicesService: AsistenteServicesService,
    private formbuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.getMedicFormula();
  }

  config = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  configModalDetalle = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  add_formula(template: TemplateRef<any>) {
    this._http.get(environment.Api_get_user).subscribe(
      (data) => {
        this.medicoModal = data;
        this.modalRef = this.modalService.show(template, this.config);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getMedicFormula() {
    this._http.get(environment.Api_get_user).subscribe(
      (data) => {
        this.AsistenteServicesService.formulaMedic(data['idmedic'], this.page)
          .toPromise()
          .then((data) => {
            console.log(data);
            this.limit = Math.ceil(data['count']);
            this.count = data['count'];
            this.formulamedicos = data['results'];
            this.showLoading = false;
          })
          .catch((error) => console.log(error));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  reload(r) {
    this.modalRef.hide();
    this.getMedicFormula();
  }

  pageChanged(p) {
    this.page = p.page;
    this.getMedicFormula();
  }

  async AbrirModal(template: TemplateRef<any>, item) {
    this.showLoading = true;
    this.AsistenteServicesService.detalleFormulaAsistente(item.id)
      .toPromise()
      .then((data: any) => {
        this.formulaTabla = data['formuladetail_set'];
        this.formulaTablaDiagnostico = data['diagnostic'];

        this.form = this.formbuilder.group({
          cc_doc: [data.medic_idoc],
          cc_pac: [data.patient_idoc],
          medic_name: [data.medic_first_name.concat(' ', data.medic_last_name)],
          patient_name: [
            data.patient_first_name.concat(' ', data.patient_last_name),
          ],

          profesional_license: [data.profesional_license],
          date_created: [data.date_created],
        });

        this.modalRef = this.modalService.show(
          template,
          this.configModalDetalle
        );
        this.showLoading = false;

        //Swal.close();
      })

      .catch((error) => console.log(error));
  }
}
