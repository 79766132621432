import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { NgForm, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { MedicoService } from '../../shared/servicio/medico/medico.service';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import { AdminService } from '../services/admin/admin.service';
import { formatDate, TypeaheadMatch } from 'ngx-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

// variable para usaer jquery en los componentes
declare var $: any;

@Component({
  selector: 'app-home-admin',

  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.css'],
})
export class HomeAdminComponent implements OnInit {
  //contenedor del componente HTML
  showLoading: boolean;

  // campo de formulario para mostrar lista de medicos en las actualizacion de
  // datos
  searchLab: FormControl;

  // campo de formulario para mostrar lista de medicos en la creación de usuarios
  create_especialidad_input: FormControl;

  // array de los datos de backend muestra informacion sobre medicos
  medicos_array: any;

  // array de los datos de backend muestra informacion sobre las especialidades de
  // un medico en particular
  especialidades: any;

  // array de los datos de la busqueda en los select de busqueda de formulario
  especialidades_search: any;

  // dato para paginacion de resultados
  p: number;
  totalpages: number;
  itemsPagina: any;

  // variable para las busquedas del formulario principal
  criterio: string = 'none';
  searchtext: string = '';
  searchtext2: string = 'none';
  orden: string = 'none';
  activate: number = 0;
  selectradio: any = 0;
  cityFormControl: FormControl;

  // estados por base de datos
  api_estados: any;

  modalRef2: BsModalRef;

  // inputs para crear y actualizar usuarios
  modal_ver_nombre_completo: string = '';
  modal_ver_nombre: string = '';
  modal_ver_nombre2: string = '';
  modal_ver_apellido: string = '';
  modal_ver_apellido2: string = '';
  modal_ver_telefono: string = '';
  modal_ver_email: string = '';
  modal_ver_tipo: string = '';
  modal_ver_user: string = '';
  modal_ver_puntos: number;
  modal_ver_matricula: string = '';
  modal_ver_estado: boolean;
  modal_ver_codigo: string = '';
  modal_ver_nombre_referido: string = '';

  //orden ascendente y descendente de la lista
  order: number = 0;
  direction: number = 0;

  //estado de debugs
  debug: boolean;
  //id de medico
  id_medic: number = 0;

  //estado de medico
  estado: boolean;

  //select estados
  estado2: string = 'none';
  text_estado: number;

  submitted = false;

  comer: any;

  // array de datos de especialidades de los medicos para actualizacion
  especialidades_array: Array<any> = [];

  //Lista de todas las ciudades
  cityList: any;

  ComercialActual: string;

  //Comercial lista

  comerciaList: any = [];

  // array de datos de especialidades de los medicos para creacion
  especialidades_array_create: Array<any> = [];

  // array de datos de especialidades de los medicos para eliminacion
  especialidades_array_delete: Array<any> = [];

  // Identificador del medico
  id: number;

  searchTextFC: FormControl;
  statusSelectorFC: FormControl;
  ciudadSelectorFC: FormControl;
  comercialSelector: FormControl;

  criterioSelectorFC: FormControl;

  /**
   * Contiene el objeto de ciudad seleccionada
   */
  selectedCity;

  /**
   * Input de fecha inicial (fecha de activación de cuenta)
   */
  startDateFC: FormControl;

  selectedComer: any;

  selectID: any;

  /**
   * Input de fecha de finalización (fecha de activación de cuenta)
   */
  endDateFC: FormControl;

  create;

  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    public _medic: MedicoService,
    public _comercial: ComercialService,
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router,
    public _helper: HelpersService,
    private admin: AdminService,
    private activeRoute: ActivatedRoute,
    private modalService: BsModalService
  ) {
    //campos de formularios para los campos select
    this.searchLab = new FormControl('', Validators.required);
    this.create_especialidad_input = new FormControl(null, Validators.required);
  }

  ngOnInit() {
    this.showLoading = true;
    this.medicos_array = [];
    this.searchTextFC = new FormControl('');
    this.statusSelectorFC = new FormControl(0);
    this.ciudadSelectorFC = new FormControl('');
    this.comercialSelector = new FormControl('');
    this.criterioSelectorFC = new FormControl('none');
    this.startDateFC = new FormControl('');
    this.endDateFC = new FormControl('');
    this.p = 1;
    this.listCiudades();
    this.debug_db();
    this.listarComer();
  }

  /**
   * Valida los valores de los input de fecha de inicio y fin para el filtro de lista
   */
  validateDate() {
    var startDate, endDate;
    if (this.startDateFC.value == undefined || this.startDateFC.value == '') {
      startDate = 'none';
    } else {
      startDate = this.getFormatedDate(this.startDateFC.value);
    }

    if (this.endDateFC.value == undefined || this.endDateFC.value == '') {
      endDate = 'none';
    } else {
      endDate = this.getFormatedDate(this.endDateFC.value);
    }
    return [startDate, endDate];
  }

  /**
   * Función que intercepta el evento de elección de fecha del NGX DatePicker
   * @param calendarView Instancia de calendario que disparó el evento
   * @param isStart Booleano que indica si al instancia originaria fue la fecha de inicio o la de fin
   */
  opencalendar(calendarView, isStart) {
    if (isStart) {
      this.startDateFC.setValue('');
    } else {
      this.endDateFC.setValue('');
    }
    calendarView.yearSelectHandler = (event: any): void => {
      calendarView._store.dispatch(calendarView._actions.select(event.date));
    };
    calendarView.setViewMode('day');
  }

  /**
   * Función que toma una fecha completa y en Y-M-D:H-M-S y la transforma a Y-M-D
   * @param joinDate Fecha
   */
  getFormatedDate(joinDate) {
    return formatDate(joinDate, 'YYYY-MM-DD', 'es');
  }

  getMedicState(state) {
    switch (state) {
      case 1:
        return 'Registrado';
      case 2:
        return 'Incomunicado';
      case 3:
        return 'Pendiente';
      case 4:
        return 'No interesado';
      case 5:
        return 'Inactivo';
      case 6:
        return 'Activo';
    }
  }

  /**
   * Obtiene la información de la ciudad seleccionada
   * @param event Evento de opción seleccionada
   */
  onCitySelected(event: TypeaheadMatch): void {
    console.log(event.item);
    this.selectedCity = event.item;
    this.isSearchTextValid(this.searchTextFC.value);
  }

  listCiudades() {
    this._comercial.list_ciudades().subscribe((data) => {
      this.cityList = data;
      this.cityList.push({
        dane_id__dane_code: 10,
        dane_id__name: 'Todas las ciudades',
        id: 0,
      });
      this.loadPreviousFilters();
    });
  }

  /**
   * Obtiene el objeto de ciudad con base en el ID de la ciudad seleccionada
   * @param cityID Id de la ciudad seleccionada
   */
  getCityById(cityID) {
    return this.cityList.find((city) => {
      return city['id'] == cityID;
    });
  }

  /**
   * Obtiene el objeto de ciudad con base en el nombre
   * @param cityName nombre de la ciudad
   */
  getCityByName(cityName) {
    return this.cityList.find((city) => {
      return city['dane_id__name'] == cityName;
    });
  }

  loadPreviousFilters() {
    this.activeRoute.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 1) {
        this.selectedCity = this.getCityByName(params['ciudad']);
        this.ciudadSelectorFC.setValue(this.selectedCity['dane_id__name']);
        this.statusSelectorFC.setValue(params['status']);
        this.criterioSelectorFC.setValue(params['filtro']);
        if (params['startDate'] === '') {
          this.startDateFC.setValue('');
        } else {
          this.startDateFC.setValue(new Date(params['startDate']));
        }
        if (params['endDate'] === '') {
          this.endDateFC.setValue('');
        } else {
          this.endDateFC.setValue(new Date(params['endDate']));
        }
        this.searchTextFC.setValue(params['searchText']);
        this.p = params['page'];
        console.log(params);
      } else {
        this.p = 1;
        this.selectedCity = this.getCityById(0);
        this.ciudadSelectorFC.setValue(this.selectedCity['dane_id__name']);
      }
      this.isSearchTextValid(this.searchTextFC.value);
      this.iniOnValueChangeListeners();
    });
  }

  iniOnValueChangeListeners() {
    this.searchTextFC.valueChanges.subscribe((value) => {
      this.isSearchTextValid(value);
    });
    this.statusSelectorFC.valueChanges.subscribe((value) => {
      this.p = 1;
      this.isSearchTextValid(this.searchTextFC.value);
    });
    this.criterioSelectorFC.valueChanges.subscribe((value) => {
      this.p = 1;
      this.isSearchTextValid(this.searchTextFC.value);
    });
    this.startDateFC.valueChanges.subscribe((onDateChange) => {
      this.p = 1;
      this.isSearchTextValid(this.searchTextFC.value);
    });
    this.endDateFC.valueChanges.subscribe((onDateChange) => {
      this.p = 1;
      this.isSearchTextValid(this.searchTextFC.value);
    });
  }

  receiveReloadSignal = function (event) {
    this.getPage(this.p);
  };

  // Contiene la lista de todos los medicos paginados.
  list_medic(p2) {
    let ciudadValue = 0;
    var [startDate, endDate] = this.validateDate();
    if (this.selectedCity) {
      ciudadValue = this.selectedCity['id'];
    }
    if (p2 === undefined) {
      p2 = 1;
    }
    const requestURL =
      environment.Api_get_all_medic_list +
      ciudadValue +
      '/' +
      0 +
      '/' +
      this.searchtext2 +
      '/' +
      this.statusSelectorFC.value +
      '/' +
      this.order +
      '/' +
      this.direction +
      '/' +
      startDate +
      '/' +
      endDate +
      '/' +
      p2;
    this._http.get(requestURL).subscribe(
      (data) => {
        console.log('lista de médicos');
        console.log(data);
        this.vista_console(data, 'Lista de medicos');
        this.medicos_array = data['info'];
        this.totalpages = data['page_info']['count'];
        this.itemsPagina = data['page_info']['total_items'];
        this.p = data['page_info']['current'];
        this.showLoading = false;
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  emitReload() {
    console.log('Refrescando página...');
    this.showLoading = true;
    this.getPage(this.p);
  }

  /**
   * Función para deshabilitar la cuenta de los médicos
   * @param medicID Id del médico a deshabilitar
   */
  desactivar(medicID) {
    this._helper
      .modalConfirm('¿Desea bloquear a este médico?')
      .then((willDelete) => {
        if (willDelete.value) {
          this.admin.activate_user(medicID, false).subscribe(
            (data) => {
              this.emitReload();
            },
            (error) => {
              console.log('there was an error');
            }
          );
        }
      });
  }

  /**
   * Función para habilitar la cuenta de los médicos
   * @param medicID Id del médico a habilitar
   */
  activar(medicID) {
    this._helper
      .modalConfirm('¿Desea activar este médico?')
      .then((willActivate) => {
        console.log(willActivate);
        console.log(medicID);
        if (willActivate.value) {
          this.admin.activate_user(medicID, true).subscribe(
            (data) => {
              this.emitReload();
            },
            (error) => {
              console.log('there was an error');
            }
          );
        }
      });
  }

  Actualizar(medicID) {
    this._helper
      .modalConfirm('¿Desea actualizar los puntos de  este médico?')
      .then((willActivate) => {
        console.log(willActivate);
        console.log(medicID);
        if (willActivate.value) {
          this.admin.Update(medicID).subscribe(
            (data) => {
              this.emitReload();
            },
            (error) => {
              this.manejador_errores(error);
            }
          );
        }
      });
  }

  // metodo para cambiar la pagina dinamicamente recibe un argumento que es el
  // numero de la pagina
  getPage(p) {
    let ciudadValue = 0;
    var [startDate, endDate] = this.validateDate();
    if (this.selectedCity) {
      ciudadValue = this.selectedCity['id'];
    }
    const requestURL =
      environment.Api_get_all_medic_list +
      ciudadValue +
      '/' +
      this.criterioSelectorFC.value +
      '/' +
      this.searchtext2 +
      '/' +
      this.statusSelectorFC.value +
      '/' +
      this.order +
      '/' +
      this.direction +
      '/' +
      startDate +
      '/' +
      endDate +
      '/' +
      p;
    this._http.get(requestURL).subscribe(
      (data) => {
        this.vista_console(data, 'paginacion');
        this.list_medic(p);
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  clear(id) {
    if (id === 1) {
      if (this.ciudadSelectorFC.value != '') {
        this.ciudadSelectorFC.setValue(null);
      }
    }
  }

  clearComer(id) {
    if (id === 1) {
      if (this.comercialSelector.value != '') {
        this.comercialSelector.setValue(null);
      }
    }
  }

  // metodo para las busquedas recibe un argumento tipo formulario donde pasamos
  // los campos de busqueda a la api
  isSearchTextValid(text) {
    if (text == undefined || text == '') {
      this.searchtext2 = 'none';
    } else {
      this.searchtext2 = text;
    }
    this.list_medic(this.p);
  }

  // metodo que recibe el identificador de un medico para mostrar la informacion
  // del usuario
  medico_id(id: number) {
    //this.dataLista = false;
    //this.spinner.show();
    this._medic.medic_id(id).subscribe(
      (data) => {
        this.vista_console(data, 'Busqueda individual medico');
        //this.spinner.hide();
        //this.dataLista = true;
        this.modal_ver_nombre_completo =
          data['user_info']['first_name'] +
          ' ' +
          data['user_info']['first_name2'] +
          ' ' +
          data['user_info']['last_name'];
        this.modal_ver_nombre = data['user_info']['first_name'];
        this.modal_ver_nombre2 = data['user_info']['first_name2'];
        this.modal_ver_apellido = data['user_info']['last_name'];
        this.modal_ver_apellido2 = data['user_info']['last_name2'];
        this.modal_ver_telefono = data['user_info']['cell'];
        this.modal_ver_email = data['user_info']['email'];
        this.modal_ver_tipo = data['user_info']['role'];
        this.modal_ver_user = data['user_info']['dni'];
        this.modal_ver_puntos = data['medic_info']['points'];
        this.modal_ver_matricula = data['medic_info']['profesional_license'];
        this.modal_ver_codigo = data['medic_info']['referral_code'];
        this.modal_ver_estado = data['medic_info']['validated'];
        this.id_medic = data['medic_info']['id'];
        this.especialidades_array = [];
        this.especialidades_array_delete = [];
        this.especialidades = data['specialties'];
        this.estado2 = data['medic_info']['state'];

        if (
          data['medic_info']['state'] === 5 ||
          data['medic_info']['state'] === 6
        ) {
          this.text_estado = 1;
        } else {
          this.text_estado = 0;
        }

        if (data['referral_info'].length === 0) {
          this.modal_ver_nombre_referido = 'No aplica';
        } else {
          this.modal_ver_nombre_referido =
            data['referral_info'][0]['medic_patreon__user_id__first_name'] +
            ' ' +
            data['referral_info'][0]['medic_patreon__user_id__last_name'];
        }
        $('#ver_medico').modal('show');
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  // Metodo de actualizacion se envian los datos de formulario a la api para
  // actualizacion del medico
  actualizar_medico(update: NgForm) {
    this.vista_console(update.value, 'Datos del formulario de actualizacion');
    //this.dataLista = false;
    if (this.validators() != '') {
      //this.dataLista = true;
      this._helper.modalError(this.validators());
    } else {
      this._medic
        .update_medic(
          this.id_medic,
          update.value['modal_ver_nombre'],
          update.value['modal_ver_nombre2'],
          update.value['modal_ver_apellido'],
          update.value['modal_ver_apellido2'],
          update.value['modal_ver_email'],
          update.value['modal_ver_matricula'],
          update.value['modal_ver_telefono'],
          update.value['modal_ver_user'],
          this.estado2,
          this.especialidades_array,
          this.especialidades_array_delete
        )
        .subscribe(
          (data) => {
            this.vista_console(data, 'Medico actualizado');
            //this.spinner.hide();
            //this.dataLista = true;
            $('#ver_medico').modal('hide');
            this.list_medic(this.p);
          },
          (error) => {
            this.manejador_errores(error);
          }
        );
    }
  }

  // Validacion de datos para las actualizaciones y creaciones de medicos
  validators() {
    var create_medic = [];
    create_medic.push(
      {
        tipo: 'text',
        nombre: 'NOMBRE',
        valor: this.modal_ver_nombre,
        requerido: 'si',
        min_length: 2,
      },
      {
        tipo: 'text',
        nombre: 'APELLIDO',
        valor: this.modal_ver_apellido,
        requerido: 'si',
        min_length: 2,
      },
      {
        tipo: 'text',
        nombre: 'APELLIDO 2',
        valor: this.modal_ver_apellido2,
        requerido: 'no',
        min_length: 2,
      },
      {
        tipo: 'phone',
        nombre: 'CELULAR',
        valor: this.modal_ver_telefono,
        requerido: 'si',
        min_length: 10,
      },
      {
        tipo: 'email',
        nombre: 'CORREO',
        valor: this.modal_ver_email,
        requerido: 'si',
        min_length: 6,
      },
      {
        tipo: 'number',
        nombre: 'DNI',
        valor: this.modal_ver_user,
        requerido: 'si',
        min_length: 5,
      },
      {
        tipo: 'alpha',
        nombre: 'LICENCIA',
        valor: this.modal_ver_matricula,
        requerido: 'si',
        min_length: 5,
      },
      {
        tipo: 'select',
        nombre: 'ESTADO',
        valor: this.estado2,
        requerido: 'si',
        min_length: 1,
      }
    );

    return this._helper.validators(create_medic);
  }

  //metodo que recibe los campos de formulario para agregar medico
  agregar_medico(save: NgForm) {
    //this.dataLista = false;
    //this.spinner.show();
    this.vista_console(save.value, 'Formulario para agregar medico');
    if (this.validators() != '') {
      //this.spinner.hide();
      //this.dataLista = true;
      var data;
      data = this.validators();
      swal({
        title:
          '<img src="assets/icons/error_modal.png" width="80px" style="margin-top:0px;"><' +
          'br>ERROR!',
        html: data,
        confirmButtonText:
          '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
          'to</p>',
        confirmButtonColor: '#6eceee',
      });
    } else {
      this._medic
        .create_medic(
          save.value['modal_ver_nombre'],
          save.value['modal_ver_nombre2'],
          save.value['modal_ver_apellido'],
          save.value['modal_ver_apellido2'],
          save.value['modal_ver_email'],
          save.value['modal_ver_matricula'],
          save.value['modal_ver_telefono'],
          save.value['modal_ver_user'],
          save.value['estado2'],
          this.especialidades_array_create
        )
        .subscribe(
          (data) => {
            this.vista_console(data, 'Registro medico exitoso');
            //this.spinner.hide();
            //this.dataLista = true;
            $('#agregar_medico').modal('hide');
            this.list_medic(this.p);
            this.especialidades_array_create = [];
          },
          (error) => {
            this.manejador_errores(error);
          }
        );
    }
  }

  //metodo que recibe el select2 de angular para hacer la peticion asincrona
  updateEspecialidad(event) {
    this._medic.list_especialidad(event).subscribe(
      (data) => {
        this.especialidades_search = data;
        this.vista_console(data, 'Buscador del select');
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  //metodo para agregar las especialidades al array
  especialidad_form() {
    if (this.searchLab.value === null) {
      this._helper.modalAlert('¡No se han escogido especialidades!');
    } else if (
      this.validar_especialidad(
        this.searchLab.value['id'],
        this.especialidades_array
      ) === true
    ) {
      this._helper.modalAlert(
        '¡Esta especialidad ya se encuentra en el detalle!'
      );
    } else if (
      this.searchLab.value['id'] === undefined ||
      this.searchLab.value['id'] === null
    ) {
      this._helper.modalAlert('¡No se han escogido especialidades!');
    } else if (
      this.validar_medico_especialidad(this.searchLab.value['name']) === true
    ) {
      this._helper.modalAlert('¡Ya se ha agregado la especialidad!');
    } else {
      this.especialidades_array.push({
        id: this.searchLab.value['id'],
        nombre: this.searchLab.value['name'],
      });
    }
  }

  // meotodo que evalua que dentro del array de especialidades  no se encuentren
  // item repetidos
  validar_especialidad(id: number, array: any) {
    var sw = false;
    for (var _i = 0; _i < array.length; _i++) {
      if (array[_i]['id'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  //metodo que evalua las especialidad dentro de la creacion de usuarios
  validar_medico_especialidad(id: string) {
    var sw = false;
    for (var _i = 0; _i < this.especialidades.length; _i++) {
      if (this.especialidades[_i]['specialty_id__name'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  // metodo que evalua las especialidades que se van a eliminar en las
  // actualializaciones
  validar_array_eliminado(id: number) {
    var sw = false;
    for (var _i = 0; _i < this.especialidades_array_delete.length; _i++) {
      if (this.especialidades_array_delete[_i]['id'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  //metodo que elimina un elemento dentro de un array de especialidades
  eliminar_especialidad(number: number, array: any) {
    array.splice(number, 1);
  }

  //metodo que elimina o agrega un elemento dentro de un array de especialidades
  eliminar_especialidad_actual(number: any, id: number) {
    var boton = '#name_' + number;
    if ($(boton).hasClass('btn update_especialidades')) {
      $(boton).removeClass('btn update_especialidades');
      $(boton).addClass('btn delete_especialidades');
      if (this.validar_array_eliminado(id) === false) {
        this.especialidades_array_delete.push({ id: id });
      }
    } else {
      $(boton).removeClass('btn delete_especialidades');
      $(boton).addClass('btn update_especialidades');
      this.especialidades_array_delete.splice(number - 1, 1);
    }
  }

  //metodo para crear un item dentro del del array de especialidades
  create_especialidad() {
    if (this.create_especialidad_input.value === null) {
      this._helper.modalAlert('¡No has escogido ninguna especialidad!');
    } else if (
      this.validar_especialidad(
        this.create_especialidad_input.value['id'],
        this.especialidades_array_create
      ) === true
    ) {
      this._helper.modalAlert(
        '¡Esta especialidad ya se encuentra en el detalle!'
      );
    } else if (
      this.create_especialidad_input.value['id'] === undefined ||
      this.create_especialidad_input.value['id'] === null
    ) {
      this._helper.modalAlert('¡No has elegido ninguna especialidad!');
    } else {
      this.especialidades_array_create.push({
        id: this.create_especialidad_input.value['id'],
        name: this.create_especialidad_input.value['name'],
      });
    }
  }

  // Metodo para  controlar las exepciones cuando falla las conexiones con el
  // servidor
  manejador_errores(error) {
    if (this.debug === true) {
      console.log(error);
    } else {
      console.log('Debug no habilitado');
    }
    if (error.status === 400) {
      this._helper.modalError('¡' + error.error + '!');
    } else if (error.statusText === 'Unknown Error') {
      this._helper.modalError('¡Error de conexión con el servidor!');
    } else if (error.status === 500) {
      this._helper.modalError('¡Error del servidor!');
    } else if (error.status === 403) {
      this._helper.modalError('¡Página no encontrada!');
      this.router.navigate(['/login']);
    }
  }

  // Metodo para mostrar el debug de los console.log en el navegador
  vista_console(data, string: string) {
    if (this.debug === true) {
      console.log(string, data);
    } else {
      console.log('Debug no habilitado');
    }
  }

  // Conexion para ver el estado del debug
  debug_db() {
    this._ip.debug().subscribe(
      (data) => {
        this.debug = data['state'];
        this.vista_console(data, 'valor del debug');
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  ordenamiento(valor: number, orden: number) {
    this.order = valor;
    this.direction = orden;
    this.list_medic(this.p);
  }

  limpiar_modal() {
    this.modal_ver_nombre = '';
    this.modal_ver_apellido = '';
    this.modal_ver_nombre2 = '';
    this.modal_ver_apellido2 = '';
    this.modal_ver_telefono = '';
    this.modal_ver_email = '';
    this.modal_ver_user = '';
    this.modal_ver_matricula = '';
    this.estado = false;
    this.estado2 = 'none';
    this.vista_console('', 'DATOS DEL FORMULARIO LIMPIOS!!');
  }

  //Actualizar lista
  actualizar_lista(event) {
    console.log(event);
    if (event === '1') {
      this.list_medic(this.p);
    }
  }

  /**
   * Angular trackBy
   * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   */
  trackByFn(index, item) {
    return item.id; // or item.id
  }

  modalEditComercial(template: TemplateRef<any>, medicID) {
    document.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        this.limpiarModelo();
      }
    });

    this.admin
      .getComercialActual(medicID)
      .toPromise()
      .then((data: any) => {
        this.comer = data;

        this.modalRef2 = this.modalService.show(template, this.config);
      })

      .catch((error) => {
        this._helper.modalError(error.error.error);
      });
  }

  limpiarModelo() {
    this.selectedComer = null; // Limpiar el modelo
    this.modalRef2.hide();
  }

  updateComercial(medicID) {
    this._helper
      .modalConfirm(
        'Por favor, confirme que desea continuar con este proceso. Recuerde que esta acción es irreversible y esto puede afectar el rendimiento, la comisión y el seguimiento  del comercial.'
      )
      .then((result) => {
        if (result.value) {
          this.admin.UpdateComercial(medicID, this.selectID).subscribe(
            () => {
              this._helper.modalSuccess('¡Datos actualizados correctamente!');
              this.modalRef2.hide();
            },
            (error) => {
              this._helper.modalError(error.error.detail);
            }
          );
        } else {
        }
      });
  }

  listarComer() {
    this.admin.list_comerciales().subscribe(
      (data) => {
        console.log(data);
        this.comerciaList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedComer = event.item.name;

    this.selectID = event.item.id;
  }
}
