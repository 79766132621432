import { ReportService } from './../services/report.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { AdminService } from './../../admin/services/admin/admin.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-asistentes',
  templateUrl: './asistentes.component.html',
  styleUrls: ['./asistentes.component.css'],
})
export class AsistentesComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  constructor(
    private _admin: AdminService,
    private _report: ReportService,
    private formbuilder: FormBuilder
  ) {
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-blue', dateInputFormat: 'DD/MM/YYYY' }
    );
  }

  Asistentes: any = [];
  medicos: any = [];

  medicosType: any;

  token = window.localStorage.getItem('token');

  form;
  modalRef: BsModalRef;
  arrayBlue2 = [];
  medicID;
  date_created_after;
  date_created_before;

  showLoading: boolean = false;
  page = 1;
  limit = 1;
  count = 0;
  config = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  ngOnInit() {
    this.getMedics();
    this.getAsistentes();
  }

  async getMedics(searchTerm: string = '') {
    const params = {
      paginate: true, // Puedes ajustar esto según tus necesidades
      search: searchTerm,
    };

    try {
      const data = await this._report.getMedics(params).toPromise();
      this.medicos = data['results'];
    } catch (error) {
      console.log(error);
    }
  }

  getAsistentes() {
    this.showLoading = true;
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getDate() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    let medicID = this.medicID || 0;

    this._admin
      .Asistentes(medicID, init, end)
      .toPromise()
      .then((data) => {
        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.Asistentes = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.getAsistentes();
  }

  onSelect2(data: Object) {
    this.medicosType = data['item']['name'];
    this.medicID = data['item']['id'];

    console.log(data);
    console.log(this.medicosType);
    // this.medicosType = '';
  }

  xslAsistentes() {
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getDate() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    window.open(
      environment.xslAssistanList +
      this.medicID +
      '/' +
      '?xls' +
      '&' +
      'token=' +
      this.token +
      '&date_created_after=' +
      init +
      '&date_created_before=' +
      end
    );
  }
}
