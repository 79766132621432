import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdminService } from '../../services/admin/admin.service';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {
  @Input() id_state: any;
  @Input() id_banner: number;


  //SALIDA DE DATOS VALOR BOOLEANO
  @Output() lista = new EventEmitter;
  constructor(public Admin: AdminService, public _helper: HelpersService) { }

  ngOnInit() {
  }


  update_state() {
    let state: boolean;
    if (this.id_state === true) { state = false; } else { state = true; }
    this.Admin.updateStateBanner(this.id_banner, state).subscribe(
      data => {
        console.log(data);
        var modalMsg = state ? 'BANNER ACTIVADO' : 'BANNER DESACTIVADO';
        this._helper.modalSuccess(modalMsg);
        this.lista.emit(true);
      },
      error => {
        console.log(error); 
        this._helper.modalAlert(this._helper.splitErrorMessages(error, '<br>'));
      }
    )
  }

}
