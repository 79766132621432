import { Injectable } from '@angular/core';
import { IpService } from './../ip/ip.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginRegisterService {

  constructor(public _ip: IpService,
    private _http: HttpClient,
    public router: Router) { }


  search_countries(city: string): Observable<any> {
    return this._http.get(environment.Api_get_countries + city + '/');
  }

  listaCiudades() {
    return this._http.get(environment.Api_all_cities);
  }
  //Registro de medico
  register_medic(first_name, last_name, address, email, dni, cell, city_id, referral_code, country_code) {
    return this._http.post(environment.Api_register_medic, { first_name, last_name, address, email, dni, cell, city_id, referral_code, country_code });
  }
}



