import { Component, OnInit, Input } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-update-admin-profile',
  templateUrl: './update-admin-profile.component.html',
  styleUrls: ['./update-admin-profile.component.css'],
})
export class UpdateAdminProfileComponent implements OnInit {
  @Input() modalRef: BsModalRef;

  // Inputs

  name: FormControl;
  name2: FormControl;
  email: FormControl;
  cell: FormControl;
  dni: FormControl;
  lastname: FormControl;
  lastname_2: FormControl;
  cellphone_validate: boolean;
  license: any;
  load = true;

  /**
   * Controla el grupo de inputs del modal
   */
  updateAdminProfileInfo: FormGroup;
  /**
   * Booleano que determina si se pulsó la opción de actualizar datos
   */
  submitted: Boolean;

  /**
   * Booleano que determina si está cargando los datos
   */
  isLoading: Boolean;

  constructor(
    private formBuilder: FormBuilder,
    private _http: HttpClient,
    public helper: HelpersService
  ) {}

  ngOnInit() {
    this.isLoading = false;
    this.updateAdminProfile();
    this.loadInfo();
  }

  updateAdminProfile() {
    this.updateAdminProfileInfo = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      name2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastname_2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      cell: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      email: [null, [Validators.required, Validators.email]],
    });
  }

  get shortUpdate() {
    return this.updateAdminProfileInfo.controls;
  }

  /**
   * Carga la información actual al perfil del administrador
   */
  loadInfo() {
    this._http.get(environment.Api_info_user).subscribe(
      (data) => {
        this.load = false;
        console.log(data);
        this.shortUpdate.name.setValue(data['user_info']['first_name']);
        this.shortUpdate.name2.setValue(data['user_info']['first_name2']);
        this.shortUpdate.lastname.setValue(data['user_info']['last_name']);
        this.shortUpdate.lastname_2.setValue(data['user_info']['last_name2']);
        this.shortUpdate.email.setValue(data['user_info']['email']);
        this.shortUpdate.cell.setValue(data['user_info']['cell']);
        this.shortUpdate.dni.setValue(data['user_info']['dni']);
        this.cellphone_validate = data['cellphone_validated'];
        this.license = data['medic_info']['profesional_license'];
      },
      (error) => {
        console.log(error);
        this.helper.modalError('¡No se pudo obtener los datos del perfil!');
      }
    );
  }

  /**
   * Actualiza la información del médico cuando se pulsa "actualizar"
   */
  updateAdminProfileData() {
    console.log(this.updateAdminProfileInfo);
    this.isLoading = true;
    if (this.updateAdminProfileInfo.valid) {
      const first_name1 = this.shortUpdate.name.value;
      const first_name2 = this.shortUpdate.name2.value;
      const last_name1 = this.shortUpdate.lastname.value;
      const last_name2 = this.shortUpdate.lastname_2.value;
      const email = this.shortUpdate.email.value;
      const cell = this.shortUpdate.cell.value;
      const dni = this.shortUpdate.dni.value;
      const country_code = 57;
      const profesional_license = this.license;
      this._http
        .post(environment.Api_info_user, {
          first_name1,
          first_name2,
          last_name1,
          last_name2,
          cell,
          email,
          dni,
          country_code,
          profesional_license,
        })
        .subscribe(
          (data) => {
            this.isLoading = false;
            this.submitted = false;
            this.modalRef.hide();
            this.helper.modalSuccess('¡Datos actualizados correctamente!');
            console.log(data);
          },
          (error) => {
            console.log(error);
            this.isLoading = false;
            this.submitted = false;
            this.helper.modalError(
              this.helper.splitErrorMessages(error.error, '<br>')
            );
          }
        );
    } else {
      this.submitted = true;
      this.isLoading = false;
    }
  }
}
