import { GoalStadisticsComponent } from './reportes/goal-stadistics/goal-stadistics.component';
import { MedicRedemptionComponent } from './reportes/medic-redemption/medic-redemption.component';
import { FormulaMedicComponent } from './reportes/formula-medic/formula-medic.component';
import { ReferirAsistenteComponent } from './referir-asistente/referir-asistente.component';
import { RegistroAsistenteComponent } from './registro-asistente/registro-asistente.component';
import { RegistrometasComponent } from './logros/registrometas/registrometas.component';
import { InfologrosComponent } from './logros/infologros/infologros.component';
import { FormulallComponent } from './reportes/formulall/formulall.component';
import { FormulacionComponent } from './reportes/formulacion/formulacion.component';
import { ProductListComponent } from './reportes/product-list/product-list.component';
import { ComercioRetailComponent } from './reportes/comercio-retail/comercio-retail.component';
import { ReportePuntosComponent } from './reportes/reporte-puntos/reporte-puntos.component';
import { ReporteMedicoPacientesComponent } from './reportes/reporte-medico-pacientes/reporte-medico-pacientes.component';
import { AdminAsociadosComponent } from './admin-asociados/admin-asociados.component';
import { MenuComponent } from './menu/menu.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeAdminComponent } from './admin-medicos/home-admin.component';
import { AdminPacientesComponent } from './admin-pacientes/admin-pacientes.component';
import { AdminComercialesComponent } from './admin-comerciales/admin-comerciales.component';
import { GraficasComercialComponent } from './graficas/graficas-comercial/graficas-comercial.component';
import { MensajeriaComponent } from './mensajeria/mensajeria.component';
import { GraficasMedicosComponent } from './graficas/graficas-medicos/graficas-medicos.component';
import { BannersComponent } from './banners/banners.component';
import { EncueestasComponent } from './encueestas/encueestas.component';
import { CreateQuestionsComponent } from './encueestas/create-questions/create-questions.component';
import { VerEncuestasComponent } from './encueestas/ver-encuestas/ver-encuestas.component';
import { AdminAdminsComponent } from './admin-admins/admin-admins.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { CiudadesComponent } from './ciudades/ciudades.component';
import { RedencionesComponent } from './redenciones/redenciones.component';
import { LogsComponent } from './logs/logs.component';
import { StatsComponent } from './stats/stats.component';
import { LogrosComponent } from './logros/logros.component';
import { ComercialActivosComponent } from './comercial-activos/comercial-activos.component';
import { ComercioNotUsedComponent } from './reportes/comercio-not-used/comercio-not-used.component';
import { AsistentesComponent } from './asistentes/asistentes.component';

const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      { path: '', redirectTo: 'tracing/medicos', pathMatch: 'full' },
      { path: 'tracing/medicos', component: HomeAdminComponent },
      { path: 'tracing/admins', component: AdminAdminsComponent },
      { path: 'tracing/asociados', component: AdminAsociadosComponent },
      { path: 'tracing/comerciales', component: AdminComercialesComponent },
      { path: 'tracing/pacientes', component: AdminPacientesComponent },
      { path: 'comercial/graphics/:id', component: GraficasComercialComponent },
      {
        path: 'comercial/:commercial_id/:pagination_id',
        component: ComercialActivosComponent,
      },
      { path: 'mensajeria', component: MensajeriaComponent },
      { path: 'medic/graphics/:id', component: GraficasMedicosComponent },
      { path: 'banners', component: BannersComponent },
      { path: 'surveys', component: EncueestasComponent },
      { path: 'notificaciones', component: NotificacionesComponent },
      { path: 'survey-info/:id', component: VerEncuestasComponent },
      { path: 'ciudades', component: CiudadesComponent },
      { path: 'redenciones/medico/:id', component: RedencionesComponent },
      { path: 'secret/logs', component: LogsComponent },
      { path: 'estadisticas', component: StatsComponent },
      { path: 'logros', component: LogrosComponent },
      { path: 'reporteMed', component: ReporteMedicoPacientesComponent },
      { path: 'reportePun', component: ReportePuntosComponent },
      { path: 'comretail', component: ComercioRetailComponent },
      { path: 'comnotused', component: ComercioNotUsedComponent },
      { path: 'prolist', component: ProductListComponent },
      { path: 'formulacion', component: FormulacionComponent },
      { path: 'formula', component: FormulallComponent },
      { path: 'infologros', component: InfologrosComponent },
      { path: 'registrometas', component: RegistrometasComponent },
      { path: 'asistentes', component: AsistentesComponent },
      { path: 'registroAsistentes', component: RegistroAsistenteComponent },
      { path: 'referirAsistente', component: ReferirAsistenteComponent },
      { path: 'formulaMedic', component: FormulaMedicComponent },
      { path: 'medicRedemption', component: MedicRedemptionComponent },
      { path: 'goalStat', component: GoalStadisticsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
