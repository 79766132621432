import {
  Component,
  OnInit,
  TemplateRef,
  Output,
  EventEmitter
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AdminService } from '../../services/admin/admin.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
declare var require: any;
let fileUpload = require('fuctbase64');

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {
  //SALIDA DE DATOS VALOR BOOLEANO
  @Output() lista = new EventEmitter();

  // parametros del modal de ngx-bootstrap
  modalRef: BsModalRef;
  config = {
    class: 'modal-dialog-centered modal-app modal-app-lg',
    backdrop: true,
    ignoreBackdropClick: true
  };

  //formulario para agregar
  selectedFile: File = null;
  bannerAdd: FormGroup;
  name: FormControl;
  file: FormControl;
  file2: FormControl;
  typeBanner: FormControl;

  //mostrar errores de validación
  submitted: boolean;
  format_file: boolean;
  format_file2: boolean;
  fileResult: any;
  fileResult2: any;

  // preloaders de carga
  preloader: boolean = false;
  div_error: number = 0;
  div_text_error: string = '';

  fileProps: any;
  file2Props: any;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public admin: AdminService,
    private modalService: BsModalService,
    public _helper: HelpersService
  ) {}

  ngOnInit() {
    this.fileProps = null;
    this.file2Props = null;

    this.submitted = false;
    this.format_file = false;
    this.format_file2 = false;
    this.formBannerAdd();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
    this.div_error = 0;
    this.div_text_error = '';
    this.formBannerAdd();
  }

  closeModal() {
    this.modalRef.hide();
  }

  formBannerAdd() {
    this.bannerAdd = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(150),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ0-9 ]*')
        ]
      ],

      description: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(150),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ0-9.,;: ]*')
        ]
      ],

      file: [null, [Validators.required]],

      file2: [null, [Validators.required]]
    });
  }

  get shortBannerAddForm() {
    return this.bannerAdd.controls;
  }

  send_image() {
    //console.log(this.bannerAdd.value.name);
    this.preloader = true;
    if (
      this.bannerAdd.valid &&
      this.format_file === true &&
      this.format_file === true
    ) {
      const image = this.fileResult;
      const image2 = this.fileResult2;
      this.admin
        .createBanner(
          this.bannerAdd.value.name,
          this.bannerAdd.value.description,
          image['__zone_symbol__value']['base64'],
          image2['__zone_symbol__value']['base64']
        )
        .subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              console.log(
                'Upload progress: ' +
                  Math.round(event.loaded / event.total) * 100 +
                  ' %'
              );
            } else if (event.type === HttpEventType.Response) {
              console.log(event);
              this.div_error = 2;
              this.div_text_error = 'Banner ha sido guardado correctamente';
              this.formBannerAdd();
              this.preloader = false;
              this.lista.emit(true);
              this.bannerAdd.reset();
              this.closeModal();
              this._helper.modalSuccess('¡Banner creado satisfactoriamente!');
            }
          },
          error => {
            this.preloader = false;
            this.div_error = 1;
            this.div_text_error = this._helper.error('', error);
          }
        );
    } else if (this.bannerAdd.invalid) {
      this.preloader = false;
      this.submitted = true;
    } else {
      this.preloader = false;
    }
  }

  onFileSelected(event) {
    this._helper.modalInfo(
      '¡Se recomienda que las imágenes tengan una relación de aspecto de 14:3 (Ejemplo: 1400x300)!'
    );

    const file: File = event.target.files.item(0);

    if (file != undefined) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/PNG' ||
        file.type === 'image/jpg' ||
        file.type === 'image/JPG' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/JPEG'
      ) {
        this.fileProps = file.name;
        console.log('formato admitido');
        this.format_file = true;
        let result = fileUpload(event);
        this.fileResult = result;
      } else {
        this.format_file = false;
        this.fileProps = null;
      }
    } else {
      this.fileProps = null;
    }
  }

  onFileSelected2(event) {
    this._helper.modalInfo(
      '¡Se recomienda que las imágenes tengan una relación de aspecto de 9:5 (Ejemplo: 1080x600)!'
    );

    const file: File = event.target.files.item(0);

    if (file != undefined) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/PNG' ||
        file.type === 'image/jpg' ||
        file.type === 'image/JPG' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/JPEG'
      ) {
        this.file2Props = file.name;
        console.log('formato admitido');
        this.format_file2 = true;
        let result = fileUpload(event);
        this.fileResult2 = result;
        console.log(result);
      } else {
        this.format_file2 = false;
        this.file2Props = null;
      }
    } else {
      this.file2Props = null;
    }
  }
}
