import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { PacientesService } from 'src/app/shared/servicio/pacientes/pacientes.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';

@Component({
  selector: 'app-add-patients',
  templateUrl: './add-patients.component.html',
  styleUrls: ['./add-patients.component.css'],
})
export class AddPatientsComponent implements OnInit {
  @Output() addpatient = new EventEmitter();

  create_patients: FormGroup;
  type_dni: FormControl;
  preloader = false;
  selectecDni: any = null;
  selectSpecialties: any = null;
  specialties: any;
  submitted = false;
  paramDni: any[] = [
    { value: 0, name: 'Cédula de ciudadanía' },
    { value: 1, name: 'Cédula de extranjería' },
  ];
  constructor(
    private formBuilder: FormBuilder,
    public _pacientes: PacientesService,
    public _helper: HelpersService
  ) {}

  ngOnInit() {
    this.createForm();
    this.specialty_medic();
  }

  createForm() {
    this.create_patients = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      cell: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      especialidad: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ. ]*'),
        ],
      ],

      type_dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],
    });
  }

  get shortCreate() {
    return this.create_patients.controls;
  }

  specialty_medic() {
    this._pacientes.especialidad_medico().subscribe((data) => {
      console.log(data);
      this.specialties = data;
    });
  }

  create() {
    // alert(this.selectecDni['value']);
    console.log(this.create_patients);

    if (this.selectecDni === null) {
      this._helper.modalError(
        '¡No has seleccionado tipo de cédula de la lista!'
      );
    } else if (this.selectSpecialties === null) {
      this._helper.modalError('¡No has seleccionado especialidad de la lista!');
    } else {
      this.preloader = true;
      if (this.create_patients.valid) {
        this._pacientes
          .create_paciente(
            this.shortCreate.name.value,
            this.shortCreate.lastname.value,
            this.selectecDni['value'],
            this.shortCreate.dni.value,
            this.shortCreate.cell.value,
            this.selectSpecialties['specialty_id__id']
          )
          .subscribe(
            (data) => {
              this.preloader = false;
              this.submitted = false;
              this.addpatient.emit(true);
              this._helper.modalSuccess(data['message']);
              console.log(data);
            },
            (error) => {
              console.log(error);
              this.preloader = false;
              this.submitted = false;
              this._helper.modalError(
                this._helper.splitErrorMessages(error.error, '<br>')
              );
            }
          );
      } else {
        this.submitted = true;
        this.preloader = false;
      }
    }
  }

  clear(id) {
    if (id === 1) {
      if (this.shortCreate.type_dni.value != '') {
        this.shortCreate.type_dni.setValue(null);
      }
    } else if (id === 2) {
      if (this.shortCreate.especialidad.value != '') {
        this.shortCreate.especialidad.setValue(null);
      }
    }
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectecDni = event.item;
    console.log(this.selectecDni);
  }

  onSelect2(event: TypeaheadMatch): void {
    this.selectSpecialties = event.item;
  }
}
