import { Component, OnInit } from '@angular/core';
import { ReportService } from './../../services/report.service';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
@Component({
  selector: 'app-medic-redemption',
  templateUrl: './medic-redemption.component.html',
  styleUrls: ['./medic-redemption.component.css'],
})
export class MedicRedemptionComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  constructor(private medic: ReportService, public _helper: HelpersService) {
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-blue', dateInputFormat: 'DD/MM/YYYY' }
    );
  }

  medicRedemption: any = [];

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;
  token = window.localStorage.getItem('token');
  date_created_after;
  date_created_before;
  date_referred;
  ngOnInit() {
    this.showLoading = true;

    this.getRedemption();
  }

  getRedemption() {
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getDate() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    this.medic
      .getMedicRedemption(this.page, init, end)
      .toPromise()
      .then((data) => {
        console.log(data);
        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.medicRedemption = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.getRedemption();
  }

  xslgetRedepmtionAdmin() {
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getDate() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    window.open(
      environment.xslRedepmtionAdminList +
        '?xls' +
        '&' +
        'token=' +
        this.token +
        '&date_created_after=' +
        init +
        '&date_created_before=' +
        end
    );
  }
}
