import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { NgForm, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { MedicoService } from '../../shared/servicio/medico/medico.service';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import { AdminService } from '../services/admin/admin.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var $: any;

@Component({
  selector: 'app-admin-admins',
  templateUrl: './admin-admins.component.html',
  styleUrls: ['./admin-admins.component.css']
})
export class AdminAdminsComponent implements OnInit {
  newAdminModalRef: BsModalRef;

  addAdminModalConfig = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true
  };

  //contenedor del componente HTML
  showLoading: boolean;

  // array de los datos de backend muestra informacion sobre comerciales
  adminList: any;

  searchTextFC: FormControl;
  criterioSelectorFC: FormControl;

  // dato para paginacion de resultados
  page: number;
  p: number;
  totalpages: number;
  itemsPagina: any;

  // variable para las busquedas del formulario principal
  criterio: string = 'none';
  searchtext2: string = 'none';
  orden: string = 'none';
  selectradio: any = 0;

  // inputs para crear y actualizar comerciales
  modal_ver_nombre: string = '';
  modal_ver_nombre2: string = '';
  modal_ver_apellido: string = '';
  modal_ver_apellido2: string = '';
  modal_ver_telefono: string = '';
  modal_ver_email: string = '';
  modal_ver_user: string = '';
  modal_ver_city: string = 'none';

  preloader: boolean = false;

  //Lista de ciudades
  cityList: any;

  //orden ascendente y descendente de la lista
  order: number = 0;
  direction: number = 0;

  //estado de debugs
  debug: boolean;

  //estado de medico
  estado: boolean;

  //select estados
  estado2: string = 'none';
  text_estado: number;

  // Identificador del medico
  id: number;

  constructor(
    private admin: AdminService,
    public _medic: MedicoService,
    public _comercial: ComercialService,
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router,
    public _helper: HelpersService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.showLoading = true;
    this.cityList = [];
    this.searchTextFC = new FormControl('');
    this.criterioSelectorFC = new FormControl('none');
    this.p = 1;
    this.listCiudades();
    this.list_admin(this.p);
    this.debug_db();
    this.searchTextFC.valueChanges.subscribe(value => {
      this.search_comercial(value);
    });
    this.criterioSelectorFC.valueChanges.subscribe(value => {
      this.p = 1;
      this.list_admin(this.p);
    });
  }

  listCiudades() {
    this._comercial.list_ciudades().subscribe(data => {
      console.log(data);
      this.cityList = data;
    });
  }

  receiveReloadSignal = function(event) {
    this.getPage(this.p);
  };

  // Contiene la lista de todos los medicos paginados.
  list_admin(p2) {
    if (p2 === undefined) {
      p2 = 1;
    }

    this.vista_console(p2, 'Valor de pagina');

    if (this.searchtext2 === '') {
      this.searchtext2 = 'none';
    }

    this.vista_console(this.searchtext2, 'Valor de busqueda');

    this.admin
      .get_admin_list(
        this.criterio,
        this.searchtext2,
        this.order,
        this.direction,
        p2
      )
      .subscribe(
        data => {
          this.vista_console(data, 'Lista de comerciales');
          this.adminList = data['info'];
          this.totalpages = data['page_info']['count'];
          this.itemsPagina = data['page_info']['total_items'];
          this.p = data['page_info']['current'];

          this.showLoading = false;
        },
        error => {
          this.manejador_errores(error);
        }
      );
  }

  // metodo para cambiar la pagina dinamicamente recibe un argumento que es el
  // numero de la pagina
  getPage(p) {
    this.admin
      .get_admin_list(
        this.criterio,
        this.searchtext2,
        this.order,
        this.direction,
        p
      )
      .subscribe(
        data => {
          this.vista_console(data, 'paginacion');
          this.list_admin(p);
        },
        error => {
          this.manejador_errores(error);
        }
      );
  }

  // metodo para las busquedas recibe un argumento tipo formulario donde pasamos
  // los campos de busqueda a la api
  search_comercial(name) {
    if (name == undefined || name == '') {
      this.searchtext2 = 'none';
    }
    this.vista_console(name, 'Buscar en la lista de comerciales');
    this.criterio = this.criterioSelectorFC.value;
    this.searchtext2 = name;
    this.list_admin(this.p);
  }

  // Metodo para  controlar las exepciones cuando falla las conexiones con el
  // servidor
  manejador_errores(error) {
    if (this.debug === true) {
      console.log(error);
    } else {
      console.log('Debug no habilitado');
    }

    if (error.status === 400) {
      this._helper.modalError('¡' + error.error + '!');
    } else if (error.statusText === 'Unknown Error') {
      this._helper.modalError('¡Error de conexión con el servidor!');
    } else if (error.status === 500) {
      this._helper.modalError('¡Error del servidor!');
    } else if (error.status === 403) {
      this._helper.modalError('¡Página no encontrada!');
      this.router.navigate(['/login']);
    }
  }

  // Metodo para mostrar el debug de los console.log en el navegador
  vista_console(data, string: string) {
    if (this.debug === true) {
      console.log(string, data);
    } else {
      console.log('Debug no habilitado');
    }
  }

  // Conexion para ver el estado del debug
  debug_db() {
    this._ip.debug().subscribe(
      data => {
        this.debug = data['state'];
        this.vista_console(data, 'valor del debug');
      },
      error => {
        this.manejador_errores(error);
      }
    );
  }

  ordenamiento(valor: number, orden: number) {
    this.order = valor;
    this.direction = orden;
    this.list_admin(this.p);
  }

  //Actualizar lista
  actualizar_lista() {
    this.list_admin(this.p);
  }

  /**
   * Función que restaura la contraseña del administrador actual. Ejecuta el servicio que envía las contraseñas al correo electrónico.
   * @param adminID ID del administrador
   */
  resendCredentials(adminID) {
    this.admin.resend_credentials(adminID).subscribe(
      data => {
        this._helper.modalSuccess(
          '¡Se envió la nueva contraseña al correo del usuario!'
        );
      },
      error => {
        this._helper.modalError(
          '¡Ocurrió un error intentando restablecer las credenciales!'
        );
      }
    );
  }

  /**
   * Abre el template modal para agregar al nuevo administrador
   * @param template template de agregar administrador
   */
  openAddNewAdminModal(template: TemplateRef<any>) {
    this.newAdminModalRef = this.modalService.show(
      template,
      this.addAdminModalConfig
    );
  }
}
