import { Component, OnInit } from '@angular/core';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-formulacion',
  templateUrl: './formulacion.component.html',
  styleUrls: ['./formulacion.component.css'],
})
export class FormulacionComponent implements OnInit {
  constructor(private medic: ReportService, public _helper: HelpersService) {}

  medics: any = [];
  medicPatient: any = [];

  showLoading: boolean = false;
  page = 1;
  limit = 1;
  count = 0;
  pacientes: any = [];
  patientType: any;
  patientID;
  medicsSelect;
  ngOnInit() {
    this.getPatient();
  }

  getPatient() {
    this.medic
      .getPatients()
      .toPromise()
      .then((data) => {
        // console.log(data);
        // this.limit = Math.ceil(data['count'] / 10);
        // this.count = data['count'];
        this.pacientes = data;
        // this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  getMedicPatient() {
    this.showLoading = true;

    this.medic
      .getMedicFormulaPatient(this.patientID)
      .toPromise()
      .then((data) => {
        // console.log(data);
        this.limit = Math.ceil(data['count'] / 10);
        this.count = data['count'];
        this.medicPatient = data['results'];
        this.showLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this._helper.modalAlert(error.error.error);
        this.showLoading = false;
      });
  }

  onSelect2(data: Object) {
    this.patientType = data['item']['cc'];
    this.patientID = data['item']['id'];
    console.log(this.patientType);
    // this.medicosType = '';
  }
}
