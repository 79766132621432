import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { FormControl } from '@angular/forms';
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';

@Component({
  selector: 'app-encueestas',
  templateUrl: './encueestas.component.html',
  styleUrls: ['./encueestas.component.css'],
})
export class EncueestasComponent implements OnInit {
  subscriptions: Subscription[] = [];

  listaEncuestas;
  searchtext;
  p: number;
  totalItems: number;
  perPage: number;
  crearRef;
  direccion;

  @ViewChild(ModalDirective) edit: ModalDirective;

  edit_id;

  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  config3 = {
    class: 'modal-dialog-centered modal-app modal-app-lg',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  config = {
    class: 'modal-dialog-centered modal-app modal-app-lg',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  // Booleano de carga
  showLoading: boolean;

  constructor(
    private admin: AdminService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private _helper: HelpersService
  ) {}

  ngOnInit() {
    this.showLoading = true;

    this.searchtext = new FormControl('');
    this.p = 1;
    this.direccion = 0;
    this.totalItems = 0;
    this.perPage = 10;
    this.filter(1);
    this.edit_id = 0;
    this.searchtext.valueChanges.subscribe((data) => {
      this.filter(1);
    });
  }

  activateEncuesta(id) {
    this.admin.toggleActivateSurvey(id, true).subscribe((data) => {
      this.filter(this.p);
      this._helper.modalSuccess('¡Encuesta activada!');
    });
  }

  deactivateEncuesta(id) {
    this._helper
      .modalConfirm('¿Desea desactivar la encuesta?')
      .then((willDelete) => {
        if (willDelete.value) {
          this.admin.toggleActivateSurvey(id, false).subscribe((data) => {
            this.filter(this.p);
            this._helper.modalSuccess('¡Encuesta desactivada!');
          });
        }
      });
  }

  openCreateModal(modalRef) {
    this.modalRef = this.modalService.show(modalRef, this.config3);
    const events = combineLatest(this.modalService.onHidden).subscribe(() =>
      this.changeDetection.markForCheck()
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.filter(this.p);
        console.log('ininin');
        this.unsubscribe();
      })
    );
  }

  openModal(id, modalRef) {
    this.edit_id = id;
    this.modalRef2 = this.modalService.show(modalRef, this.config3);
    const events = combineLatest(this.modalService.onHidden).subscribe(() =>
      this.changeDetection.markForCheck()
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.filter(this.p);
        console.log('ininin2');
        this.unsubscribe();
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  eliminarEncuesta(id) {
    this._helper
      .modalConfirm('¿Desea eliminar esta encuesta?')
      .then((willDelete) => {
        if (willDelete.value) {
          this.admin.delete_survey(id).subscribe(
            (data) => {
              this.filter(this.p);
              this._helper.modalSuccess('¡Encuesta eliminada!');
            },
            (error) => {
              this._helper.modalAlert('¡No se pudo eliminar la encuesta!');
            }
          );
        }
      });
  }

  toggleDireccion() {
    this.showLoading = true;
    this.direccion = this.direccion == 0 ? 1 : 0;
    this.filter(this.p);
  }

  filter(page) {
    var param = this.searchtext.value;
    var filtro = 'title';
    if (param == '') {
      filtro = 'none';
      param = 'none';
    }
    this.admin.list_surveys(filtro, param, 0, this.direccion, page).subscribe(
      (data) => {
        console.log(data);
        this.totalItems = data['page_info']['count'];
        this.perPage = data['page_info']['total_items'];
        this.p = data['page_info']['current'];
        this.listaEncuestas = data['info'];
        console.log('filtrado');

        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
