import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from './../ip/ip.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MedicoService {
  constructor(
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router
  ) { }

  // Api que devuelve los datos de un medico por ID
  medic_id(id: number) {
    return this._http.get(environment.Api_medic_id + id);
  }

  // Api que actualiza un medico
  update_medic(
    id: number,
    first_name: string,
    first_name2: string,
    last_name: string,
    last_name2: string,
    email: string,
    profesional_license: string,
    cell: string,
    dni: string,
    state: any,
    specialties: Array<any>,
    specialties_delete: Array<any>
  ) {
    return this._http.post(environment.Api_medic_id + id + '/', {
      first_name,
      first_name2,
      last_name,
      last_name2,
      email,
      profesional_license,
      cell,
      dni,
      state,
      country_code: '57',
      specialties,
      specialties_delete,
    });
  }

  update_medic_address(
    id: number,
    first_name: string,
    first_name2: string,
    address: string,
    city_id: string,
    last_name: string,
    last_name2: string,
    email: string,
    profesional_license: string,
    cell: string,
    dni: string,
    state: any,
    specialties: Array<any>,
    specialties_delete: Array<any>
  ) {
    return this._http.post(environment.Api_medic_id + id + '/', {
      first_name,
      first_name2,
      address,
      city_id,
      last_name,
      last_name2,
      email,
      profesional_license,
      cell,
      dni,
      state,
      country_code: '57',
      specialties,
      specialties_delete,
    });
  }

  // Api que devuelve las especialidades en general
  list_especialidad(data: string) {
    return this._http.get(environment.Api_get_especialties + data);
  }

  getAllSpecialties() {
    return this._http.get(environment.Api_get_especialties2);
  }

  list_estados() {
    return this._http.get(environment.Api_get_states);
  }

  create_medic(
    first_name: string,
    first_name2: string,
    last_name: string,
    last_name2: string,
    email: string,
    profesional_license: string,
    cell: string,
    dni: string,
    state: number,
    specialties: Array<any>
  ) {
    return this._http.post(environment.Api_medic_register, {
      first_name,
      first_name2,
      last_name,
      last_name2,
      email,
      profesional_license,
      cell,
      country_code: '57',
      dni,
      state,
      specialties,
    });
  }

  create_medic_adress(
    first_name: string,
    first_name2: string,
    address: string,
    city_id: string,
    last_name: string,
    last_name2: string,
    email: string,
    profesional_license: string,
    cell: string,
    dni: string,
    cc_type: string,
    state: number,
    specialties: Array<any>
  ) {
    return this._http.post(environment.Api_medic_register, {
      first_name,
      first_name2,
      address,
      city_id,
      last_name,
      last_name2,
      email,
      profesional_license,
      cell,
      country_code: '57',
      dni,
      cc_type,
      state,
      specialties,
    });
  }

  graphics_medic(year: string) {
    return this._http.get(environment.Api_get_medic_statistics + year + '/');
  }

  list_banner() {
    return this._http.get(environment.Api_list_bannerMedic);
  }

  getListaEncuestas() {
    return this._http.get(environment.Api_list_surveys);
  }

  verEncuesta(id) {
    return this._http.get(environment.Api_view_survey + id);
  }

  enviarEncuesta(data) {
    return this._http.post(environment.Api_send_survey, data);
  }

  prize() {
    return this._http.get(environment.Api_get_prize);
  }

  redeem(reward_id, brand_id, site_id) {
    let body = {
      reward_id: reward_id,
      brand_id: brand_id,
      site_id: site_id,
    };
    console.log(body);

    return this._http.post(environment.api_get_rem, body);
  }

  getBrand(reward_id) {
    const params = reward_id;
    return this._http.get(environment.Api_get_brand + params);
  }

  getZones(reward_id, brand_id) {
    const params = reward_id + '/' + brand_id;
    return this._http.get(environment.Api_get_zones + params);
  }

  getCitys(reward_id, brand_id, zone_id) {
    const params = reward_id + '/' + brand_id + '/' + zone_id;
    return this._http.get(environment.Api_get_citys + params);
  }

  getSities(reward_id, brand_id, zone_id, city_id) {
    const params = reward_id + '/' + brand_id + '/' + zone_id + '/' + city_id;
    return this._http.get(environment.Api_get_site + params);
  }

  verified_cell(code) {
    return this._http.post(environment.api_verified_cell, { code });
  }

  pointPersonal() {
    return this._http.get(environment.api_mypoints);
  }

  statusPoint() {
    return this._http.get(environment.api_statement_point + 1);
  }
  formulaMedic(idmedic, page) {
    let params = idmedic + '?page=' + page;
    return this._http.get(environment.formula + params);
  }

  asistenteMedico(page) {
    let params = '?page=' + page;
    return this._http.get(environment.listaAsistentes);
  }

  products(name) {
    let params = '?name=' + name || '';
    return this._http.get(environment.productsmedics + params);
  }

  diagnosticos(name) {
    let params = '?name=' + name || '';
    return this._http.get(environment.diagnosticos + params);
  }

  formulaDetail(id) {
    let params = id;
    return this._http.get(environment.detalleFormula + params);
  }

  crearFormula(
    medic_id,
    patient_id,
    email_patient,
    description,
    diagnostic,
    formuladetail_set
  ) {
    const body = {
      medic_id: medic_id,
      patient_id: patient_id,
      email_patient: email_patient,
      description: description,
      diagnostic: diagnostic,
      formuladetail_set: formuladetail_set,
    };

    return this._http.post(environment.crearFormula, body);
  }

  crearAsistente(dni, email, first_name, last_name, cell) {
    const body = {
      dni: dni,
      email: email,
      first_name: first_name,
      last_name: last_name,
      cell: cell,
    };

    return this._http.post(environment.crearAsistente, body);
  }

  buscarPaciente(cc) {
    const body = {
      cc: cc,
    };

    return this._http.post(environment.BuscarPaciente, body);
  }
  getLogrosMedico(page) {
    let params = '?page=' + page;
    return this._http.get(environment.medicoLogros + params);
  }

  getLogrosMedicoCompletado(page) {
    let params = '?page=' + page;
    return this._http.get(environment.MedicAchivment + params);
  }

  getReferidoAsistente(page) {
    let params = '?page=' + page;
    return this._http.get(environment.ListadoMedicoReferido + params);
  }

  getReferidoLista(page) {
    let params = '?page=' + page;
    return this._http.get(environment.referrelMedic + params);
  }

  createPhtoto(body) {
    return this._http.post(environment.CreatePhoto, body);
  }

  changeStatusAssistant(data, newState) {
    let params = data.id + '/';

    const requestBody = {
      state: newState,
    };

    return this._http.put(
      environment.changeStatusAssistant + params,
      requestBody
    );
  }
}
