import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService } from '../services/admin/admin.service';
import { MedicoService } from 'src/app/shared/servicio/medico/medico.service';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-mensajeria',
  templateUrl: './mensajeria.component.html',
  styleUrls: ['./mensajeria.component.css'],
})
export class MensajeriaComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  mensajeFormGroup: FormGroup;

  especialidades_search;

  // array de datos de especialidades de los medicos para actualizacion
  especialidades_array: Array<any> = [];

  // campo de formulario para mostrar lista de medicos en las actualizacion de
  searchLab: FormControl;

  // array de los datos de backend muestra informacion sobre las especialidades de  un medico en particular
  especialidades: any;

  loading;

  listaCanales;

  listaEspecialidades;

  listaTiposMensaje;

  MensajesArray: any = [];

  date_created_after;
  date_created_before;
  medic_id__user_id__dni__icontains;
  medic_id_user_iddni_icontains;
  message_id__date_created;

  page = 1;
  limit = 1;
  count = 0;

  showLoading: boolean;

  constructor(
    private admin: AdminService,
    private _medic: MedicoService,
    private helper: HelpersService
  ) {
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-blue', dateInputFormat: 'YYYY/MM/DD' }
    );
  }

  ngOnInit() {
    this.getMessagges();

    this.especialidades = [];
    this.loading = true;
    this.listaCanales = [];
    this.especialidades_array = [];
    this.searchLab = new FormControl('', Validators.required);
    this.mensajeFormGroup = new FormGroup({
      tituloFormControl: new FormControl(null, [
        Validators.required,
        Validators.maxLength(50),
      ]),
      cuerpoFormControl: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(160),
      ]),
      descripcionFormContol: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(150),
      ]),
      tipoMensajeFormControl: new FormControl(null, Validators.required),
      todosLosMedicosFormControl: new FormControl(false),
    });
    this.admin.specialties_list().subscribe(
      (data) => {
        this.especialidades_search = data;
      },
      (error) => {}
    );

    this.admin.getListaTipoMensaje().subscribe((data) => {
      this.listaTiposMensaje = data;
    });

    this.admin.getListaCanales().subscribe((data) => {
      this.listaCanales = data;
      for (let canal of this.listaCanales) {
        this.mensajeFormGroup.addControl(
          'canales[' + canal.id + ']',
          new FormControl(false)
        );
      }
      this.loading = false;
    });
  }

  onDateSelected(event: any) {
    this.getMessagges();
  }

  getMessagges() {
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + this.date_created_before.getDate()).toString().slice(-2)) ||
      '';
    let init =
      (this.date_created_after &&
        `${initYear}-${initMonth.padStart(2, '0')}-${initDay.padStart(
          2,
          '0'
        )} 00:00`) ||
      '';

    let end =
      (this.date_created_before &&
        `${endYear}-${endMonth.padStart(2, '0')}-${endDay.padStart(
          2,
          '0'
        )} 23:59`) ||
      '';

    // if (
    //   this.date_created_after.getTime() > this.date_created_before.getTime()
    // ) {
    //   this.helper.modalError('Las fechas no coinciden');
    // } else {
    this.admin
      .returnMessages(
        this.page,
        this.medic_id__user_id__dni__icontains,
        this.medic_id_user_iddni_icontains,
        this.message_id__date_created,
        init,
        end
      )
      .toPromise()
      .then((data) => {
        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.MensajesArray = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
    // }
  }

  pageChanged(p) {
    this.page = p.page;
    this.getMessagges();
  }

  //metodo que recibe el select2 de angular para hacer la peticion asincrona
  updateEspecialidad(event) {
    this._medic.list_especialidad(event).subscribe(
      (data) => {
        this.especialidades_search = data;
      },
      (error) => {}
    );
  }

  //metodo que elimina un elemento dentro de un array de especialidades
  eliminar_especialidad(number: number, array: any, id) {
    array.splice(number, 1);
  }

  //validar arrays
  value_array(array: any, number: number) {
    var sw = false;
    for (var _i = 0; _i < array.length; _i++) {
      if (array[_i]['id'] === number) {
        sw = true;
        array.splice(_i, 1);
      }
    }
    return sw;
  }

  // meotodo que evalua que dentro del array de especialidades  no se encuentren
  // item repetidos
  validar_especialidad(id: number, array: any) {
    var sw = false;
    for (var _i = 0; _i < array.length; _i++) {
      if (array[_i]['id'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  //[Ey, esta vainas se puede generalizar. Se usa en varias vistas. Por lo menos los mensajes del modal]
  //metodo para agregar las especialidades al array
  especialidad_form(event: TypeaheadMatch) {
    if (event.item === null) {
      this.helper.modalError('¡No se ha escogido ninguna especialidad!');
    } else if (
      this.validar_especialidad(event.item['id'], this.especialidades_array) ===
      true
    ) {
      this.helper.modalError(
        '¡Esta especialidad ya se encuentra en el detalle!'
      );
    } else if (event.item['id'] === undefined || event.item['id'] === null) {
      this.helper.modalError('¡No se ha escogido ninguna especialidad!');
    } else if (this.validar_medico_especialidad(event.item['name']) === true) {
      this.helper.modalError(
        '¡La especialidad ya se encuentra agregada al médico!'
      );
    } else {
      this.especialidades_array.push({
        id: event.item['id'],
        nombre: event.item['name'],
      });
    }
  }

  //metodo que evalua las especialidad dentro de la creacion de usuarios
  validar_medico_especialidad(id: string) {
    var sw = false;
    for (var _i = 0; _i < this.especialidades.length; _i++) {
      if (this.especialidades[_i]['specialty_id__name'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  validate() {
    var valid = this.mensajeFormGroup.valid;
    var falseCount = 0;
    for (let canal of this.listaCanales) {
      if (
        this.mensajeFormGroup.get('canales[' + canal.id + ']').value == false
      ) {
        falseCount++;
      }
    }

    if (falseCount == this.listaCanales.length) {
      valid = false;
    }
    console.log(
      this.mensajeFormGroup.get('todosLosMedicosFormControl').value +
        ', ' +
        (this.especialidades_array.length == 0)
    );

    if (!this.mensajeFormGroup.get('todosLosMedicosFormControl').value) {
      if (this.especialidades_array.length == 0) {
        valid = false;
      }
    }

    return valid;
  }

  resetForm() {
    this.mensajeFormGroup.reset();
    this.especialidades_array = [];
  }

  send() {
    if (this.validate()) {
      var formData = this.mensajeFormGroup;
      var canales = [];
      for (let canal of this.listaCanales) {
        if (formData.get('canales[' + canal.id + ']').value) {
          canales.push(canal.id);
        }
      }
      var especialidades = [];
      if (!formData.get('todosLosMedicosFormControl').value) {
        this.especialidades_array.forEach((element) => {
          especialidades.push(element);
        });
      }
      var requestData = {
        title: formData.get('tituloFormControl').value,
        body: formData.get('cuerpoFormControl').value,
        channel: canales,
        description: formData.get('descripcionFormContol').value,
        message_type_id: formData.get('tipoMensajeFormControl').value,
        recipients_specialties: especialidades,
        all_medics: formData.get('todosLosMedicosFormControl').value,
      };
      this.admin.enviarMensajeMasivo(requestData).subscribe(
        (data) => {
          this.helper.modalSuccess('¡Mensaje enviado satisfactoriamente!');
          this.resetForm();
        },
        (error) => {
          this.helper.modalError(
            this.helper.splitErrorMessages(error.error, '<br>')
          );
          console.log(error);
        }
      );
    } else {
      var errorMessages = this.generateFormErrorMessages(
        this.mensajeFormGroup.controls
      );
      console.log(errorMessages);
      if (errorMessages.length > 0) {
        this.helper.modalError(errorMessages);
      } else {
        this.helper.modalError('¡Campos incompletos!');
      }
    }
  }

  /**
   * Toma los formControl del formGroup que recibe los datos del mensaje de difusión y valida su longitud.
   * @param formGroupControlList Form group que contiene los componentes de input
   * @returns Lista de errores en forma de cadena
   */
  generateFormErrorMessages(formGroupControlList) {
    var titleErrorListString = '';
    var subjectErrorListString = '';
    var descriptionErrorListString = '';

    console.log(formGroupControlList);

    //Título
    var titleErrors = formGroupControlList.tituloFormControl.errors;
    if (titleErrors) {
      if (titleErrors.minlength) {
        titleErrorListString +=
          '¡El título debe contener MÍNIMO ' +
          titleErrors.minlength.requiredLength +
          ' caracteres! \n';
      } else if (titleErrors.maxlength) {
        titleErrorListString +=
          '¡El título debe contener MÁXIMO ' +
          titleErrors.maxlength.requiredLength +
          ' caracteres! \n';
      }
    }
    //Razón
    var reasonErrors = formGroupControlList.descripcionFormContol.errors;
    if (reasonErrors) {
      if (reasonErrors.minlength) {
        subjectErrorListString +=
          '¡El motivo debe contener MÍNIMO ' +
          reasonErrors.minlength.requiredLength +
          ' caracteres! \n';
      } else if (reasonErrors.maxlength) {
        subjectErrorListString +=
          '¡El motivo debe contener MÁXIMO ' +
          reasonErrors.maxlength.requiredLength +
          ' caracteres! \n';
      }
    }

    //Cuerpo
    var subjectErrors = formGroupControlList.cuerpoFormControl.errors;
    if (subjectErrors) {
      if (subjectErrors.minlength) {
        descriptionErrorListString +=
          '¡La descripción debe contener MÍNIMO ' +
          subjectErrors.minlength.requiredLength +
          ' caracteres! \n';
      } else if (subjectErrors.maxlength) {
        descriptionErrorListString +=
          '¡La descripción debe contener MÁXIMO ' +
          subjectErrors.maxlength.requiredLength +
          ' caracteres! \n';
      }
    }

    var errorsListString = '';
    if (titleErrorListString.length > 0) {
      errorsListString += titleErrorListString;
    }
    if (subjectErrorListString.length > 0) {
      errorsListString += subjectErrorListString;
    }
    if (descriptionErrorListString.length > 0) {
      errorsListString += descriptionErrorListString;
    }
    return errorsListString;
  }
}
