import { Component, OnInit, TemplateRef } from '@angular/core';
import { MedicoService } from './../../shared/servicio/medico/medico.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-medico-referido',
  templateUrl: './medico-referido.component.html',
  styleUrls: ['./medico-referido.component.css'],
})
export class MedicoReferidoComponent implements OnInit {
  modalRef: BsModalRef;
  idmedic;

  constructor(private medic: MedicoService, private _http: HttpClient) {}

  referidoMedico: any = [];

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;

  ngOnInit() {
    this.showLoading = true;
    this.getReferidoMedico();
  }

  getReferidoMedico() {
    this.medic
      .getReferidoLista(this.page)
      .toPromise()
      .then((data) => {
        console.log(data);
        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.referidoMedico = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
    (error) => {
      console.log(error);
    };
  }

  pageChanged(p) {
    this.page = p.page;
    this.getReferidoMedico();
  }
}
