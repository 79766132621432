import { EndpageComponent } from './endpage/endpage.component';
import { LandingregisterComponent } from './landingregister/landingregister.component';
import { AssociateGuard } from './guards/associate.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { ComercialGuard } from './guards/comercial.guard';
import { MedicoGuard } from './guards/medico.guard';
import { AdminGuard } from './guards/admin.guard';
import { ComprobacionCuentaComponent } from './comprobacion-cuenta/comprobacion-cuenta.component';
import { AsistenteGuard } from './guards/asistente.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   canActivate: [HomeGuard]
  // },

  {
    path: 'landing',
    component: LandingregisterComponent,
  },
  {
    path: 'end',
    component: EndpageComponent,
  },
  {
    path: '',
    loadChildren: './login/login.module#LoginModule',
    canActivate: [LoginGuard],
  },
  {
    path: 'home/comercial',
    loadChildren: './comercial/comercial.module#ComercialModule',
    canActivate: [ComercialGuard],
  },
  {
    path: 'home/medic',
    loadChildren: './medico/medico.module#MedicoModule',
    canActivate: [MedicoGuard],
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
    canActivate: [AdminGuard],
  },
  {
    path: 'associate',
    loadChildren: './associate/associate.module#AssociateModule',
    canActivate: [AssociateGuard],
  },
  {
    path: 'asistentes',
    loadChildren: './asistentes/asistentes.module#AsistentesModule',
    canActivate: [AsistenteGuard],
  },
  { path: 'check/account/:code', component: ComprobacionCuentaComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
