import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComercialRoutingModule } from './comercial-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComercialComponent } from './comercial/home-comercial/home-comercial.component';
import { SeguimientoComponent } from './comercial/seguimiento/seguimiento.component';
import { MedicComponent } from './comercial/seguimiento/medic/medic.component';
import { ReferralsComponent } from './comercial/seguimiento/referrals/referrals.component';
import { GraphicsComponent } from './comercial/graphics/graphics.component';
import { LinkpageComponent } from './util/menu/linkpage/linkpage.component';
import { UpdateMedicComponent } from './util/update-medic/update-medic.component';
import { MenuComponent } from './menu/menu.component';
import { MenuHorizontalComponent } from './menu-horizontal/menu-horizontal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// NGX-BOOTSTRAP
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// NGX Bootstrap DatePicker, config y lenguaje
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
defineLocale('es', esLocale);

import { TokenInterceptor } from '../interceptors/token.interceptor';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UpdateComercialComponent } from './menu/update-comercial/update-comercial.component';
import { UpdatePasswordPersonalComponent } from './menu/update-password-personal/update-password-personal.component';
import { UpdateMedicComercialComponent } from './comercial/home-comercial/update-medic-comercial/update-medic-comercial.component';
import { CreateMedicComercialComponent } from './comercial/home-comercial/create-medic-comercial/create-medic-comercial.component';
import { SharedModule } from '../shared/shared.module';
import { LogrosComponent } from './logros/logros.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MedicRedClientComponent } from './reportes/medic-red-client/medic-red-client.component';
import { BonosClientesComercialComponent } from './reportes/bonos-clientes-comercial/bonos-clientes-comercial.component';
import { PuntosVencerComponent } from './reportes/puntos-vencer/puntos-vencer.component';
import { BonoNoUtilizadoComponent } from './reportes/bono-no-utilizado/bono-no-utilizado.component';
import { ProductComercialComponent } from './reportes/product-comercial/product-comercial.component';
import { ComercialFormulaReportComponent } from './reportes/comercial-formula-report/comercial-formula-report.component';

@NgModule({
  declarations: [
    DashboardComponent,
    HomeComercialComponent,
    SeguimientoComponent,
    MedicComponent,
    UpdateMedicComponent,
    ReferralsComponent,
    GraphicsComponent,
    LinkpageComponent,
    MenuComponent,
    MenuHorizontalComponent,
    UpdateComercialComponent,
    UpdatePasswordPersonalComponent,
    UpdateMedicComercialComponent,
    CreateMedicComercialComponent,
    LogrosComponent,
    MedicRedClientComponent,
    BonosClientesComercialComponent,
    PuntosVencerComponent,
    BonoNoUtilizadoComponent,
    ProductComercialComponent,
    ComercialFormulaReportComponent,
  ],
  imports: [
    CommonModule,
    ComercialRoutingModule,
    NgxPaginationModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class ComercialModule {}
