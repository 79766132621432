import {
  Component,
  OnInit,
  TemplateRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from 'ngx-bootstrap/modal';

import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin/admin.service';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';

const GUARDADO = 1;
const GUARDANDO = 2;
const ERROR_GUARDADO = 3;

@Component({
  selector: 'app-create-questions',
  templateUrl: './create-questions.component.html',
  styleUrls: ['./create-questions.component.css'],
})
export class CreateQuestionsComponent implements OnInit {
  id: string;
  submitted;
  name_surveys;
  description_surveys;
  preguntasListFormControls;
  respuestasListFormControls;
  preguntasIds;

  tituloFormControl: FormControl;
  descripcionFormControl: FormControl;

  recursiveId;
  sendingRecursive;

  //1: Guardado, 2: Guardando, 3: Error al guardar
  guardando;
  GUARDADO = GUARDADO;
  GUARDANDO = GUARDANDO;
  ERROR_GUARDADO = ERROR_GUARDADO;

  tempId;
  tempAnswerId;

  @Input() surveyId;
  @Input() modalRef;

  showLoading: boolean;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public admin: AdminService,
    public helper: HelpersService
  ) {}

  ngOnInit() {
    this.showLoading = true;

    this.guardando = GUARDADO;
    this.tempId = 0;
    this.tempAnswerId = 0;
    this.preguntasListFormControls = {};
    this.preguntasIds = [];
    this.respuestasListFormControls = {};
    this.tituloFormControl = new FormControl();
    this.descripcionFormControl = new FormControl();
    this.loadQuestions();
  }

  cambiarNombre() {
    console.log('cambiando nombre');
    var nombre = this.tituloFormControl.value;
    console.log(nombre);
    this.admin.edit_survey_title(this.surveyId, nombre).subscribe(
      (data) => {
        this.guardando = GUARDADO;
      },
      (error) => {
        this.guardando = ERROR_GUARDADO;
      }
    );
  }

  cambiarDescripcion() {
    console.log('cambiando descripcion');
    var desc = this.descripcionFormControl.value;
    console.log(desc);

    this.admin.edit_survey_description(this.surveyId, desc).subscribe(
      (data) => {
        console.log('Si cambio descripcion');
        this.guardando = GUARDADO;
      },
      (error) => {
        console.log('No cambio');
        this.guardando = ERROR_GUARDADO;
      }
    );
  }

  loadQuestions() {
    //this.admin.list_idSurvey(this.surveyId).subscribe(data => {
    this.admin.list_idSurvey(this.surveyId).subscribe(
      (data) => {
        console.log(data);
        for (var i = 0; i < this.preguntasIds.length; i++) {
          if (!this.preguntasIds[i].toString().includes('temp')) {
            this.preguntasIds.splice(i, 1);
            i--;
          }
        }

        this.name_surveys = data['survey'].title;
        this.tituloFormControl = new FormControl(this.name_surveys, {
          updateOn: 'blur',
        });
        this.tituloFormControl.valueChanges.subscribe((event) => {
          this.guardando = GUARDANDO;
          this.cambiarNombre();
        });

        this.description_surveys = data['survey'].description;
        this.descripcionFormControl = new FormControl(
          this.description_surveys,
          { updateOn: 'blur' }
        );
        this.descripcionFormControl.valueChanges.subscribe((event) => {
          this.guardando = GUARDANDO;
          this.cambiarDescripcion();
        });

        data['body'].forEach((element) => {
          var id = element.question.id;
          this.preguntasIds.push(element.question.id);

          this.preguntasListFormControls[id] = new FormControl(
            element.question.question,
            {
              validators: [Validators.required],
              updateOn: 'blur',
            }
          );
          this.preguntasListFormControls[id].valueChanges.subscribe((evt) => {
            this.guardando = GUARDANDO;
            this.sendQuestion(id);
          });

          this.respuestasListFormControls[id] = [];
          for (let ans of element.answers) {
            var control = new FormControl(ans.answer, {
              validators: [Validators.required],
              updateOn: 'blur',
            });
            var answer = { id: ans.id, control: control };
            this.respuestasListFormControls[id].push(answer);

            control.valueChanges.subscribe((evt) => {
              this.guardando = GUARDANDO;
              this.sendAnswer(id, ans.id, evt);
            });
          }
        });

        this.showLoading = false;
      },
      (error) => {
        this.modalRef.hide();
        this.helper.modalAlert('¡No se pudo cargar la encuesta!');
      }
    );
  }

  hide() {
    this.modalRef.hide();
  }

  questionFormControlById(id) {
    return this.preguntasListFormControls[id];
  }

  createQuestion() {
    var id = 'temp' + this.tempId;
    this.preguntasIds.push(id);
    this.tempId++;
    this.preguntasListFormControls[id] = new FormControl('', {
      validators: [Validators.required],
    });

    this.respuestasListFormControls[id] = [];
    this.createAnswer(id);
    this.createAnswer(id);
    var tempRespuestas = [];
    for (let answer of this.respuestasListFormControls[id]) {
      tempRespuestas.push(answer.control.value);
    }
    var data = [
      {
        question: this.preguntasListFormControls[id].value,
        answers: tempRespuestas,
      },
    ];

    this.admin.create_question(this.surveyId, data).subscribe(
      (data) => {
        console.log(data);
        var newId = data['ids'][0]['question_id'];
        this.preguntasIds.splice(this.indexByid(id), 1, newId);

        var control = this.preguntasListFormControls[id];
        var fc = new FormControl(control.value, {
          validators: control.validators,
          updateOn: 'blur',
        });
        this.preguntasListFormControls[newId] = fc;
        fc.valueChanges.subscribe((evt) => {
          this.guardando = GUARDANDO;
          this.sendQuestion(newId);
        });
        this.respuestasListFormControls[newId] = [];
        console.log(this.respuestasListFormControls[id].entries());
        for (let ans of data['ids'][0]['answers_ids']) {
          console.log(ans);
          var fc = new FormControl('', {
            validators: control.validators,
            updateOn: 'blur',
          });
          var tempAnswer = { control: fc, id: ans };
          this.respuestasListFormControls[newId].push(tempAnswer);

          fc.valueChanges.subscribe((evt) => {
            this.guardando = GUARDANDO;
            console.log(newId + ', ' + ans + ', ' + evt);
            this.sendAnswer(newId, ans, evt);
          });
        }
        console.log(this.preguntasListFormControls);
        delete this.preguntasListFormControls[id];
        delete this.respuestasListFormControls[id];
        //this.loadQuestions();
        this.guardando = GUARDADO;
      },
      (error) => {
        console.log(error);
        this.guardando = ERROR_GUARDADO;
      }
    );
  }

  deleteQuestion(id, index) {
    id = id.toString();
    if (id.includes('temp')) {
      console.log(this.preguntasIds);
      this.preguntasIds.splice(index, 1);
    } else {
      this.admin.delete_question(id).subscribe(
        (data) => {
          console.log(id);
          console.log(this.preguntasIds);
          this.preguntasIds.splice(index, 1);
          this.helper.modalAlert('¡Pregunta eliminada!');
        },
        (error) => {
          this.helper.modalAlert('¡Error al eliminar la pregunta!');
        }
      );
    }
  }

  createAnswer(questionId) {
    console.log(questionId);

    var id = 'temp' + this.tempAnswerId;
    this.tempAnswerId++;
    var control = new FormControl('', {
      validators: [Validators.required],
      updateOn: 'blur',
    });
    var answer = { control: control, id: id };
    this.respuestasListFormControls[questionId].push(answer);

    control.valueChanges.subscribe((evt) => {
      this.guardando = GUARDANDO;
      this.sendAnswer(questionId, id, evt);
    });
  }

  deleteAnswer(index: number, id, question_id) {
    console.log(id);
    this.guardando = GUARDANDO;
    id = id.toString();
    if (id.includes('temp')) {
      this.guardando = GUARDADO;
      this.respuestasListFormControls[question_id].splice(index, 1);
    } else {
      this.admin.delete_survey_answer(id).subscribe(
        (data) => {
          this.guardando = GUARDADO;
          this.respuestasListFormControls[question_id].splice(index, 1);
        },
        (error) => {
          this.guardando = ERROR_GUARDADO;
        }
      );
    }
  }

  indexByid(id) {
    for (var i = 0; i < this.preguntasIds.length; i++) {
      if (this.preguntasIds[i] == id) return i;
    }
  }

  //Esta función es hermosa, es de mis trabajamos más bellos y una de las únicas veces que he usado recursividad por
  //fuera de la universidad, por favor tratala con amor.
  //Funciona enviando una por una las preguntas editadas, cuando obtiene respuesta del servidor pasa a la siguiente ya que funciona asincronamente
  recursiveSend(id) {
    if (this.recursiveId == this.respuestasListFormControls[id].length) return;
    var answer = this.respuestasListFormControls[id][this.recursiveId];
    this.recursiveId++;
    console.log(answer.id);

    if (answer.id.toString().includes('temp')) {
      //cuando la respuesta es nueva
      this.admin.create_survey_answer(id, [answer.control.value]).subscribe(
        (data) => {
          answer.id = data['answer_ids'];
          this.recursiveSend(id);
          this.guardando = GUARDADO;
          console.log(data);
        },
        (error) => {
          console.log(error);
          this.guardando = ERROR_GUARDADO;
        }
      );
    } else {
      //cuando la respuesta es antigua
      this.admin.edit_survey_answer(answer.id, answer.control.value).subscribe(
        (data) => {
          this.recursiveSend(id);
          this.guardando = GUARDADO;
          console.log(data);
        },
        (error) => {
          console.log(error);
          this.guardando = ERROR_GUARDADO;
        }
      );
    }
  }

  sendAnswer(questionId, answerId, value) {
    console.log(answerId);

    if (answerId.toString().includes('temp')) {
      //cuando la respuesta es nueva
      this.admin.create_survey_answer(questionId, value).subscribe(
        (data) => {
          answerId = data['answer_ids'];
          this.guardando = GUARDADO;
          console.log(data);
        },
        (error) => {
          console.log(error);
          this.guardando = ERROR_GUARDADO;
        }
      );
    } else {
      //cuando la respuesta es antigua

      this.admin.edit_survey_answer(answerId, value).subscribe(
        (data) => {
          this.guardando = GUARDADO;
          console.log(data);
        },
        (error) => {
          console.log(error);
          this.guardando = ERROR_GUARDADO;
        }
      );
    }
  }

  sendQuestion(id) {
    this.guardando = GUARDANDO;
    id = id.toString();
    console.log(id);

    var question = this.preguntasListFormControls[id].value;
    this.admin.edit_survey_question(id, question).subscribe(
      (data) => {
        this.guardando = GUARDADO;
      },
      (error) => {
        console.log(error);
        this.guardando = ERROR_GUARDADO;
      }
    );
  }
}
