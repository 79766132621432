import { Component, OnInit } from '@angular/core';
import { ReportService } from './../../../admin/services/report.service';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';

@Component({
  selector: 'app-puntos-vencer',
  templateUrl: './puntos-vencer.component.html',
  styleUrls: ['./puntos-vencer.component.css'],
})
export class PuntosVencerComponent implements OnInit {
  medics: any = [];

  medicPointReport: any = [];

  page = 1;
  limit = 1;
  count = 0;
  medicsSelect = '';

  showLoading: boolean = false;

  constructor(private medic: ReportService, public _helper: HelpersService) {}

  ngOnInit() {
    this.getMedics();
  }

  getMedics() {
    this.medic
      .getMedicComercial()
      .toPromise()
      .then((data) => {
        console.log(data);
        // this.limit = Math.ceil(data['count'] / 10);
        // this.count = data['count'];
        this.medics = data;
        // this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  getPointReport() {
    this.showLoading = true;

    this.medic
      .getPointReport(this.medicsSelect)
      .toPromise()
      .then((data) => {
        console.log(data);

        this.medicPointReport = data['object'];

        this.showLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this._helper.modalAlert('Medico no encontrado');
        this.showLoading = false;
      });
  }
}
