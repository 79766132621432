import { AsistenteServicesService } from './../../services/asistente-services.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import swal from 'sweetalert2';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-asistente-lista',
  templateUrl: './asistente-lista.component.html',
  styleUrls: ['./asistente-lista.component.css'],
})
export class AsistenteListaComponent implements OnInit {
  modalRef: BsModalRef;

  cc_type = '';
  first_name;
  last_name;
  cc;
  country_code = '';
  cell;
  especialidad = '';

  specialties: any;

  selectSpecialties: any = null;

  form;

  configMetas = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    private AsistenteServicesService: AsistenteServicesService,
    private modalService: BsModalService,
    private _http: HttpClient,
    public _helper: HelpersService,
    public router: Router
  ) {}

  asistentes: any = [];

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;

  ngOnInit() {
    this.showLoading = true;

    this.getasistant();
    this.specialty_medic();
  }
  getasistant() {
    this.AsistenteServicesService.AssistanRegList(this.page)
      .toPromise()
      .then((data) => {
        console.log(data);
        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.asistentes = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.getasistant();
  }

  async AbrirModalCrearAsistente(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.configMetas);
  }

  crearAsist() {
    this.AsistenteServicesService.crearAsistente(
      this.cc_type,
      this.first_name,
      this.last_name,
      this.cc,
      this.country_code,
      this.cell,
      this.selectSpecialties['specialty_id__id']
    )
      .toPromise()
      .then((data) => {
        swal({
          title:
            '<img src="assets/icons/good.png" width="80px" style="margin-top:0px;">',
          html: 'Asistente creado',
          confirmButtonText:
            '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
            'to</p>',
          confirmButtonColor: '#6eceee',
        });
        this.getasistant();
        this.modalRef.hide();
      })

      .catch((error) => {
        this._helper.modalError('Error al crear paciente');
      });
  }

  specialty_medic() {
    this.AsistenteServicesService.especialidad_medico().subscribe((data) => {
      console.log(data);
      this.specialties = data;
    });
  }

  onSelect2(event: TypeaheadMatch): void {
    this.selectSpecialties = event.item;
  }
}
