import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { NgForm, FormControl } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css'],
})
export class BannersComponent implements OnInit {
  /**
   *
   */
  baseImgURL;

  /**
   * Input de búsqueda
   */
  searchTextFC: FormControl;

  /**
   * Selector de filtro
   */
  criterioSelectorFC: FormControl;

  /**
   * Booleano que controla si se está filtrando por banner acitvo
   */
  isActiveON;
  /**
   * Booleano que controla si se está filtrando por banner inacitvo
   */
  isInactiveON;

  //listado del banner de la Api
  list_banner: any;

  // variable para las busquedas del formulario principal
  criterio: string = 'none';
  searchtext: string = '';
  searchtext2: string = 'none';
  orden: string = 'none';
  activate: number = 2;
  selectradio: any = 2;

  // dato para paginacion de resultados
  page: number;
  p: number;
  totalpages: number;
  itemsPagina: any;

  //orden ascendente y descendente de la lista
  order: number = 0;
  direction: number = 0;

  showLoading: boolean;

  constructor(private _http: HttpClient, public helper: HelpersService) {}

  ngOnInit() {
    this.showLoading = true;

    this.baseImgURL = environment.baseImageUrl;
    this.isActiveON = false;
    this.isInactiveON = false;
    this.searchTextFC = new FormControl('');
    this.criterioSelectorFC = new FormControl('none');
    this.p = 1;
    this.list_banners(this.p);
    this.searchTextFC.valueChanges.subscribe((value) => {
      this.searchBanner(value);
    });
    this.criterioSelectorFC.valueChanges.subscribe((onChange) => {
      this.list_banners(this.p);
    });
  }

  list_banners(p2) {
    console.log(p2);

    if (p2 === undefined) {
      p2 = 1;
    } else {
      p2 - 1;
    }

    if (this.searchtext2 === '') {
      this.searchtext2 = 'none';
    }
    this._http
      .get(
        environment.Api_list_banner +
          this.criterioSelectorFC.value +
          '/' +
          this.searchtext2 +
          '/' +
          this.activate +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          p2 +
          '/'
      )
      .subscribe(
        (data) => {
          this.list_banner = data['info'];
          this.totalpages = data['page_info']['count'];
          this.itemsPagina = data['page_info']['total_items'];
          this.p = data['page_info']['current'];
          console.log(data);
          this.showLoading = false;
        },
        (error) => {
          this.helper.error('', error);
        }
      );
  }

  getPage(p) {
    this._http
      .get(
        environment.Api_list_banner +
          this.criterioSelectorFC.value +
          '/' +
          this.searchtext2 +
          '/' +
          this.activate +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          p
      )
      .subscribe(
        (onComplete) => {
          this.list_banners(p);
        },
        (error) => {
          this.helper.error('', error);
        }
      );
  }

  /**
   * Función que busca banner por título
   * @param bannerName Título del banner
   */
  searchBanner(bannerName) {
    if (bannerName == undefined || bannerName == '') {
      this.searchtext2 = 'none';
    }
    this.searchtext2 = bannerName;
    this.list_banners(this.p);
  }

  onActiveToggle() {
    this.isActiveON = !this.isActiveON;
    this.updateActiveSearchValue(this.isActiveON, this.isInactiveON);
  }

  onInactiveToggle() {
    this.isInactiveON = !this.isInactiveON;
    this.updateActiveSearchValue(this.isActiveON, this.isInactiveON);
  }

  updateActiveSearchValue(isActive, isInactive) {
    if ((isActive && isInactive) || (!isActive && !isInactive)) {
      this.activate = 2;
    } else if (isActive) {
      this.activate = 1;
    } else if (isInactive) {
      this.activate = 0;
    }
    this.list_banners(this.p);
  }

  //Actualizar lista
  actualizar_lista(event) {
    console.log(event);
    if (event === true) {
      this.list_banners(this.p);
    }
  }
}
