import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import { FormControl } from '@angular/forms';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';

@Component({
  selector: 'app-ciudades',
  templateUrl: './ciudades.component.html',
  styleUrls: ['./ciudades.component.css'],
})
export class CiudadesComponent implements OnInit {
  constructor(
    private admin: AdminService,
    private _helper: HelpersService,
    private comercial: ComercialService
  ) {}

  listaCiudades;
  listaCiudadesFormControls;
  newCity;

  allCiudades;

  showLoading: boolean;

  ngOnInit() {
    this.showLoading = true;

    this.newCity = new FormControl(null);
    this.listaCiudades = [];
    this.allCiudades = [];
    this.listaCiudadesFormControls = [];
    this.listaCiudadesDane();
    this.getCiudades();
  }

  listaCiudadesDane() {
    this.admin.city_pool_list().subscribe(
      (data) => {
        console.log(data);
        var tempList: any;
        tempList = data;
        this.allCiudades = [];
        for (let ciudad of tempList) {
          var obj = ciudad;
          obj['showName'] =
            ciudad.dane_code + ' - ' + ciudad.name + ' - ' + ciudad.id;
          this.allCiudades.push(obj);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCiudades() {
    this.listaCiudades = [];
    this.listaCiudadesFormControls = [];
    this.comercial.list_ciudades().subscribe(
      (data) => {
        this.listaCiudades = data;
        for (let ciudad of this.listaCiudades) {
          this.listaCiudadesFormControls.push({
            ciudad: ciudad,
            control: new FormControl(ciudad.name),
          });
        }
        console.log(this.listaCiudadesFormControls);

        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  borrarCiudad(index) {
    this._helper
      .modalConfirm('¿Deseas desactivar esta ciudad?')
      .then((willDelete) => {
        if (willDelete.value) {
          var id = this.listaCiudadesFormControls[index].ciudad.id;
          this.admin.delete_city(id).subscribe(
            (data) => {
              console.log(data);
              this.getCiudades();
            },
            (error) => {
              console.log(error);
              this._helper.modalAlert(
                'No se puede desactivar la ciudad porque tiene usuarios asignados'
              );
            }
          );
        }
      });
  }

  agregarCiudad() {
    var id = this.newCity.value.split(' - ')[2];
    console.log(id);

    if (this.newCity.valid) {
      this.admin.add_city(id, '').subscribe(
        (data) => {
          console.log(data);
          this.newCity.reset();
          this._helper.modalAlert('Ciudad activada');
          this.getCiudades();
        },
        (error) => {
          this._helper.modalAlert(
            'No se pudo activar la ciudad, inténtelo de nuevo.'
          );
          console.log(error);
        }
      );
    } else {
      console.log('error');
    }
  }
}
