import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { ReportService } from './../../services/report.service';

@Component({
  selector: 'app-reporte-puntos',
  templateUrl: './reporte-puntos.component.html',
  styleUrls: ['./reporte-puntos.component.css'],
})
export class ReportePuntosComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  medicPointReport: any = [];

  page = 1;
  limit = 1;
  count = 0;
  medicsSelect = '';
  date_created_after;
  date_created_before;
  medicos: any = [];
  medicosType: any;
  medicID;
  showLoading: boolean = false;

  constructor(private medic: ReportService, public _helper: HelpersService) {
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-blue', dateInputFormat: 'DD/MM/YYYY' }
    );
  }

  ngOnInit() {
    this.getMedics();
  }

  async getMedics(searchTerm: string = '') {
    const params = {
      paginate: true, // Puedes ajustar esto según tus necesidades
      search: searchTerm,
    };

    try {
      const data = await this.medic.getMedics(params).toPromise();
      this.medicos = data['results'];
    } catch (error) {
      console.log(error);
    }
  }

  onSelect2(data: Object) {
    this.medicosType = data['item']['name'];
    this.medicID = data['item']['id'];

    console.log(data);
    console.log(this.medicosType);
    // this.medicosType = '';
  }

  getPointReport() {
    this.showLoading = true;

    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getDate() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    this.medic
      .getAdminPointReport(this.medicID, init, end)
      .toPromise()
      .then((data) => {
        console.log(data);

        this.medicPointReport = data['object'];
        this.showLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this._helper.modalAlert('Medico no encontrado');
        this.showLoading = false;
      });
  }
}
