import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private _http: HttpClient) {}

  getMedics(params: any) {
    return this._http.get(environment.ListMedicsGeneral, { params });
  }

  getMedicComercial() {
    return this._http.get(environment.ListarMedicComercial);
  }

  getViewFormulaAll(page, search) {
    let params = '?page=' + page + (search ? '&search=' + search : '');
    return this._http.get(environment.viewFormula + params);
  }

  getPatients() {
    return this._http.get(environment.allPatient);
  }

  getMedicFormulaPatient(id) {
    let params = '/' + id;
    return this._http.get(environment.formulaPatient + params);
  }

  getComercialRetail(start, end, search) {
    let params =
      (start ? '?start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '') +
      (search ? '?search=' + search : '');
    return this._http.get(environment.ComercialRetail + params);
  }
  getAdminRetail(start, end, search) {
    let params =
      (start ? '?start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '') +
      (search ? '?search=' + search : '');

    return this._http.get(environment.administradorRetail + params);
  }

  getProductList(
    page,
    product__name,
    product__ean_code,
    product__maker,
    provider
  ) {
    let params =
      '?page=' +
      page +
      (product__name ? '&product__name=' + product__name : '') +
      (product__ean_code ? '&product__ean_code=' + product__ean_code : '') +
      (product__maker ? '&product__maker=' + product__maker : '') +
      (provider ? '&provider=' + provider : '');
    return this._http.get(environment.ProductList + params);
  }

  getAdminProductList(
    page,
    product__name,
    product__ean_code,
    product__maker,
    provider,
    start,
    end
  ) {
    let params =
      '?page=' +
      page +
      (product__name ? '&product__name=' + product__name : '') +
      (product__ean_code ? '&product__ean_code=' + product__ean_code : '') +
      (product__maker ? '&product__maker=' + product__maker : '') +
      (provider ? '&provider=' + provider : '') +
      (start ? '&start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '');
    return this._http.get(environment.adminProductList + params);
  }

  getComercialNotUsed(page, start, end, search) {
    let params =
      '?page=' +
      page +
      (start ? '&start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '') +
      (search ? '&search=' + search : '');
    return this._http.get(environment.ComercialNotUsed + params);
  }

  getAdminNotUsed(page, start, end, search) {
    let params =
      '?page=' +
      page +
      (start ? '&start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '') +
      (search ? '&search=' + search : '');

    return this._http.get(environment.administradorNotUsed + params);
  }

  getMedicPatient(id, start, end, search) {
    let params =
      id +
      '/' +
      (start ? '?start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '') +
      (search ? '?search=' + search : '');

    return this._http.get(environment.ListarMedicAndPatiendAdmin + params);
  }

  getPointReport(medic_id) {
    let params = medic_id ? '?medic_id=' + medic_id : '';

    return this._http.get(environment.ListarPointReport + params);
  }

  getAdminPointReport(medic_id, start, end) {
    let params = medic_id
      ? '?medic_id=' + medic_id
      : '' +
        (start ? '&start=' + encodeURIComponent(start) : '') +
        (end ? '&end=' + encodeURIComponent(end) : '');

    return this._http.get(environment.administradorPointReport + params);
  }

  getMedicRedemption(page, start, end) {
    let params =
      '?page=' +
      page +
      (start ? '&start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '');
    return this._http.get(environment.medicRedemption + params);
  }

  getFormulaMedic(page) {
    let params = '?page=' + page;
    return this._http.get(environment.formulaMedicReport + params);
  }

  getComercialFormulaMedic(page) {
    let params = '?page=' + page;
    return this._http.get(environment.comercialMedicReport + params);
  }

  getComercialREdemption(page, start, end) {
    let params =
      '?page=' +
      page +
      (start ? '&start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '');
    return this._http.get(environment.comercialRedemption + params);
  }

  eficiencyStat(start, end) {
    const body = { start: start, end: end };

    return this._http.post(environment.efficiency, body);
  }
}
