import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate() {
    if (localStorage.getItem('token') == null) {
      return true;
    } else {
      if (localStorage.getItem('type') === '1') {
        this.router.navigate(['/home/comercial']);
        return false;
      } else if (localStorage.getItem('type') === '2') {
        this.router.navigate(['/home/medic']);
        return false;
      } else if (localStorage.getItem('type') === '3') {
        this.router.navigate(['/admin']);
        return false;
      } else {
        localStorage.clear();
        this.router.navigate(['']);
        return true;
      }
      // else if (localStorage.getItem('type') === '4') {
      //   this.router.navigate(['/admin']);
      //   return false;
      // }
    }
  }
}
