import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css'],
})
export class NotificacionesComponent implements OnInit {
  constructor(
    private admin: AdminService,
    private activeRoute: ActivatedRoute
  ) {}

  /**
   * Determina si los datos a mostrar han sido cargados y están listos para mostrar.
   */
  isDataLoaded;

  /**
   * Contiene los títulos de las notificaciones  de la aplicación
   */
  notificationsTitleList;

  /**
   * Contiene los títulos de los procesos  de la aplicación
   */
  processesTitleList;

  /**
   * Contiene los métodos que ejecutan los llamados a la API de cada notifcación
   */
  notificationTogglersList;

  /**
   * Contiene los métodos que ejecutan los llamados a la API de cada proceso
   */
  processesTogglersList;

  ngOnInit() {
    this.isDataLoaded = false;
    this.notificationsTitleList = [];
    this.notificationTogglersList = [];
    this.processesTitleList = [];
    this.processesTogglersList = [];
    this.getNotificationAndActionTitles();
    this.notificacionesEstadoInicial();
  }

  /**
   * Instancia el arreglo de títulos de las notificaciones y procesos
   */
  getNotificationAndActionTitles() {
    this.notificationsTitleList.push(
      'Notificar a usuarios que tengan productos pendientes por referir [Semanal]'
    );
    this.notificationsTitleList.push(
      'Notificar a usuarios que lleven más de una semana sin referir [Semanal]'
    );
    this.notificationsTitleList.push(
      'Notificar a médicos que no han referido a otros médicos [Mensual]'
    );

    this.processesTitleList.push(
      'Marcar usuarios como inactivos automáticamente [Diario]'
    );
  }

  notificacionesEstadoInicial() {
    this.admin.get_notification_status().subscribe((data) => {
      this.notificationTogglersList.push(data['enable_reward_available_check']);
      this.notificationTogglersList.push(data['enable_unused_code_30']);
      this.notificationTogglersList.push(data['enable_refferal_check_7']);

      this.processesTogglersList.push(data['inactive_check']);
      this.isDataLoaded = true;
    });
  }

  notifyStateChange(position, isNotificationOrProcess) {
    this.isDataLoaded = false;
    var nextState;
    if (isNotificationOrProcess) {
      nextState = !this.notificationTogglersList[position];
      this.notificationTogglersList[position] = !this.notificationTogglersList[
        position
      ];
    } else {
      nextState = !this.processesTogglersList[position];
      this.processesTogglersList[position] = !this.processesTogglersList[
        position
      ];
    }
    this.changeState(position, isNotificationOrProcess, nextState);
  }

  changeState(position, isNotificationOrProcess, nextState) {
    if (isNotificationOrProcess) {
      switch (position) {
        case 0:
          this.admin
            .activar_reward_available_check(nextState)
            .subscribe((onComplete) => {
              this.isDataLoaded = true;
            });
          break;
        case 1:
          this.admin
            .activar_unused_code_30(nextState)
            .subscribe((onComplete) => {
              this.isDataLoaded = true;
            });
          break;
        case 2:
          this.admin
            .activar_referral_check_7(nextState)
            .subscribe((onComplete) => {
              this.isDataLoaded = true;
            });
          break;
      }
    } else {
      switch (position) {
        case 0:
          this.admin
            .activar_monthly_inactive_check(nextState)
            .subscribe((onComplete) => {
              this.isDataLoaded = true;
            });
          break;
      }
    }
  }
}
