import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { NgForm } from '@angular/forms';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { AdminService } from '../services/admin/admin.service';
import { HostListener } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  constructor(
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router,
    public _helper: HelpersService,
    private modalService: BsModalService
  ) {
    this.getScreenSize();
  }
  cont = 0;
  nombre = '';
  rol = '';

  // actualizar registros personales
  nombre_modal: string;
  nombre2_modal: string;
  apellido1_modal: string;
  apellido2_modal: string;
  celular_modal: string;
  email_modal: string;
  dni_modal: string;
  profesional_lisense: string;
  debug: boolean;

  // formulario actualizar contraseñas
  pass_old = '';
  pass1 = '';
  pass2 = '';

  screenHeight: any;
  screenWidth: any;

  // Formularios
  profileModalRef: BsModalRef;
  passwordModalRef: BsModalRef;

  // Configuración de formularios
  perfilConfig = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  passwordConfig = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    this.menu();
    this.user();
    this.debug_db();
  }

  user() {
    this._http.get(environment.Api_get_user).subscribe(
      (data) => {
        (this.nombre = data['user_name'] + ' ' + data['last_name']),
          (this.rol = data['role']);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // Metodo para  controlar las exepciones cuando falla las conexiones con el
  // servidor
  manejador_errores(error) {
    if (this.debug === true) {
      console.log(error);
    } else {
      console.log('Debug no habilitado');
    }

    if (error.status === 400) {
      this._helper.modalError('¡' + error.error + '!');
    } else if (error.statusText === 'Unknown Error') {
      this._helper.modalError('¡Error de conexión con el servidor!');
    } else if (error.status === 500) {
      this._helper.modalError('¡Error del servidor!');
    } else if (error.status === 403) {
      this._helper.modalError('¡Página no encontrada!');
      this.router.navigate(['/login']);
    }
  }

  debug_db() {
    this._ip.debug().subscribe(
      (data) => {
        this.debug = data['state'];
        this.vista_console(data, 'valor del debug');
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  vista_console(data, string: string) {
    if (this.debug === true) {
    } else {
      console.log('Debug no habilitado');
    }
  }

  /**
   * Función que dispara el modal de actualizar los datos del administrador
   * @param template Referencia del template de actualizar datos
   */
  updateProfileData(template: TemplateRef<any>) {
    this.profileModalRef = this.modalService.show(template, this.perfilConfig);
  }

  /**
   * Función que dispara el modal de actualziar contraseña
   * @param template Referencia del template de actualizar contraseña
   */
  updateAdminPassword(template: TemplateRef<any>) {
    this.passwordModalRef = this.modalService.show(
      template,
      this.passwordConfig
    );
  }

  logout() {
    this._http.get(environment.Api_logout).subscribe(
      (data) => {
        console.log('logout');

        localStorage.removeItem('token');
        localStorage.removeItem('type');
        this.router.navigate(['/']);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  menu() {
    $('#menu-toggle').click(function (e) {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
  }
}
