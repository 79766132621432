import { Component, OnInit } from '@angular/core';
// NGX Bootstrap DatePicker, config y lenguaje
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MedicoService } from 'src/app/shared/servicio/medico/medico.service';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { FormControl } from '@angular/forms';
defineLocale('es', esLocale);

/**
 * Chart.js
 * Gráficas
 */
import { Chart } from 'chart.js';

@Component({
  selector: 'app-graphics',
  templateUrl: './graphics.component.html',
  styleUrls: ['./graphics.component.css'],
})
export class GraphicsComponent implements OnInit {
  /**
   * Gráfica creada
   */
  public chart: Chart;

  dateFormControl: FormControl;

  totalPoints;
  totalPointsArray;

  meses = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ];

  mesesNumber = {
    january: 0,
    february: 1,
    march: 2,
    april: 3,
    may: 4,
    june: 5,
    july: 6,
    august: 7,
    september: 8,
    october: 9,
    november: 10,
    december: 11,
  };

  // datos de calendario
  years: any = new Date();
  locale = 'es';
  date: Date = new Date();

  // datos de la lista
  dataLista = true;

  // valores generales
  referedPatients: any;
  referedDoctors: any;
  comercial_id: string;
  loading: boolean;

  /**
   * Array que contendrá los puntos por encuestas, pacientes, médicos
   * y logros del mes seleccionado.
   */
  public monthlyPoints = [
    { label: 'Puntos por Encuestas', points: 0 },
    { label: 'Puntos por Pacientes', points: 0 },
    { label: 'Puntos por Médicos', points: 0 },
    { label: 'Puntos por Logros', points: 0 },
  ];

  selection: number;

  constructor(
    private localeService: BsLocaleService,
    public helper: HelpersService,
    private router: ActivatedRoute,
    private medic: MedicoService
  ) {
    this.localeService.use(this.locale);
  }

  ngOnInit() {
    this.selection = 1;

    this.loading = true;
    this.totalPointsArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    this.dateFormControl = new FormControl(new Date().getFullYear());

    this.router.paramMap.subscribe((data) => {
      this.comercial_id = data.get('id');
      this.clickcalendar(this.years);
    });

    this.dateFormControl.valueChanges.subscribe((year) => {
      this.clickcalendar(year);
    });
  }

  // Funcion para abrir calendario y mostrar solo año
  opencalendar(container) {
    container.yearSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('year');
  }

  changeChart(number) {
    this.selection = number;
    this.getMedicData(this.years);
  }

  // valor seleccionado por el usuario
  clickcalendar(event) {
    this.monthlyPoints = [
      { label: 'Puntos por Encuestas', points: 0 },
      { label: 'Puntos por Pacientes', points: 0 },
      { label: 'Puntos por Médicos', points: 0 },
      { label: 'Puntos por Logros', points: 0 },
    ];

    this.loading = true;
    this.dataLista = false;
    const yearFormateado = formatDate(event, 'yyyy', 'en');
    this.years = yearFormateado;

    this.getMedicData(this.years);
  }

  getMedicData(year) {
    this.medic.graphics_medic(year).subscribe(
      (data) => {
        console.log(data);
        this.dataLista = true;
        this.referedDoctors = data['total_medics_referred'];
        this.referedPatients = data['total_patients_referred'];
        this.totalPoints = data['total_points'];

        // Si ya existía un chart, se destruye para crear el nuevo
        if (this.chart && this.chart !== null) {
          this.chart.destroy();
        }

        this.generateGraphics(
          data['medics_by_month'],
          data['patients_by_month'],
          data['medic_points_final'],
          data['patients_points_final'],
          data['survey_points_final'],
          data['achievements_points_final']
        );
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.dataLista = true;
        this.helper.modalError(
          this.helper.splitErrorMessages(error.error, '<br>')
        );
        this.loading = false;
      }
    );
  }

  /**
   * Función que toma una lista de puntajes por mes (en desorden) y su respectivo año y
   * devuelve un arreglo ORDENADO con los puntajes obtenidos que ha sido referido por mes
   * @param scoreList Lista de puntajes desordenada
   * @returns Un arreglo con el total de puntos ORDENADOS por mes
   */
  getScoresGraphicPerMonth(scoreList) {
    const pointsPerMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    Object.keys(scoreList).forEach((key) => {
      if (key !== 'year') {
        pointsPerMonth[this.mesesNumber[key]] = scoreList[key];
        this.totalPointsArray[this.mesesNumber[key]] += scoreList[key];
      }
    });
    return pointsPerMonth;
  }

  /**
   * Función que toma una lista de contadores de referidos por mes (en desorden) y su respectivo año.
   * @param referedPeopleCounterList Lista que contiene la cantidad de referidos por mes
   * @returns Un arreglo con la cantidad de referidos ORDENADOS por mes
   */
  getReferedCounterGraphicPerMonth(referedPeopleCounterList) {
    const referedCounter = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    Object.keys(referedPeopleCounterList).forEach((key) => {
      if (key !== 'year') {
        referedCounter[this.mesesNumber[key]] = referedPeopleCounterList[key];
      }
    });
    return referedCounter;
  }

  /**
   * Genera las gráficas de puntos acumulados y de personal referido
   * @param referedDoctorsList Lista con la cantidad de médicos referidos
   * @param referedPatientsList Lista con la cantidad de pacientes referidos
   * @param doctorsPointsList Lista con la cantidad de puntos obtenidos por referir médicos
   * @param patientsPointsList Lista con la cantidad de puntos obtenidos por referir pacientes
   * @param surveysPointsList Lista con la cantidad de puntos obtenidos por completar encuestas
   * @param achievementsPointsList Lista con la cantidad de puntos obtenidos por alcanzar logros
   */
  generateGraphics(
    referedDoctorsList,
    referedPatientsList,
    doctorsPointsList,
    patientsPointsList,
    surveysPointsList,
    achievementsPointsList
  ) {
    // Puntos
    const doctorPointschart = this.getScoresGraphicPerMonth(doctorsPointsList);
    const patientsPointschart = this.getScoresGraphicPerMonth(
      patientsPointsList
    );
    const surveysPointschart = this.getScoresGraphicPerMonth(surveysPointsList);
    const achievementsPointschart = this.getScoresGraphicPerMonth(
      achievementsPointsList
    );

    // Puntos
    const doctorsChart = this.getReferedCounterGraphicPerMonth(
      referedDoctorsList
    );
    const patientsChart = this.getReferedCounterGraphicPerMonth(
      referedPatientsList
    );

    // Se inicializa el gráfico pasandole los valores y nombres
    this.initCharts(
      patientsPointschart,
      surveysPointschart,
      doctorPointschart,
      achievementsPointschart,
      this.meses,
      doctorsChart,
      patientsChart
    );
  }

  /**
   * Inicialización del gráfico que se mostrará para mostrar los puntos ganados en el mes
   *
   * @param arrPuntosPaciente: Array que posee los puntos ganados al referir pacientes.
   * @param arrPuntosEncuestas: Array que posee los puntos ganados por realizar encuestas.
   * @param arrPuntosMedico: Array que posee los puntos ganados por referir médicos.
   * @param arrPuntosLogros: Array que posee los puntos ganados por cumplir logros.
   * @param arrNombresMeses: Array que posee los nombres de los meses.
   * @param arrReferidosDoctores: Array que posee la cantidad de doctores referidos.
   * @param arrReferidosPacientes: Array que posee la cantidad de pacientes referidos.
   */
  initCharts(
    arrPuntosPaciente,
    arrPuntosEncuestas,
    arrPuntosMedico,
    arrPuntosLogros,
    arrNombresMeses,
    arrReferidosDoctores,
    arrReferidosPacientes
  ) {
    Chart.defaults.global.defaultFontColor = '#452c80';
    Chart.defaults.global.defaultFontFamily = 'TTCommonsBold';

    if (this.selection == 1) {
      // Se crea una nueva instancia de Chart con el ID
      // del canvas en el HTML
      this.chart = new Chart('chartPuntosGanadosAnual', {
        type: 'bar',
        parentInstance: this,
        data: {
          // labels: weatherDays,
          labels: arrNombresMeses,
          // Los últimos 3 meses
          datasets: [
            {
              data: arrPuntosEncuestas,
              label: 'Puntos por Encuestas',
              backgroundColor: '#61c4ff',
              borderColor: 'white',
              borderWidth: 1,
            },
            {
              data: arrPuntosPaciente,
              label: 'Puntos por Pacientes',
              backgroundColor: '#89d3ff',
              borderColor: 'white',
              borderWidth: 1,
            },
            {
              data: arrPuntosMedico,
              label: 'Puntos por Médicos',
              backgroundColor: '#452c80',
              borderColor: 'white',
              borderWidth: 1,
            },
            {
              data: arrPuntosLogros,
              label: 'Puntos por Logros',
              backgroundColor: '#a295bf',
              borderColor: 'white',
              borderWidth: 1,
            },
          ],
        },
        options: {
          // Evento al hacer click que muestra los datos del mes
          onClick: this.chartClickEvent,
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // Para no mostrar la leyenda arriba, el cual oculta las barras
            display: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  color: 'rgb(208, 202, 223)',
                },
                ticks: {
                  // suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                  // OR //
                  beginAtZero: true, // minimum value will be 0.
                },
              },
            ],
          },
        },
      });
    } else if (this.selection == 2) {
      this.chart = new Chart('chartMedicosReferidosAnual', {
        type: 'bar',
        data: {
          // labels: weatherDays,
          labels: arrNombresMeses,
          // Los últimos 3 meses
          datasets: [
            {
              data: arrReferidosDoctores,
              label: 'Médicos Referidos',
              backgroundColor: '#61c4ff',
              borderColor: 'white',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // Para no mostrar la leyenda arriba, el cual oculta las barras
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgb(208, 202, 223)',
                },
                ticks: {
                  // suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                  // OR //
                  beginAtZero: true, // minimum value will be 0.
                },
              },
            ],
          },
        },
      });
    } else if (this.selection == 3) {
      this.chart = new Chart('chartPacientesReferidosAnual', {
        type: 'bar',
        data: {
          // labels: weatherDays,
          labels: arrNombresMeses,
          // Los últimos 3 meses
          datasets: [
            {
              data: arrReferidosPacientes,
              label: 'Pacientes Referidos',
              backgroundColor: '#61c4ff',
              borderColor: 'white',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // Para no mostrar la leyenda arriba, el cual oculta las barras
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgb(208, 202, 223)',
                },
                ticks: {
                  // suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                  // OR //
                  beginAtZero: true, // minimum value will be 0.
                },
              },
            ],
          },
        },
      });
    }
  }

  /**
   * Evento de click en un mes del gráfico
   * @param evt Evento general del mouse el cual se pasa
   * a la función getElementAtEvent para que retorne el elemento
   * en la posición del click.
   *
   * .getElementAtEvent(e)
   * https://www.chartjs.org/docs/latest/developers/api.html
   *
   * https://stackoverflow.com/a/51117539/10901296
   */
  chartClickEvent(evt, array) {
    const activePoint = this.chart.getElementAtEvent(evt)[0];
    // Array de puntos ganados en el mes
    const arrMonthlyPoints = [];
    if (activePoint !== undefined) {
      // Variable que enlaza el entorno global del componente con el de chart.js
      // https://stackoverflow.com/a/51117539/10901296
      const parentThis = array[0]['_chart']['config']['parentInstance'];
      const data = activePoint._chart.data;
      // Se realiza un forEach para sacar los valores del mes clickeado
      data.datasets.forEach((element) => {
        const nombre = element.label;
        const points = element.data[activePoint._index];
        // Se pushea el valor al array
        arrMonthlyPoints.push({ label: nombre, points: points });
      });
      // Se igualan los valores
      parentThis.monthlyPoints = arrMonthlyPoints;
      parentThis.clicked = true;
      /**
       * Para seleccionar el valor en específico que se está clickeando
       */
      // const datasetIndex = activePoint._datasetIndex;
      // const label = data.datasets[datasetIndex].label;
      // const value = data.datasets[datasetIndex].data[activePoint._index];
      // console.log(label, value);
    }
  }
}
