import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class IpService {
  public ip: string;
  // headers cuando no se envia token por defecto
  public headers_post: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {}

  // IP del servidor a conectar
  public servidor() {
    return (this.ip = 'http://192.168.1.145:8000');
  }

  // Debug de console.log en angular, Pide una peticion si devuelve true se
  // habilitan los errores para visualizar en el navegador
  public debug() {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(environment.Api_debug, { headers: httpHeaders });
  }
}
