import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ComercialService } from './../../shared/servicio/comercial/comercial.service';

import { FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-logros',
  templateUrl: './logros.component.html',
  styleUrls: ['./logros.component.css'],
})
export class LogrosComponent implements OnInit {
  constructor(
    private _medico: ComercialService,
    private modalService: BsModalService,
    private formbuilder: FormBuilder
  ) {}

  comercialLogro: any = [];
  form;
  modalRef: BsModalRef;
  // formulaTabla: any = [];

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;
  config = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  ngOnInit() {
    this.showLoading = true;

    this.getComercialLogros();
  }

  getComercialLogros() {
    this._medico
      .getLogrosComercial(this.page)
      .toPromise()
      .then((data) => {
        console.log(data);

        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.comercialLogro = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.getComercialLogros();
  }
}
