import { Component, OnInit, ɵConsole } from '@angular/core';
// NGX Bootstrap DatePicker, config y lenguaje
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { formatDate, Location } from '@angular/common';
import { SeguimientoService } from '../../../shared/servicio/seguimienti/seguimiento.service';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../services/admin/admin.service';
import { FormControl } from '@angular/forms';

defineLocale('es', esLocale);

/**
 * Chart.js
 * Gráficas
 */
import { Chart } from 'chart.js';

@Component({
  selector: 'app-graficas-comercial',
  templateUrl: './graficas-comercial.component.html',
  styleUrls: ['./graficas-comercial.component.css'],
})
export class GraficasComercialComponent implements OnInit {
  /**
   * Gráfica creada
   */
  public chart: Chart;

  totalPointsArray;

  graphicsData: any;
  graphicsLayout: any;
  graphicsConfig: any;

  puntosChartData;
  puntosChartLayout;
  puntosChartConfig;

  //datos de calendario
  years: any = new Date();
  locale = 'es';
  date: Date = new Date();

  dateFormControl: FormControl;

  //datos de la lista
  dataLista: boolean = true;

  //valores generales
  prom: any;
  register: any;
  point: any;
  comercial_id: string;
  loading: boolean;
  totalPoints: any;
  comercialFullName: String;

  pastFilters;

  meses = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ];

  mesesNumber = {
    january: 0,
    february: 1,
    march: 2,
    april: 3,
    may: 4,
    june: 5,
    july: 6,
    august: 7,
    september: 8,
    october: 9,
    november: 10,
    december: 11,
  };

  // Promedio de puntos mensual
  puntosPromedio: any;
  // Pacientes anual
  qtyPacientes: any;
  // Total de médicos referidos mensual
  qtyMedicosReferidos: any;
  showLoading: boolean;

  /**
   * Array que contendrá los puntos por encuestas, pacientes, médicos
   * y logros del mes seleccionado.
   */
  public monthlyPoints = [
    { label: 'Puntos por Pacientes', points: 0 },
    { label: 'Puntos por Médicos', points: 0 },
    { label: 'Puntos por Encuestas', points: 0 },
    { label: 'Puntos por Logros', points: 0 },
  ];

  selection: number;

  constructor(
    private localeService: BsLocaleService,
    public seguimiento: SeguimientoService,
    public helper: HelpersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private admin: AdminService
  ) {
    this.localeService.use(this.locale);
  }

  ngOnInit() {
    this.selection = 1;

    this.loading = true;
    this.totalPointsArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    this.graphicsData = [[], []];
    this.graphicsLayout = [[], []];
    this.graphicsConfig = { barmode: 'stack', responsive: true };

    this.puntosChartConfig = { responsive: true };
    this.puntosChartData = [];

    this.dateFormControl = new FormControl(new Date().getFullYear());

    this.activatedRoute.paramMap.subscribe((data) => {
      this.comercial_id = data.get('id');
      this.clickcalendar(this.years);
    });

    this.dateFormControl.valueChanges.subscribe((event) => {
      this.clickcalendar(event);
    });

    this.pastFilters = history.state;
  }

  // Funcion para abrir calendario y mostrar solo año
  opencalendar(container) {
    container.yearSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('year');
  }

  getActiveFiltersCount() {
    return Object.keys(this.pastFilters).length;
  }

  goBack() {
    if (this.getActiveFiltersCount() > 1) {
      this.router.navigate(['/admin/tracing/comerciales'], {
        queryParams: this.pastFilters,
      });
    } else {
      this.location.back();
    }
  }

  // valor seleccionado por el usuario
  clickcalendar(event) {
    this.monthlyPoints = [
      { label: 'Puntos por Pacientes', points: 0 },
      { label: 'Puntos por Médicos', points: 0 },
      { label: 'Puntos por Encuestas', points: 0 },
      { label: 'Puntos por Logros', points: 0 },
    ];

    this.loading = true;
    this.dataLista = false;
    const yearFormateado = formatDate(event, 'yyyy', 'en');
    this.years = yearFormateado;

    this.getComercialData();
  }

  /**
   * Trae la data del comercial elegido
   */
  getComercialData() {
    this.admin
      .comerciales_referidos_mes(this.years, this.comercial_id)
      .subscribe(
        (data) => {
          console.log('datos del comercial');
          console.log(data);
          this.comercialFullName = data['comercial_name'];
          this.dataLista = true;

          this.generateGraphics(
            data['monthly_patients'],
            data['monthly_medics'],
            data['monthly_surveys'],
            data['monthly_achievements'],
            data['monthly_activity']
          );
          this.puntosPromedio = data['average_points'];
          this.qtyMedicosReferidos = data['quantity'];
          this.qtyPacientes = data['patients_quantity'];
          this.totalPoints = data['total_points'];

          this.prom = data['average_points'];
          this.register = data['quantity'];
          this.point = data['total_points'];
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.dataLista = true;
          this.helper.error('', error);
          this.loading = false;
        }
      );
  }

  /**
   * Función que toma una lista de puntajes por mes (en desorden) y su respectivo año y
   * devuelve un arreglo ORDENADO con los puntajes obtenidos que ha sido referido por mes
   * @param scoreList Lista de puntajes desordenada
   */
  getScoresGraphicPerMonth(scoreList) {
    var pointsPerMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    Object.keys(scoreList).forEach((key) => {
      if (key != 'year') {
        pointsPerMonth[this.mesesNumber[key]] = scoreList[key];
        this.totalPointsArray[this.mesesNumber[key]] += scoreList[key];
      }
    });
    return pointsPerMonth;
  }

  /**
   * Función que toma una lista de contadores de referidos por mes (en desorden) y su respectivo año.
   * @param referedPeopleCounterList Lista que contiene la cantidad de referidos por mes
   * @returns Un arreglo con la cantidad de referidos ORDENADOS por mes
   */
  getReferedCounterGraphicPerMonth(referedPeopleCounterList) {
    var referedCounter = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    Object.keys(referedPeopleCounterList).forEach((key) => {
      if (key != 'year') {
        referedCounter[this.mesesNumber[key]] = referedPeopleCounterList[key];
      }
    });
    return referedCounter;
  }

  /**
   * Genera las gráficas de puntos acumulados y de personal referido
   */
  generateGraphics(
    monthlyPatients,
    monthlyMedics,
    monthlySurveys,
    monthlyAchievements,
    monthlyActivity
  ) {
    // Puntos
    const monthlyPatientschart = this.getScoresGraphicPerMonth(monthlyPatients);
    const monthlyMedicsChart = this.getScoresGraphicPerMonth(monthlyMedics);
    const monthlySurveysChart = this.getScoresGraphicPerMonth(monthlySurveys);
    const monthlyAchievementsChart = this.getScoresGraphicPerMonth(
      monthlyAchievements
    );
    // Puntos
    const monthlyActivityChart = this.getReferedCounterGraphicPerMonth(
      monthlyActivity
    );

    // Se inicializa el gráfico pasandole los valores y nombres
    this.initCharts(
      monthlyPatientschart,
      monthlyMedicsChart,
      monthlySurveysChart,
      monthlyAchievementsChart,
      this.meses,
      monthlyActivityChart
    );
  }

  /**
   * Al cambiar el chart
   */
  changeChart(number) {
    this.selection = number;
    this.getComercialData();
  }

  /**
   * Inicialización del gráfico que se mostrará para mostrar los puntos ganados en el mes
   */
  initCharts(
    arrPuntosPaciente,
    arrPuntosMedicos,
    arrPuntosEncuestas,
    arrPuntosLogros,
    arrNombresMeses,
    arrActividadMensual
  ) {
    Chart.defaults.global.defaultFontColor = '#452c80';
    Chart.defaults.global.defaultFontFamily = 'TTCommonsBold';

    if (this.selection == 1) {
      // Se crea una nueva instancia de Chart con el ID
      // del canvas en el HTML
      this.chart = new Chart('chartPuntosGanadosAnual', {
        type: 'bar',
        parentInstance: this,
        data: {
          // labels: weatherDays,
          labels: arrNombresMeses,
          // Los últimos 3 meses
          datasets: [
            {
              data: arrPuntosPaciente,
              label: 'Puntos por Pacientes',
              backgroundColor: '#61c4ff',
              borderColor: 'white',
              borderWidth: 1,
            },
            {
              data: arrPuntosMedicos,
              label: 'Puntos por Médicos',
              backgroundColor: '#89d3ff',
              borderColor: 'white',
              borderWidth: 1,
            },
            {
              data: arrPuntosEncuestas,
              label: 'Puntos por Encuestas',
              backgroundColor: '#452c80',
              borderColor: 'white',
              borderWidth: 1,
            },
            {
              data: arrPuntosLogros,
              label: 'Puntos por Logros',
              backgroundColor: '#a295bf',
              borderColor: 'white',
              borderWidth: 1,
            },
          ],
        },
        options: {
          // Evento al hacer click que muestra los datos del mes
          onClick: this.chartClickEvent,
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // Para no mostrar la leyenda arriba, el cual oculta las barras
            display: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  // suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                  // OR //
                  beginAtZero: true, // minimum value will be 0.
                },
              },
            ],
          },
        },
      });
    } else if (this.selection == 2) {
      this.chart = new Chart('chartMedicosReferidosAnual', {
        type: 'bar',
        data: {
          // labels: weatherDays,
          labels: arrNombresMeses,
          // Los últimos 3 meses
          datasets: [
            {
              data: arrActividadMensual,
              label: 'Actividad Mensual',
              backgroundColor: '#61c4ff',
              borderColor: 'white',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // Para no mostrar la leyenda arriba, el cual oculta las barras
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
              },
            ],
            yAxes: [
              {
                display: true,
                ticks: {
                  // suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                  // OR //
                  beginAtZero: true, // minimum value will be 0.
                },
              },
            ],
          },
        },
      });
    }
  }

  /**
   * Evento de click en un mes del gráfico
   * @param evt Evento general del mouse el cual se pasa
   * a la función getElementAtEvent para que retorne el elemento
   * en la posición del click.
   *
   * .getElementAtEvent(e)
   * https://www.chartjs.org/docs/latest/developers/api.html
   *
   * https://stackoverflow.com/a/51117539/10901296
   */
  chartClickEvent(evt, array) {
    const activePoint = this.chart.getElementAtEvent(evt)[0];
    // Array de puntos ganados en el mes
    const arrMonthlyPoints = [];
    if (activePoint !== undefined) {
      // Variable que enlaza el entorno global del componente con el de chart.js
      // https://stackoverflow.com/a/51117539/10901296
      const parentThis = array[0]['_chart']['config']['parentInstance'];
      const data = activePoint._chart.data;
      // Se realiza un forEach para sacar los valores del mes clickeado
      data.datasets.forEach((element) => {
        const nombre = element.label;
        const points = element.data[activePoint._index];
        // Se pushea el valor al array
        arrMonthlyPoints.push({ label: nombre, points: points });
      });
      console.log(arrMonthlyPoints);

      // Se igualan los valores
      parentThis.monthlyPoints = arrMonthlyPoints;
      parentThis.clicked = true;
      /**
       * Para seleccionar el valor en específico que se está clickeando
       */
      // const datasetIndex = activePoint._datasetIndex;
      // const label = data.datasets[datasetIndex].label;
      // const value = data.datasets[datasetIndex].data[activePoint._index];
      // console.log(label, value);
    }
  }
}
