import { Component, OnInit, TemplateRef } from '@angular/core';
import { AdminService } from './../../services/admin/admin.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import swal from 'sweetalert2';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { formatDate } from 'ngx-bootstrap';

@Component({
  selector: 'app-infologros',
  templateUrl: './infologros.component.html',
  styleUrls: ['./infologros.component.css'],
})
export class InfologrosComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  constructor(
    private _admin: AdminService,
    private modalService: BsModalService,
    private formbuilder: FormBuilder,
    public _helper: HelpersService
  ) {
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-blue', dateInputFormat: 'YYYY-MM-DD' }
    );
  }

  metas: any = [];
  form;
  modalRef: BsModalRef;

  magnitude_type = '';
  metric = '';
  operator = '';
  is_active = '';
  magnitude: string;
  value: string;
  name: string;
  search;

  frequency: any;
  showLoading: boolean = true;

  date_start;
  date_end;

  page = 1;
  limit = 1;
  count = 0;
  config = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  configMetas = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  ngOnInit() {
    this.showLoading = true;
    this.getMetasList();
  }

  getMetasList() {
    this._admin
      .getMetas(this.page, this.search)
      .toPromise()
      .then((data) => {
        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.metas = data['results'];

        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.getMetasList();
  }

  async AbrirModalCrearMeta(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.configMetas);
  }

  async AbrirModal(template: TemplateRef<any>, item) {
    this.showLoading = true;

    this.metas.id = item.id;

    this._admin
      .getMetaDetail(item.id)
      .toPromise()
      .then((data: any) => {
        this.form = this.formbuilder.group({
          magnitude: [data.magnitude],
          magnitude_type: [data.magnitude_type],
          metric: [data.metric],
          name: [data.name],
          operator: [data.operator],
          value: [data.value],
          is_active: data.is_active ? 1 : 0,
          date_start_edit: [data.date_start],
          date_end_edit: [data.date_end],
          formula: [data.formula],
          descripcion: [data.descripcion],
        });
        this.modalRef = this.modalService.show(template, this.config);
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }
  onDateChange(newDate: Date | string): void {
    if (typeof newDate === 'string') {
      newDate = new Date(newDate);
    }

    const formattedDate = newDate.toISOString().split('T')[0];

    if (formattedDate !== this.form.get('date_start_edit').value) {
      this.form.patchValue({ date_start_edit: formattedDate });
    }
  }

  onDateChange2(newDate: Date | string): void {
    if (typeof newDate === 'string') {
      newDate = new Date(newDate);
    }

    const formattedDate2 = newDate.toISOString().split('T')[0];

    if (formattedDate2 !== this.form.get('date_end_edit').value) {
      this.form.patchValue({ date_end_edit: formattedDate2 });
    }
  }

  updateLogro(id) {
    const formValues = this.form.value;
    const magnitude_type = formValues.magnitude_type;
    const metric = formValues.metric;
    const operator = formValues.operator;
    const is_active = formValues.is_active;
    const magnitude = formValues.magnitude;
    const value = formValues.value;
    const name = formValues.name;
    const date_start_edit = this.formatDate(formValues.date_start_edit);
    const date_end_edit = this.formatDate(formValues.date_end_edit);

    this._admin
      .logroUpdate(
        id,
        magnitude_type,
        metric,
        operator,
        is_active,
        magnitude,
        value,
        name,
        date_start_edit,
        date_end_edit
      )
      .toPromise()
      .then(() => {
        swal({
          title:
            '<img src="assets/icons/good.png" width="80px" style="margin-top:0px;">',
          html: 'Logro actualizado',
          confirmButtonText:
            '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
            'to</p>',
          confirmButtonColor: '#6eceee',
        });
        this.modalRef.hide();
        this.getMetasList();
      })
      .catch((error) => {
        this._helper.modalError('Error al actualizar logro');
      });
  }

  formatDate(date) {
    if (!date) {
      return '';
    }
    const formattedDate = new Date(date);
    return formattedDate.toISOString().slice(0, 10);
  }

  crearMeta() {
    let initYear = (this.date_start && this.date_start.getFullYear()) || '';

    let initMonth =
      (this.date_start &&
        ('0' + (this.date_start.getMonth() + 1)).toString().slice(-2)) ||
      '';

    let initDay =
      (this.date_start &&
        ('0' + this.date_start.getDate()).toString().slice(-2)) ||
      '';

    let endYear = (this.date_end && this.date_end.getFullYear()) || '';

    let endMonth =
      (this.date_end &&
        ('0' + (this.date_end.getMonth() + 1)).toString().slice(-2)) ||
      '';

    let endDay =
      (this.date_end && ('0' + this.date_end.getDate()).toString().slice(-2)) ||
      '';

    let init =
      (this.date_start && initYear + '-' + initMonth + '-' + initDay) || '';

    let end = (this.date_end && endYear + '-' + endMonth + '-' + endDay) || '';
    if (this.date_end.getTime() < this.date_start.getTime()) {
      this._helper.modalError(
        'La fecha final no puede ser menor que la fecha inicial'
      );
    } else {
      this._admin
        .crearMeta(
          this.magnitude_type,
          this.metric,
          this.operator,
          this.is_active,
          this.magnitude,
          this.value,
          this.name,
          init,
          end
        )
        .toPromise()
        .then(() => {
          swal({
            title:
              '<img src="assets/icons/good.png" width="80px" style="margin-top:0px;">',
            html: 'Meta creada',
            confirmButtonText:
              '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
              'to</p>',
            confirmButtonColor: '#6eceee',
          });
          this.getMetasList();
          this.modalRef.hide();
        })

        .catch((error) => {
          // this._swal.showErrorModal('Error', error.error.mensaje);
          this._helper.modalError('Error al crear meta');
        });
    }
  }
}
