import { Component, OnInit } from '@angular/core';
import { MedicoService } from 'src/app/shared/servicio/medico/medico.service';

@Component({
  selector: 'app-encuestas',
  templateUrl: './encuestas.component.html',
  styleUrls: ['./encuestas.component.css']
})
export class EncuestasComponent implements OnInit {

  encuestas;
  dataLista = false;
  constructor(private _medico: MedicoService) { }

  ngOnInit() {
    this._medico.getListaEncuestas().subscribe(data => {
      this.dataLista = true;
      console.log(data)
      this.encuestas = data;
    },error=>{console.log(error)}
    );
  }

}
