import { LogrosuccesComponent } from './logrosucces/logrosucces.component';
import { MedicoReferidoComponent } from './medico-referido/medico-referido.component';
import { MedicoAsistentesComponent } from './medico-asistentes/medico-asistentes.component';
import { AsistentesComponent } from './asistentes/asistentes.component';
import { LogrosmedicoComponent } from './logrosmedico/logrosmedico.component';
import { FormulasComponent } from './formulas/formulas.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MedicoComponent } from './medico/medico.component';
import { GraphicsComponent } from './graphics/graphics.component';
import { ProductsComponent } from './products/products.component';
import { EncuestasComponent } from './encuestas/encuestas.component';
import { RealizarEncuestaComponent } from './encuestas/realizar-encuesta/realizar-encuesta.component';
import { MispuntosComponent } from './mispuntos/mispuntos.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: '', component: MedicoComponent },
      { path: 'graphics', component: GraphicsComponent },
      { path: 'products', component: ProductsComponent },
      { path: 'encuestas', component: EncuestasComponent },
      { path: 'formula', component: FormulasComponent },
      { path: 'logrosmedico', component: LogrosmedicoComponent },
      { path: 'asistentes', component: AsistentesComponent },
      { path: 'medicoAsistentes', component: MedicoAsistentesComponent },
      { path: 'medicoReferido', component: MedicoReferidoComponent },
      { path: 'logroSucces', component: LogrosuccesComponent },

      {
        path: 'encuestas/detalleEncuesta/:id',
        component: RealizarEncuestaComponent,
      },
      { path: 'historial', component: MispuntosComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MedicoRoutingModule {}
