import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { FormControl } from '@angular/forms';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';

@Component({
  selector: 'app-logros',
  templateUrl: './logros.component.html',
  styleUrls: ['./logros.component.css'],
})
export class LogrosComponent implements OnInit {
  showLoading: boolean;

  constructor(private admin: AdminService, private _helper: HelpersService) {}

  logroFormControlsIds;
  logroFormControls;
  logroId;

  ngOnInit() {
    this.showLoading = true;
    this.getLogros();
  }

  getLogros() {
    this.admin.get_achievement().subscribe((data) => {
      this.logroFormControlsIds = [];
      this.logroFormControls = {};
      for (let i of Object.keys(data)) {
        var logro = data[i];
        this.logroFormControlsIds.push(logro['id']);
        this.logroFormControls[logro['id']] = {
          control: new FormControl(logro['required_points']),
          logro: logro,
        };
      }

      this.showLoading = false;
    });
  }

  editarLogro(id) {
    var valor = this.logroFormControls[id].control.value;
    this.admin.edit_achievement(id, valor).subscribe(
      (data) => {
        console.log(data);
        this._helper.modalSuccess('Actualizado con éxito');
      },
      (error) => {
        console.log(error);
        this._helper.modalError('Ocurrió un error');
      }
    );
  }
}
