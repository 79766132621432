import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
  Form,
} from '@angular/forms';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import { AdminService } from '../../services/admin/admin.service';

@Component({
  selector: 'app-add-new-admin',
  templateUrl: './add-new-admin.component.html',
  styleUrls: ['./add-new-admin.component.css'],
})
export class AddNewAdminComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    public _helper: HelpersService,
    private _comercial: ComercialService,
    private admin: AdminService
  ) {}

  @Input() modalRef: BsModalRef;

  //SALIDA DE DATOS VALOR BOOLEANO
  @Output() lista = new EventEmitter();

  //Para hacer el reload de la lista
  @Output() reloadEmitter = new EventEmitter();

  /**
   * Form group que contiene la lista de inputs del administrador a editar
   */
  newAdminFormGroup: FormGroup;

  /**
   * Booleando que determina si se está cargando algo
   */
  isLoading;

  /**
   * Booleano que determina si está cargando la información del admin
   */
  load;

  /**
   * Booleano que determina si se intentó enviar la información al server
   */
  submitted;

  //inputs del modal
  firstNameFC: FormControl;
  middleNameFC: FormControl;
  lastNameFC: FormControl;
  secondLastNameFC: FormControl;
  cellphoneFC: FormControl;
  dniFC: FormControl;
  emailFC: FormControl;
  cityFC: FormControl;

  /**
   * Nombre del administrador
   */
  user: string;
  /**
   * Id del administrador
   */
  id: number;
  /**
   * Rol del usuario (administrador)
   */
  role: string;
  /**
   * Booleano que determina si el administrador actual está habilitado o no
   */
  isActive: boolean;
  /**
   * Contiene el id de la ciudad actual a la que pertenece el administrador
   */
  city_id: number;

  /**
   * Contiene el arrego de objetos de ciudades
   */
  cityList;

  /**
   * Lista que contiene solo los nombres de las ciudades (para el typehead)
   */
  cityListString: string[];

  ngOnInit() {
    this.isLoading = true;
    this.load = true;
    this.submitted = false;
    this.cityListString = [];
    this.loadSelectedAdminData();
  }

  emitReload() {
    this.reloadEmitter.emit({});
  }

  loadSelectedAdminData() {
    this.newAdminFormGroup = this.formBuilder.group({
      firstNameFC: [
        new FormControl(''),
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]+'),
        ],
      ],

      middleNameFC: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastNameFC: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]+'),
        ],
      ],

      secondLastNameFC: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      cellphoneFC: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      dniFC: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      emailFC: [null, [Validators.required, Validators.email]],

      cityFC: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]+'),
        ],
      ],
    });
    this._comercial.list_ciudades().subscribe((data) => {
      //Lista de ciudades
      console.log(data);
      this.cityList = data;
      this.cityList.forEach((city) => {
        this.cityListString.push(city.dane_id__name);
      });
      console.log(this.cityListString);
      //Inicializando info del admin
      this.newAdminFormGroup.controls.firstNameFC.setValue('');
      this.newAdminFormGroup.controls.middleNameFC.setValue('');
      this.newAdminFormGroup.controls.lastNameFC.setValue('');
      this.newAdminFormGroup.controls.secondLastNameFC.setValue('');
      this.newAdminFormGroup.controls.cellphoneFC.setValue('');
      this.newAdminFormGroup.controls.dniFC.setValue('');
      this.newAdminFormGroup.controls.emailFC.setValue('');
      this.newAdminFormGroup.controls.cityFC.setValue('');
      this.load = false;
      this.isLoading = false;
    });
  }

  /**
   * Función que obtine el nombre de una ciudad con base en su ID
   * @param cityID ID de la ciudad
   */
  getCityNameFromID(cityID) {
    var currentCity = this.cityList.filter((city) => {
      return city.id == cityID;
    });
    return currentCity[0].dane_id__name;
  }

  /**
   * Función que obtine el ID de una ciudad con base en su nombre
   * @param cityName Nombre de la ciudad
   */
  getCityIDFromName(cityName) {
    var currentCity = this.cityList.filter((city) => {
      return city.dane_id__name == cityName;
    });
    return currentCity[0].id;
  }

  /**
   * Función que recolecta los datos de los input del form group, los vaida y luego los envía a la base de datos en caso de estar correctos.
   */
  createNewAdmin() {
    if (this.newAdminFormGroup.valid) {
      this.admin
        .create_admin(
          this.newAdminFormGroup.controls.firstNameFC.value,
          this.newAdminFormGroup.controls.middleNameFC.value,
          this.newAdminFormGroup.controls.lastNameFC.value,
          this.newAdminFormGroup.controls.secondLastNameFC.value,
          this.newAdminFormGroup.controls.emailFC.value,
          this.newAdminFormGroup.controls.cellphoneFC.value,
          this.newAdminFormGroup.controls.dniFC.value,
          this.getCityIDFromName(this.newAdminFormGroup.controls.cityFC.value)
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.submitted = true;
            this.isLoading = false;
            this.emitReload();
            this.modalRef.hide();
            this._helper.modalSuccess('¡Nuevo administrador agregado!');
          },
          (error) => {
            this.submitted = true;
            this.isLoading = false;
            this._helper.modalError(
              this._helper.splitErrorMessages(error.error, '<br>')
            );
          }
        );
    } else {
      this.submitted = true;
      this.load = false;
      this.isLoading = false;
    }
  }

  get shortCreate() {
    return this.newAdminFormGroup.controls;
  }
}
