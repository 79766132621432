import { ReportService } from './../services/report.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { AdminService } from './../../admin/services/admin/admin.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-referir-asistente',
  templateUrl: './referir-asistente.component.html',
  styleUrls: ['./referir-asistente.component.css'],
})
export class ReferirAsistenteComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  constructor(
    private _admin: AdminService,
    private _report: ReportService,
    private formbuilder: FormBuilder
  ) {
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-blue', dateInputFormat: 'DD/MM/YYYY' }
    );
  }

  referidoAsistentes: any = [];
  medicos: any = [];

  medicosType: any;

  form;
  modalRef: BsModalRef;
  arrayBlue2 = [];
  medicID;
  date_created_after;
  date_created_before;
  search;

  token = window.localStorage.getItem('token');

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;
  config = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  ngOnInit() {
    this.showLoading = true;
    this.getReferidoAsistente();
  }

  // async getMedics() {
  //   await this._report
  //     .getMedics()
  //     .toPromise()
  //     .then((data: Object) => {
  //       this.medicos = data;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  getReferidoAsistente() {
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getDate() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    this._admin
      .referidoAsistente(this.page, init, end, this.search)
      .toPromise()
      .then((data) => {
        console.log(data);
        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.referidoAsistentes = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.getReferidoAsistente();
  }

  // onSelect2(data: Object) {
  //   this.medicosType = data['item']['user_id']['first_name'];
  //   this.medicID = data['item']['id'];

  //   console.log(data);
  //   console.log(this.medicosType);
  //    this.medicosType = '';
  // }

  xslReferidos() {
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getDate() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    window.open(
      environment.xslReferaList +
        '?xls' +
        '&' +
        'token=' +
        this.token +
        '&date_created_after=' +
        init +
        '&date_created_before=' +
        end
    );
  }
}
