import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MedicoService } from '../../../shared/servicio/medico/medico.service';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from '@angular/forms';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import { AdminService } from '../../services/admin/admin.service';

declare var $: any;

@Component({
  selector: 'app-update-admin',
  templateUrl: './update-admin.component.html',
  styleUrls: ['./update-admin.component.css'],
})
export class UpdateAdminComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    public _medic: MedicoService,
    public _helper: HelpersService,
    private _comercial: ComercialService,
    private admin: AdminService
  ) {}

  /**
   * Contiene la información del administrador seleccionado a modificar
   */
  @Input() adminData: any;

  //SALIDA DE DATOS VALOR BOOLEANO
  @Output() lista = new EventEmitter();

  //Para hacer el reload de la lista
  @Output() reloadEmitter = new EventEmitter();

  /**
   * Contiene el modal del administrador
   */
  adminDataModalRef: BsModalRef;

  /**
   * Contiene la configuración del modal de administrador
   */
  adminModalConfig = {
    keyboard: false,
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
  };

  /**
   * Form group que contiene la lista de inputs del administrador a editar
   */
  updateSelectedAdminInfo: FormGroup;

  /**
   * Booleando que determina si se está cargando algo
   */
  isLoading;

  /**
   * Booleano que determina si está cargando la información del admin
   */
  load;

  /**
   * Booleano que determina si se intentó enviar la información al server
   */
  submitted;

  //inputs del modal
  firstNameFC: FormControl;
  middleNameFC: FormControl;
  lastNameFC: FormControl;
  secondLastNameFC: FormControl;
  cellphoneFC: FormControl;
  dniFC: FormControl;
  emailFC: FormControl;
  cityFC: FormControl;

  /**
   * Nombre del administrador
   */
  user: string;
  /**
   * Id del administrador
   */
  id: number;
  /**
   * Rol del usuario (administrador)
   */
  role: string;
  /**
   * Booleano que determina si el administrador actual está habilitado o no
   */
  isActive: boolean;
  /**
   * Contiene el id de la ciudad actual a la que pertenece el administrador
   */
  city_id: number;

  /**
   * Contiene el arrego de objetos de ciudades
   */
  cityList;

  /**
   * Lista que contiene solo los nombres de las ciudades (para el typehead)
   */
  cityListString: string[];

  ngOnInit() {
    this.isLoading = true;
    this.load = true;
    this.submitted = false;
    this.cityListString = [];
  }

  /**
   * Función que oculta el modal de actualizar datos
   */
  close() {
    this.emitReload();
    this.adminDataModalRef.hide();
  }

  emitReload() {
    this.reloadEmitter.emit({});
  }

  /**
   * Función que carga la información del administrador actual a modificar
   * @param modalTemplate ngTemplate que contiene el modal de actualizar datos
   */
  loadSelectedAdminData(modalTemplate: TemplateRef<any>) {
    this.updateSelectedAdminInfo = this.formBuilder.group({
      firstNameFC: [
        new FormControl(''),
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]+'),
        ],
      ],

      middleNameFC: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastNameFC: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]+'),
        ],
      ],

      secondLastNameFC: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      cellphoneFC: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      dniFC: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      emailFC: [null, [Validators.required, Validators.email]],

      cityFC: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ]+'),
        ],
      ],
    });
    this._comercial.list_ciudades().subscribe((data) => {
      //Lista de ciudades
      console.log(data);
      this.cityList = data;
      this.cityList.forEach((city) => {
        this.cityListString.push(city.dane_id__name);
      });
      console.log(this.cityListString);
      //Inicializando info del admin
      this.updateSelectedAdminInfo.controls.firstNameFC.setValue(
        this.adminData['first_name']
      );
      this.updateSelectedAdminInfo.controls.middleNameFC.setValue(
        this.adminData['first_name2']
      );
      this.updateSelectedAdminInfo.controls.lastNameFC.setValue(
        this.adminData['last_name']
      );
      this.updateSelectedAdminInfo.controls.secondLastNameFC.setValue(
        this.adminData['last_name2']
      );
      this.updateSelectedAdminInfo.controls.cellphoneFC.setValue(
        this.adminData['cell']
      );
      this.updateSelectedAdminInfo.controls.dniFC.setValue(
        this.adminData['dni']
      );
      this.updateSelectedAdminInfo.controls.emailFC.setValue(
        this.adminData['email']
      );
      this.updateSelectedAdminInfo.controls.cityFC.setValue(
        this.getCityNameFromID(this.adminData['city_id__id'])
      );
      this.city_id = this.adminData['city_id__id'];
      this.user = this.adminData['username'];
      this.id = this.adminData['id'];
      this.role = this.adminData['role'];
      this.isActive = this.adminData['is_active'];
      this.adminDataModalRef = this.modalService.show(
        modalTemplate,
        this.adminModalConfig
      );
      this.load = false;
      this.isLoading = false;
    });
  }

  /**
   * Función que obtine el nombre de una ciudad con base en su ID
   * @param cityID ID de la ciudad
   */
  getCityNameFromID(cityID) {
    var currentCity = this.cityList.filter((city) => {
      return city.id == cityID;
    });
    return currentCity[0].dane_id__name;
  }

  /**
   * Función que obtine el ID de una ciudad con base en su nombre
   * @param cityName Nombre de la ciudad
   */
  getCityIDFromName(cityName) {
    var currentCity = this.cityList.filter((city) => {
      return city.dane_id__name == cityName;
    });
    return currentCity[0].id;
  }

  /**
   * Función que recolecta los datos de los input del form group, los vaida y luego los envía a la base de datos en caso de estar correctos.
   */
  updateAdminData() {
    if (this.updateSelectedAdminInfo.valid) {
      this.admin
        .edit_admin(
          this.id,
          this.updateSelectedAdminInfo.controls.firstNameFC.value,
          this.updateSelectedAdminInfo.controls.middleNameFC.value,
          this.updateSelectedAdminInfo.controls.lastNameFC.value,
          this.updateSelectedAdminInfo.controls.secondLastNameFC.value,
          this.updateSelectedAdminInfo.controls.emailFC.value,
          this.updateSelectedAdminInfo.controls.cellphoneFC.value,
          this.updateSelectedAdminInfo.controls.dniFC.value,
          this.getCityIDFromName(
            this.updateSelectedAdminInfo.controls.cityFC.value
          ),
          'True'
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.submitted = true;
            this.isLoading = false;
            this.close();
            this._helper.modalSuccess('¡Datos actualizados correctamente!');
          },
          (error) => {
            console.log(error);
            this.submitted = true;
            this.isLoading = false;
            this._helper.modalAlert(
              this.splitErrorMessages(error.error, '<br>')
            );
          }
        );
    } else {
      this.submitted = true;
      this.load = false;
      this.isLoading = false;
    }
  }

  get shortUpdate() {
    return this.updateSelectedAdminInfo.controls;
  }

  /**
   * Toma la lista de errores enviada desde el backend, concatenada con alguna etiqueta, y los devuelve en forma de cadena.
   * @param errorMessage mensajes de error concatenados con alguna palabra o etiqueta
   * @param splitString etiqueta o palabra con la que fue concatenado el error
   */
  splitErrorMessages(errorMessage, splitString) {
    var errorList = errorMessage.split(splitString);
    var errorString = '¡';
    var size = errorList.length - 1;
    for (let i = 0; i < size; i++) {
      if (i == size - 1) {
        errorString += errorList[i] + '!';
      } else {
        errorString += errorList[i] + '!\n¡';
      }
    }
    return errorString;
  }
}
