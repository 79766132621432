import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

/**
 * Interceptor para el error 403 Forbidden
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private http: HttpClient, private router: Router) {}

  /**
   * Intercepta las peticiones http y si tiene un error 403 Forbidden desloggea al usuario.
   * @param request 
   * @param next 
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(err => {
        console.log(err);
        
        if (err.status === 403) { 
          console.log('into');
          
            // auto logout if 403 response returned from api
            localStorage.removeItem('token');
            localStorage.removeItem('type');
            this.router.navigate(["/"]);
        } else if(err.status === 500 || err.status === 400 && err.url != environment.Api_log_error){
          var headers = new HttpHeaders({ 'Token': 'Bearer ' + localStorage.getItem('token') });
          var errorDetails = {};
          if(err.hasOwnProperty('error')){
            errorDetails = err['error']['error'];
          } else {
            errorDetails = err['message'];
          }
          this.http.post(environment.Api_log_error, {
            error_type: err.status,
            error_details: errorDetails,
            route: err.url
          }, {headers: headers}).subscribe(data => {});
        } 

        return throwError(err);
    }));
  }       

}