import { Component, OnInit, Input } from '@angular/core';
import {NgForm, Validators, FormBuilder,FormGroup, FormControl} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-update-admin-password',
  templateUrl: './update-admin-password.component.html',
  styleUrls: ['./update-admin-password.component.css']
})

export class UpdateAdminPasswordComponent implements OnInit {

  /**
   * Contiene la información del administrador seleccionado a modificar
   */
  @Input() modalRef: BsModalRef;

  /**
   * Form group que contiene los campos de contraseña
   */
  updateAdminPass:FormGroup;

  /**
   * Booleano que determina si se hizo la acción de enviar la información al server.
   */
  submitted: boolean; 

  /**
   * Booleano que indica si está cargando la información o np
   */
  isLoading:boolean;

  constructor(private formBuilder: FormBuilder, private _http: HttpClient, public helper: HelpersService) {

  }

  ngOnInit() {
    this.isLoading = false;
    this.updateForm();
  }


  updateForm() {
    this.updateAdminPass = this.formBuilder.group({

      password_old: [null, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20),
        Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ0123456789 ]*')]],

      password1: [null, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20),
        Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ0123456789 ]*')]],

      password2: [null, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20),
        Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ0123456789 ]*')]],

    });
  }

  get shortPass() {
    return this.updateAdminPass.controls;
  }

  updatePassword() {
    this.isLoading = true;
    if (this.updateAdminPass.valid) {
      if (this.shortPass.password1.value === this.shortPass.password2.value) {
        let password = this.shortPass.password_old.value;
        let password1 = this.shortPass.password1.value;
        let password2 = this.shortPass.password1.value;
        if (password === password1 && password === password2) {
          this.isLoading = false;
          this.submitted = false;
          this.helper.modalError("¡La nueva contraseña no puede ser igual a la anterior!");
        } else {
          this._http.post(environment.Api_update_password, { password, password1, password2 }).subscribe(data => {
            this.isLoading = false;
            this.submitted = false;
            this.modalRef.hide();
            this.helper.modalSuccess("¡Datos actualizados!")
          }, error => {
            this.isLoading = false;
            this.submitted = false;
            this.helper.modalError(this.helper.error("", error));
          })
        }
      } else {
        this.isLoading = false;
        this.helper.modalError("¡Las contraseñas no coinciden!");
      }
    } else {
      this.submitted = true;
      this.isLoading = false;
    }
  }

}