import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MedicoService } from '../../../shared/servicio/medico/medico.service';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import { AdminService } from '../../services/admin/admin.service';

declare var $: any;

@Component({
  selector: 'app-update-comercial',
  templateUrl: './update-comercial.component.html',
  styleUrls: ['./update-comercial.component.css'],
})
export class UpdateComercialComponent implements OnInit {
  // id del medico
  @Input() id_comercial: number;

  // parametros del modal de ngx-bootstrap

  /**
   * Modal de cambiar datos de comercial
   */
  comercialInfoModal: BsModalRef;

  /**
   * Configuración de cómo se presenta el modal de comercial
   */
  comercialModalConfig = {
    animated: true,
    class: 'modal-md',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  /**
   * Controla el grupo de inputs del modal
   */
  updateAdminProfileInfo: FormGroup;

  //inputs del modal
  firstNameFC: FormControl;
  middleNameFC: FormControl;
  lastNameFC: FormControl;
  secondLastNameFC: FormControl;
  cellphoneFC: FormControl;
  emailFC: FormControl;
  userFC: FormControl;
  dniFC: FormControl;
  city: string;
  id: number;
  role: string;
  isActive: boolean;

  /**
   *
   */
  load: boolean;

  /**
   *
   */
  isLoading;

  /**
   * Indica si la información intentó ser enviada
   */
  submitted;

  /**
   * Controla el grupo de inputs del modal
   */
  updateComercialFormGroup: FormGroup;

  //SALIDA DE DATOS VALOR BOOLEANO
  @Output() lista = new EventEmitter();

  //Para hacer el reload de la lista
  @Output() reloadEmitter = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    public _medic: MedicoService,
    public _helper: HelpersService,
    private _comercial: ComercialService,
    private admin: AdminService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.load = true;
    this.submitted = false;
  }

  /**
   *
   * @param comercialID
   * @param template
   */
  showComercialModal(comercialID, template: TemplateRef<any>) {
    this.loadCommercialInfo(comercialID);
    this.comercialInfoModal = this.modalService.show(
      template,
      this.comercialModalConfig
    );
  }

  /**
   *
   * @param comercialID
   */
  loadCommercialInfo(comercialID) {
    this._comercial.comercial_id(comercialID).subscribe(
      (data) => {
        if (data != null) {
          data = data[0];
          this.userFC = new FormControl(data['username']);
          this.city = data['city_id__id'];
          this.id = data['id'];
          this.role = data['role'];
          this.isActive = data['is_active'];
          this.updateAdminProfileInfo = this.formBuilder.group({
            firstNameFC: [
              null,
              [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50),
                Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
              ],
            ],

            middleNameFC: [
              null,
              [
                Validators.minLength(2),
                Validators.maxLength(50),
                Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ]*'),
              ],
            ],

            lastNameFC: [
              null,
              [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50),
                Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ]*'),
              ],
            ],

            secondLastNameFC: [
              null,
              [
                Validators.minLength(2),
                Validators.maxLength(50),
                Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ]*'),
              ],
            ],

            cellphoneFC: [
              null,
              [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10),
                Validators.pattern(/^\d+$/),
              ],
            ],

            dniFC: [
              null,
              [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(10),
                Validators.pattern(/^\d+$/),
              ],
            ],

            emailFC: [null, [Validators.required, Validators.email]],
          });
          this.updateAdminProfileInfo.controls.firstNameFC.setValue(
            data['first_name']
          );
          this.updateAdminProfileInfo.controls.middleNameFC.setValue(
            data['first_name2']
          );
          this.updateAdminProfileInfo.controls.lastNameFC.setValue(
            data['last_name']
          );
          this.updateAdminProfileInfo.controls.secondLastNameFC.setValue(
            data['last_name2']
          );
          this.updateAdminProfileInfo.controls.cellphoneFC.setValue(
            data['cell']
          );
          this.updateAdminProfileInfo.controls.dniFC.setValue(data['dni']);
          this.updateAdminProfileInfo.controls.emailFC.setValue(data['email']);
        }
        this.load = false;
        this.isLoading = false;
      },
      (error) => {
        this._helper.error('', error);
        this.isLoading = false;
      }
    );
  }

  /**
   *
   */
  isComercialInfoCorrect() {
    this.isLoading = true;
    if (this.updateAdminProfileInfo.valid) {
      this.sendUpdatedComercial();
    } else {
      this.submitted = true;
      this.load = false;
      this.isLoading = false;
    }
  }

  /**
   *
   */
  sendUpdatedComercial() {
    this.admin
      .editComercial(
        this.id_comercial,
        this.firstNameFC.value,
        this.middleNameFC.value,
        this.lastNameFC.value,
        this.secondLastNameFC.value,
        this.emailFC.value,
        this.cellphoneFC.value,
        this.dniFC.value,
        this.city,
        this.isActive
      )
      .subscribe(
        (onComplete) => {
          this.isLoading = false;
          this.submitted = false;
          console.log(onComplete);
        },
        (error) => {
          this.isLoading = false;
          this.submitted = false;
          console.log(error);
        }
      );
  }

  enviar_credenciales() {
    this.admin.resend_credentials(this.id).subscribe(
      (data) => {
        this._helper.modalAlert('¡Contraseña enviada al correo del usuario!');
      },
      (error) => {
        this._helper.modalAlert('¡Error al enviar las credenciales!');
      }
    );
  }

  // emitReload(){
  //   this.reloadEmitter.emit({});
  // }

  // desactivar(){
  //   swal({
  //     title: "¿Deseas bloquear este comercial?",
  //     type: 'warning',
  //     showConfirmButton: true,
  //     showCancelButton: true
  //   })
  //   .then((willDelete) => {
  //     if(willDelete.value){
  //       this.admin.activate_user(this.id, false).subscribe(data => {
  //         this.modalRef.hide();
  //         this.emitReload();
  //       }, error => {

  //       });
  //     }
  //   });

  // }

  // activar(){
  //   this.admin.activate_user(this.id, true).subscribe(data => {
  //     this.modalRef.hide();
  //     this.emitReload();
  //   }, error => {

  //   });
  // }
}
