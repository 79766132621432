import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from './../ip/ip.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ComercialService {
  constructor(
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router
  ) {}

  // HTTP requests
  create_comercial(
    role: string,
    first_name: string,
    first_name2: string,
    last_name: string,
    last_name2: string,
    email: string,
    cell: string,
    dni: string,
    city_id: string
  ) {
    return this._http.post(environment.Api_comercial_register, {
      first_name,
      last_name,
      first_name2,
      last_name2,
      email,
      cell,
      role,
      dni,
      city_id,
    });
  }

  list_ciudades() {
    return this._http.get(environment.Api_all_cities);
  }

  comercial_id(id: number) {
    return this._http.get(environment.Api_get_user_details + id + '/');
  }

  resend_credentials(medicID) {
    return this._http.post(environment.API_RESET_CREDENTIALS_COMERCIAL_MEDIC, {
      medic_id: medicID,
    });
  }
  getLogrosComercial(page) {
    let params = '?page=' + page;
    return this._http.get(environment.ComercialLogros + params);
  }

  // MODALS

  modalAlert(msg) {
    swal({
      title:
        '<img src="assets/icons/error_modal.png" height="80px" style="margin-top:0px;"><' +
        'br>',
      html: '<strong>' + msg + '</strong>',
      confirmButtonText:
        '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
        'to</p>',
      confirmButtonColor: '#6eceee',
    });
  }

  modalSuccess(text) {
    return swal({
      title: text,
      type: 'success',
    });
  }

  modalConfirm(text) {
    return swal({
      title: text,
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    });
  }
}
