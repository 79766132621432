import { ReportService } from './../../services/report.service';
import { Component, OnInit } from '@angular/core';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-reporte-medico-pacientes',
  templateUrl: './reporte-medico-pacientes.component.html',
  styleUrls: ['./reporte-medico-pacientes.component.css'],
})
export class ReporteMedicoPacientesComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  constructor(private medic: ReportService, public _helper: HelpersService) {
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-blue', dateInputFormat: 'DD/MM/YYYY' }
    );
  }

  medicos: any = [];
  medicPatient: any = [];

  showLoading: boolean = false;
  page = 1;
  limit = 1;
  count = 0;
  medicsSelect = '';
  medicosType: any;
  medicID;
  date_created_after;
  date_created_before;
  token = window.localStorage.getItem('token');
  search;

  ngOnInit() {
    this.getMedics();
  }

  async getMedics(searchTerm: string = '') {
    const params = {
      paginate: true, // Puedes ajustar esto según tus necesidades
      search: searchTerm,
    };

    try {
      const data = await this.medic.getMedics(params).toPromise();
      this.medicos = data['results'];
    } catch (error) {
      console.log(error);
    }
  }

  onSelect2(data: Object) {
    this.medicosType = data['item']['name'];
    this.medicID = data['item']['id'];

    console.log(data);
    console.log(this.medicosType);
    // this.medicosType = '';
  }

  getMedicPatient() {
    this.showLoading = true;
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + this.date_created_before.getDate()).toString().slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    this.medic
      .getMedicPatient(this.medicID, init, end, this.search)
      .toPromise()
      .then((data) => {
        console.log(data);

        this.medicPatient = data;
        this.showLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this._helper.modalAlert('Medico no encontrado');
        this.showLoading = false;
      });
  }

  xslReportMedic() {
    let initYear =
      (this.date_created_after && this.date_created_after.getFullYear()) || '';

    let initMonth =
      (this.date_created_after &&
        ('0' + (this.date_created_after.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let initDay =
      (this.date_created_after &&
        ('0' + this.date_created_after.getDate()).toString().slice(-2)) ||
      '';

    let endYear =
      (this.date_created_before && this.date_created_before.getFullYear()) ||
      '';

    let endMonth =
      (this.date_created_before &&
        ('0' + (this.date_created_before.getMonth() + 1))
          .toString()
          .slice(-2)) ||
      '';

    let endDay =
      (this.date_created_before &&
        ('0' + this.date_created_before.getDate()).toString().slice(-2)) ||
      '';

    let init =
      (this.date_created_after && initYear + '-' + initMonth + '-' + initDay) ||
      '';

    let end =
      (this.date_created_before && endYear + '-' + endMonth + '-' + endDay) ||
      '';
    window.open(
      environment.ListarMedicAndPatiendAdmin +
        this.medicID +
        '?xls' +
        '&' +
        'token=' +
        this.token +
        '&start=' +
        init +
        '&end=' +
        end +
        '&search=' +
        this.search
    );
  }
}
