import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MedicoService } from '../../../shared/servicio/medico/medico.service';
import { NgForm, FormControl, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';

declare var $: any;

@Component({
  selector: 'app-update-medic',
  templateUrl: './update-medic.component.html',
  styleUrls: ['./update-medic.component.css'],
})
export class UpdateMedicComponent implements OnInit {
  // id del medico
  @Input() id_medic: number;

  // parametros del modal de ngx-bootstrap
  modalRef: BsModalRef;
  config = {
    class: 'modal-lg',
  };

  /**
   * ID del médico que está siendo editado
   */
  medicID;

  //inputs del modal
  nombre: string;
  nombre2: string;
  apellido: string;
  apellido2: string;
  telefono: any;
  email: string;
  user: string;
  puntos: number;
  matricula: string;
  codigo: string;
  referido: string;
  estado: any;
  estado2: any;

  // array de datos de especialidades de los medicos para actualizacion
  especialidades_array: Array<any> = [];

  // array de datos de especialidades de los medicos para creacion
  especialidades_array_create: Array<any> = [];

  // array de datos de especialidades de los medicos para eliminacion
  especialidades_array_delete: Array<any> = [];

  // array de los datos de backend muestra informacion sobre las especialidades de  un medico en particular
  especialidades: any;

  //valor string del estado
  text_estado: number;

  // array de los datos de la busqueda en los select de busqueda de formulario
  especialidades_search: any;

  // campo de formulario para mostrar lista de medicos en las actualizacion de
  searchLab: FormControl;

  // preloaders de carga
  preloader: boolean = false;
  div_error: number = 0;
  div_text_error: string = '';

  //SALIDA DE DATOS VALOR BOOLEANO
  @Output() lista = new EventEmitter();

  constructor(
    private modalService: BsModalService,
    public _medic: MedicoService,
    public _helper: HelpersService,
    private _comercial: ComercialService
  ) {
    this.searchLab = new FormControl(null, Validators.required);
  }

  ngOnInit() {}

  medico_id(medic, template: TemplateRef<any>) {
    this._medic.medic_id(medic).subscribe(
      (data) => {
        console.log('Datos del médico');
        console.log(data);
        this.medicID = data['user_info'];
        this.nombre = data['user_info']['first_name'];
        this.nombre2 = data['user_info']['first_name2'];
        this.apellido = data['user_info']['last_name'];
        this.apellido2 = data['user_info']['last_name2'];
        this.telefono = data['user_info']['cell'];
        this.email = data['user_info']['email'];
        this.user = data['user_info']['dni'];
        this.puntos = data['medic_info']['points'];
        this.matricula = data['medic_info']['profesional_license'];
        this.codigo = data['medic_info']['referral_code'];
        this.estado = data['medic_info']['validated'];
        this.especialidades_array = [];
        this.especialidades_array_delete = [];
        this.especialidades = data['specialties'];
        this.estado2 = data['medic_info']['state'];

        if (
          data['medic_info']['state'] === 5 ||
          data['medic_info']['state'] === 6
        ) {
          this.text_estado = 1;
        } else {
          this.text_estado = 0;
        }

        if (data['referral_info'].length === 0) {
          this.referido = 'No aplica';
        } else {
          this.referido =
            data['referral_info'][0]['medic_patreon__user_id__first_name'] +
            ' ' +
            data['referral_info'][0]['medic_patreon__user_id__last_name'];
        }

        this.modalRef = this.modalService.show(template, this.config);
      },
      (error) => {
        //this.manejador_errores(error);
        this._helper.error('', error);
      }
    );
  }

  //metodo que recibe el select2 de angular para hacer la peticion asincrona
  updateEspecialidad(event) {
    this._medic.list_especialidad(event).subscribe(
      (data) => {
        this.especialidades_search = data;
      },
      (error) => {
        //this.manejador_errores(error);
        this._helper.error('', error);
      }
    );
  }

  //metodo para agregar las especialidades al array
  especialidad_form() {
    this.value_array(
      this.especialidades_array_delete,
      this.searchLab.value['id']
    );
    if (this.searchLab.value === null) {
      swal({
        title:
          '<img src="assets/icons/error_modal.png" width="80px" style="margin-top:0px;"><' +
          'br>ERROR!',
        html: '<strong>NO HAS ESCOGIDO NINGUNA ESPECIALIDAD!</strong>',
        confirmButtonText:
          '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
          'to</p>',
        confirmButtonColor: '#6eceee',
      });
    } else if (
      this.validar_especialidad(
        this.searchLab.value['id'],
        this.especialidades_array
      ) === true
    ) {
      swal({
        title:
          '<img src="assets/icons/error_modal.png" width="80px" style="margin-top:0px;"><' +
          'br>ERROR!',
        html:
          '<strong>ESTA ESPECIALIDAD YA SE ENCUENTRA EN EL DETALLE!</strong>',
        confirmButtonText:
          '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
          'to</p>',
        confirmButtonColor: '#6eceee',
      });
    } else if (
      this.searchLab.value['id'] === undefined ||
      this.searchLab.value['id'] === null
    ) {
      swal({
        title:
          '<img src="assets/icons/error_modal.png" width="80px" style="margin-top:0px;"><' +
          'br>ERROR!',
        html: '<strong>NO HAS ESCOGIDO NINGUNA ESPECIALIDAD</strong>',
        confirmButtonText:
          '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
          'to</p>',
        confirmButtonColor: '#6eceee',
      });
    } else if (
      this.validar_medico_especialidad(this.searchLab.value['name']) === true
    ) {
      swal({
        title:
          '<img src="assets/icons/error_modal.png" width="80px" style="margin-top:0px;"><' +
          'br>ERROR!',
        html:
          '<strong>LA ESPECIALIDAD YA SE ENCUENTRA AGREGADO AL MEDICO</strong>',
        confirmButtonText:
          '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
          'to</p>',
        confirmButtonColor: '#6eceee',
      });
    } else {
      this.especialidades_array.push({
        id: this.searchLab.value['id'],
        nombre: this.searchLab.value['name'],
      });
    }
  }

  //metodo que evalua las especialidad dentro de la creacion de usuarios
  validar_medico_especialidad(id: string) {
    var sw = false;
    for (var _i = 0; _i < this.especialidades.length; _i++) {
      if (this.especialidades[_i]['specialty_id__name'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  // meotodo que evalua que dentro del array de especialidades  no se encuentren
  // item repetidos
  validar_especialidad(id: number, array: any) {
    var sw = false;
    for (var _i = 0; _i < array.length; _i++) {
      if (array[_i]['id'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  //metodo que elimina o agrega un elemento dentro de un array de especialidades
  eliminar_especialidad_actual(number: any, id: number) {
    var boton = '#name_' + number;
    if ($(boton).hasClass('btn update_especialidades')) {
      $(boton).removeClass('btn update_especialidades');
      $(boton).addClass('btn delete_especialidades');
      // $(boton).css("display","none");
      if (this.validar_array_eliminado(id) === false) {
        this.especialidades_array_delete.push({ id: id });
        this.especialidades.splice(number, 1);
      }
    } else {
      $(boton).removeClass('btn delete_especialidades');
      $(boton).addClass('btn update_especialidades');
      this.especialidades_array_delete.splice(number - 1, 1);
    }
  }

  // metodo que evalua las especialidades que se van a eliminar en las actualializaciones
  validar_array_eliminado(id: number) {
    var sw = false;
    for (var _i = 0; _i < this.especialidades_array_delete.length; _i++) {
      if (this.especialidades_array_delete[_i]['id'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  //metodo que elimina un elemento dentro de un array de especialidades
  eliminar_especialidad(number: number, array: any, id) {
    array.splice(number, 1);
    this.especialidades_array_delete.push({ id: id });
  }

  //validar arrays
  value_array(array: any, number: number) {
    var sw = false;
    for (var _i = 0; _i < array.length; _i++) {
      if (array[_i]['id'] === number) {
        sw = true;
        array.splice(_i, 1);
      }
    }
    return sw;
  }

  enviar_credenciales(medicID) {
    this._comercial.resend_credentials(medicID).subscribe(
      (onSuccess) => {
        this._comercial.modalAlert(
          '¡Se ha enviado la nueva contraseña al correo!'
        );
      },
      (error) => {
        this._comercial.modalAlert(
          '¡Ocurrió un error al enviar las credenciales, intente denuevo!'
        );
      }
    );
  }

  // Metodo de actualizacion se envian los datos de formulario a la api para actualizacion del medico
  actualizar_medico(update: NgForm) {
    this.preloader = true;
    this._medic
      .update_medic(
        this.id_medic,
        update.value['nombre'],
        update.value['nombre2'],
        update.value['apellido'],
        update.value['apellido2'],
        update.value['email'],
        update.value['matricula'],
        update.value['telefono'],
        update.value['user'],
        this.estado2,
        this.especialidades_array,
        this.especialidades_array_delete
      )
      .subscribe(
        (data) => {
          this.lista.emit('1');
          this.modalRef.hide();
          this.preloader = false;
          this.div_error = 0;
          this.div_text_error = '';
        },
        (error) => {
          this.preloader = false;
          this.div_error = 1;
          this.div_text_error = this._helper.error('', error);
        }
      );
  }
}
