import { Pipe, PipeTransform } from '@angular/core';

import { formatDate } from '@angular/common';
 

@Pipe({

 name: 'stringToDate'

})

export class StringToDatePipe implements PipeTransform {
 

 transform(value: any): any {

 const data = value;

//  const readableDate = data.substring(3, 6) + data.substring(0, 2) + data.substring(5, data.length);

 // console.log(new Date(readableDate));

 const horaFormulario = formatDate(data, 'yyyy/mm/dd', 'en');

 return horaFormulario;

 }
 

}