// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// const baseurl = 'https://mefid.com.co';
// const baseFront = 'https://mefid.co';
// const baseImageUrl = 'https://mefid.com.co';

// const baseurl = 'http://192.168.1.66:8000';
// const baseurl = 'http://25.102.115.7:8000';
const baseurl = 'https://dev.mefid.com.co';

const baseurldiagnostico = 'https://api.convenios.unidrogas.com/';

//const baseurl = 'https://mefid.com.co';
// const baseFront = 'http://192.168.1.80:4200';
const baseFront = 'https://mefid.co';
// const baseImageUrl = 'http://192.168.1.66:8000';
const baseImageUrl = 'https://mefid.com.co';

const androidRedirect =
  'https://play.google.com/store/apps/details?id=com.binar10.mefid';
const iosRedirect = 'https://apps.apple.com/co/app/mefid/id1473990265';
export const environment = {
  production: false,
  baseImageUrl,

  //Landing registro
  api_generate_url: baseurl + '/medic/generate_url/',
  api_formulario: baseurl + '/medic/add_referral_session/',


  playStoreLink: androidRedirect,
  iTunesLink: iosRedirect,
  // Rutas del login
  ApiLink: baseFront,
  Api_login: baseurl + '/user/login/',
  Api_update_medic: baseurl + '/user/update_password_medic/',
  Api_forgot_password: baseurl + '/user/forgot_password/',
  Api_verify_code: baseurl + '/user/recover_password/',
  Api_change_password: baseurl + '/user/change_password/',

  // Login register
  Api_get_countries: baseurl + '/administrator/cities_list/',
  Api_register_medic: baseurl + '/medic/register_referred_medic/',

  // Rutas de comerciales
  API_RESET_CREDENTIALS_COMERCIAL_MEDIC:
    baseurl + '/comercial/resend_credentials_medic/',
  Api_comercial_register: baseurl + '/administrator/comercial_register/',

  // Rutas ciudad
  Api_add_city: baseurl + '/administrator/add_city/',
  Api_delete_city: baseurl + '/administrator/delete_city/',

  // Rutas de medicos
  Api_medic_id: baseurl + '/comercial/medic_details/',
  Api_medic_register: baseurl + '/comercial/medic_register/',
  Api_get_especialties: baseurl + '/administrator/specialties_list/',
  Api_get_especialties2: baseurl + '/administrator/specialties_list2/',
  Api_get_states: baseurl + '/administrator/state_list/',
  Api_get_medic_statistics: baseurl + '/medic/medic_statistics/',
  Api_get_prize: baseurl + '/medic/get_rewards',
  api_get_rem: baseurl + '/medic/redeem_reward/',
  // Rutas nuevas de medico
  Api_get_brand: baseurl + '/medic/get_brand/',
  Api_get_zones: baseurl + '/medic/get_zone/',
  Api_get_citys: baseurl + '/medic/get_city/',
  Api_get_site: baseurl + '/medic/get_site/',

  // <--
  api_verified_cell: baseurl + '/medic/verify_phone/',
  api_verified_verify: baseurl + '/medic/send_verify_phone/',

  api_mypoints: baseurl + '/medic/view_redemptions/',
  api_statement_point: baseurl + '/medic/points_statement/',

  // Rutas de pacientes
  Api_get_medic_specialties: baseurl + '/medic/get_medic_specialties/',
  Api_create_patient: baseurl + '/medic/add_referral/',

  // Rutas seguimientos
  Api_tracing_medic_id: baseurl + '/medic_referrals/',
  Api_monitoring_graphics: baseurl + '/comercial/monitoring/',

  // Listas
  Api_get_medic: baseurl + '/comercial/medic_list/',
  Api_get_monitoring_comercial: baseurl + '/comercial/medic_monitoring/',
  Api_get_medic_referral: baseurl + '/comercial/medic_referrals/',
  Api_get_comercial_referral: baseurl + '/comercial/referrals/',
  Api_get_referral: baseurl + '/medic/view_my_referrals/',

  // info user menu
  Api_get_user: baseurl + '/user/get_user_name/',
  Api_logout: baseurl + '/user/logout/',
  Api_info_user: baseurl + '/user/user_info/',
  Api_update_password: baseurl + '/user/update_password/',
  api_surveys: baseurl + '/medic/view_active_surveys/',

  // debug
  Api_debug: baseurl + '/administrator/switch_debug/',

  // Rutas de admin
  Api_get_all_commercial_active_list:
    baseurl + '/administrator/comercial_activity/',
  Api_get_all_commercial_list: baseurl + '/administrator/comercial_list/',
  Api_get_all_medic_list: baseurl + '/administrator/medic_list/',
  Api_get_all_message: baseurl + '/administrator/message_list/',
  Api_get_all_patient_list: baseurl + '/administrator/patient_list/',
  Api_get_all_user_list: baseurl + '/administrator/users_list/',
  Api_get_user_details: baseurl + '/administrator/users_details/',
  Api_post_user_details: baseurl + '/administrator/users_details/',
  Api_get_user_activate: baseurl + '/administrator/activate_user/',
  Api_medic_graphics: baseurl + '/administrator/medic_statistics/',
  Api_comercial_graphics: baseurl + '/administrator/comercial_statistics/',
  Api_get_channels: baseurl + '/administrator/channel_list/',
  Api_enviar_mensaje_masivo: baseurl + '/administrator/send_messages/',
  Api_message_type: baseurl + '/administrator/messagetype_list/',
  Api_get_admin_list: baseurl + '/administrator/admins_list/',
  Api_admin_register: baseurl + '/administrator/comercial_register/',
  Api_admin_resend_credentials: baseurl + '/administrator/resend_credentials/',
  Api_view_medic_redemptions:
    baseurl + '/administrator/view_medic_redemptions/',
  Api_get_logs: baseurl + '/administrator/get_logs/',
  Api_pool_cities: baseurl + '/administrator/cities_list3/',
  Api_admin_stats: baseurl + '/administrator/general_statistics/',
  Api_admin_get_achievement: baseurl + '/administrator/get_achievement/',
  Api_admin_edit_achievement: baseurl + '/administrator/edit_achievement/',
  Api_all_cities: baseurl + '/administrator/cities_list2/',
  Api_admin_medic_details: baseurl + '/administrator/medic_details/',
  API_UPDATE_COMERCIAL_DATA: baseurl + '/administrator/users_details/',

  // Rutas del banner
  Api_create_banner: baseurl + '/administrator/create_banner/',
  Api_list_banner: baseurl + '/administrator/banners_list/',
  Api_change_banner: baseurl + '/administrator/select_banner/',
  Api_list_bannerId: baseurl + '/administrator/banner_details/',
  Api_delete_banner: baseurl + '/administrator/delete_banner/',
  Api_list_bannerMedic: baseurl + '/medic/view_banner/',

  // rutas notificaciones
  Api_activar_referral_check_7:
    baseurl + '/administrator/enable_refferal_check_7/',
  Api_activar_unused_code_30: baseurl + '/administrator/enable_unused_code_30/',
  Api_activar_reward_available_check:
    baseurl + '/administrator/enable_reward_available_check/',
  API_MARCAR_USUARIOS_INACTIVOs_STATUS:
    baseurl + '/administrator/enable_inactive_check/',
  Api_get_notification_status:
    baseurl + '/administrator/get_notification_status/',

  // rutas encuestas
  Api_create_survey: baseurl + '/administrator/create_survey/',
  Api_create_question: baseurl + '/administrator/add_survey_question',
  Api_list_survey: baseurl + '/administrator/survey_details/',
  Api_list_surveys: baseurl + '/medic/view_active_surveys/',
  Api_view_survey: baseurl + '/medic/view_survey_details/',
  Api_send_survey: baseurl + '/medic/fill_survey/',
  Api_admin_list_surveys: baseurl + '/administrator/surveys_list',
  Api_create_answer: baseurl + '/administrator/add_survey_answer/',
  Api_update_survey_answer: baseurl + '/administrator/edit_survey_answer/',
  Api_update_survey_question: baseurl + '/administrator/edit_survey_question/',
  Api_update_survey_title: baseurl + '/administrator/edit_survey_title/',
  Api_update_survey_description: baseurl + '/administrator/edit_description/',
  Api_delete_survey_answer: baseurl + '/administrator/delete_survey_answer/',
  Api_delete_survey: baseurl + '/administrator/delete_survey/',
  Api_delete_question: baseurl + '/administrator/delete_survey_question/',
  Api_toggle_activate_survey: baseurl + '/administrator/activate_survey/',

  // ERROR DEL SERVIDOR
  Api_log_error: baseurl + '/user/errors_log/',
  Api_check_account: baseurl + '/user/email_verification/',

  // RUTAS ASOCIADO - ASSOCIATE
  associate: {
    GET_TOKEN: baseurl + '/associate/get_token',
    GET_MEDICS: baseurl + '/associate/view_medics',
  },

  //RUTA REPORTES

  ListMedicsGeneral: baseurl + '/administrator/all_medic_list/',

  ListarMedicComercial: baseurl + '/comercial/all_medic_list/',

  ListarMedicAndPatiend: baseurl + '/comercial/patient_medic/',

  ListarMedicAndPatiendAdmin: baseurl + '/administrator/patient_medic/',

  ListarPointReport: baseurl + '/comercial/medic_point_report/',

  administradorPointReport: baseurl + '/administrator/medic_point_report/',

  ComercialRetail: baseurl + '/comercial/retails/',

  administradorRetail: baseurl + '/administrator/retails/',

  ComercialNotUsed: baseurl + '/comercial/retails/not_used/',

  administradorNotUsed: baseurl + '/administrator/retails/not_used/',

  ProductList: baseurl + '/comercial/retails/productlist/',

  adminProductList: baseurl + '/administrator/retails/productlist/',

  formulaPatient: baseurl + '/administrator/view_formula_patient',

  allPatient: baseurl + '/administrator/view_patient_all/',

  viewFormula: baseurl + '/administrator/view_formula_all/',

  formula: baseurl + '/medic/view_formula_medic/',

  productsmedics: baseurl + '/medic/product_list/',

  diagnosticos: baseurldiagnostico + '/formula/diax_list',

  detalleFormula: baseurl + '/medic/view_formula_info/',

  detalleFormulaAsistente: baseurl + '/assistant/view_formula_info/',

  crearFormula: baseurl + '/medic/formula_medics_create/',

  BuscarPaciente: baseurl + '/medic/patient_referral_search/',

  ListarMetas: baseurl + '/administrator/goals_list/',

  MetasDetail: baseurl + '/administrator/goals_detail/',

  editMeta: baseurl + '/administrator/goal_update',

  registroMetas: baseurl + '/administrator/goals_register/',

  medicoLogros: baseurl + '/medic/medics_goals_list/',

  MedicAchivment: baseurl + '/medic/medics_goals_achieved/',

  CrearMeta: baseurl + '/administrator/goals_create/',

  listaAsistentes: baseurl + '/medic/assistant_list/',

  ComercialLogros: baseurl + '/comercial/medics_goals_list/',

  registroAsistentes: baseurl + '/administrator/assistant_reg_list/',

  asistentes: baseurl + '/administrator/assistant_list',

  referirAsistente: baseurl + '/administrator/assistant_referral_list/',

  ListadoMedicoReferido: baseurl + '/medic/assistant_reg_list/',

  referrelMedic: baseurl + '/medic/assistant_referral_list/',

  crearAsistente: baseurl + '/medic/create_assistant/',

  rolListaAsistente: baseurl + '/assistant/assistant_reg_list/',

  addReferral: baseurl + '/assistant/add_referral/',

  ReferralList: baseurl + '/assistant/assistant_referral_list/',

  getMedicEspecialitiesAssistant: baseurl + '/assistant/get_medic_specialties/',

  getMedicPhoto: baseurl + '/medic/get_photo/',

  CreatePhoto: baseurl + '/medic/upload_photo/',

  xslAssistanList: baseurl + '/administrator/assistant_list/',

  xslRegList: baseurl + '/administrator/assistant_reg_list/',

  xslReferaList: baseurl + '/administrator/assistant_referral_list/',

  medicRedemption: baseurl + '/administrator/medic_redemptions_client/',

  formulaMedicReport: baseurl + '/administrator/formula_medics_report/',

  comercialMedicReport: baseurl + '/comercial/formula_medics_report/',

  comercialRedemption: baseurl + '/comercial/medic_redemptions_client/',

  efficiency: baseurl + '/administrator/report_efficiency/',

  xslRedepmtionAdminList: baseurl + '/administrator/medic_redemptions_client/',

  xslRetailAdmin: baseurl + '/administrator/retails/',

  xslComercialRedeption: baseurl + '/comercial/medic_redemptions_client/xls/',

  xslComercialRetail: baseurl + '/comercial/retails/xls/',

  RetailNotUsedAmidmin: baseurl + '/administrator/retails/not_used/',

  RetailNotUsedComercial: baseurl + '/comercial/retails/not_used/xls/',

  xslProductAdmin: baseurl + '/administrator/retails/productlist/',

  xslproductComercial: baseurl + '/comercial/retails/productlist/xls/',

  changeStatusAssistant: baseurl + '/medic/assistant_update/',

  campaingMefid: baseurl + '/administrator/goals_simple_list/',

  editComercialByAdmin: baseurl + '/administrator/comercial-medic-relation/',

  ListComercialAdmin: baseurl + '/administrator/comercial-list/',

  saberComercial: baseurl + '/administrator/comercial-by-medic-id/',

  formulaAssistant: baseurl + '/assistant/view_formula_medic/',

  ProductAssistant: baseurl + '/assistant/product_list/',

  PatientListAssistant: baseurl + '/assistant/patient_list/',

  CreateFormulaAsistente: baseurl + '/assistant/formula_medics_create/',

  getPrizeAssistant: baseurl + '/assistant/get_rewards/',

  getBrandAssistant: baseurl + '/assistant/get_brand/',

  getZoneAssistant: baseurl + '/assistant/get_zone/',

  getCityAssistant: baseurl + '/assistant/get_city/',

  getSiteAssistant: baseurl + '/assistant/get_site/',

  getPoints: baseurl + '/assistant/points_statement/',

  redemAssistant: baseurl + '/assistant/redeem_reward/',

  UpdatePointMedic: baseurl + '/administrator/update_medic_point/'
};
