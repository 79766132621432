import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MedicoRoutingModule } from './medico-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MedicoComponent } from './medico/medico.component';
import { MenuComponent } from './menu/menu.component';
import { MenuHorizontalComponent } from './menu-horizontal/menu-horizontal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// NGX-BOOTSTRAP
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TokenInterceptor } from '../interceptors/token.interceptor';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
import { ClipboardModule } from 'ngx-clipboard';
import { GraphicsComponent } from './graphics/graphics.component';
import { ProductsComponent } from './products/products.component';
import { SliderComponent } from './products/slider/slider.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { EncuestasComponent } from './encuestas/encuestas.component';
import { RealizarEncuestaComponent } from './encuestas/realizar-encuesta/realizar-encuesta.component';
import { PrizeComponent } from './products/prize/prize.component';
import { UpdateMedicComponent } from './dashboard/update-medic/update-medic.component';
import { VerifiedcellComponent } from './dashboard/update-medic/verifiedcell/verifiedcell.component';
// RECOMMENDED
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SharedModule } from '../shared/shared.module';
import { FilterComponent } from './medico/filter/filter.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AddPatientsComponent } from './medico/add-patients/add-patients.component';
import { UpdatePasswordPersonalComponent } from './dashboard/update-password-personal/update-password-personal.component';
import { MispuntosComponent } from './mispuntos/mispuntos.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormulasComponent } from './formulas/formulas.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AddFormulaComponent } from './formulas/add-formula/add-formula.component';
import { LogrosmedicoComponent } from './logrosmedico/logrosmedico.component';
import { AsistentesComponent } from './asistentes/asistentes.component';
import { MedicoAsistentesComponent } from './medico-asistentes/medico-asistentes.component';
import { MedicoReferidoComponent } from './medico-referido/medico-referido.component';
import { LogrosuccesComponent } from './logrosucces/logrosucces.component';
import { QrCodeModule } from 'ng-qrcode';

@NgModule({
  declarations: [
    DashboardComponent,
    MedicoComponent,
    MenuComponent,
    MenuHorizontalComponent,
    GraphicsComponent,
    ProductsComponent,
    SliderComponent,
    EncuestasComponent,
    RealizarEncuestaComponent,
    PrizeComponent,
    UpdateMedicComponent,
    VerifiedcellComponent,
    FilterComponent,
    AddPatientsComponent,
    UpdatePasswordPersonalComponent,
    MispuntosComponent,
    FormulasComponent,
    AddFormulaComponent,
    LogrosmedicoComponent,
    AsistentesComponent,
    MedicoAsistentesComponent,
    MedicoReferidoComponent,
    LogrosuccesComponent,
  ],
  imports: [
    CommonModule,
    MedicoRoutingModule,
    NgxPaginationModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ClipboardModule,
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    SharedModule,
    QrCodeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class MedicoModule {}
