import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MedicoService } from './../../../shared/servicio/medico/medico.service';
import swal from 'sweetalert2';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-formula',
  templateUrl: './add-formula.component.html',
  styleUrls: ['./add-formula.component.css'],
})
export class AddFormulaComponent implements OnInit {
  constructor(private medic: MedicoService, public _helper: HelpersService) {}

  productsModal: any;

  diagnosticosModal: any;

  nombreCompleto: string;

  entidad = '';

  product_id: number;

  productosmedicos: Array<Object>;

  diagnosticosmedicos: any;

  productosMedicos: any = [];

  pacienteConsultado: any = [];

  prod;

  arrayBlue = [];

  prod2;

  arrayBlue2 = [];

  @Input() medicM: any;

  email: string = 'mefidemail@binar10.co';

  description: string;

  paciente: string;

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  modalRef: BsModalRef;

  @Output() reloadFormula = new EventEmitter();

  async ngOnInit() {
    this.productsMedics();
    this.diagnosticos();
    console.log(this.medicM);
  }

  guardarFormula() {}

  changeTypeaheadLoading() {
    this.product_id = null;
  }

  async productsMedics() {
    if (this.prod == '' || this.prod == null) {
      this.productosmedicos = [];
    }
    await this.medic
      .products(this.productsModal)
      .toPromise()
      .then((data: Object) => {
        this.productosmedicos = data['response']['data'];
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async diagnosticos() {
    if (this.prod2 == '' || this.prod2 == null) {
      this.diagnosticosmedicos = [];
    }
    await this.medic
      .diagnosticos(this.diagnosticosModal)
      .toPromise()
      .then((data) => {
        console.log(data);
        this.diagnosticosmedicos = data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onSelect(data: Object) {
    this.arrayBlue.push({
      ...data['item'],
      quantity: 1,
      dose_quantity: 1,
      days: 1,
      interval: 1,
      via: 1,
      fraction: 1,
      presentation: 1,
      description: '',
    });
    this.productsModal = '';
  }
  onSelect2(data: Object) {
    this.arrayBlue2.push(data['item']);
    this.diagnosticosModal = '';
  }

  crear() {
    this.medic
      .crearFormula(
        this.medicM.idmedic,
        this.pacienteConsultado.id,
        this.email,
        this.description,
        this.arrayBlue2,
        this.arrayBlue
      )
      .toPromise()
      .then((data: any) => {
        console.log(data);

        swal({
          title:
            '<img src="assets/icons/good.png" width="80px" style="margin-top:0px;">',
          html: 'Formula creada',
          confirmButtonText:
            '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
            'to</p>',
          confirmButtonColor: '#6eceee',
        });
        this.reloadFormula.emit('r');
      })

      .catch((error) => {
        this._helper.modalError(error.error.error);
      });

  }

  buscarpaciente() {
    this.medic
      .buscarPaciente(this.paciente)
      .toPromise()
      .then((data: any) => {
        console.log(data);
        this.pacienteConsultado = data;
        this.nombreCompleto = data['first_name'] + ' ' + data['last_name'];
      })
      .catch((error) => {
        this._helper.modalError('Paciente no encontrado');
      });
  }
}
