import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginRegisterService } from '../shared/servicio/login-register/login-register.service';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl
} from '@angular/forms';
import { HelpersService } from '../shared/servicio/helpers/helpers.service'
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { AdminService } from 'src/app/admin/services/admin/admin.service';
import { BsDatepickerConfig, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PacientesService } from '../shared/servicio/pacientes/pacientes.service';

@Component({
  selector: 'app-landingregister',
  templateUrl: './landingregister.component.html',
  styleUrls: ['./landingregister.component.css']
})
export class LandingregisterComponent implements OnInit {


  datePickerConfig: Partial<BsDatepickerConfig>;

  legalDisclaimerConfig = {
    animated: true,
    class: 'modal-login modal-width-terms modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false
  };

  create_patients: FormGroup;
  city: FormControl;
  name: FormControl;
  lastname: FormControl;
  identification: FormControl;
  cell: FormControl;
  email: FormControl;
  selectedOption: FormControl;
  submitted: boolean;
  preloader: boolean = false;
  especialidades: any = [];
  selectecDni: any = null;
  selectecGenre: any = null;
  selectSpecialties: any = null;
  specialties: string[] = []; // Variable para almacenar las especialidade
  ciudad_list: any = [];
  showFields = false;
  isCodeVerified: boolean = false;


  //Nombre del Formulario del select de ciudades
  select: FormControl;

  //Valores de formulario de register
  first_name: string;
  last_name: string;
  dni: number;

  cellphone: string;

  noResult: boolean;

  //id
  id: string;
  //parametro recibido por la Url codigo referido
  code: any;

  //errores
  div_register: number = 0;
  text_error_register: string = '';

  selected: string;

  sesion: string;

  paramDni: any[] = [
    { value: 0, name: 'Cédula de ciudadanía' },
    { value: 1, name: 'Cédula de extranjería' },
  ];

  paramGenero: any[] = [
    { value: 'masculino', name: 'Masculino' },
    { value: 'femenino', name: 'Femenino' },
    { value: 'indefinido', name: 'Indefinido' },

  ];


  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    public router: Router,
    public _helper: HelpersService,
    private formBuilder: FormBuilder,
    private _http: HttpClient,
    public _pacientes: PacientesService,
    public _loginR: LoginRegisterService,



  ) {
    this.select = new FormControl(null, Validators.required);
    this.code = this.route.snapshot.paramMap.get('referal');
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-blue', dateInputFormat: 'DD/MM/YYYY' }
    );
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((data: any) => {
      this.sesion = data.params.session
    })

    this.createForm();
    this.submitted = false;
    this.noResult = false;
    this.validateToken()
    this.cities_list();


  }

  get shortCreate() {
    return this.create_patients.controls;
  }

  createForm() {
    this.create_patients = this.formBuilder.group({

      city: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ. ]*')  // Añadido el punto (.)
        ]
      ],


      fecha: [
        null,
        [
          Validators.required,
        ]
      ],

      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      cell: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      especialidad: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ. ]*'),
        ],
      ],

      type_dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      genero: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],
      email: [null, [Validators.required, Validators.email]],

      sesion: this.sesion,

      selectedOption: [
        null,
        [Validators.required, Validators.min(1), Validators.pattern(/^\d+$/)]
      ]
    });
  }

  /**
   *
   * @param disclaimerTemplate
   */



  create(): void {
    if (this.selectecDni === null) {
      this._helper.modalError('¡No has seleccionado tipo de cédula de la lista!');
    } else if (this.selectSpecialties === null) {
      this._helper.modalError('¡No has seleccionado especialidad de la lista!');
    } else {
      this.preloader = true;
      if (this.create_patients.valid) {
        const authorizationToken = this.shortCreate.sesion.value; // Obtener el valor de Authorization desde this.sesion

        const formattedFecha = this.formatDateToYYYYMMDD(this.shortCreate.fecha.value);
        this._pacientes.create_pacienteWithoutSession(
          this.shortCreate.name.value,
          this.shortCreate.lastname.value,
          this.selectecDni['value'],
          this.shortCreate.dni.value,
          this.shortCreate.cell.value,
          this.selectecGenre['value'], // Asegúrate de que el género esté en el campo correcto
          this.shortCreate.selectedOption.value, // City ID
          this.shortCreate.email.value,
          formattedFecha, // Fecha formateada
          this.selectSpecialties.specialty_id__id,
          57, // Código de país por defecto
          authorizationToken // Pasar el valor de Authorization como el token
        ).subscribe(
          (data) => {
            this.preloader = false;
            this.submitted = false;
            this._helper.modalSuccess(data['message']);
            console.log(data);
            // Redirigir a otra ruta
            this.router.navigate(['/end']);
          },
          (error) => {
            console.log(error);
            this.preloader = false;
            this.submitted = false;

            // Verificar si el error contiene el mensaje adicional esperado
            if (error.error && error.error.message) {
              this._helper.modalError(error.error.message);
            } else if (error.error && error.error.detail) {
              this._helper.modalError(this._helper.splitErrorMessages(error.error.detail, '<br>'));
            } else {
              this._helper.modalError('Ocurrió un error inesperado. Por favor, inténtalo de nuevo.');
            }
          }
        );
      } else {
        this.submitted = true;
        this.preloader = false;
      }
    }
  }



  formatDateToYYYYMMDD(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }


  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  cities_list() {
    this._loginR.listaCiudades().subscribe(
      data => {
        this.ciudad_list = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelect2(event: TypeaheadMatch): void {
    this.create_patients.controls.selectedOption.setValue(event['item']['id']);
    // this._helper.modalInfo('¡Ha seleccionado ' + event['item']['dane_id__name'] + "!");
  }


  onSelect(event: TypeaheadMatch): void {
    this.selectecDni = event.item;
    console.log(this.selectecDni);
  }

  onSelectGenre(event: TypeaheadMatch): void {
    this.selectecGenre = event.item;
    console.log(this.selectecGenre);
  }

  onSelectSpecialty(event: any): void {
    this.selectSpecialties = event.item; // Almacenar el objeto completo de la especialidad seleccionada
  }

  clear(id) {
    if (id === 1) {
      if (this.shortCreate.type_dni.value != '') {
        this.shortCreate.type_dni.setValue(null);
      }
    } else if (id === 2) {
      if (this.shortCreate.especialidad.value != '') {
        this.shortCreate.especialidad.setValue(null);
      }
    }
    else if (id === 3) {
      if (this.shortCreate.genero.value != '') {
        this.shortCreate.genero.setValue(null);
      }
    }
  }

  validateToken(): void {
    if (!this.create_patients.get('sesion').value) {
      this._helper.modalInfo('Por favor escriba el código');
      return;
    }

    const apiUrl = 'https://mefid.com.co/medic/verification_url/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      session: this.create_patients.get('sesion').value
    };

    this._http.post(apiUrl, body, { headers }).subscribe(
      (response: any) => {
        this.specialties = response.speciality; // Almacenar el objeto completo de la especialidad
        this.showFields = true; // Mostrar los campos ocultos
        this.isCodeVerified = true; // Deshabilitar el input
        this._helper.modalSuccess('Código verificado con éxito');
      },
      error => {
        this._helper.modalError(error.error.error);
      }
    );
  }
}
