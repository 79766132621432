import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl
} from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
declare var $: any;

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  modalRef: BsModalRef;
  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true
  };

  @Output() state = new EventEmitter();
  @Output() searchT = new EventEmitter();
  @Output() orderT = new EventEmitter();
  @Output() createPatient = new EventEmitter();


  search: FormGroup;
  filter: FormControl;
  buscar: FormControl;
  direction: FormControl;

  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>; // Obtén una referencia al template del modal
  @ViewChild('codeElement') codeElement!: ElementRef;
  @ViewChild('tooltip') tooltip!: ElementRef;

  selectecSearch: any;
  selectecOrder: any;
  orderL = 0;
  urlGenerada: string = ''; // Variable para almacenar la URL generada
  tiempo_expire;
  codeUnique: string = ''
  showTooltip: boolean = false;

  paramSearch: any[] = [
    { value: 'name', name: 'nombre' },
    { value: 'last_name', name: 'apellido' },
    { value: 'dni', name: 'cedula' }
  ];

  paramOrder: any[] = [
    { value: 0, name: 'Fecha' },
    { value: 1, name: 'Primer nombre' },
    { value: 2, name: 'Primer apellido' },
    { value: 3, name: 'Cedula' },
    { value: 4, name: 'Celular' },
    { value: 5, name: 'Correo' },
    { value: 6, name: 'Especialidad' },
    { value: 7, name: 'Puntos' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private _http: HttpClient,
    public _helper: HelpersService


  ) { }

  ngOnInit() {
    this.searchForm();
  }

  searchForm() {
    this.search = this.formBuilder.group({
      filter: [null, [Validators.required]],
      buscar: [null, [Validators.required]]
    });
    this.direction = new FormControl(null);

    this.selectecSearch = null;
    this.selectecOrder = null;
  }

  filterState(id) {
    this.state.emit(id);
  }

  get shortSearch() {
    return this.search.controls;
  }

  copyToClipboard(code: string) {
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.showTooltip = true;
  }

  hideTooltip() {
    setTimeout(() => {
      this.showTooltip = false;
    }, 2000);
  }

  clear(id) {
    // alert(id);
    // console.log(this.direction);
    if (id === 1) {
      if (this.direction.value !== '') {
        this.direction.setValue(null);
      }
    } else if (id === 2) {
      if (this.shortSearch.filter.value !== '') {
        this.shortSearch.filter.setValue(null);
      }
    }
  }

  onSelect(event: TypeaheadMatch): void {
    console.log(event);

    this.selectecSearch = event;
  }

  onSelect2(event: TypeaheadMatch): void {
    console.log(event);

    this.selectecOrder = event;
  }

  searchP() {
    console.log(this.selectecSearch);

    if (this.selectecSearch !== null && this.shortSearch.buscar.value != null) {
      // console.log(this.selectecSearch['value']);
      const arr: Array<any> = [];
      arr.push(
        { filter: this.selectecSearch },
        { search: this.shortSearch.buscar.value }
      );

      console.log(arr);

      this.searchT.emit(arr);
    }
  }

  order(order) {
    if (this.selectecOrder !== null) {
      this.orderL = order;
      const arr: Array<any> = [];
      arr.push({ order: order }, { direction: this.selectecOrder });
      this.orderT.emit(arr);
    }
  }

  add_medic(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  patientChange($event) {
    this.modalRef.hide();
    this.createPatient.emit(true);
  }

  generateQr(): void {
    const apiUrl = environment.api_generate_url;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {};  // Puedes añadir el payload necesario si el endpoint lo requiere

    this._http.post(apiUrl, body, { headers }).subscribe(
      (response: any) => {
        this.urlGenerada = response.url;
        this.codeUnique = response.session
        this._helper.modalSuccess('Código generado con éxito');
        this.mostrarModalQR(this.modalTemplate); // Pasa el template del modal a la función mostrarModalQR
      },
      error => {
        this._helper.modalAlert('Error al generar código');
      }
    );
  }


  async mostrarModalQR(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }


}
