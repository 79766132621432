import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { PacientesService } from '../../shared/servicio/pacientes/pacientes.service';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { AdminService } from '../services/admin/admin.service';
import { formatDate } from 'ngx-bootstrap';

declare var $: any;

@Component({
  selector: 'app-admin-pacientes',
  templateUrl: './admin-pacientes.component.html',
  styleUrls: ['./admin-pacientes.component.css'],
})
export class AdminPacientesComponent implements OnInit {
  page: any;
  p: any;
  totalpages: number;
  itemsPagina: any;
  debug: boolean;
  searchtext: string = '';
  searchtext2: string = 'none';
  criterio: string = 'none';
  pacientes_api: any;
  activate: number = 0;
  especialidades_api: any;
  especialidad: string = 'none';
  selectradio: number = 0;
  dni: string;
  telefono: string;
  dataLista = true;

  searchTextFC: FormControl;
  criterioSelectorFC: FormControl;

  /**
   * Input de fecha inicial (fecha de activación de cuenta)
   */
  startDateFC: FormControl;

  /**
   * Input de fecha de finalización (fecha de activación de cuenta)
   */
  endDateFC: FormControl;

  //medico.component.css//orden
  order: number = 1;
  direction: number = 0;

  constructor(
    public _pacientes: PacientesService,
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router,
    private formBuilder: FormBuilder,
    public _helper: HelpersService,
    private admin: AdminService
  ) {}

  ngOnInit() {
    this.dataLista = false;
    this.searchTextFC = new FormControl('');
    this.criterioSelectorFC = new FormControl('none');
    this.startDateFC = new FormControl('');
    this.endDateFC = new FormControl('');
    this.p = 1;
    this.searchTextFC.valueChanges.subscribe((value) => {
      this.search_medic(value);
    });
    this.criterioSelectorFC.valueChanges.subscribe((value) => {
      this.p = 1;
      this.list_pacientes(this.p);
    });
    this.startDateFC.valueChanges.subscribe((onDateChange) => {
      this.p = 1;
      this.list_pacientes(this.p);
    });
    this.endDateFC.valueChanges.subscribe((onDateChange) => {
      this.p = 1;
      this.list_pacientes(this.p);
    });
    this.list_pacientes(this.p);
    this.debug_db();
  }

  /**
   * Valida los valores de los input de fecha de inicio y fin para el filtro de lista
   */
  validateDate() {
    var startDate, endDate;
    if (this.startDateFC.value == undefined || this.startDateFC.value == '') {
      startDate = 'none';
    } else {
      startDate = this.getFormatedDate(this.startDateFC.value);
    }

    if (this.endDateFC.value == undefined || this.endDateFC.value == '') {
      endDate = 'none';
    } else {
      endDate = this.getFormatedDate(this.endDateFC.value);
    }
    return [startDate, endDate];
  }

  /**
   * Función que intercepta el evento de elección de fecha del NGX DatePicker
   * @param calendarView Instancia de calendario que disparó el evento
   * @param isStart Booleano que indica si al instancia originaria fue la fecha de inicio o la de fin
   */
  opencalendar(calendarView, isStart) {
    if (isStart) {
      this.startDateFC.setValue('');
    } else {
      this.endDateFC.setValue('');
    }
    calendarView.yearSelectHandler = (event: any): void => {
      calendarView._store.dispatch(calendarView._actions.select(event.date));
    };
    calendarView.setViewMode('day');
  }

  /**
   * Función que toma una fecha completa y en Y-M-D:H-M-S y la transforma a Y-M-D
   * @param joinDate Fecha
   */
  getFormatedDate(joinDate) {
    return formatDate(joinDate, 'YYYY-MM-DD', 'es');
  }

  list_pacientes(p2) {
    var [startDate, endDate] = this.validateDate();

    if (p2 === undefined) {
      p2 = 1;
    } else {
      p2 - 1;
    }

    if (this.searchtext2 === '') {
      this.searchtext2 = 'none';
    }
    this._http
      .get(
        environment.Api_get_all_patient_list +
          0 +
          '/' +
          this.searchtext2 +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          startDate +
          '/' +
          endDate +
          '/' +
          p2 +
          '/'
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.vista_console(data, 'Referidos Pacientes');
          this.pacientes_api = data['info'];
          this.totalpages = data['page_info']['count'];
          this.itemsPagina = data['page_info']['total_items'];
          this.p = data['page_info']['current'];
          this.dataLista = true;
        },
        (error) => {
          this.manejador_errores(error);
          console.log(error);
        }
      );
  }

  getPage(p: number) {
    var [startDate, endDate] = this.validateDate();
    this._http
      .get(
        environment.Api_get_all_patient_list +
          this.criterioSelectorFC.value +
          '/' +
          this.searchtext2 +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          startDate +
          '/' +
          endDate +
          '/' +
          p
      )
      .subscribe(
        (data) => {
          this.vista_console(data, 'paginacion');
          console.log(data);
          this.dataLista = true;
          this.list_pacientes(p);
        },
        (error) => {
          this.manejador_errores(error);
        }
      );
  }

  search_medic(name) {
    if (name == undefined || name == '') {
      this.searchtext2 = 'none';
    }
    this.vista_console(name, 'Buscar en la lista de pacientes');
    this.searchtext2 = name;
    this.list_pacientes(this.p);
  }

  especialidades_medico() {
    this._pacientes.especialidad_medico().subscribe(
      (data) => {
        this.especialidades_api = data;
        console.log(this.especialidades_api);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  vista_console(data, string: string) {
    if (this.debug === true) {
      console.log(string, data);
    } else {
      console.log('Debug no habilitado');
    }
  }

  // Conexion para ver el estado del debug
  debug_db() {
    this._ip.debug().subscribe(
      (data) => {
        this.debug = data['state'];
        this.vista_console(data, 'valor del debug');
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  // Metodo para  controlar las exepciones cuando falla las conexiones con el
  // servidor
  manejador_errores(error) {
    if (this.debug === true) {
      console.log(error);
    } else {
      console.log('Debug no habilitado');
    }
    if (error.status === 400) {
      this._helper.modalError('¡' + error.error + '!');
    } else if (error.statusText === 'Unknown Error') {
      this._helper.modalError('¡Error de conexión con el servidor!');
    } else if (error.status === 500) {
      this._helper.modalError('¡Error del servidor!');
    } else if (error.status === 403) {
      this._helper.modalError('¡Página no encontrada!');
      this.router.navigate(['/login']);
    }
  }

  ordenamiento(valor: number, orden: number) {
    this.order = valor;
    this.direction = orden;
    this.list_pacientes(this.p);
  }
}
