import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

declare var $: any;
@Injectable({ providedIn: 'root' })
export class HelpersService {
  constructor() {}
  public token: string;
  validators(array: any) {
    var validar_textos = /^([a-z ñáéíóú]{2,60})$/i;
    var data = '';
    for (let i = 0; i < array.length; i++) {
      //para inputs de tipo texto
      if (array[i]['tipo'] === 'text') {
        if (array[i]['requerido'] === 'si') {
          if (
            array[i]['valor'] === undefined ||
            /^\s+$/.test(array[i]['valor']) ||
            array[i]['valor'] === ''
          ) {
            data += array[i]['nombre'] + ' ES REQUERIDO <BR>';
          } else if (array[i]['valor'].length < array[i]['min_length']) {
            data +=
              array[i]['nombre'] +
              ' DEBE TENER AL MENOS ' +
              array[i]['min_length'] +
              ' CARAC' +
              'TERES<BR>';
          } else if (!validar_textos.test(array[i]['valor'])) {
            data += array[i]['nombre'] + ' SOLO DEBEN SER LETRAS <BR>'; //para no requeridos
          }
        } else if (array[i]['requerido'] === 'no') {
          if (array[i]['valor'].length > 0) {
            if (array[i]['valor'].length < array[i]['min_length']) {
              data +=
                array[i]['nombre'] +
                ' DEBE TENER AL MENOS ' +
                array[i]['min_length'] +
                ' CARAC' +
                'TERES<BR>';
            } else if (!validar_textos.test(array[i]['valor'])) {
              data += array[i]['nombre'] + ' SOLO DEBEN SER LETRAS <BR>';
            }
          }
        }
      }

      // para inputs de tipo phone
      if (array[i]['tipo'] === 'phone') {
        if (array[i]['requerido'] === 'si') {
          if (
            array[i]['valor'] === undefined ||
            /^\s+$/.test(array[i]['valor']) ||
            array[i]['valor'] === ''
          ) {
            data += array[i]['nombre'] + ' ES REQUERIDO <BR>';
          } else if (array[i]['valor'].length < array[i]['min_length']) {
            data +=
              array[i]['nombre'] +
              ' DEBE TENER AL MENOS ' +
              array[i]['min_length'] +
              ' CARAC' +
              'TERES<BR>';
          } else if (!/^([3]{1})+([0-9]{9})$/.test(array[i]['valor'])) {
            data += array[i]['nombre'] + ' INVALIDO <BR>'; // para no requeridos
          }
        } else if (array[i]['requerido'] === 'no') {
          if (array[i]['valor'].length > 0) {
            if (array[i]['valor'].length < array[i]['min_length']) {
              data +=
                array[i]['nombre'] +
                ' DEBE TENER AL MENOS ' +
                array[i]['min_length'] +
                ' CARAC' +
                'TERES<BR>';
            } else if (!/^([3]{1})+([0-9]{9})$/.test(array[i]['valor'])) {
              data += array[i]['nombre'] + ' INVALIDO <BR>';
            }
          }
        }
      }

      // para inputs de tipo email
      if (array[i]['tipo'] === 'email') {
        if (array[i]['requerido'] === 'si') {
          if (
            array[i]['valor'] === undefined ||
            /^\s+$/.test(array[i]['valor']) ||
            array[i]['valor'] === ''
          ) {
            data += array[i]['nombre'] + ' ES REQUERIDO <BR>';
          } else if (array[i]['valor'].length < array[i]['min_length']) {
            data +=
              array[i]['nombre'] +
              ' DEBE TENER AL MENOS ' +
              array[i]['min_length'] +
              ' CARAC' +
              'TERES<BR>';
          } else if (
            !/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
              array[i]['valor']
            )
          ) {
            data += array[i]['nombre'] + ' INVALIDO <BR>'; //para no requeridos
          }
        } else if (array[i]['requerido'] === 'no') {
          if (array[i]['valor'].length > 0) {
            if (array[i]['valor'].length < array[i]['min_length']) {
              data +=
                array[i]['nombre'] +
                ' DEBE TENER AL MENOS ' +
                array[i]['min_length'] +
                ' CARAC' +
                'TERES<BR>';
            } else if (
              !/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
                array[i]['valor']
              )
            ) {
              data += array[i]['nombre'] + ' INVALIDO <BR>';
            }
          }
        }
      }

      //para inputs de tipo number
      if (array[i]['tipo'] === 'number') {
        if (array[i]['requerido'] === 'si') {
          if (
            array[i]['valor'] === undefined ||
            /^\s+$/.test(array[i]['valor']) ||
            array[i]['valor'] === ''
          ) {
            data += array[i]['nombre'] + ' ES REQUERIDO <BR>';
          } else if (array[i]['valor'].length < array[i]['min_length']) {
            data +=
              array[i]['nombre'] +
              ' DEBE TENER AL MENOS ' +
              array[i]['min_length'] +
              ' CARAC' +
              'TERES<BR>';
          } else if (!/^[0-9]*$/.test(array[i]['valor'])) {
            data += array[i]['nombre'] + ' INVALIDO SOLO CONTIENE NUMEROS <BR>'; //para no requeridos
          }
        } else if (array[i]['requerido'] === 'no') {
          if (array[i]['valor'].length > 0) {
            if (array[i]['valor'].length < array[i]['min_length']) {
              data +=
                array[i]['nombre'] +
                ' DEBE TENER AL MENOS ' +
                array[i]['min_length'] +
                ' CARAC' +
                'TERES<BR>';
            } else if (!/^[0-9]*$/.test(array[i]['valor'])) {
              data +=
                array[i]['nombre'] + ' INVALIDO SOLO CONTIENE NUMEROS <BR>';
            }
          }
        }
      }

      //para inputs de tipo alfanumericos
      if (array[i]['tipo'] === 'alpha') {
        if (array[i]['requerido'] === 'si') {
          if (
            array[i]['valor'] === undefined ||
            /^\s+$/.test(array[i]['valor']) ||
            array[i]['valor'] === ''
          ) {
            data += array[i]['nombre'] + ' ES REQUERIDO <BR>';
          } else if (array[i]['valor'].length < array[i]['min_length']) {
            data +=
              array[i]['nombre'] +
              ' DEBE TENER AL MENOS ' +
              array[i]['min_length'] +
              ' CARAC' +
              'TERES<BR>'; //para no requeridos;
          }
        } else if (array[i]['requerido'] === 'no') {
          if (array[i]['valor'].length > 0) {
            if (array[i]['valor'].length < array[i]['min_length']) {
              data +=
                array[i]['nombre'] +
                ' DEBE TENER AL MENOS ' +
                array[i]['min_length'] +
                ' CARAC' +
                'TERES<BR>';
            }
          }
        }
      }

      //para inputs de tipo select
      if (array[i]['tipo'] === 'select') {
        if (array[i]['requerido'] === 'si') {
          if (
            array[i]['valor'] === undefined ||
            /^\s+$/.test(array[i]['valor']) ||
            array[i]['valor'] === ''
          ) {
            data += array[i]['nombre'] + ' ES REQUERIDO <BR>';
          } else if (!/^[0-9]*$/.test(array[i]['valor'])) {
            data += array[i]['nombre'] + ' NO PUEDE SER NULO <BR>';
          } else if (array[i]['valor'] < 0) {
            data += array[i]['nombre'] + ' NO VALIDO POR AQUI <BR>'; //para no requeridos
          }
        } else if (array[i]['requerido'] === 'no') {
          if (array[i]['valor'].length > 0) {
            if (!/^[0-9]*$/.test(array[i]['valor'])) {
              data += array[i]['nombre'] + ' NO PUEDE SER NULO <BR>';
            } else if (array[i]['valor'] < 0) {
              data += array[i]['nombre'] + ' NO VALIDO <BR>';
            }
          }
        }
      }
    }

    return data;
  }

  error(component = null, msjerror) {
    console.log(msjerror);
    var retorno = '';
    if (component === 'login') {
      if (msjerror.status === 206) {
        if (msjerror.error['role'] === 'medic') {
          this.token = msjerror.error['token'];
          window.localStorage.setItem('token', this.token);
          localStorage.setItem('type', '2');
          $('#updatePass').modal({ backdrop: 'static', keyboard: false });
        } else {
          retorno = msjerror['error'];
        }
      }
    }

    // FUERA DE LOGIN

    if (msjerror.status === 400) {
      if (component != 'login') {
        retorno = msjerror['error']['message'];
        if (retorno === undefined) {
          retorno = msjerror['error'];
        }
      }
    } else if (msjerror.statusText === 'Unknown Error') {
      retorno = msjerror['error'];
    } else if (msjerror.status === 500) {
      retorno = 'Error no esperado';
    }

    return retorno;
  }

  /**
   * Toma la lista de errores enviada desde el backend, concatenada con alguna etiqueta, y los devuelve en forma de cadena.
   * @param errorMessage mensajes de error concatenados con alguna palabra o etiqueta
   * @param splitString etiqueta o palabra con la que fue concatenado el error
   */
  splitErrorMessages(errorMessage, splitString) {
    console.log(errorMessage);

    if (errorMessage.hasOwnProperty('message')) {
      errorMessage = errorMessage.message;
    } else {
      while (errorMessage.hasOwnProperty('error')) {
        errorMessage = errorMessage.error;
      }
    }

    var errorList = errorMessage.split(splitString);
    console.log(errorList);
    var errorString = '';
    var size = errorList.length - 1;
    for (let i = 0; i < errorList.length; i++) {
      if (errorList[i] != '') {
        errorString += '¡' + errorList[i] + '!\n';
      }
    }
    console.log(errorString);

    return errorString;
  }

  /**
   *
   * @param msg
   */
  modalError(msg) {
    swal({
      title: msg,
      type: 'error',
    });
  }

  /**
   *
   * @param msg
   */
  modalAlert(msg) {
    swal({
      title: msg,
      type: 'warning',
    });
  }

  /**
   *
   * @param msg
   */
  modalInfo(msg) {
    swal({
      title: msg,
      type: 'info',
    });
  }

  /**
   *
   * @param text
   */
  modalConfirm(text) {
    return swal({
      title: text,
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    });
  }

  /**
   *
   * @param text
   */
  modalSuccess(text) {
    return swal({
      title: text,
      type: 'success',
    });
  }
}
