import {
  Component,
  OnInit,
  Input,
  Output,
  TemplateRef,
  EventEmitter,
} from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { MedicoService } from 'src/app/shared/servicio/medico/medico.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HelpersService } from '../../../../shared/servicio/helpers/helpers.service';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';

@Component({
  selector: 'app-update-medic-comercial',
  templateUrl: './update-medic-comercial.component.html',
  styleUrls: ['./update-medic-comercial.component.css'],
})
export class UpdateMedicComercialComponent implements OnInit {
  @Input() id_medic;
  @Output() updateMedicE = new EventEmitter();
  state: any[] = [
    { value: 0, name: 'Seleccione' },
    { value: 1, name: 'Registrado' },
    { value: 2, name: 'Incomunicado' },
    { value: 3, name: 'Pendiente' },
    { value: 4, name: 'No interesado' },
    { value: 5, name: 'Inactivo' },
    { value: 6, name: 'Activo' },
  ];

  specialties: any;
  special_list: any = [];

  update: FormGroup;
  name: FormControl;
  name2: FormControl;
  address: FormControl;
  email: FormControl;
  cell: FormControl;
  dni: FormControl;
  lastname: FormControl;
  lastname_2: FormControl;
  license: FormControl;
  stateForm: FormControl;
  especial: FormControl;

  selectedState: any; // Seleccion del estado
  selectedSpecial: any; // seleccion de especialidad
  stateMedic: any; // estado del medico
  cellphone_validate: boolean; // validacion de celular

  arrEspecialidad: Array<any> = []; // especialidades para agregar medico
  arrEspecialidadDelete: Array<any> = []; // especialidades que se eliminaran a un medico
  cityForm: FormControl;
  cityList: any = [];
  submitted = false;
  preloader: boolean = false;
  load: boolean = true;
  modalRef: BsModalRef;
  direccionCompleta: string = ''; // Inicializa la variable
  selectedCity: any;

  config = {
    animated: true,
    class: 'modal-sm modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  constructor(
    private _helper: HelpersService,
    private formBuilder: FormBuilder,
    public _medic: MedicoService,
    private modalService: BsModalService,
    public medic: MedicoService,
    private _comercial: ComercialService,

  ) { }

  ngOnInit() {
    this.listCiudades();
    this.info();
    this.specialist_list();
    this.updateMedic();
  }

  listCiudades() {
    this._comercial.list_ciudades().subscribe(
      (data) => {
        console.log(data);
        this.cityList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }


  updateMedic() {
    this.update = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      name2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      address: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],
      cityId: [null, Validators.required],       // Almacena el id (valor real)
      cityName: ['', Validators.required],

      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastname_2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      stateForm: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ_ ]*'),
        ],
      ],

      cell: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      dni: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],


      cc_type: [
        '',
        [
          Validators.required,
        ],
      ],

      email: [null, [Validators.required, Validators.email]],

      license: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(20),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ1234567890 ]*'),
        ],
      ],

      especial: [null, Validators.pattern(/(a-zA-Z)+/)],
    });
  }

  get shortUpdate() {
    return this.update.controls;
  }


  updateAddress() {
    const tipoVia = (document.getElementById('tipoVia') as HTMLSelectElement).value || '';
    const numero1 = (document.getElementById('numero1') as HTMLInputElement).value || '';
    const numero2 = (document.getElementById('numero2') as HTMLInputElement).value || '';
    const numero3 = (document.getElementById('numero3') as HTMLInputElement).value || '';

    // Validar que todos los campos estén completos
    if (tipoVia && numero1 && numero2 && numero3) {
      this.direccionCompleta = `${tipoVia} ${numero1}#  ${numero2}-${numero3}`;
    } else {
      this.direccionCompleta = '';  // Limpiar si falta algún campo
    }

    // Asignar la dirección construida al campo oculto "address"
    (document.getElementById('address') as HTMLInputElement).value = this.direccionCompleta.trim();
  }


  clear(id) {
    if (id === 1) {
      if (this.shortUpdate.stateForm.value != '') {
        this.shortUpdate.stateForm.setValue(null);
      }
    }
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedState = event.item;
  }

  onSelect2(event: TypeaheadMatch, template: TemplateRef<any>): void {
    this.selectedSpecial = event.item;
    this.modalRef = this.modalService.show(template, this.config);
    this.shortUpdate.especial.setValue(null);
  }

  specialist_list() {
    this._medic.getAllSpecialties().subscribe(
      (data) => {
        console.log(data);
        this.special_list = data;
      },
      (error) => {
        console.log(error);
        this._helper.modalError(
          '¡Ocurrió un error cargando la lista de especialidades!'
        );
      }
    );
  }

  addSpecialties() {
    let data: boolean;
    data = this.validArrRepeat(this.selectedSpecial['id']);
    if (data === false) {
      this.arrEspecialidad.push({
        id: this.selectedSpecial['id'],
        nombre: this.selectedSpecial['name'],
      });
    } else {
      this._helper.modalAlert('¡Esta especialidad ya fue agregada a la lista!');
    }
    this.modalRef.hide();
  }

  validArrRepeat(id) {
    var sw = false;
    for (var _i = 0; _i < this.specialties.length; _i++) {
      if (this.specialties[_i]['specialty_id__id'] === id) {
        sw = true;
      }
    }

    for (var _i = 0; _i < this.arrEspecialidad.length; _i++) {
      if (this.arrEspecialidad[_i]['id'] === id) {
        sw = true;
      }
    }

    return sw;
  }

  eliminarPos(nombre, idEspecial, arr, id, tipe) {
    if (tipe === 1) {
      this.arrEspecialidadDelete.push({
        id: idEspecial,
        nombre: nombre,
      });
    }
    arr.splice(id, 1);
  }


  info() {
    this._medic.medic_id(this.id_medic).subscribe(
      (data) => {
        this.load = false;
        console.log(data);
        this.arrEspecialidad = [];
        this.arrEspecialidadDelete = [];
        this.specialties = data['specialties'];

        // Actualiza los controles con los datos recibidos
        this.shortUpdate.name.setValue(data['user_info']['first_name']);
        this.shortUpdate.name2.setValue(data['user_info']['first_name2']);

        // Dirección
        const address = data['medic_info']['address'];
        this.shortUpdate.address.setValue(address);
        this.direccionCompleta = address;

        // Nombres y apellidos
        this.shortUpdate.lastname.setValue(data['user_info']['last_name']);
        this.shortUpdate.lastname_2.setValue(data['user_info']['last_name2']);
        this.shortUpdate.email.setValue(data['user_info']['email']);
        this.shortUpdate.cell.setValue(data['user_info']['cell']);
        // Obtener la ciudad y actualizar controles cityName y cityId
        const cityIdFromApi = data['city_medic_id'];
        const cityObj = this.cityList.find(city => city.id === cityIdFromApi);
        if (cityObj) {
          this.shortUpdate.cityName.setValue(cityObj['dane_id__name']);
          this.shortUpdate.cityId.setValue(cityObj.id);
        }

        // Resto de datos
        this.shortUpdate.dni.setValue(data['user_info']['dni']);
        // this.shortUpdate.cc_type.setValue(data['user_info']['cc_type']);

        this.shortUpdate.license.setValue(data['medic_info']['profesional_license']);
        this.cellphone_validate = data['cellphone_verified'];
        this.stateMedic = data['medic_info']['state'];
        this.shortUpdate.stateForm.setValue(
          this.state[data['medic_info']['state']]['name']
        );
        this.selectedState = this.state[data['medic_info']['state']]['value'];
      },
      (error) => {
        this._helper.modalError(
          this._helper.splitErrorMessages(error.error, '<br>')
        );
      }
    );
  }


  onCitySelect(event: TypeaheadMatch): void {
    const selectedCity = event.item; // Objeto con { id, dane_id__name, ... }
    // Actualiza el control del nombre (visible) y el id (oculto)
    this.update.patchValue({
      cityName: selectedCity.dane_id__name,
      cityId: selectedCity.id
    });
    this.selectedCity = selectedCity;
  }

  updateMedicP() {
    this.updateAddress();

    const state = this.selectedState['value'] || this.selectedState;
    // Obtén el id de la ciudad desde el control oculto
    const cityId = this.update.get('cityId').value;

    this.medic.update_medic_address(
      this.id_medic,
      this.update.get('name').value,
      this.update.get('name2').value,
      this.direccionCompleta,
      cityId, // Aquí se envía solo el id
      this.update.get('lastname').value,
      this.update.get('lastname_2').value,
      this.update.get('email').value,
      this.update.get('license').value,
      this.update.get('cell').value,
      this.update.get('dni').value,
      state,
      this.arrEspecialidad,
      this.arrEspecialidadDelete
    ).subscribe(
      (data) => {
        console.log(data);
        this.info();
        this.updateMedicE.emit(true);
        this._helper.modalSuccess('¡Datos actualizados correctamente!');
      },
      (error) => {
        this.preloader = false;
        this.submitted = false;
        console.log(error);
        this._helper.modalError(this._helper.splitErrorMessages(error.error, '<br>'));
      }
    );
  }




}
