import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  NgForm,
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import { HttpClient } from '@angular/common/http';
import { MedicoService } from '../../../../shared/servicio/medico/medico.service';

@Component({
  selector: 'app-verifiedcell',
  templateUrl: './verifiedcell.component.html',
  styleUrls: ['./verifiedcell.component.css'],
})
export class VerifiedcellComponent implements OnInit {
  verified: FormGroup;
  code: FormControl;
  submitted = false;
  preloader: boolean = false;
  load: boolean = true;

  @Output() verifiedS = new EventEmitter();
  constructor(
    private _http: HttpClient,
    private formBuilder: FormBuilder,
    public helper: HelpersService,
    public medic: MedicoService
  ) {}

  ngOnInit() {
    this.verifiedForm();
  }

  verifiedForm() {
    this.verified = this.formBuilder.group({
      code: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
          Validators.pattern(/^\d+$/),
        ],
      ],
    });
  }

  get shortVerified() {
    return this.verified.controls;
  }

  sendverified() {
    this.preloader = true;
    if (this.verified.valid) {
      this.medic.verified_cell(this.shortVerified.code.value).subscribe(
        (data) => {
          console.log(data);
          this.preloader = false;
          this.submitted = false;
          this.verifiedS.emit(true);
        },
        (error) => {
          // console.log(error);
          // this.toastr.error(this.helper.error("",error), "error", {
          //   positionClass: 'toast-top-right',
          //   timeOut: 5000
          // });
          this.helper.modalError(
            this.helper.splitErrorMessages(error.error, '<br>')
          );
          this.preloader = false;
          this.submitted = false;
        }
      );
    } else {
      this.submitted = true;
      this.preloader = false;
    }
  }
}
