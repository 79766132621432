import {
  Component,
  OnInit,
  Input,
  Output,
  TemplateRef,
  EventEmitter,
} from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { MedicoService } from 'src/app/shared/servicio/medico/medico.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HelpersService } from '../../../../shared/servicio/helpers/helpers.service';

@Component({
  selector: 'app-update-medic-comercial',
  templateUrl: './update-medic-comercial.component.html',
  styleUrls: ['./update-medic-comercial.component.css'],
})
export class UpdateMedicComercialComponent implements OnInit {
  @Input() id_medic;
  @Output() updateMedicE = new EventEmitter();
  state: any[] = [
    { value: 0, name: 'Seleccione' },
    { value: 1, name: 'Registrado' },
    { value: 2, name: 'Incomunicado' },
    { value: 3, name: 'Pendiente' },
    { value: 4, name: 'No interesado' },
    { value: 5, name: 'Inactivo' },
    { value: 6, name: 'Activo' },
  ];

  specialties: any;
  special_list: any = [];

  update: FormGroup;
  name: FormControl;
  name2: FormControl;
  email: FormControl;
  cell: FormControl;
  dni: FormControl;
  lastname: FormControl;
  lastname_2: FormControl;
  license: FormControl;
  stateForm: FormControl;
  especial: FormControl;

  selectedState: any; // Seleccion del estado
  selectedSpecial: any; // seleccion de especialidad
  stateMedic: any; // estado del medico
  cellphone_validate: boolean; // validacion de celular

  arrEspecialidad: Array<any> = []; // especialidades para agregar medico
  arrEspecialidadDelete: Array<any> = []; // especialidades que se eliminaran a un medico

  submitted = false;
  preloader: boolean = false;
  load: boolean = true;
  modalRef: BsModalRef;

  config = {
    animated: true,
    class: 'modal-sm modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  constructor(
    private _helper: HelpersService,
    private formBuilder: FormBuilder,
    public _medic: MedicoService,
    private modalService: BsModalService,
    public medic: MedicoService
  ) {}

  ngOnInit() {
    this.info();
    this.specialist_list();
    this.updateMedic();
  }

  updateMedic() {
    this.update = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      name2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      lastname_2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      stateForm: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ_ ]*'),
        ],
      ],

      cell: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      email: [null, [Validators.required, Validators.email]],

      license: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(20),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ1234567890 ]*'),
        ],
      ],

      especial: [null, Validators.pattern(/(a-zA-Z)+/)],
    });
  }

  get shortUpdate() {
    return this.update.controls;
  }

  info() {
    this._medic.medic_id(this.id_medic).subscribe(
      (data) => {
        this.load = false;
        console.log(data);
        this.arrEspecialidad = []; // especialidades para agregar medico
        this.arrEspecialidadDelete = []; // especialidades que se eliminaran a un medico
        this.specialties = data['specialties'];
        this.shortUpdate.name.setValue(data['user_info']['first_name']);
        this.shortUpdate.name2.setValue(data['user_info']['first_name2']);
        this.shortUpdate.lastname.setValue(data['user_info']['last_name']);
        this.shortUpdate.lastname_2.setValue(data['user_info']['last_name2']);
        this.shortUpdate.email.setValue(data['user_info']['email']);
        this.shortUpdate.cell.setValue(data['user_info']['cell']);
        this.shortUpdate.dni.setValue(data['user_info']['dni']);
        this.shortUpdate.license.setValue(
          data['medic_info']['profesional_license']
        );
        this.cellphone_validate = data['cellphone_verified'];
        this.stateMedic = data['medic_info']['state'];
        this.shortUpdate.stateForm.setValue(
          this.state[data['medic_info']['state']]['name']
        );
        this.selectedState = this.state[data['medic_info']['state']]['value'];
      },
      (error) => {
        this._helper.modalError(
          this._helper.splitErrorMessages(error.error, '<br>')
        );
      }
    );
  }

  clear(id) {
    if (id === 1) {
      if (this.shortUpdate.stateForm.value != '') {
        this.shortUpdate.stateForm.setValue(null);
      }
    }
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedState = event.item;
  }

  onSelect2(event: TypeaheadMatch, template: TemplateRef<any>): void {
    this.selectedSpecial = event.item;
    this.modalRef = this.modalService.show(template, this.config);
    this.shortUpdate.especial.setValue(null);
  }

  specialist_list() {
    this._medic.getAllSpecialties().subscribe(
      (data) => {
        console.log(data);
        this.special_list = data;
      },
      (error) => {
        console.log(error);
        this._helper.modalError(
          '¡Ocurrió un error cargando la lista de especialidades!'
        );
      }
    );
  }

  addSpecialties() {
    let data: boolean;
    data = this.validArrRepeat(this.selectedSpecial['id']);
    if (data === false) {
      this.arrEspecialidad.push({
        id: this.selectedSpecial['id'],
        nombre: this.selectedSpecial['name'],
      });
    } else {
      this._helper.modalAlert('¡Esta especialidad ya fue agregada a la lista!');
    }
    this.modalRef.hide();
  }

  validArrRepeat(id) {
    var sw = false;
    for (var _i = 0; _i < this.specialties.length; _i++) {
      if (this.specialties[_i]['specialty_id__id'] === id) {
        sw = true;
      }
    }

    for (var _i = 0; _i < this.arrEspecialidad.length; _i++) {
      if (this.arrEspecialidad[_i]['id'] === id) {
        sw = true;
      }
    }

    return sw;
  }

  eliminarPos(nombre, idEspecial, arr, id, tipe) {
    if (tipe === 1) {
      this.arrEspecialidadDelete.push({
        id: idEspecial,
        nombre: nombre,
      });
    }
    arr.splice(id, 1);
  }

  updateMedicP() {
    this.preloader = true;
    if (this.update.valid) {
      if (
        this.selectedState === undefined ||
        this.selectedState['value'] === 0
      ) {
        this._helper.modalAlert('¡Debes seleccionar un estado!');
        this.preloader = false;
      } else {
        let state;
        if (this.selectedState['value'] === undefined) {
          state = this.selectedState;
        } else {
          state = this.selectedState['value'];
        }
        this.medic
          .update_medic(
            this.id_medic,
            this.shortUpdate.name.value,
            this.shortUpdate.name2.value,
            this.shortUpdate.lastname.value,
            this.shortUpdate.lastname_2.value,
            this.shortUpdate.email.value,
            this.shortUpdate.license.value,
            this.shortUpdate.cell.value,
            this.shortUpdate.dni.value,
            state,
            this.arrEspecialidad,
            this.arrEspecialidadDelete
          )
          .subscribe(
            (data) => {
              console.log(data);
              this.preloader = false;
              this.submitted = false;
              this.info();
              this.updateMedicE.emit(true);
              this._helper.modalSuccess('¡Datos actualizados correctamente!');
            },
            (error) => {
              this.preloader = false;
              this.submitted = false;
              this._helper.modalError(
                this._helper.splitErrorMessages(error.error, '<br>')
              );
            }
          );
      }
    } else {
      this.submitted = true;
      this.preloader = false;
    }
  }
}
