import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl
} from '@angular/forms';
import { ComercialService } from 'src/app/shared/servicio/comercial/comercial.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { AdminService } from '../../services/admin/admin.service';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';

@Component({
  selector: 'app-update-comercial-admin',
  templateUrl: './update-comercial-admin.component.html',
  styleUrls: ['./update-comercial-admin.component.css']
})
export class UpdateComercialAdminComponent implements OnInit {
  @Input() id_comercial;
  @Output() updateMedicE = new EventEmitter();
  submitted = false;
  preloader: boolean = false;
  load: boolean = true;

  cityList: any = [];
  selectedCity: any;

  update: FormGroup;
  name: FormControl;
  name2: FormControl;
  email: FormControl;
  cell: FormControl;
  dni: FormControl;
  lastname: FormControl;
  lastname_2: FormControl;
  cityForm: FormControl;

  isActive: any;

  constructor(
    private formBuilder: FormBuilder,
    private _comercial: ComercialService,
    private admin: AdminService,
    public _helper: HelpersService
  ) {}

  ngOnInit() {
    this.listCiudades();
    this.updateComercial();
    this.info();
  }

  updateComercial() {
    console.log('me ejecuto primero');

    this.update = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*')
        ]
      ],

      name2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*')
        ]
      ],

      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*')
        ]
      ],

      cityForm: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*')
        ]
      ],

      lastname_2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*')
        ]
      ],

      cell: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/)
        ]
      ],

      dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/)
        ]
      ],

      email: [null, [Validators.required, Validators.email]]
    });
  }

  buscarPorId(id) {
    return this.cityList.filter(element => {
      return element.id == id;
    })[0];
  }

  get shortUpdate() {
    return this.update.controls;
  }

  info() {
    console.log('me ejecuto de ultimo');
    this._comercial.comercial_id(this.id_comercial).subscribe(
      data => {
        this.load = false;
        console.log(data);
        this.shortUpdate.name.setValue(data[0]['first_name']);
        this.shortUpdate.name2.setValue(data[0]['first_name2']);
        this.shortUpdate.lastname.setValue(data[0]['last_name']);
        this.shortUpdate.lastname_2.setValue(data[0]['last_name2']);
        this.shortUpdate.email.setValue(data[0]['email']);
        this.shortUpdate.cell.setValue(data[0]['cell']);
        this.shortUpdate.dni.setValue(data[0]['username']);
        this.selectedCity = this.buscarPorId(data[0]['city_id__id']);
        this.shortUpdate.cityForm.setValue(data[0]['city_id__dane_id__name']);
        this.isActive = data[0]['is_active'];
        console.log(this.selectedCity);
      },
      error => {
        console.log(error);
      }
    );
  }

  listCiudades() {
    this._comercial.list_ciudades().subscribe(
      data => {
        console.log(data);
        this.cityList = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedCity = event.item;
  }

  clear(id) {
    if (id === 1) {
      if (this.shortUpdate.cityForm.value != '') {
        this.shortUpdate.cityForm.setValue(null);
      }
    }
  }

  updateComercialC() {
    console.log(this.update);
    this.preloader = true;
    if (this.update.valid) {
      this.admin
        .editComercial(
          this.id_comercial,
          this.shortUpdate.name.value,
          this.shortUpdate.name2.value,
          this.shortUpdate.lastname.value,
          this.shortUpdate.lastname_2.value,
          this.shortUpdate.email.value,
          this.shortUpdate.cell.value,
          this.shortUpdate.dni.value,
          this.selectedCity['id'],
          this.isActive
        )
        .subscribe(
          data => {
            console.log(data);
            this.preloader = false;
            this.submitted = false;
            this.info();
            this.updateMedicE.emit(true);
            this._helper.modalSuccess('¡Datos actualizados correctamente!');
          },
          error => {
            this._helper.modalError(
              this._helper.splitErrorMessages(error.error, '<br>')
            );
            this.preloader = false;
            this.submitted = false;
          }
        );
    } else {
      this.submitted = true;
      this.preloader = false;
    }
  }
}
