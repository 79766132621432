import { AsistentesModule } from './asistentes/asistentes.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//Locales

import { SharedModule } from './shared/shared.module';
import { ComercialModule } from './comercial/comercial.module';
import { MedicoModule } from './medico/medico.module';
import { AdminModule } from './admin/admin.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// interceptor de token
import { TokenInterceptor } from './interceptors/token.interceptor';
import { HomeResolveComponent } from './home-resolve/home-resolve.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ComprobacionCuentaComponent } from './comprobacion-cuenta/comprobacion-cuenta.component';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { LandingregisterComponent } from './landingregister/landingregister.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { QrCodeModule } from 'ng-qrcode';
import { EndpageComponent } from './endpage/endpage.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    HomeResolveComponent,
    ComprobacionCuentaComponent,
    LandingregisterComponent,
    EndpageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    AdminModule,
    MedicoModule,
    AsistentesModule,
    ComercialModule,
    BrowserAnimationsModule,
    TypeaheadModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    QrCodeModule,
    BsDatepickerModule.forRoot(),


  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-Co' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
