import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  activate_user(id: number, activate: boolean) {
    return this.http.post(environment.Api_get_user_activate, {
      user_id: id,
      active: activate,
    });
  }

  enviarMensajeMasivo(data) {
    return this.http.post(environment.Api_enviar_mensaje_masivo, data);
  }

  getListaCanales() {
    return this.http.get(environment.Api_get_channels);
  }

  getListaTipoMensaje() {
    return this.http.get(environment.Api_message_type);
  }

  medicos_referidos_mes(year: any, id: any) {
    return this.http.get(
      environment.Api_medic_graphics + year + '/' + id + '/'
    );
  }

  comerciales_referidos_mes(year: any, id: any) {
    return this.http.get(
      environment.Api_comercial_graphics + year + '/' + id + '/'
    );
  }

  createBanner(
    name: string,
    description: string,
    image_web: string,
    image_mobile: string
  ) {
    return this.http.post(
      environment.Api_create_banner,
      { name, description, image_web, image_mobile },
      { reportProgress: true, observe: 'events' }
    );
  }

  updateStateBanner(banner_id: number, activate: boolean) {
    return this.http.post(environment.Api_change_banner, {
      banner_id,
      activate,
    });
  }

  banner_id(id: number) {
    return this.http.get(environment.Api_list_bannerId + id);
  }

  banner_update(
    id: number,
    name: string,
    description: string,
    image_mobile: string,
    image_web: string
  ) {
    return this.http.post(
      environment.Api_list_bannerId + id + '/',
      { name, description, image_web, image_mobile },
      { reportProgress: true, observe: 'events' }
    );
  }

  delete_banner(banner_id: number) {
    return this.http.post(environment.Api_delete_banner, { banner_id });
  }

  specialties_list() {
    return this.http.get(environment.Api_get_especialties2);
  }

  create_survey(
    title: string,
    all_medics: Boolean,
    recipients_specialties: any
  ) {
    return this.http.post(environment.Api_create_survey, {
      title,
      all_medics,
      recipients_specialties,
    });
  }

  create_question(survey_id, items) {
    return this.http.post(environment.Api_create_question + '/', {
      survey_id,
      items,
    });
  }

  list_idSurvey(survey_id: number) {
    return this.http.get(environment.Api_list_survey + survey_id);
  }

  list_surveys(
    filtro: any,
    param: any,
    orden: number,
    direccion: number,
    page: number
  ) {
    return this.http.get(
      environment.Api_admin_list_surveys +
        '/' +
        filtro +
        '/' +
        param +
        '/' +
        orden +
        '/' +
        direccion +
        '/' +
        page
    );
  }

  list_active_surgeons(commercialID, paginationID) {
    return this.http.get(
      environment.Api_get_all_commercial_active_list +
        commercialID +
        '/' +
        paginationID
    );
  }

  create_survey_answer(question_id: number, answer: any) {
    console.log(answer);
    return this.http.post(environment.Api_create_answer, {
      question_id: question_id,
      answers: [answer],
    });
  }

  edit_survey_answer(answer_id: number, answer: string) {
    console.log(answer);
    return this.http.post(environment.Api_update_survey_answer, {
      answer: { id: answer_id, answer: answer },
    });
  }

  edit_survey_question(question_id: number, question: string) {
    return this.http.post(environment.Api_update_survey_question, {
      question: { id: question_id, question: question },
    });
  }

  edit_survey_title(survey_id: number, title: string) {
    return this.http.post(environment.Api_update_survey_title, {
      title: { id: survey_id, title: title },
    });
  }

  edit_survey_description(survey_id: number, descr: string) {
    return this.http.post(environment.Api_update_survey_description, {
      survey_id: survey_id,
      description: descr,
    });
  }

  delete_survey_answer(answer_id: number) {
    return this.http.post(environment.Api_delete_survey_answer, {
      answer_id: answer_id,
    });
  }

  delete_survey(survey_id: number) {
    return this.http.post(environment.Api_delete_survey, {
      survey_id: survey_id,
    });
  }

  delete_question(question_id: number) {
    return this.http.post(environment.Api_delete_question, {
      question_id: question_id,
    });
  }

  toggleActivateSurvey(id_survey, status) {
    return this.http.post(environment.Api_toggle_activate_survey, {
      survey_id: id_survey,
      active: status,
    });
  }

  activar_referral_check_7(enable) {
    return this.http.post(environment.Api_activar_referral_check_7, {
      enable: enable,
    });
  }

  activar_monthly_inactive_check(enable) {
    return this.http.post(environment.API_MARCAR_USUARIOS_INACTIVOs_STATUS, {
      enable: enable,
    });
  }

  activar_unused_code_30(enable) {
    return this.http.post(environment.Api_activar_unused_code_30, {
      enable: enable,
    });
  }

  activar_reward_available_check(enable) {
    return this.http.post(environment.Api_activar_reward_available_check, {
      enable: enable,
    });
  }

  get_notification_status() {
    return this.http.get(environment.Api_get_notification_status);
  }

  add_city(id, descripcion) {
    return this.http.post(environment.Api_add_city, {
      city_id: id,
      description: descripcion,
    });
  }

  delete_city(id) {
    return this.http.post(environment.Api_delete_city, { city_id: id });
  }

  get_admin_list(filter, parameter, order, direction, page_number) {
    return this.http.get(
      environment.Api_get_admin_list +
        filter +
        '/' +
        parameter +
        '/' +
        order +
        '/' +
        direction +
        '/none/none/' +
        page_number
    );
  }

  resend_credentials(id) {
    return this.http.post(environment.Api_admin_resend_credentials, {
      user_id: id,
    });
  }

  view_medic_redemptions(id, page) {
    return this.http.get(
      environment.Api_view_medic_redemptions + id + '/' + page
    );
  }

  get_logs(order, direction, user_id, action_id, page) {
    return this.http.get(
      environment.Api_get_logs +
        order +
        '/' +
        direction +
        '/' +
        user_id +
        '/' +
        action_id +
        '/' +
        page +
        '/'
    );
  }

  city_pool_list() {
    return this.http.get(environment.Api_pool_cities);
  }

  load_stats(year, city) {
    return this.http.get(environment.Api_admin_stats + year + '/' + city + '/');
  }

  get_achievement() {
    return this.http.get(environment.Api_admin_get_achievement);
  }

  edit_achievement(id, required_points) {
    return this.http.post(environment.Api_admin_edit_achievement, {
      achievement_id: id,
      required_points: required_points,
    });
  }

  medic_details(id) {
    return this.http.get(environment.Api_admin_medic_details + id + '/');
  }

  list_ciudades() {
    return this.http.get(environment.Api_all_cities);
  }

  create_admin(
    first_name: string,
    first_name2: string,
    last_name: string,
    last_name2: string,
    email: string,
    cell: string,
    dni: string,
    city_id: string
  ) {
    var role = 'admin';
    return this.http.post(environment.Api_admin_register, {
      first_name,
      last_name,
      first_name2,
      last_name2,
      email,
      cell,
      role,
      dni,
      city_id,
    });
  }

  editComercial(
    comercialID,
    firstName,
    secondName,
    lastName,
    secondLastName,
    email,
    cellphone,
    dni,
    city,
    isActive
  ) {
    var data = {
      first_name: firstName,
      first_name2: secondName,
      last_name: lastName,
      last_name2: secondLastName,
      email: email,
      cell: cellphone,
      dni: dni,
      city: city,
      active: isActive,
      country_code: '57',
    };
    return this.http.post(
      environment.API_UPDATE_COMERCIAL_DATA + comercialID + '/',
      data
    );
  }

  edit_admin(
    user_id,
    first_name: string,
    first_name2: string,
    last_name: string,
    last_name2: string,
    email: string,
    cell: string,
    dni: string,
    city: string,
    active: string
  ) {
    var data = {
      first_name: first_name,
      first_name2: first_name2,
      last_name: last_name,
      last_name2: last_name2,
      email: email,
      cell: cell,
      dni: dni,
      city: city,
      active: active,
      country_code: '57',
    };
    return this.http.post(
      environment.Api_post_user_details + user_id + '/',
      data
    );
  }

  getMetas(page, search) {
    let params = '?page=' + page + (search ? '&search=' + search : '');
    return this.http.get(environment.ListarMetas + params);
  }

  getMetaDetail(id) {
    return this.http.get(environment.MetasDetail + id + '/');
  }

  getCampaing(params: any) {
    return this.http.get(environment.campaingMefid, { params });
  }

  PutMetaDetail(
    magnitude_type,
    metric,
    operator,
    is_active,
    magnitude,
    value,
    name
  ) {
    const body = {
      magnitude_type: magnitude_type,
      metric: metric,
      operator: operator,
      is_active: is_active,
      magnitude: magnitude,
      value: value,
      name: name,
    };

    return this.http.put(environment.MetasDetail, body);
  }

  logroUpdate(
    id,
    magnitude_type,
    metric,
    operator,
    is_active,
    magnitude,
    value,
    name,
    date_start,
    date_end
  ) {
    const body = {
      magnitude_type: magnitude_type,
      metric: metric,
      operator: operator,
      is_active: is_active,
      magnitude: magnitude,
      value: value,
      name: name,
      date_start: date_start,
      date_end: date_end,
    };

    return this.http.put(environment.editMeta + `/${id}` + '/', body);
  }

  registroMetas(page, search, goals) {
    let params =
      '?page=' +
      page +
      (search ? '&search=' + search : '') +
      (goals ? '&goals=' + goals : '');
    return this.http.get(environment.registroMetas + params);
  }

  returnMessages(
    page,
    message_id__title__icontains,
    medic_id__user_id__dni__icontains,
    message_id__date_created,
    message_id__date_created__gte,
    message_id__date_created__lte
  ) {
    let params =
      '?page=' +
      page +
      (message_id__title__icontains
        ? '&message_id__title__icontains=' + message_id__title__icontains
        : '') +
      (medic_id__user_id__dni__icontains
        ? '&medic_id__user_id__dni__icontains=' +
          medic_id__user_id__dni__icontains
        : '') +
      (message_id__date_created
        ? '&message_id__date_created=' + message_id__date_created
        : '') +
      (message_id__date_created__gte
        ? '&message_id__date_created__gte=' + message_id__date_created__gte
        : '') +
      (message_id__date_created__lte
        ? '&message_id__date_created__lte=' + message_id__date_created__lte
        : '');
    return this.http.get(environment.Api_get_all_message + params);
  }

  registroAsistente(id, start, end) {
    let params =
      '/' +
      id +
      (start ? '&start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '');
    return this.http.get(environment.registroAsistentes + params);
  }

  Asistentes(id, start, end) {
    let params =
      '/' +
      id +
      (start ? '&start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '');
    return this.http.get(environment.asistentes + params);
  }

  referidoAsistente(page, start, end, search) {
    let params =
      '?page=' +
      page +
      (start ? '&start=' + encodeURIComponent(start) : '') +
      (end ? '&end=' + encodeURIComponent(end) : '') +
      (search ? '&search=' + search : '');
    return this.http.get(environment.referirAsistente + params);
  }

  crearMeta(
    magnitude_type,
    metric,
    operator,
    is_active,
    magnitude,
    value,
    name,
    date_start,
    date_end
  ) {
    const body = {
      magnitude_type: magnitude_type,
      metric: metric,
      operator: operator,
      is_active: is_active,
      magnitude: magnitude,
      value: value,
      name: name,
      date_start: date_start,
      date_end: date_end,
    };

    return this.http.post(environment.CrearMeta, body);
  }

  list_comerciales() {
    return this.http.get(environment.ListComercialAdmin);
  }

  getComercialActual(id) {
    let params = id;
    return this.http.get(environment.saberComercial + params);
  }

  UpdateComercial(id, userId) {
    let params = id;

    const url =
      `${environment.editComercialByAdmin}update/${params}/${userId}` + '/';

    const body = {
      user_id: userId,
    };

    return this.http.put(url, body);
  }
}
