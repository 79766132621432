import { MedicoService } from 'src/app/shared/servicio/medico/medico.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  /**
   * Modal para mostrar al usuario
   * que el código para referir ha sido copiado.
   */
  codeCopyModal: BsModalRef;
  /**
   * Configuración del codeCopyModal
   */
  codeCopyModalConfig = {
    animated: true,
    class: 'modal-login modal-width-terms modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  image_web: File = null;
  image_mobile: File = null;

  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',

    backdrop: true,
    ignoreBackdropClick: true,
  };

  config2 = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  configPhoto = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',

    backdrop: true,
    ignoreBackdropClick: true,
  };

  toggleButton = true;

  photo: any = [];

  constructor(
    private _http: HttpClient,
    public router: Router,
    private modalService: BsModalService,
    private _clipboardService: ClipboardService,
    private _helper: HelpersService,
    private MedicoService: MedicoService
  ) {}

  //Datos de user
  nombre: string;
  rol: any;
  points: any;
  surveys: any;
  referral_code: string = '';

  ngOnInit() {
    this.user();
    this.menu();
    this.notification();
    this.getphoto();
  }

  AbrirModalPhoto(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.configPhoto);
  }

  getphoto() {
    this._http.get(environment.getMedicPhoto).subscribe(
      (data) => {
        this.photo = data;
      },
      (error) => {
        this._helper.modalError(error.error);
      }
    );
  }

  async createPhotoMedic() {
    const formData: FormData = new FormData();
    await formData.append('image_web', this.image_web, this.image_web.name);
    await formData.append(
      'image_mobile',
      this.image_mobile,
      this.image_mobile.name
    );

    this.MedicoService.createPhtoto(formData)
      .toPromise()
      .then((data) => {
        swal({
          title:
            '<img src="assets/icons/good.png" width="80px" style="margin-top:0px;">',
          html: 'Foto creada con exito',
          confirmButtonText:
            '<p style="margin-top:0px; margin-bottom:0px; font-family: "coolvetica"; ">Acep' +
            'to</p>',
          confirmButtonColor: '#6eceee',
        });
        this.getphoto();
        this.modalRef.hide();
      })

      .catch((error) => {
        this._helper.modalError('Error al crear foto');
      });
  }

  onUploadChange(evt: any) {
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];

    if (!allowed_types.includes(evt.target.files[0].type)) {
      console.log('oppssss');
      return false;
    }
    const file = evt.target.files[0];

    if (file) {
      this.image_web = file;
    } else {
      this.image_web = null;
    }
  }

  onUploadChangeMobile(evt: any) {
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];

    if (!allowed_types.includes(evt.target.files[0].type)) {
      console.log('oppssss');
      return false;
    }
    const file = evt.target.files[0];

    if (file) {
      this.image_mobile = file;
    } else {
      this.image_mobile = null;
    }
  }

  user() {
    this._http.get(environment.Api_get_user).subscribe(
      (data) => {
        console.log('datos del dashboard');
        console.log(data);
        (this.nombre = data['user_name'] + ' ' + data['last_name']),
          (this.rol = data['role']);
        this.points = data['points'];
        this.referral_code = data['referral_code'];
      },
      (error) => {
        console.log(error);
        this._helper.modalError(error.error);
      }
    );
  }

  menu() {
    $('#menu-toggle').click(function (e) {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
  }

  logout() {
    this._http.get(environment.Api_logout).subscribe(
      (data) => {
        console.log(data);
        localStorage.removeItem('token');
        localStorage.removeItem('type');
        this.router.navigate(['/login']);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  notification() {
    var lista;
    this._http.get(environment.api_surveys).subscribe((data) => {
      // this.surveys=data.length;
      lista = data;
      this.surveys = lista.length;

      console.log(lista.length);
    });
  }

  perfil(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  pass(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, this.config2);
  }

  // funcion para obtener enlace de referido
  getLink(template: TemplateRef<any>) {
    this._clipboardService.copyFromContent(
      environment.ApiLink + '/register/' + this.referral_code
    );

    this.codeCopyModal = this.modalService.show(
      template,
      this.codeCopyModalConfig
    );
  }
}
