import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { NgForm } from '@angular/forms';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { HostListener } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',

    backdrop: true,
    ignoreBackdropClick: true,
  };
  config2 = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router,
    public _helper: HelpersService,
    private modalService: BsModalService
  ) {
    this.getScreenSize();
  }
  cont: number = 0;
  nombre: string = '';
  rol: string = '';

  //actualizar registros personales
  nombre_modal: string;
  nombre_modal2: string;
  apellido1_modal: string;
  apellido2_modal: string;
  celular_modal: string;
  email_modal: string;
  dni_modal: string;
  profesional_lisense: string;
  debug: boolean;

  //formulario actualizar contraseñas
  pass_old: string = '';
  pass1: string = '';
  pass2: string = '';

  preloader: boolean = false;
  div_error: number = 0;
  text_error: string = '';

  screenHeight: any;
  screenWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    this.user();
    this.menu();
  }

  user() {
    this._http.get(environment.Api_get_user).subscribe(
      (data) => {
        console.log(data);
        (this.nombre = data['user_name'] + ' ' + data['last_name']),
          (this.rol = data['role']);
      },
      (error) => {
        this._helper.modalError('¡' + error.error + '!');
      }
    );
  }

  perfil() {
    this._http.get(environment.Api_info_user).subscribe(
      (data) => {
        this.nombre_modal = data['user_info']['first_name'];
        this.nombre_modal2 = data['user_info']['first_name2'];
        this.apellido1_modal = data['user_info']['last_name'];
        this.apellido2_modal = data['user_info']['last_name2'];
        this.celular_modal = data['user_info']['cell'];
        this.email_modal = data['user_info']['email'];
        this.dni_modal = data['user_info']['dni'];
        this.profesional_lisense = data['medic_info']['profesional_license'];
        $('#info_user').modal('show');
        console.log(data);
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  actualizar_user(update: NgForm) {
    this.preloader = true;
    console.log(update.value);
    let first_name1 = update.value['nombre_modal'];
    let first_name2 = update.value['nombre_modal2'];
    let last_name1 = update.value['apellido1_modal'];
    let last_name2 = update.value['apellido2_modal'];
    let cell = update.value['celular_modal'];
    let email = update.value['email_modal'];
    let dni = update.value['dni_modal'];
    let profesional_license = this.profesional_lisense;
    this._http
      .post(environment.Api_info_user, {
        first_name1,
        first_name2,
        last_name1,
        last_name2,
        cell,
        email,
        dni,
        profesional_license,
      })
      .subscribe(
        (data) => {
          this.preloader = false;
          this.div_error = 0;
          this.text_error = '';
          $('#info_user').modal('hide');
          console.log(data);
        },
        (error) => {
          this.preloader = false;
          this.div_error = 1;
          this.text_error = this._helper.error('', error);
        }
      );
  }

  validator(value) {
    var array = [];
    switch (value) {
      case 1:
        array.push(
          {
            tipo: 'text',
            nombre: 'NOMBRE',
            valor: this.nombre_modal,
            requerido: 'si',
            min_length: 2,
          },
          {
            tipo: 'text',
            nombre: 'APELLIDO',
            valor: this.apellido1_modal,
            requerido: 'si',
            min_length: 2,
          },
          {
            tipo: 'text',
            nombre: 'APELLIDO 2',
            valor: this.apellido2_modal,
            requerido: 'no',
            min_length: 2,
          },
          {
            tipo: 'phone',
            nombre: 'CELULAR',
            valor: this.celular_modal,
            requerido: 'si',
            min_length: 10,
          },
          {
            tipo: 'email',
            nombre: 'CORREO',
            valor: this.email_modal,
            requerido: 'si',
            min_length: 6,
          },
          {
            tipo: 'number',
            nombre: 'DNI',
            valor: this.dni_modal,
            requerido: 'si',
            min_length: 6,
          },
          {
            tipo: 'alpha',
            nombre: 'LICENCIA',
            valor: this.profesional_lisense,
            requerido: 'si',
            min_length: 3,
          }
        );

        break;

      case 2:
        array.push(
          {
            tipo: 'alpha',
            nombre: 'CONTRASEÑA ANTIGUA',
            valor: this.pass_old,
            requerido: 'si',
            min_length: 2,
          },
          {
            tipo: 'alpha',
            nombre: 'CONTRASEÑA 1',
            valor: this.pass1,
            requerido: 'si',
            min_length: 2,
          },
          {
            tipo: 'alpha',
            nombre: 'CONTRASEÑA 2',
            valor: this.pass2,
            requerido: 'si',
            min_length: 2,
          }
        );

        break;
    }
    return this._helper.validators(array);
  }

  update_password(value, up: NgForm = null) {
    switch (value) {
      case 1:
        $('#pass_change').modal('show');
        break;
      case 2:
        this.preloader = true;
        console.log(up.value);
        let password = up.value['pass_old'];
        let password1 = up.value['pass1'];
        let password2 = up.value['pass2'];
        if (password1 === password2) {
          this._http
            .post(environment.Api_update_password, {
              password,
              password1,
              password2,
            })
            .subscribe(
              (data) => {
                this.preloader = false;
                this.div_error = 0;
                this.text_error = '';
                this.pass_old = '';
                this.pass1 = '';
                this.pass2 = '';
                this._helper.modalSuccess('¡Contraseña actualizada!');
                $('#pass_change').modal('hide');
              },
              (error) => {
                this.preloader = false;
                this.div_error = 1;
                this.text_error = this._helper.error('', error);
                this._helper.modalError('¡' + error.error + '!');
              }
            );
        } else {
          this.preloader = false;
          this.div_error = 1;
          this.text_error = 'Las contraseñas no coinciden';
        }
        break;
    }
  }

  // Metodo para  controlar las exepciones cuando falla las conexiones con el
  // servidor
  manejador_errores(error) {
    if (this.debug === true) {
      console.log(error);
    } else {
      console.log('Debug no habilitado');
    }
    if (error.status === 400) {
      this._helper.modalError('¡' + error.error + '!');
    } else if (error.statusText === 'Unknown Error') {
      this._helper.modalError('¡Error de conexión con el servidor!');
    } else if (error.status === 500) {
      this._helper.modalError('¡Error del servidor!');
    } else if (error.status === 403) {
      this._helper.modalError('¡Página no encontrada!');
      this.router.navigate(['/login']);
    }
  }

  debug_db() {
    this._ip.debug().subscribe(
      (data) => {
        this.debug = data['state'];
        this.vista_console(data, 'valor del debug');
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  vista_console(data, string: string) {
    if (this.debug === true) {
      console.log(string, data);
    } else {
      console.log('Debug no habilitado');
    }
  }

  menu() {
    $('#menu-toggle').click(function (e) {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
  }

  perfil2(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  pass(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, this.config2);
  }

  logout() {
    this._http.get(environment.Api_logout).subscribe(
      (data) => {
        localStorage.removeItem('token');
        localStorage.removeItem('type');
        this.router.navigate(['/']);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
