import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ElementRef,
  OnDestroy
} from '@angular/core';
/**
 * Chart.js
 * Gráficas
 */
import { Chart } from 'chart.js';
// Configuración de colores de los charts
Chart.defaults.global.defaultFontColor = '#452c80';
Chart.defaults.global.defaultFontFamily = 'TTCommonsBold';
// Configuración base de los chart
const baseConfig: Chart.ChartConfiguration = {
  type: 'bar',
  options: {
    responsive: true,
    maintainAspectRatio: false,
    // responsiveAnimationDuration: 500,
    legend: {
      // Para no mostrar la leyenda arriba, el cual oculta las barras
      display: false
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            color: 'rgb(208, 202, 223)'
          },
          ticks: {
            // suggestedMin: 0, // minimum will be 0, unless there is a lower value.
            // OR //
            beginAtZero: true // minimum value will be 0.
            // stepSize: 1
          }
        }
      ]
    }
  }
};

@Component({
  selector: 'app-encuesta-chart',
  templateUrl: './encuesta-chart.component.html',
  styleUrls: ['./encuesta-chart.component.css']
})
export class EncuestaChartComponent
  implements OnInit, AfterViewInit, OnDestroy {
  showLoading: boolean;
  /**
   * Referencia del ID del HTML a variable
   */
  @ViewChildren('pr_chart', { read: ElementRef }) chartElementRefs: QueryList<
    ElementRef
  >;

  charts: Chart[] = [];
  chartData = [];
  /**
   * Datos que vienen del componente padre
   * Array de votos
   * Array de títulos de las preguntas
   * Array de respuestas
   */
  @Input() questionsVotes;
  @Input() questionTitles;
  @Input() questionAnswers;

  constructor() {}

  ngOnInit() {
    this.generateChartsByQuestion();
  }

  /**
   * Un ciclo de vida que se ejecuta cuando se ha completado la carga del componente
   */
  ngAfterViewInit() {
    /**
     * MULTIPLE MULTI CHARTS
     * Muy después de una búsqueda bien fuerte fue que di con este stackblitz
     * que me ayudó a realizar esto
     * https://stackblitz.com/edit/angular-chartjs-multiple-charts-olq61n
     */
    this.charts = this.chartElementRefs.map((chartElementRef, index) => {
      const config = Object.assign({}, baseConfig, {
        data: this.chartData[index]
      });
      // Retorna el chart con el elemento nativo y la configuración base
      return new Chart(chartElementRef.nativeElement, config);
    });
  }

  /**
   * Ciclo de vida que se ejecuta al cerrar el componente
   * Se itera el array de charts y se destruye cada uno para no generar problemas de canvas
   */
  ngOnDestroy() {
    this.charts.forEach(chart => {
      chart.destroy();
      console.log('Destruido el chart');
    });
  }
  /**
   * Genera un chart por cada array de votos de respuestas perteneciente a cada pregunta
   * Se itera el array de arrays de votos por respuesta
   *
   * Por ejemplo, dentro del array que llega del componente padre [[votosA], [votosB]], en la posición 0 estará:
   *
   * [1, 0, 1]
   *  A  B  C
   *
   * El array de números es el dato que necesita el chartjs para mostrarlos en la gráfica
   * Se itera y se pushea en el array chartData, que contendrá el array de las propiedades que necesita
   * chartjs para mostrar los datos.
   */
  generateChartsByQuestion() {
    this.questionsVotes.forEach((arrVotesOfEachQuestion, i) => {
      // Se pushea en chartData y luego en el ciclo de vida ngAfterViewInit se generan
      this.chartData.push({
        // Propiedades por cada chart
        labels: this.labelsLength(arrVotesOfEachQuestion),
        datasets: [
          {
            data: arrVotesOfEachQuestion,
            backgroundColor: '#61c4ff',
            borderColor: 'white',
            borderWidth: 1,
            showLine: false
          }
        ]
      });
    });
  }
  /**
   * Dependiendo del número de respuestas, será el número de letras en orden
   * alfabético que se generarán.
   */
  labelsLength(arrVotesOfEachQuestion) {
    const options = [];
    for (var j = 0; j < arrVotesOfEachQuestion.length; j++) {
      options.push(String.fromCharCode(65 + j));
    }
    return options;
  }
  /**
   * Dependiendo del indice, será la letra que retornará
   * @param index Índice del array
   */
  getLetterByOption(index) {
    return String.fromCharCode(65 + index);
  }
}
