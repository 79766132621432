import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray
} from '@angular/forms';
import { AdminService } from '../../services/admin/admin.service';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { Router } from '@angular/router';
import { CreateQuestionsComponent } from '../create-questions/create-questions.component';
@Component({
  selector: 'app-create-encuesta',
  templateUrl: './create-encuesta.component.html',
  styleUrls: ['./create-encuesta.component.css']
})
export class CreateEncuestaComponent implements OnInit {
  // parametros del modal de ngx-bootstrap
  modalRef: BsModalRef;
  config = {
    class: 'modal-dialog-centered modal-app',
    backdrop: true,
    ignoreBackdropClick: true
  };

  // parametros del modal de especialidades de ngx-bootstrap
  modalRef2: BsModalRef;
  config2 = {
    class: 'modal-dialog-centered modal-app',
    backdrop: true,
    ignoreBackdropClick: false
  };

  // parametros del modal de especialidades de ngx-bootstrap
  modalRef3: BsModalRef;
  config3 = {
    class: 'modal-dialog-centered modal-app',
    backdrop: true,
    ignoreBackdropClick: true
  };

  //formulario para agregar
  title: FormControl;
  select: FormControl;
  search_specilties_text: string;

  //formulario crear preguntas y respuestas
  name_surveys: string;
  question: FormControl;
  answer: FormControl;
  id_survey: any;
  encuesta_api: any;

  // preloaders de carga
  preloader: boolean = false;
  div_error: number = 0;
  div_text_error: string = '';

  //formulario encuesta
  formsurveysAdd: FormGroup;

  //mostrar errores de validación
  submitted: boolean;

  //datos de la api, especialidades
  specialties: any;
  search_specialties: Array<any> = [];
  select_specialties: Array<any> = [];
  error_selected: string;
  questionIds: Array<string>;
  answersArray: Object;

  preguntasFormControls;
  respuestasFormControls;

  @Input() modalReference: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public admin: AdminService,
    public _helper: HelpersService
  ) {}

  ngOnInit() {
    console.log('into init');

    this.submitted = null;
    this.id_survey = 0;

    this.specialties_list();
    this.answersArray = {};
    this.questionIds = [];
    this.question = new FormControl('', Validators.required);
    this.formSurveysAdd();
  }

  hideCrear() {
    console.log(this.modalReference);
    console.log('closing');
    this.modalReference.hide();
  }

  formSurveysAdd() {
    this.formsurveysAdd = this.formBuilder.group({
      title: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-Z ]+')
        ]
      ],

      select: [0, [Validators.required, Validators.min(1)]]
    });

    this.submitted = false;
  }

  get shortSurveysForm() {
    return this.formsurveysAdd.controls;
  }

  surveysAdd(template: TemplateRef<any>) {
    this.preloader = true;
    if (this.formsurveysAdd.valid) {
      console.log('Seguro para realizar accion');
      let boolean: boolean;
      if (this.shortSurveysForm.select.value === 1) {
        boolean = true;
      } else {
        boolean = false;
      }
      this.admin
        .create_survey(
          this.shortSurveysForm.title.value,
          boolean,
          this.select_specialties
        )
        .subscribe(
          data => {
            console.log(data);
            this.preloader = false;
            this.name_surveys = this.shortSurveysForm.title.value;
            this.id_survey = data['survey_id'];
            this.formSurveysAdd();
            this.div_error = 0;
            //this.modalRef.hide();
            this.hideCrear();
            this.modalRef3 = this.modalService.show(template, this.config3);
            this.questionIds.push('temp');
            this.answersArray['temp'] = [
              new FormControl('', Validators.required),
              new FormControl('', Validators.required)
            ];
          },
          error => {
            this.preloader = false;
            this.div_error = 1;
            this.div_text_error = this._helper.error('', error);
            this._helper.modalAlert('¡Ocurrió un error!');
          }
        );
    } else if (this.formsurveysAdd.invalid) {
      this.preloader = false;
      this.submitted = true;
    }
  }

  typeSelect(template: TemplateRef<any>, data: number) {
    let value = this.shortSurveysForm.select.value;
    console.log(typeof data);

    if (value === 2 || data === 2) {
      this.search_specilties_text = '';
      this.search_specialties = [];
      this.modalRef2 = this.modalService.show(template, this.config2);
    } else if (value === 1) {
      this.select_specialties = [];
    }
  }

  specialties_list() {
    this.admin.specialties_list().subscribe(
      data => {
        this.specialties = data;
        console.log(this.specialties);
      },
      error => {
        console.log(error);
      }
    );
  }

  specialties_array() {
    this.search_specialties = [];
    var array = this.specialties;
    var pal = this.search_specilties_text;
    var index;
    for (index in array) {
      var item = array[index];
      var name = item.name;
      var id = item.id;
      if (pal.length != 0 && name.length != 0) {
        if (name.toLowerCase().search(pal.toLowerCase()) != -1) {
          this.search_specialties.push({
            name: name,
            id: id
          });
        }
      }
    }
  }

  selected_specialties(id: number, name: string) {
    if (this.repeat_selected(this.select_specialties, id) === false) {
      this.select_specialties.push({
        name: name,
        id: id
      });
      this.error_selected = '';
    } else {
      this.error_selected = 'La especialidad ya está seleccionada.';
    }
  }

  repeat_selected(array: any, id: number) {
    var sw = false;
    for (var _i = 0; _i < array.length; _i++) {
      if (array[_i]['id'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  delete_selected(array: any, id: number) {
    array.splice(id, 1);
  }

  /****FUNCIONES AGREGAR  PREGUNTAS A UNA ENCUESTA*/

  sendAnswer() {
    this.preloader = true;
    if (this.submitted && this.validate('temp') && this.question.valid) {
      let arr = this.fixArray(this.answersArray['temp'], this.question.value);

      this.admin.create_question(this.id_survey, arr).subscribe(
        data => {
          console.log(data);
          this.preloader = false;
          this.submitted = false;
          this.div_error = 0;
          this.div_text_error = '';

          this.question.reset();

          this.admin.list_idSurvey(this.id_survey).subscribe(
            data => {
              console.log(data);
              this.encuesta_api = data['body'];
              this.updateQuestions(data);
            },
            error => {
              console.log(error);
            }
          );
        },
        error => {
          console.log(error);
          this.preloader = false;
          this.div_error = 1;
          this.div_text_error = this._helper.error('', error);
        }
      );
    } else {
      this.preloader = false;
      this.submitted = true;
    }
  }

  fixArray(array: any, question: string) {
    let fix: Array<any> = [];
    let fixFinal: Array<any> = [];
    for (var _i = 0; _i < array.length; _i++) {
      fix.push(array[_i]['value']);
    }

    fixFinal.push({
      question: question,
      answers: fix
    });
    return fixFinal;
  }

  updateQuestions(data) {
    this.questionIds = [];
    this.answersArray = {};
    data.body.forEach(element => {
      var id = element.question.id;
      this.questionIds.push(id);

      this.answersArray[id] = [];
      element.answers.forEach(element => {
        this.answersArray[id].push(element);
      });
    });
    this.answersArray['temp'] = [
      new FormControl('', Validators.required),
      new FormControl('', Validators.required)
    ];
    this.questionIds.push('temp');
  }

  validate(questionId) {
    var valid = true;
    this.answersArray[questionId].forEach(element => {
      if (element.invalid) valid = false;
    });
    return valid;
  }
}
