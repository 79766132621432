import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  /**
   * Modal para mostrar al usuario
   * que el código para referir ha sido copiado.
   */
  codeCopyModal: BsModalRef;
  /**
   * Configuración del codeCopyModal
   */
  codeCopyModalConfig = {
    animated: true,
    class: 'modal-login modal-width-terms modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',

    backdrop: true,
    ignoreBackdropClick: true,
  };

  config2 = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  toggleButton = true;

  constructor(
    private _http: HttpClient,
    public router: Router,
    private modalService: BsModalService,
    private _clipboardService: ClipboardService,
    private _helper: HelpersService
  ) {}

  //Datos de user
  nombre: string;
  rol: any;
  points: any;
  surveys: any;
  referral_code: string = '';

  ngOnInit() {
    this.user();
    //  this.menu();
    this.notification();
  }

  user() {
    this._http.get(environment.Api_get_user).subscribe(
      (data) => {
        console.log('datos del dashboard');
        console.log(data);
        (this.nombre = data['user_name'] + ' ' + data['last_name']),
          (this.rol = data['role']);
        this.points = data['points'];
        this.referral_code = data['referral_code'];
      },
      (error) => {
        console.log(error);
        this._helper.modalError(error.error);
      }
    );
  }

  //  menu() {
  //    $('#menu-toggle').click(function (e) {
  //      e.preventDefault();
  //      $('#wrapper').toggleClass('toggled');
  //    });
  //  }

  logout() {
    this._http.get(environment.Api_logout).subscribe(
      (data) => {
        console.log(data);
        localStorage.removeItem('token');
        localStorage.removeItem('type');
        this.router.navigate(['/login']);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  notification() {
    var lista;
    this._http.get(environment.api_surveys).subscribe((data) => {
      // this.surveys=data.length;
      lista = data;
      this.surveys = lista.length;

      console.log(lista.length);
    });
  }

  perfil(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  pass(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, this.config2);
  }

  // funcion para obtener enlace de referido
  getLink(template: TemplateRef<any>) {
    this._clipboardService.copyFromContent(
      environment.ApiLink + '/register/' + this.referral_code
    );

    this.codeCopyModal = this.modalService.show(
      template,
      this.codeCopyModalConfig
    );
  }
}
