import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { IpService } from '../../../../shared/servicio/ip/ip.service';
import { HelpersService } from '../../../../shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { formatDate } from 'ngx-bootstrap';
import { Location } from '@angular/common';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css'],
})
export class ReferralsComponent implements OnInit {
  //datos de la paginación
  paginate_change: any;
  totalpages: number;
  itemsPagina: any;

  //Id del medico
  id: any;

  //Obtiene la lista de referidos de la api
  referedList: any;

  //ordenar por atributo en ascendente o descendente
  order: number = 0;
  direction: number = 0;

  //Filtra por los estados de los medicos
  state: number = 2;

  //Lista de datos
  dataLista: boolean = true;

  /**
   * String que contiene el valor del input de búsqueda
   */
  search: string = 'none';

  /**
   * Selector de criterio (filtro)
   */
  criterioSelectorFC: FormControl;

  /**
   * Input de fecha inicial (fecha de activación de cuenta)
   */
  startDateFC: FormControl;

  /**
   * Input de fecha de finalización (fecha de activación de cuenta)
   */
  endDateFC: FormControl;

  /**
   * Input de búsqueda
   */
  searchTextFC: FormControl;
  /**
   * Booleano que indica si está activado el filtro por usuarios activos
   */
  isActiveFilterON;

  /**
   * Booleano que indica si está activado el filtro por usuarios inactivos
   */
  isInactiveFilterON;

  /**
   * Booleano que indica si está activado el ordenamiento por puntos
   */
  isPointFilterActive;

  /**
   * Booleano que indica si está activado el orden ascendente de datos
   */
  isAscendingActive;

  /**
   * Booleano que indica si está activado el orden descendente de datos
   */
  isDescendingActive;

  /**
   * Filtros activos de la ruta anterior
   */
  pastFilters;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private _http: HttpClient,
    public _ip: IpService,
    public _helper: HelpersService
  ) {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.dataLista = false;

    this.referedList = [];

    this.isActiveFilterON = false;
    this.isInactiveFilterON = false;
    this.isAscendingActive = true;
    this.isDescendingActive = false;
    this.isPointFilterActive = false;

    this.startDateFC = new FormControl('');
    this.endDateFC = new FormControl('');
    this.searchTextFC = new FormControl('');
    this.criterioSelectorFC = new FormControl('none');
    this.criterioSelectorFC.valueChanges.subscribe((value) => {
      this.list_referals(this.paginate_change);
    });
    this.searchTextFC.valueChanges.subscribe((value) => {
      this.search_filter(value);
    });
    this.startDateFC.valueChanges.subscribe((onDateChange) => {
      this.list_referals(this.paginate_change);
    });
    this.endDateFC.valueChanges.subscribe((onDateChange) => {
      this.list_referals(this.paginate_change);
    });
    this.list_referals(1);
    this.pastFilters = history.state;
  }

  getActiveFiltersCount() {
    return Object.keys(this.pastFilters).length;
  }

  goBack() {
    if (this.getActiveFiltersCount() > 1) {
      this.router.navigate(['/home/comercial/tracing'], {
        queryParams: this.pastFilters,
      });
    } else {
      this.router.navigate(['/home/comercial/tracing']);
    }
  }

  /**
   * Valida los valores de los input de fecha de inicio y fin para el filtro de lista
   */
  validateDate() {
    var startDate, endDate;
    if (this.startDateFC.value == undefined || this.startDateFC.value == '') {
      startDate = 'none';
    } else {
      startDate = this.getFormatedDate(this.startDateFC.value);
    }

    if (this.endDateFC.value == undefined || this.endDateFC.value == '') {
      endDate = 'none';
    } else {
      endDate = this.getFormatedDate(this.endDateFC.value);
    }
    return [startDate, endDate];
  }

  /**
   * Función que intercepta el evento de elección de fecha del NGX DatePicker
   * @param calendarView Instancia de calendario que disparó el evento
   * @param isStart Booleano que indica si al instancia originaria fue la fecha de inicio o la de fin
   */
  opencalendar(calendarView, isStart) {
    if (isStart) {
      this.startDateFC.setValue('');
    } else {
      this.endDateFC.setValue('');
    }
    calendarView.yearSelectHandler = (event: any): void => {
      calendarView._store.dispatch(calendarView._actions.select(event.date));
    };
    calendarView.setViewMode('day');
  }

  /**
   * Función que toma una fecha completa y en Y-M-D:H-M-S y la transforma a Y-M-D
   * @param joinDate Fecha
   */
  getFormatedDate(joinDate) {
    return formatDate(joinDate, 'YYYY-MM-DD', 'es');
  }

  //Lista de los medicos referidos
  list_referals(page) {
    var [startDate, endDate] = this.validateDate();
    this._http
      .get(
        environment.Api_get_comercial_referral +
          this.id +
          '/' +
          this.criterioSelectorFC.value +
          '/' +
          this.search +
          '/' +
          this.state +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          startDate +
          '/' +
          endDate +
          '/' +
          page
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.referedList = data['info'];
          this.totalpages = data['page_info']['count'];
          this.itemsPagina = data['page_info']['total_items'];
          this.paginate_change = data['page_info']['current'];
          this.dataLista = true;
        },
        (error) => {
          this.dataLista = true;

          this._helper.modalError('¡Error de conexión!');
        }
      );
  }

  //Paginacion de los datos obtenidos por la api.
  pagination(page2) {
    this.dataLista = false;
    var [startDate, endDate] = this.validateDate();
    this._http
      .get(
        environment.Api_get_comercial_referral +
          this.id +
          '/' +
          this.criterioSelectorFC.value +
          '/' +
          this.search +
          '/' +
          this.state +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          startDate +
          '/' +
          endDate +
          '/' +
          page2
      )
      .subscribe(
        (data) => {
          this.list_referals(page2);
        },
        (error) => {
          this.dataLista = true;

          this._helper.modalError('¡Error de conexión!');
        }
      );
  }

  //Actualizar la lista de medicos para indicar parametros de busqueda
  //filtrar, buscar por estados y ordenamiento ascendente y descendente
  search_filter(name) {
    if (name != '') {
      this.search = name;
    } else {
      this.search = 'none';
    }
    this.list_referals(this.paginate_change);
  }

  //ordenar la lista acepta dos parametros su orden y parametro a ordenar.
  ordenamiento(valor: number, orden: number) {
    this.order = valor;
    this.direction = orden;
    this.list_referals(this.paginate_change);
  }

  actualizar_lista(event) {
    if (event === '1') {
      this.list_referals(this.paginate_change);
    }
  }

  /**
   * Cambia el estado del filtro por usuarios activos
   */
  onActiveToggle() {
    this.isActiveFilterON = !this.isActiveFilterON;
    this.updateActiveSearchValue(
      this.isActiveFilterON,
      this.isInactiveFilterON
    );
  }

  /**
   * Cambia el estado del filtro por usuarios inactivos
   */
  onInactiveToggle() {
    this.isInactiveFilterON = !this.isInactiveFilterON;
    this.updateActiveSearchValue(
      this.isActiveFilterON,
      this.isInactiveFilterON
    );
  }

  /**
   * Actualiza el valor de la búsqueda dependiendo del filtro activo. Si ambos están activos o desactivados,
   * se obtendrán todos los usuarios. En caso de que alguno de los 2 esté activo, solo se obtendrán los usuarios
   * correspondientes al filtro que se encuentre activado.
   * @param isActive estado del filtro de usuarios activos
   * @param isInactive estado del filtro de usuarios inactivos
   */
  updateActiveSearchValue(isActive, isInactive) {
    this.dataLista = false;
    if ((isActive && isInactive) || (!isActive && !isInactive)) {
      this.state = 2;
    } else if (isActive) {
      this.state = 1;
    } else if (isInactive) {
      this.state = 0;
    }
    this.list_referals(this.paginate_change);
  }

  /**
   * Controla el ordenamiento por puntos del referido
   */
  onPointStateChange() {
    this.dataLista = false;
    this.isPointFilterActive = !this.isPointFilterActive;
    if (this.isPointFilterActive) {
      this.order = 5;
    } else {
      this.order = 0;
    }
    this.list_referals(this.paginate_change);
  }

  /**
   * Cambia el estado de orden de los resultados de la búsqueda a ascendente o descendente.
   */
  onOrderChange() {
    this.isAscendingActive = !this.isAscendingActive;
    this.isDescendingActive = !this.isDescendingActive;
    this.direction = this.isDescendingActive * 1;
    this.list_referals(this.paginate_change);
  }
}
