import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appShowPassword]'
})
export class ShowPasswordDirective {
  private _shown = false;

  constructor(private el: ElementRef) {
    this.setup();
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = 'OCULTAR';
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = 'MOSTRAR';
    }
  }

  setup() {
    const parent = this.el.nativeElement.parentNode;

    const span = document.createElement('span');
    span.innerHTML = `MOSTRAR`;
    // span.setAttribute('href', 'button');
    span.className = `text-mefid-blue1 tt-commons-regular form-control-mefid-end
      form-control-mefid-password-h cursor pr-1 pr-sm-3 d-flex align-items-center`;

    span.addEventListener('click', event => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }
}
