import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from '../../shared/servicio/ip/ip.service';
declare var $: any;
@Component({
  selector: 'app-menu-horizontal',
  templateUrl: './menu-horizontal.component.html',
  styleUrls: ['./menu-horizontal.component.css']
})
export class MenuHorizontalComponent implements OnInit {

constructor(public _ip : IpService, private _http : HttpClient, public router : Router) {}

ngOnInit() {
    this.menu();
}

// funcion javascript para el toogle del movil
menu() {

    $(document).ready(function () {
        $('#sidebarCollapse').on('click', function () {
            $('#sidebar').toggleClass('active');
        });
    });

}


}