import { Component, OnInit } from '@angular/core';
import { MedicoService } from '../../shared/servicio/medico/medico.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mispuntos',
  templateUrl: './mispuntos.component.html',
  styleUrls: ['./mispuntos.component.css']
})
export class MispuntosComponent implements OnInit {
  page: any;
  p: any;
  totalpages: number;
  itemsPagina: any;
  points: any;

  //
  points1: any;
  redeemed_points: any;
  points_to_expire: any;
  expired_points: any;
  expiration_date: any;

  showLoading: boolean;
  constructor(public medico: MedicoService, private _http: HttpClient) {
    this.page = 1;
  }

  ngOnInit() {
    this.showLoading = true;

    this.puntosMedicos(this.page);
    this.historyPoints();
  }

  isOdd(number: any) {
    if (number % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Función que chequea si el array es par o impar
   * De ser par el array, chequea que i sea menor al array - 2
   * De ser impar, chequea que i sea menor al array - 1
   * Todo esto es para agregar o no agregar un borde abajo de cada cupón
   * Clase css prize-body:before
   * @param array lenght del array
   * @param number i durante el for
   */
  isParImpar(array, number) {
    // Si el array es par
    if (array % 2 === 0) {
      if (number < array - 2) {
        return true;
      } else {
        return false;
      }
    }
    // Si el array es impar
    else {
      if (number < array - 1) {
        return true;
      } else {
        return false;
      }
    }
  }

  puntosMedicos(p2) {
    this._http.get(environment.api_mypoints + p2).subscribe(data => {
      console.log(data);
      this.points = data['info'];
      this.totalpages = data['page_info']['count'];
      this.itemsPagina = data['page_info']['total_items'];
      this.p = data['page_info']['current'];
    });
  }
  historyPoints() {
    this._http.get(environment.api_statement_point).subscribe(data => {
      this.points1 = data['points'];
      this.redeemed_points = data['redeemed_points'];
      this.points_to_expire = data['points_to_expire'];
      this.expired_points = data['expired_points'];
      this.expiration_date = data['expiration_date'];

      console.log(data);
      this.showLoading = false;
    });
  }

  getPage(p: number) {
    this._http.get(environment.api_mypoints + p).subscribe(
      data => {
        this.puntosMedicos(p);
      },
      error => {}
    );
  }

  /**
   * Función que determina si un cupón ha sido utilizado
   * @param couponState Estado del cupón
   * @returns retorna true si el cupón ya ha sido utilizado. Falso si no fue así.
   */
  wasCouponRedeemed(couponState) {
    switch (couponState) {
      case 'created':
        return false;
      case 'sent':
        return false;
      case 'accepted':
        return true;
    }
  }
}
