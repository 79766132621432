import { AsistenteServicesService } from './../../services/asistente-services.service';
import {
  Component,
  OnInit,
  TemplateRef,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelpersService } from 'src/app/shared/servicio/helpers/helpers.service';
import swal from 'sweetalert2';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-referra-list',
  templateUrl: './referra-list.component.html',
  styleUrls: ['./referra-list.component.css'],
})
export class ReferraListComponent implements OnInit {
  constructor(
    private AsistenteServicesService: AsistenteServicesService,
    private modalService: BsModalService,
    private _http: HttpClient,
    public _helper: HelpersService,
    public router: Router
  ) { }

  asistentes: any = [];
  @Output() reloadFormula = new EventEmitter();
  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>; // Obtén una referencia al template del modal


  config = {
    animated: true,
    class: 'modal-dialog-centered modal-sm', // Ajusta la clase para el tamaño y centrado del modal
    backdrop: true,
    ignoreBackdropClick: true,
  };

  modalRef: BsModalRef;
  cc_type = '';
  first_name;
  last_name;
  cc;
  country_code = '';
  cell;
  especialidad = '';
  codeUnique: string = ''
  showTooltip: boolean = false;



  configMetas = {
    animated: true,
    class: 'modal-xl modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  specialties: any;
  urlGenerada: string = ''; // Variable para almacenar la URL generada
  tiempo_expire


  selectSpecialties: any = null;

  showLoading: boolean = true;
  page = 1;
  limit = 1;
  count = 0;

  ngOnInit() {
    this.showLoading = true;

    this.referall();
    this.specialty_medic();
  }
  referall() {
    this.AsistenteServicesService.referralList(this.page)
      .toPromise()
      .then((data) => {
        console.log(data);
        this.limit = Math.ceil(data['count']);
        this.count = data['count'];
        this.asistentes = data['results'];
        this.showLoading = false;
      })
      .catch((error) => console.log(error));
  }

  pageChanged(p) {
    this.page = p.page;
    this.referall();
  }

  async AbrirModalCrearAsistente(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.configMetas);
  }

  crearAsist() {
    this.AsistenteServicesService.crearAsistente(
      this.cc_type,
      this.first_name,
      this.last_name,
      this.cc,
      this.country_code,
      this.cell,
      this.selectSpecialties['specialty_id__id']
    )
      .toPromise()
      .then((data) => {
        this._helper.modalSuccess(data['message']);
        window.location.reload()
        this.modalRef.hide();
      })

      .catch((error) => {
        this._helper.modalError(error.error.error)
      });
  }

  specialty_medic() {
    this.AsistenteServicesService.especialidad_medico().subscribe((data) => {
      console.log(data);
      this.specialties = data;
    });
  }

  onSelect2(event: TypeaheadMatch): void {
    this.selectSpecialties = event.item;
  }

  generateQr(): void {
    const apiUrl = environment.api_generate_url;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {};  // Puedes añadir el payload necesario si el endpoint lo requiere

    this._http.post(apiUrl, body, { headers }).subscribe(
      (response: any) => {
        this.urlGenerada = response.url;
        this.codeUnique = response.session
        this._helper.modalSuccess('Código generado con éxito');
        this.mostrarModalQR(this.modalTemplate); // Pasa el template del modal a la función mostrarModalQR
      },
      error => {
        this._helper.modalAlert('Error al generar código');
      }
    );
  }
  async mostrarModalQR(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  copyToClipboard(code: string) {
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.showTooltip = true;
  }

  hideTooltip() {
    setTimeout(() => {
      this.showTooltip = false;
    }, 2000);
  }

}
