import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IpService } from '../../shared/servicio/ip/ip.service';
import { PacientesService } from '../../shared/servicio/pacientes/pacientes.service';
import { HelpersService } from '../../shared/servicio/helpers/helpers.service';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
declare var $: any;
@Component({
  selector: 'app-medico',
  templateUrl: './medico.component.html',
  styleUrls: ['./medico.component.css'],
})
export class MedicoComponent implements OnInit {
  isCollapsed = true;
  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>; // Obtén una referencia al template del modal
  page: any;
  p: any;
  totalpages: number;
  itemsPagina: any;
  debug: boolean;
  searchtext: string = '';
  searchtext2: string = 'none';
  criterio: string = 'none';
  pacientes_api: any;
  activate: number = 2;
  especialidades_api: any;
  especialidad: string = 'none';
  selectradio: number = 0;
  dni: string;
  telefono: string;
  dataLista = false;
  show: Number = -1;
  expanded: boolean = false;
  urlGenerada: string = ''; // Variable para almacenar la URL generada
  tiempo_expire


  //medico.component.css//orden
  order: number = 0;
  direction: number = 0;

  preloader: boolean = false;
  div_error: number = 0;
  text_error: string = '';

  config = {
    animated: true,
    class: 'modal-dialog-centered modal-sm', // Ajusta la clase para el tamaño y centrado del modal
    backdrop: true,
    ignoreBackdropClick: true,
  };

  modalRef: BsModalRef;


  constructor(
    public _pacientes: PacientesService,
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router,
    private formBuilder: FormBuilder,
    public _helper: HelpersService,
    private modalService: BsModalService,

  ) {}

  ngOnInit() {
    this.list_pacientes(this.page);
    this.debug_db();
  }

  list_pacientes(p2) {
    this.dataLista = false;
    if (p2 === undefined) {
      p2 = 1;
    } else {
      p2 - 1;
    }

    if (this.searchtext2 === '') {
      this.searchtext2 = 'none';
    }
    this._http
      .get(
        environment.Api_get_referral +
          this.criterio +
          '/' +
          this.searchtext2 +
          '/' +
          this.activate +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          p2
      )
      .subscribe(
        (data) => {
          this.dataLista = true;
          this.pacientes_api = data['info'];
          this.pacientes_api.forEach((element, index) => {
            this.pacientes_api[index]['collapse'] = false;
          });
          console.log(this.pacientes_api);
          this.totalpages = data['page_info']['count'];
          this.itemsPagina = data['page_info']['total_items'];
          this.p = data['page_info']['current'];
        },
        (error) => {
          this.dataLista = true;
          this.manejador_errores(error);
        }
      );
  }

  getPage(p: number) {
    this._http
      .get(
        environment.Api_get_referral +
          this.criterio +
          '/' +
          this.searchtext2 +
          '/' +
          this.activate +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          p
      )
      .subscribe(
        (data) => {
          this.vista_console(data, 'paginacion');
          this.dataLista = true;
          this.list_pacientes(p);
        },
        (error) => {
          this._helper.modalError(
            this._helper.splitErrorMessages(error.error, '<br>')
          );
        }
      );
  }

  search_medic(search: NgForm) {
    if (
      search.value['searchtext'] == undefined ||
      search.value['searchtext'] == ''
    ) {
      this.searchtext2 = 'none';
    }
    this.criterio = search.value['criterio'];
    this.searchtext2 = search.value['searchtext'];
    this.activate = search.value['selectradio'];
    this.list_pacientes(this.page);
  }

  especialidades_medico() {
    this._pacientes.especialidad_medico().subscribe(
      (data) => {
        this.especialidades_api = data;
        console.log(this.especialidades_api);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  valid() {
    var create_paciente = [];
    create_paciente.push(
      {
        tipo: 'number',
        nombre: 'DNI',
        valor: this.dni,
        requerido: 'si',
        min_length: 6,
      },
      {
        tipo: 'phone',
        nombre: 'TELEFONO',
        valor: this.telefono,
        requerido: 'si',
        min_length: 10,
      },
      {
        tipo: 'select',
        nombre: 'ESPECILIDAD',
        valor: this.especialidad,
        requerido: 'si',
        min_length: 1,
      }
    );

    return this._helper.validators(create_paciente);
  }

  vista_console(data, string: string) {
    if (this.debug === true) {
      console.log(string, data);
    } else {
      console.log('Debug no habilitado');
    }
  }

  // Conexion para ver el estado del debug
  debug_db() {
    this._ip.debug().subscribe(
      (data) => {
        this.debug = data['state'];
        this.vista_console(data, 'valor del debug');
      },
      (error) => {
        this.manejador_errores(error);
      }
    );
  }

  // Metodo para  controlar las exepciones cuando falla las conexiones con el
  // servidor
  manejador_errores(error) {
    if (this.debug === true) {
      console.log(error);
    } else {
      console.log('Debug no habilitado');
    }
    if (error.status === 400) {
      this._helper.modalError('¡' + error.error + '!');
    } else if (error.statusText === 'Unknown Error') {
      this._helper.modalError('¡Error de conexión con el servidor!');
    } else if (error.status === 500) {
      this._helper.modalError('¡Error en el servidor!');
    } else if (error.status === 403) {
      this._helper.modalError('¡Página no encontrada!');
      this.router.navigate(['/login']);
    }
  }

  ordenamiento(valor: number, orden: number) {
    this.order = valor;
    this.direction = orden;
    this.list_pacientes(this.page);
  }

  showPatients(id: Number, det: boolean) {
    if (det === true) {
      this.show = id;
      this.expanded = true;
    } else {
      this.show = -1;
      this.expanded = false;
    }
  }

  stateChange($event) {
    //alert($event);
    this.activate = $event;
    this.list_pacientes(this.page);
  }

  searchChange($event) {
    this.searchtext2 = $event[1]['search'];
    this.criterio = $event[0]['filter'];
    this.list_pacientes(this.page);
  }

  orderChange($event) {
    this.direction = $event[0]['order'];
    this.order = $event[1]['direction'];
    console.log(this.direction);
    this.list_pacientes(this.page);
  }

  patientCreated($event) {
    this.list_pacientes(this.page);
  }


}
