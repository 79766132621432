import { Component, OnInit, TemplateRef } from '@angular/core';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IpService } from '../../../shared/servicio/ip/ip.service';
import { SeguimientoService } from '../../../shared/servicio/seguimienti/seguimiento.service';
import { HttpClient } from '@angular/common/http';
import { MedicoService } from '../../../shared/servicio/medico/medico.service';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { formatDate } from 'ngx-bootstrap';

declare var $: any;
@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.css'],
})
export class SeguimientoComponent implements OnInit {
  constructor(
    public _seguimiento: SeguimientoService,
    public _ip: IpService,
    private _http: HttpClient,
    public router: Router,
    public _helper: HelpersService,
    public _medic: MedicoService,
    private modalService: BsModalService,
    private activeRoute: ActivatedRoute
  ) {
    this.searchLab = new FormControl('', Validators.required);
  }

  updateMedicModalRef: BsModalRef;

  updateMedicModalConfig = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  //datos de la paginación
  paginate_change: number;
  totalpages: number;
  itemsPagina: any;

  //Obtiene lista de medicos de la Api
  referral: any;

  //Filtra por los estados de los medicos
  state: number = 2;

  //Filtrar por los datos de los medicos como nombre y apellidos
  filter: string = 'none';
  search: string = 'none';
  search_text: string = '';

  //ordenar por atributo en ascendente o descendente
  order: number = 0;
  direction: number = 0;

  //Formulario de actualizacion de datos por medico
  nombre_update: string;
  nombre2_update: string;
  apellido_update: string;
  apellido2_update: string;
  celular_update: string;
  correo_update: string;
  dni_update: string;
  puntos_saludables_update: string;
  matricula_update: string;
  codigo_referido_update: string;
  nombre_referido_update: string;
  id_medico: number;

  //Valor del select de busqueda de medicos de la api
  searchLab: FormControl;

  //obtenemos el valor del estado que tiene un medico actualmente
  estado2: string = 'none';
  text_estado: number;

  //Obtiene las especialidades de un medico de la api
  especialidades_search: any;

  //Guarda las especialidades que se van agregando
  especialidades_array: Array<any> = [];

  //especialidades de un medico
  especialidades: any;

  // array de datos de especialidades de los medicos para eliminacion
  especialidades_array_delete: Array<any> = [];

  //lista para mostrar la informacion
  dataLista: boolean = true;

  //id user
  id: number;

  /**
   * Input de fecha inicial (fecha de activación de cuenta)
   */
  startDateFC: FormControl;

  /**
   * Input de fecha de finalización (fecha de activación de cuenta)
   */
  endDateFC: FormControl;

  /**
   * Input de búsqueda
   */
  searchTextFC: FormControl;

  /**
   *
   */
  criterioSelectorFC: FormControl;

  p;

  isInactiveFilterActive;
  isActiveFilterActive;
  isReferedMedicOrderActive;
  isReferedPatientOrderActive;
  isTotalPointOrderActive;
  isAscendingActive;
  isDescendingActive;

  ngOnInit() {
    this.dataLista = false;
    this.referral = [];

    this.criterioSelectorFC = new FormControl('none');
    this.searchTextFC = new FormControl('');
    this.startDateFC = new FormControl('');
    this.endDateFC = new FormControl('');

    this.isInactiveFilterActive = false;
    this.isActiveFilterActive = false;
    this.isReferedMedicOrderActive = false;
    this.isReferedPatientOrderActive = false;
    this.isTotalPointOrderActive = false;
    this.isAscendingActive = true;
    this.isDescendingActive = false;
    this.loadPreviousFilters();
  }

  /**
   * Establece los valores iniciales de los filtros, teniendo en cuenta si viene información
   * de otra vista
   */
  loadPreviousFilters() {
    this.activeRoute.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 1) {
        this.showPointFilterChange(params['sort']);
        this.showOrderChange(params['ascend']);
        this.criterioSelectorFC.setValue(params['filtro']);
        if (params['startDate'] === '') {
          this.startDateFC.setValue('');
        } else {
          this.startDateFC.setValue(new Date(params['startDate']));
        }
        if (params['endDate'] === '') {
          this.endDateFC.setValue('');
        } else {
          this.endDateFC.setValue(new Date(params['endDate']));
        }
        this.searchTextFC.setValue(params['searchText']);
        this.p = params['page'];
        console.log(params);
      } else {
        this.p = 1;
      }
      this.isSearchTextValid(this.searchTextFC.value);
      this.iniOnValueChangeListeners();
    });
  }

  /**
   * Inicializa los eventos valueChange de cada componente de form control
   */
  iniOnValueChangeListeners() {
    this.searchTextFC.valueChanges.subscribe((value) => {
      this.isSearchTextValid(value);
    });
    this.criterioSelectorFC.valueChanges.subscribe((value) => {
      this.list_seguimiento(this.p);
    });
    this.startDateFC.valueChanges.subscribe((onDateChange) => {
      this.list_seguimiento(this.p);
    });
    this.endDateFC.valueChanges.subscribe((onDateChange) => {
      this.list_seguimiento(this.p);
    });
  }

  /**
   * Activa o desactiva (Visualmente) los botones de orden ascendente o descendente según el valor de dirección.
   * @param ascendOrDescend dirección de ordenamiento
   */
  showOrderChange(ascendOrDescend) {
    switch (ascendOrDescend) {
      case 0:
        this.isDescendingActive = false;
        this.isAscendingActive = true;
        break;
      case 1:
        this.isDescendingActive = true;
        this.isAscendingActive = false;
        break;
    }
    this.direction = ascendOrDescend;
  }

  /**
   * Activa o desactiva (visualmente) los botones de filtro por puntos o referidos en la lista seguimiento
   * según su valor de orden.
   * @param order El filtro de ordenamiento seleccionado
   */
  showPointFilterChange(order) {
    switch (order) {
      case 3: //total de puntos
        this.isReferedMedicOrderActive = false;
        this.isReferedPatientOrderActive = false;
        this.isTotalPointOrderActive = true;
        break;
      case 4: //pacientes referidos
        this.isReferedMedicOrderActive = false;
        this.isReferedPatientOrderActive = true;
        this.isTotalPointOrderActive = false;
        break;
      case 5: //médicos referidos
        this.isReferedMedicOrderActive = true;
        this.isReferedPatientOrderActive = false;
        this.isTotalPointOrderActive = false;
        break;
      case 0: //ninguna
        this.isReferedMedicOrderActive = false;
        this.isReferedPatientOrderActive = false;
        this.isTotalPointOrderActive = false;
        break;
    }
    this.order = order;
  }

  /**
   * Valida los valores de los input de fecha de inicio y fin para el filtro de lista
   */
  validateDate() {
    var startDate, endDate;
    if (this.startDateFC.value == undefined || this.startDateFC.value == '') {
      startDate = 'none';
    } else {
      startDate = this.getFormatedDate(this.startDateFC.value);
    }

    if (this.endDateFC.value == undefined || this.endDateFC.value == '') {
      endDate = 'none';
    } else {
      endDate = this.getFormatedDate(this.endDateFC.value);
    }
    return [startDate, endDate];
  }

  /**
   * Función que intercepta el evento de elección de fecha del NGX DatePicker
   * @param calendarView Instancia de calendario que disparó el evento
   * @param isStart Booleano que indica si al instancia originaria fue la fecha de inicio o la de fin
   */
  opencalendar(calendarView, isStart) {
    if (isStart) {
      this.startDateFC.setValue('');
    } else {
      this.endDateFC.setValue('');
    }
    calendarView.yearSelectHandler = (event: any): void => {
      calendarView._store.dispatch(calendarView._actions.select(event.date));
    };
    calendarView.setViewMode('day');
  }

  /**
   * Función que toma una fecha completa y en Y-M-D:H-M-S y la transforma a Y-M-D
   * @param joinDate Fecha
   */
  getFormatedDate(joinDate) {
    return formatDate(joinDate, 'YYYY-MM-DD', 'es');
  }

  //Listado de pacientes referidos por medico.
  list_seguimiento(page) {
    var [startDate, endDate] = this.validateDate();
    this._http
      .get(
        environment.Api_get_monitoring_comercial +
          this.criterioSelectorFC.value +
          '/' +
          this.search +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          this.state +
          '/' +
          startDate +
          '/' +
          endDate +
          '/' +
          page
      )
      .subscribe(
        (data) => {
          this.dataLista = true;
          console.log(data);
          this.referral = data['info'];
          this.totalpages = data['page_info']['count'];
          this.itemsPagina = data['page_info']['total_items'];
          this.p = data['page_info']['current'];
        },
        (error) => {
          this.dataLista = true;
          this._helper.modalError('¡Error de conexión!');
        }
      );
  }

  //Paginacion de los datos obtenidos por la api.
  pagination(page2) {
    var [startDate, endDate] = this.validateDate();
    this._http
      .get(
        environment.Api_get_monitoring_comercial +
          this.criterioSelectorFC.value +
          '/' +
          this.search +
          '/' +
          this.order +
          '/' +
          this.direction +
          '/' +
          this.state +
          '/' +
          startDate +
          '/' +
          endDate +
          '/' +
          page2
      )
      .subscribe(
        (data) => {
          this.dataLista = true;
          this.list_seguimiento(page2);
        },
        (error) => {
          this.dataLista = true;
          this._helper.modalError('¡Error de conexión!');
        }
      );
  }

  //Actualizar la lista de medicos para indicar parametros de busqueda
  //filtrar, buscar por estados y ordenamiento ascendente y descendente
  isSearchTextValid(text) {
    if (text == undefined || text == '') {
      this.search = 'none';
    } else {
      this.search = text;
    }
    this.list_seguimiento(this.p);
  }

  //ordenar la lista acepta dos parametros su orden y parametro a ordenar.
  ordenamiento(valor: number, orden: number) {
    this.order = valor;
    this.direction = orden;
    this.list_seguimiento(this.p);
  }

  onInactiveStateChange() {
    this.isInactiveFilterActive = !this.isInactiveFilterActive;
    this.onAccountStatusFilterChange(
      this.isActiveFilterActive,
      this.isInactiveFilterActive
    );
  }

  onActiveStateChange() {
    this.isActiveFilterActive = !this.isActiveFilterActive;
    this.onAccountStatusFilterChange(
      this.isActiveFilterActive,
      this.isInactiveFilterActive
    );
  }

  onReferedMedicStateChange() {
    this.isReferedMedicOrderActive = !this.isReferedMedicOrderActive;
    this.onPointFiltersChange(this.isReferedMedicOrderActive, false, false);
  }

  onReferedPatientStateChange() {
    this.isReferedPatientOrderActive = !this.isReferedPatientOrderActive;
    this.onPointFiltersChange(false, this.isReferedPatientOrderActive, false);
  }

  onTotalPointStateChange() {
    this.isTotalPointOrderActive = !this.isTotalPointOrderActive;
    this.onPointFiltersChange(false, false, this.isTotalPointOrderActive);
  }

  onPointFiltersChange(
    didMedicFilterChange,
    didPatientFilterChange,
    didTotalFilterChange
  ) {
    if (didMedicFilterChange) {
      this.order = 5;
      this.isReferedPatientOrderActive = didPatientFilterChange;
      this.isTotalPointOrderActive = didTotalFilterChange;
    } else if (didPatientFilterChange) {
      this.order = 4;
      this.isReferedMedicOrderActive = didMedicFilterChange;
      this.isTotalPointOrderActive = didTotalFilterChange;
    } else if (didTotalFilterChange) {
      this.order = 3;
      this.isReferedMedicOrderActive = didMedicFilterChange;
      this.isReferedPatientOrderActive = didPatientFilterChange;
    } else {
      this.order = 0;
    }
    this.list_seguimiento(this.p);
  }

  onOrderChange() {
    this.isAscendingActive = !this.isAscendingActive;
    this.isDescendingActive = !this.isDescendingActive;
    this.direction = this.isDescendingActive * 1;
    this.list_seguimiento(this.p);
  }

  onAccountStatusFilterChange(onActiveON, onInactiveON) {
    if ((onActiveON && onInactiveON) || (!onActiveON && !onInactiveON)) {
      this.state = 2;
    } else if (onActiveON) {
      this.state = 1;
    } else if (onInactiveON) {
      this.state = 0;
    }
    this.list_seguimiento(this.p);
  }

  //Obtiene las especialidades dependiendo del texto del select
  updateEspecialidad(event) {
    this._medic.list_especialidad(event).subscribe(
      (data) => {
        this.especialidades_search = data;
      },
      (error) => {
        //this.manejador_errores(error);
        this._helper.error('', error);
      }
    );
  }

  //Guarda las especialidades que selecciona el usuario en un arrayList
  especialidad_form() {
    if (this.searchLab.value === null) {
      this._helper.modalAlert('¡No has escogido ninguna especialidad!');
    } else if (
      this.validar_especialidad(
        this.searchLab.value['id'],
        this.especialidades_array
      ) === true
    ) {
      this._helper.modalAlert('¡Esta especialidad ya ha sido seleccionada!');
    } else if (
      this.searchLab.value['id'] === undefined ||
      this.searchLab.value['id'] === null
    ) {
      this._helper.modalAlert('¡No se ha escogido alguna especialidad!');
    } else if (
      this.validar_medico_especialidad(this.searchLab.value['name']) === true
    ) {
      this._helper.modalAlert('¡Esta especialidad ya se encuentra agregada!');
    } else {
      this.especialidades_array.push({
        id: this.searchLab.value['id'],
        nombre: this.searchLab.value['name'],
      });
    }
  }

  //valida array de las especialidades que se van agregando
  validar_especialidad(id: number, array: any) {
    var sw = false;
    for (var _i = 0; _i < array.length; _i++) {
      if (array[_i]['id'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  //valida las especialidades de un medico
  validar_medico_especialidad(id: string) {
    var sw = false;
    for (var _i = 0; _i < this.especialidades.length; _i++) {
      if (this.especialidades[_i]['specialty_id__name'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  //metodo que elimina o agrega un elemento dentro de un array de especialidades
  eliminar_especialidad_actual(number: any, id: number) {
    var boton = '#name_' + number;
    if ($(boton).hasClass('btn update_especialidades')) {
      $(boton).removeClass('btn update_especialidades');
      $(boton).addClass('btn delete_especialidades');
      if (this.validar_array_eliminado(id) === false) {
        this.especialidades_array_delete.push({ id: id });
      }
    } else {
      $(boton).removeClass('btn delete_especialidades');
      $(boton).addClass('btn update_especialidades');
      this.especialidades_array_delete.splice(number - 1, 1);
    }
  }

  // metodo que evalua las especialidades que se van a eliminar en las actualializaciones
  validar_array_eliminado(id: number) {
    var sw = false;
    for (var _i = 0; _i < this.especialidades_array_delete.length; _i++) {
      if (this.especialidades_array_delete[_i]['id'] === id) {
        sw = true;
      }
    }
    return sw;
  }

  //metodo que elimina un elemento dentro de un array de especialidades
  eliminar_especialidad(number: number, array: any) {
    array.splice(number, 1);
  }

  //actualiza informacion del medico
  actualizar_medico(update: NgForm) {
    //this.dataLista=false;
    if (this.validators() != '') {
      this.dataLista = true;
      this._helper.modalError(this.validators());
    } else {
      this._medic
        .update_medic(
          this.id_medico,
          update.value['nombre_update'],
          update.value['nombre2_update'],
          update.value['apellido_update'],
          update.value['apellido2_update'],
          update.value['correo_update'],
          update.value['matricula_update'],
          update.value['celular_update'],
          update.value['dni_update'],
          this.estado2,
          this.especialidades_array,
          this.especialidades_array_delete
        )
        .subscribe(
          (data) => {
            this.dataLista = true;
            this.list_seguimiento(this.p);
            $('#ver_medico').modal('hide');
          },
          (error) => {
            this.dataLista = true;
            this._helper.modalError('¡' + error.error + '!');
          }
        );
    }
  }

  // Validacion de datos para las actualizaciones y creaciones de medicos
  validators() {
    var create_medic = [];
    create_medic.push(
      {
        tipo: 'text',
        nombre: 'NOMBRE',
        valor: this.nombre_update,
        requerido: 'si',
        min_length: 2,
      },
      {
        tipo: 'text',
        nombre: 'APELLIDO',
        valor: this.apellido_update,
        requerido: 'si',
        min_length: 2,
      },
      {
        tipo: 'text',
        nombre: 'APELLIDO 2',
        valor: this.apellido2_update,
        requerido: 'no',
        min_length: 2,
      },
      {
        tipo: 'phone',
        nombre: 'CELULAR',
        valor: this.celular_update,
        requerido: 'si',
        min_length: 10,
      },
      {
        tipo: 'email',
        nombre: 'CORREO',
        valor: this.correo_update,
        requerido: 'si',
        min_length: 6,
      },
      {
        tipo: 'number',
        nombre: 'DNI',
        valor: this.dni_update,
        requerido: 'si',
        min_length: 5,
      },
      {
        tipo: 'alpha',
        nombre: 'LICENCIA',
        valor: this.matricula_update,
        requerido: 'si',
        min_length: 5,
      },
      {
        tipo: 'select',
        nombre: 'ESTADO',
        valor: this.estado2,
        requerido: 'si',
        min_length: 1,
      }
    );
    return this._helper.validators(create_medic);
  }

  actualizar_lista(event) {
    console.log(event);
    if (event === '1') {
      this.list_seguimiento(this.p);
    }
  }

  /**
   * Función que abre el modal para actualizar los datos del médico
   * @param template template de actualizar médico
   */
  updateMedic(template: TemplateRef<any>) {
    this.updateMedicModalRef = this.modalService.show(
      template,
      this.updateMedicModalConfig
    );
  }
}
