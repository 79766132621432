import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { HelpersService } from '../../../shared/servicio/helpers/helpers.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-update-medic',
  templateUrl: './update-medic.component.html',
  styleUrls: ['./update-medic.component.css'],
})
export class UpdateMedicComponent implements OnInit {
  specialties: any;
  update: FormGroup;
  name: FormControl;
  name2: FormControl;
  email: FormControl;
  cell: FormControl;
  dni: FormControl;
  lastname: FormControl;
  lastname_2: FormControl;
  cellphone_validate: boolean;
  submitted = false;
  preloader: boolean = false;
  license: any;
  load: boolean = true;
  modalRef: BsModalRef;

  config = {
    animated: true,
    class: 'modal-dialog-centered modal-app modal-app-sm',
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    private _http: HttpClient,
    private formBuilder: FormBuilder,
    public helper: HelpersService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.info();
    this.updateMedic();
  }

  updateMedic() {
    this.update = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ ]*'),
        ],
      ],

      name2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ]*'),
        ],
      ],

      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ]*'),
        ],
      ],

      lastname_2: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern('[a-zA-ZáéíóúÁÉÍÓÚÑñ]*'),
        ],
      ],

      cell: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      dni: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
          Validators.pattern(/^\d+$/),
        ],
      ],

      email: [null, [Validators.required, Validators.email]],
    });
  }

  get shortUpdate() {
    return this.update.controls;
  }

  info() {
    this._http.get(environment.Api_info_user).subscribe(
      (data) => {
        this.load = false;
        console.log(data);
        this.specialties = data['specialties:'];
        this.shortUpdate.name.setValue(data['user_info']['first_name']);
        this.shortUpdate.name2.setValue(data['user_info']['first_name2']);
        this.shortUpdate.lastname.setValue(data['user_info']['last_name']);
        this.shortUpdate.lastname_2.setValue(data['user_info']['last_name2']);
        this.shortUpdate.email.setValue(data['user_info']['email']);
        this.shortUpdate.cell.setValue(data['user_info']['cell']);
        this.shortUpdate.dni.setValue(data['user_info']['dni']);
        this.cellphone_validate = data['cellphone_validated'];
        this.license = data['medic_info']['profesional_license'];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  updateMedicP() {
    console.log(this.update);
    this.preloader = true;
    if (this.update.valid) {
      let first_name1 = this.shortUpdate.name.value;
      let first_name2 = this.shortUpdate.name2.value;
      let last_name1 = this.shortUpdate.lastname.value;
      let last_name2 = this.shortUpdate.lastname_2.value;
      let email = this.shortUpdate.email.value;
      let cell = this.shortUpdate.cell.value;
      let dni = this.shortUpdate.dni.value;
      let country_code = 57;
      let profesional_license = this.license;

      this._http
        .post(environment.Api_info_user, {
          first_name1,
          first_name2,
          last_name1,
          last_name2,
          cell,
          email,
          dni,
          country_code,
          profesional_license,
        })
        .subscribe(
          (data) => {
            this.preloader = false;
            this.submitted = false;
            this.info();
            this.helper.modalSuccess('¡Datos actualizados correctamente!');
            console.log(data);
          },
          (error) => {
            console.log(error);
            this.preloader = false;
            this.submitted = false;
            this.helper.modalError(
              this.helper.splitErrorMessages(error.error, '<br>')
            );
          }
        );
    } else {
      this.submitted = true;
      this.preloader = false;
    }
  }

  validateCell(template: TemplateRef<any>) {
    this._http.post(environment.api_verified_verify, {}).subscribe(
      (data) => {
        this.helper.modalInfo(
          '¡Te hemos enviado un código a tu nuevo numero celular!'
        );
      },
      (error) => {
        this.helper.modalError(
          this.helper.splitErrorMessages(error.error, '<br>')
        );
        console.log(error);
      }
    );

    this.modalRef = this.modalService.show(template, this.config);
  }

  verifiedSuccess($event) {
    if ($event === true) {
      this.modalRef.hide();
      this.info();
    }
  }
}
